import AButton from "aloha-vue/src/AButton/AButton";
import ModalCreateOrUpdate from "./ModalCreateOrUpdate/ModalCreateOrUpdate.vue";
import UiAntragstellerRechtsformZusatzReadOnlyElement from "./UiAntragstellerRechtsformZusatzReadOnly/UiAntragstellerRechtsformZusatzReadOnlyElement/UiAntragstellerRechtsformZusatzReadOnlyElement.vue";

import translate from "../../../../../global/directives/translate";

import HttpMixin from "../../../../../global/mixins/HttpMixin";
import UIComponentMixin from "../../../../../global/components/ui/UIComponentMixin";

import {
  cloneDeep,
  isNil,
  omit,
  orderBy,
  reduce,
  size,
  isArray,
} from "lodash-es";

// @vue/component
export default {
  name: "UiAntragstellerRechtsformZusatz",
  components: {
    AButton,
    ModalCreateOrUpdate,
    UiAntragstellerRechtsformZusatzReadOnlyElement,
  },
  directives: {
    translate,
  },
  mixins: [
    HttpMixin,
    UIComponentMixin,
  ],
  props: {
    model: {
      type: Array,
      required: true,
    },
    errors: {
      type: [Array, Object],
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      loading: true,
      katalogLoading: {
        krechtsform: true,
        elementfoerderregel: true
      },
      katalogData: {
        krechtsform: [],
        elementfoerderregel: []
      },
      confirmOptions: undefined,
      currentIndex: undefined,
      statusModal: {
        confirm: false,
        createEdit: false
      },
    };
  },
  computed: {
    modelSize() {
      return size(this.model);
    },

    modelList() {
      return orderBy(this.model, ["pos"]);
    },

    currentModalHeader() {
      return {
        text: (!isNil(this.currentIndex) ? "_MSG_PARAM_RECHTSFORM_ZUSATZ_ELEMENT_MODAL_EDIT_TITLE_" : "_MSG_PARAM_RECHTSFORM_ZUSATZ_ELEMENT_MODAL_ADD_TITLE_")
      };
    },

    translationEmptyMsg() {
      return { text: "_MSG_PARAM_RECHTSFORM_ZUSATZ_EMPTY_" };
    },

    globalErrors() {
      if (!isArray(this.errors)) {
        return omit(this.errors, ["id", "label", "rechtsformen", "regeln"]);
      }
      return undefined;
    },
  },
  beforeCreate() {
    this.$options.components.Modal = require("../../../../../global/components/Modal/Modal.vue").default;
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loadKatalogData("krechtsform");
      this.loadKatalogData("elementfoerderregel");
    },

    loadKatalogData(key) {
      this.katalogLoading[key] = true;
      this.getListHttp({
        url: `katalog/`,
        urlParams: {
          key: key,
        },
      }).then(
        response => {
          this.katalogData[key] = response;
        }
      ).finally(() => {
        this.katalogLoading[key] = false;
        this.loading = reduce(this.katalogLoading, (val, el) => (val || el), false);
      });
    },

    openModalCreate() {
      this.currentModel = undefined;
      this.currentIndex = undefined;
      this.statusModal.createEdit = true;
    },

    openModalUpdate(index) {
      this.currentIndex = index;
      this.currentModel = this.modelList[index];
      this.statusModal.createEdit = true;
    },

    closeModal({ model } = {}) {
      if (model) {
        this.onInput_mixin({ value: model });
      }
      this.statusModal.createEdit = false;
      this.currentModel = undefined;
      this.currentIndex = undefined;
    },

    moveUpZweig(index) {
      if (index === 0) {
        return;
      }
      const MODEL = cloneDeep(this.model);

      const ELEM = MODEL[index];
      MODEL.splice(index, 1);
      MODEL.splice(index - 1, 0, ELEM);
      this.onInput_mixin({ value: MODEL });
    },

    moveDownZweig(index) {
      if (index >= this.model.length - 1) {
        return;
      }
      const MODEL = cloneDeep(this.model);
      const ELEM = MODEL[index];
      MODEL.splice(index, 1);
      MODEL.splice(index + 1, 0, ELEM);
      this.onInput_mixin({ value: MODEL });
    },

    openConfirmDelete(index) {
      this.confirmOptions = {
        okClass: "a_btn a_btn_primary",
        title: "_MSG_PARAM_RECHTSFORM_ZUSATZ_ELEMENT_MODAL_REMOVE_TITLE_",
        msg: `_MSG_PARAM_RECHTSFORM_ZUSATZ_ELEMENT_MODAL_REMOVE_BODY_`,
        okLabel: "_BTN_REMOVE_",
        okCallback: () => this.deleteZweig(index),
        cancelCallback: this.closeConfirmDelete,
        loading: false,
      };
      this.statusModal.confirm = true;
    },

    deleteZweig(index) {
      const MODEL = cloneDeep(this.model);
      MODEL.splice(index, 1);
      this.onInput_mixin({ value: MODEL });
      this.closeConfirmDelete();
    },

    closeConfirmDelete() {
      this.statusModal.confirm = false;
    },
  },
};
