import UiTreeReadOnlyGroup from "../UiTreeReadOnlyGroup/UiTreeReadOnlyGroup.vue";
import UIReadOnlyMixin from "../../UIReadOnlyMixin";

import UiTreeAPI from "../compositionAPI/UiTreeAPI";

// @vue/component
export default {
  name: "UiTree",
  components: {
    UiTreeReadOnlyGroup,
  },
  mixins: [
    UIReadOnlyMixin,
  ],
  props: {
    model: {
      type: [Object, Array],
      required: false,
      default: undefined,
    },
  },
  setup(props) {
    const {
      fieldsLocal,
      isArrayLocal,
      modelLocal,
    } = UiTreeAPI(props);

    return {
      fieldsLocal,
      isArrayLocal,
      modelLocal,
    };
  },
  beforeCreate() {
    this.$options.components.FormElementReadOnly = require("../../../FormElement/FormElementReadOnly/FormElementReadOnly.vue").default;
  },
};
