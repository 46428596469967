import HttpMixin from "./HttpMixin";
import { createNamespacedHelpers } from "vuex";
const {
  mapGetters,
  mapMutations,
} = createNamespacedHelpers("profile");

import {
  cloneDeep,
} from "lodash-es";

export default {
  mixins: [
    HttpMixin,
  ],
  computed: {
    me() {
      return this.GET_ME ? cloneDeep(this.GET_ME) : undefined;
    },
    ...mapGetters([
      "GET_ME",
    ]),
  },
  methods: {
    getUser() {
      window.userPromise = new Promise((resolve, reject) => {
        if (this.me) {
          return resolve(this.me);
        }
        this.getHttp({ url: "profil/" }).then(
          response => {
            this.MUT_SET_ME(cloneDeep(response));
            return resolve(response);
          },
          error => {
            return reject(error);
          }
        );
      });
      return window.userPromise;
    },

    ...mapMutations([
      "MUT_SET_ME",
    ]),
  },
};
