import UiValidatedJsonReadOnly from "../../UiValidatedJson/UiValidatedJsonReadOnly/UiValidatedJsonReadOnly.vue";

import UIReadOnlyMixin from "../../UIReadOnlyMixin";

import OptionsAPI from "../compositionAPI/OptionsAPI";


// @vue/component
export default {
  name: "UiRandomStringPartsReadOnly",
  components: {
    UiValidatedJsonReadOnly,
  },
  mixins: [
    UIReadOnlyMixin,
  ],
  setup() {
    const {
      jsonOptions,
    } = OptionsAPI();

    return {
      jsonOptions,
    };
  },
};
