import AButton from "aloha-vue/src/AButton/AButton";

import FocusAPI from "./compositionAPI/FocusAPI";

// @vue/component
export default {
  name: "TheMenuJump",
  components: {
    AButton,
  },
  setup() {
    const {
      setFocusTo,
    } = FocusAPI();

    return {
      setFocusTo,
    };
  },
};
