import {
  syConfig,
} from "../compositionAPI/SyConfigAPI";

import {
  isNil,
} from "lodash-es";


export default {
  methods: {
    getSyConfigsValue(value, defaultValue) {
      const SY_CONFIG_VALUE = syConfig.value?.[value];

      return isNil(SY_CONFIG_VALUE) ? defaultValue : SY_CONFIG_VALUE;
    },
  },
};
