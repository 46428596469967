const PublicUmfragePage = () => import(/* webpackChunkName: "PublicUmfragePage" */ "../../views/Umfragen/PublicUmfragePage/PublicUmfragePage.vue");
const QrCodeInvalidPage = () => import(/* webpackChunkName: "QrCodeInvalidPage" */ "../../views/QrCode/QrCodeInvalidPage/QrCodeInvalidPage.vue");
const QrCodeList = () => import(/* webpackChunkName: "QrCodeList" */ "../../views/QrCode/QrCodeList/QrCodeList.vue");
const UmfragenDetails = () => import(/* webpackChunkName: "UmfragenDetails" */ "../../views/Umfragen/UmfragenDetails/UmfragenDetails.vue");
const UmfragenList = () => import(/* webpackChunkName: "UmfragenList" */ "../../views/Umfragen/UmfragenList/UmfragenList.vue");
const UmfrageplaeneDetails = () => import(/* webpackChunkName: "UmfrageplaeneDetails" */ "../../views/Umfragen/UmfrageplaeneDetails/UmfrageplaeneDetails.vue");
const UmfrageplaeneList = () => import(/* webpackChunkName: "UmfrageplaeneList" */ "../../views/Umfragen/UmfrageplaeneList/UmfrageplaeneList.vue");
const UmfrageversionVorschau = () => import(/* webpackChunkName: "UmfrageversionVorschau" */ "../../views/Umfragen/UmfrageversionVorschau/UmfrageversionVorschau.vue");

export default [
  {
    path: "/umfragen/",
    name: "root.umfragen",
    component: UmfragenList,
    meta: {
      permissions: [
        "umfrage.ui",
      ],
    },
  },
  {
    path: "/umfragen/:id/",
    name: "root.umfragen.details",
    component: UmfragenDetails,
    meta: {
      permissions: [
        "umfrage.ui",
      ],
    },
  },
  {
    path: "/umfragen/:id/vorschau/",
    name: "root.umfragen.vorschau",
    component: UmfrageversionVorschau,
    meta: {
      permissions: [
        "umfrage.ui",
      ],
    },
  },
  {
    path: "/umfrageplaene/",
    name: "root.umfrageplaene",
    component: UmfrageplaeneList,
    meta: {
      permissions: [
        "umfrageplan.ui",
      ],
    },
  },
  {
    path: "/umfrageplaene/:id/",
    name: "root.umfrageplaene.details",
    component: UmfrageplaeneDetails,
    meta: {
      permissions: [
        "umfrageplan.ui",
      ],
    },
  },
  {
    path: "/feedback/:kennung/",
    name: "root.oeffentliche_umfrage",
    component: PublicUmfragePage,
  },
  {
    path: "/qrcodes/",
    name: "root.qrcodes",
    component: QrCodeList,
  },
  {
    path: "/qr_invalid/:qr_id/",
    name: "root.qrcodes-invalid",
    component: QrCodeInvalidPage,
  },
];
