import UIComponentMixin from "../UIComponentMixin";
import {
  isUndefined,
  isNil,
} from "lodash-es";

// @vue/component
export default {
  name: "UiRange",
  mixins: [
    UIComponentMixin,
  ],
  props: {
    model: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      statusDrug: undefined,
    };
  },
  computed: {
    getMin() {
      return isUndefined(this.options.min) ? 0 : this.options.min;
    },

    getMax() {
      return isUndefined(this.options.max) ? 10 : this.options.max;
    },

    getValue() {
      return isNil(this.model) ? 0 : this.model;
    },

    getPointsCount() {
      return this.getMax - this.getMin;
    },

    getScaleItems() {
      return this.getPointsCount + 1;
    },

    getStyleFill() {
      return `width: ${ this.getLeftKnob }%`;
    },

    getStyleKnob() {
      return `left: ${ this.getLeftKnob }%`;
    },

    getLeftKnob() {
      return this.getValue * 100 / this.getPointsCount;
    },
  },
  methods: {
    onClick(event) {
      if (this.isDisabled) {
        return;
      }
      const PERCENT = this.offsetByMouseInPercent(event);
      this.setValue({ percent: PERCENT });
    },

    dragStart() {
      if (this.isDisabled) {
        return;
      }
      this.statusDrug = true;
    },

    drag(event) {
      if (this.isDisabled) {
        return;
      }
      const PERCENT = this.offsetByMouseInPercent(event);
      this.setValue({ percent: PERCENT });
    },

    dragEnd(event) {
      if (this.isDisabled) {
        return;
      }
      const PERCENT = this.offsetByMouseInPercent(event);
      this.setValue({ percent: PERCENT });
      this.statusDrug = false;
    },

    relativeMouseOffset(offset, base) {
      const BOUNDS = base.getBoundingClientRect();
      const LEFT = offset.clientX - BOUNDS.left;
      if (LEFT < 0) {
        return 0;
      }
      if (LEFT > BOUNDS.width) {
        return 100;
      }
      return LEFT / BOUNDS.width * 100;
    },

    offsetByMouseInPercent(event) {
      return this.relativeMouseOffset(event, this.$el);
    },

    setValue({ percent }) {
      const VALUE = Math.round(percent * this.getPointsCount / 100);
      if (VALUE !== this.model) {
        this.onInput({ value: VALUE });
      }
    },

    onInput({ value }) {
      this.onInput_mixin({ value });
    },
  },
};
