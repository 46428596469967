import {
  gettext,
  isTranslatePlaceholder,
  replaceText,
} from "../functions/utils";

import {
  addNotification as addNotificationFromAloha,
} from "aloha-vue/src/compositionAPI/ANotificationAPI";

export default function NotificationAPI() {
  return {
    addNotification,
  };
}

export function addNotification({ text, type = "success", timeout, extra }) {
  let textLocal = text;
  const TYPE = type === "error" ? "danger" : type;
  if (isTranslatePlaceholder(text)) {
    textLocal = replaceText({ text: gettext(textLocal), object: extra });
  }
  addNotificationFromAloha({
    text: textLocal, type: TYPE, timeout,
  });
}
