import {
  computed,
  toRef,
} from "vue";

import {
  errorLabelGlobalTypes,
  toFormElementFromParameter,
} from "../../../functions/mappingForParameterToFormElement";
import {
  forEach,
  isNil,
  isUndefined,
} from "lodash-es";

export default function LabelsAPI(props) {
  const idPrefix = toRef(props, "idPrefix");
  const labels = toRef(props, "labels");
  const optionsList = toRef(props, "optionsList");

  const addOptionsReplaceLabel = ({ options, labelsFromList }) => {
    if (errorLabelGlobalTypes[options.type]) {
      labelsFromList[options.id]._shouldReplaceLabel = true;
      labelsFromList[options.id]._optionsLabel = options;
    }
    return labelsFromList;
  };

  const setId = ({ options, isChild }) => {
    if (options.htmlId) {
      return options.htmlId;
    }
    const ID_SUFFIX = options.attrId || options.key || options.id;
    if (isChild) {
      return `${ ID_SUFFIX }`;
    }
    return `${ idPrefix.value }${ ID_SUFFIX }`;
  };

  const addSubOptions = ({ parent_labels, options }) => {
    let fields = options.fields;
    let hasElementErrors = false;
    let isErrorLink = true;
    if (!fields && options.formFields) {
      fields = options.formFields?.base;
      // TODO: options.formFields.typed;
      hasElementErrors = true;
      isErrorLink = false;
    }

    if (!isNil(fields)) {
      const CURRENT_LABEL_OPTIONS = parent_labels[options.id];
      const SUB_OPTIONS = toFormElementFromParameter({
        obj: fields,
      }).list;

      forEach(SUB_OPTIONS, opt => {
        CURRENT_LABEL_OPTIONS[opt.id] = {
          _label: opt.label,
          _hasElementErrors: hasElementErrors || !!opt.element_errors,
          _usePureIdForLink: opt.usePureIdForLink,
        };
        if (isErrorLink) {
          CURRENT_LABEL_OPTIONS[opt.id]._link = !isUndefined(opt.isErrorLink) ? opt.isErrorLink : !options.element_errors;
        } else {
          CURRENT_LABEL_OPTIONS[opt.id]._link = false;
        }
        if (opt.usePureIdForLink) {
          CURRENT_LABEL_OPTIONS[opt.id]._id = `${ CURRENT_LABEL_OPTIONS._id }_${ setId({ options: opt, isChild: true }) }`;
        } else {
          CURRENT_LABEL_OPTIONS[opt.id]._id = setId({ options: opt, isChild: true });
        }
        addOptionsReplaceLabel({ options: opt, labelsFromList: CURRENT_LABEL_OPTIONS });

        addSubOptions({
          parent_labels: CURRENT_LABEL_OPTIONS,
          options: opt,
        });
      });
    }
  };

  const labelsFromOptionsList = computed(() => {
    const LABELS_FROM_OPTIONS_LIST = {};
    forEach(optionsList.value, options => {
      LABELS_FROM_OPTIONS_LIST[options.id] = {
        _label: options.label,
        _link: !isUndefined(options.isErrorLink) ? options.isErrorLink : true,
        _id: setId({ options, isChild: false }),
        _hasElementErrors: !!options.element_errors || !!options.formFields,
      };
      addOptionsReplaceLabel({ options, labelsFromList: LABELS_FROM_OPTIONS_LIST });

      addSubOptions({
        parent_labels: LABELS_FROM_OPTIONS_LIST,
        options,
      });
    });

    return LABELS_FROM_OPTIONS_LIST;
  });

  const labelFiltered = computed(() => {
    const LABELS = {};
    forEach(labels.value, (labelObject, key) => {
      LABELS[key] = {
        _label: labelObject.label,
        _link: labelObject.link,
        _id: labelObject.id,
      };
    });

    return LABELS;
  });

  const labelsLocal = computed(() => {
    return Object.assign({}, labelFiltered.value, labelsFromOptionsList.value);
  });

  return {
    labelsLocal,
  };
}
