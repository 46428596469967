import AngularLink from "../AngularLink/AngularLink.vue";
import TooltipContent from "./TooltipContent/TooltipContent.vue";

import TooltipMixin from "./TooltipMixin";

const TIME = new Date().getTime();

// @vue/component
export default {
  name: "PuxTooltip",
  components: {
    AngularLink,
    TooltipContent,
  },
  mixins: [
    TooltipMixin,
  ],
  props: {
    id: {
      type: String,
      required: false,
      default: () => `tooltip_${ TIME }`,
      info: "HTML-Attribut 'id'",
    },
    tooltipTag: {
      type: String,
      required: false,
      default: "span",
      info: "Parent-tag",
    },
    tagAttributes: {
      type: Object,
      required: false,
      default: () => ({}),
      info: "Extra Attributes für Parent Tag"
    },
    tooltipPlacement: {
      type: String,
      required: false,
      default: "top",
      validator: value => ["top", "left", "bottom", "right"].indexOf(value) !== -1,
      info: "Standardrichtung für Tooltip",
    },
    tooltipHide: {
      type: Boolean,
      required: false,
      info: "Tooltip nicht anzeigen",
    },
    classForTooltipInner: {
      type: [String, Object],
      default: undefined,
      required: false,
    },
  },
  computed: {
    idForTooltipContent() {
      return `${ this.id }_tooltip_content`;
    },
  },
  methods: {
    mouseenterLocal() {
      if (this.tooltipHide) {
        return;
      }
      this.showTooltip();
    },

    mouseleaveLocal() {
      this.closeTooltipWithTimer();
    },

    onFocus() {
      if (this.tooltipHide) {
        return;
      }
      this.showTooltip();
    },

    onBlur() {
      this.closeTooltip();
    },
  },
};
