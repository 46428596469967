import GlobalOptions from "../../../const/GlobalOptions";
import translate from "../../../directives/translate";
import UIComponentMixin from "../UIComponentMixin";

// @vue/component
export default {
  name: "UiAutomatic",
  directives: {
    translate,
  },
  mixins: [
    UIComponentMixin,
  ],
  data() {
    return {
      UiInputIcon: GlobalOptions.UiInputIcon,
    };
  },
  computed: {
    translationMsgMathExpression() {
      return { text: "_MSG_UI_AUTOMATIC_EDIT_MATH_EXPRESSION_" };
    },

    translationMsgDecimalSignal() {
      return { text: "_MSG_UI_AUTOMATIC_EDIT_DECIMAL_SIGNAL_" };
    },

    translationMsgDefault() {
      return { text: "_MSG_UI_AUTOMATIC_EDIT_DEFAULT_" };
    },
  },
  methods: {
    getTranslation() {
      if (this.options.type === "math_expression") {
        return this.translationMsgMathExpression;
      } else if (this.options.type === "decimal_signal") {
        return this.translationMsgDecimalSignal;
      }
      return this.translationMsgDefault;
    },
  },
};
