// @vue/component
export default {
  name: "UiAnzahlBetreuerFerienReadOnly",
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    className: {
      type: String,
      default: "",
    },
    model: {
      type: Array,
      default: undefined,
    },
  },
};
