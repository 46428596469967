import UIComponentMixin from "../../../../../global/components/ui/UIComponentMixin";

// @vue/component
export default {
  name: "UiAnzahlBetreuerFerien",
  mixins: [
    UIComponentMixin,
  ],
  props: {
    model: {
      type: Array,
      required: true,
      validator: model => {
        return model && model.length === 3;
      },
    },
    errors: {
      type: [Array, Object],
      default: undefined,
    },
  },
  data() {
    return {
      optionsTeilnehmer: {
        type: "text",
        id: "teilnehmer",
        label: "Label für TeilnehmehmerInnen",
        translate: true,
        view: "v",
        required: true,
      },
      optionsTeilnehmer_mit_behinderung: {
        type: "text",
        id: "teilnehmer_mit_behinderung",
        label: "Label für Teilnehmer mit BetreuerInnen",
        translate: true,
        view: "v",
        required: true,
      },
      optionsBetreuer: {
        type: "text",
        id: "betreuer",
        label: "Label für BetreuerInnen",
        translate: true,
        view: "v",
        required: true,
      },
    };
  },
  beforeCreate() {
    this.$options.components = this.$options.components || {};
    this.$options.components.FormElement = require("../../../../../global/components/FormElement/FormElement.vue").default;
  },
};
