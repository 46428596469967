import HttpMixin from "../../../../../global/mixins/HttpMixin";
import UIComponentMixin from "../../../../../global/components/ui/UIComponentMixin";
import UiValidatedJson from "../../../../../global/components/ui/UiValidatedJson/UiValidatedJson.vue";

import OptionsAPI from "./compositionAPI/OptionsAPI";

// @vue/component
export default {
  name: "UiAuszahlungsmodi",
  components: {
    UiValidatedJson,
  },
  mixins: [
    HttpMixin,
    UIComponentMixin,
  ],
  setup() {
    const {
      jsonOptions
    } = OptionsAPI();

    return {
      jsonOptions,
    };
  }
};
