const GutscheinaktionDetails = () => import(/* webpackChunkName: "GutscheinaktionDetails" */ "../../views/Gutschein/GutscheinaktionDetails/GutscheinaktionDetails.vue");
const GutscheinaktionList = () => import(/* webpackChunkName: "GutscheingeneratorDetails" */ "../../views/Gutschein/GutscheinaktionList/GutscheinaktionList.vue");
const GutscheinaktionSeiteDetails = () => import(/* webpackChunkName: "GutscheinaktionDetails" */ "../../views/Gutschein/GutscheinaktionSeiteDetails/GutscheinaktionSeiteDetails.vue");
const GutscheinaktionSeiteZugriff = () => import(/* webpackChunkName: "GutscheinaktionDetails" */ "../../views/Gutschein/GutscheinaktionSeiteZugriff/GutscheinaktionSeiteZugriff.vue");
const GutscheinDetails = () => import(/* webpackChunkName: "GutscheinDetails" */ "../../views/Gutschein/GutscheinDetails/GutscheinDetails.vue");
const GutscheingeneratorDetails = () => import(/* webpackChunkName: "GutscheingeneratorDetails" */ "../../views/Gutscheingenerator/GutscheingeneratorDetails/GutscheingeneratorDetails.vue");
const GutscheingeneratorList = () => import(/* webpackChunkName: "GutscheingeneratorList" */ "../../views/Gutscheingenerator/GutscheingeneratorList/GutscheingeneratorList.vue");
const GutscheinList = () => import(/* webpackChunkName: "GutscheinList" */ "../../views/Gutschein/GutscheinList/GutscheinList.vue");

export default [
  {
    path: "/gutscheine/",
    name: "root.gutscheine",
    component: GutscheinList,
  },
  {
    path: "/gutscheine/:id/",
    name: "root.gutscheine.details",
    component: GutscheinDetails,
  },
  {
    path: "/gutscheingeneratoren/",
    name: "root.gutscheingeneratoren",
    component: GutscheingeneratorList,
  },
  {
    path: "/gutscheingeneratoren/:id/",
    name: "root.gutscheingeneratoren.details",
    component: GutscheingeneratorDetails,
  },
  {
    path: "/gutscheinaktionen/",
    name: "root.gutscheinaktionen",
    component: GutscheinaktionList,
    meta: {
      permissions: [
        "gutscheinaktion.ui",
      ],
    },
  },
  {
    path: "/gutscheinaktionen/:id/",
    name: "root.gutscheinaktionen.details",
    component: GutscheinaktionDetails,
    meta: {
      permissions: [
        "gutscheinaktion.ui",
      ],
    },
  },
  {
    path: "/gutscheinaktionen/:id/seiten/:guas_id/",
    name: "root.gutscheinaktionen.details.seite",
    component: GutscheinaktionSeiteDetails,
    meta: {
      permissions: [
        "gutscheinaktionseite.ui",
      ],
    },
  },
  {
    path: "/gutscheinaktion/:gua_kuerzel/",
    name: "root.gutscheinaktionenseitezugriff",
    component: GutscheinaktionSeiteZugriff,
  },
];
