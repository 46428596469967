const GalerietypDetails = () => import(/* webpackChunkName: "GalerietypDetails" */ "../../views/Galerie/GalerietypDetails/GalerietypDetails.vue");
const GalerietypList = () => import(/* webpackChunkName: "GalerietypList" */ "../../views/Galerie/GalerietypList/GalerietypList.vue");

export default [
  {
    path: "/galerietypen/",
    name: "root.galerietypen",
    component: GalerietypList,
    meta: {
      permissions: [
        "galerietyp.ui",
      ],
    },
  },
  {
    path: "/galerietypen/:id/",
    name: "root.galerietypen.details",
    component: GalerietypDetails,
    meta: {
      permissions: [
        "galerietyp.ui",
      ],
    },
  },
];
