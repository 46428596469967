import ASpinner from "aloha-vue/src/ASpinner/ASpinner";
import PuxTranslate from "../PuxTranslate/PuxTranslate.vue";

// @vue/component
export default {
  name: "PuxCloak",
  components: {
    ASpinner,
    PuxTranslate,
  },
  props: {
    align: {
      type: String,
      required: false,
      default: "center",
      validator: value => ["center", "left", "right"].indexOf(value) !== -1,
      info: "Ausrichtung in Komponente",
    },
    extra: {
      type: Object,
      required: false,
      default: undefined,
      info: "Extra-Objekt für Übersetzung",
    },
    left: {
      type: Boolean,
      required: false,
      info: "Richtung, wo Text angezeigt wird",
    },
    msg: {
      type: String,
      required: false,
      default: "Wird geladen",
      info: "Text für Loading",
    },
    size: {
      type: String,
      required: false,
      default: "xl",
      validator: value => ["xs", "sm", "md", "lg", "xl"].indexOf(value) !== -1,
      info: "Schriftgröße",
    },
  },
  computed: {
    alignLocal() {
      return `a_text_${ this.align }`;
    },

    messageLocal() {
      return this.msg === undefined ? "_TXT_LOADING_" : this.msg;
    },
  },
};
