import UiDynamischeTabelle from "../../DynamischeTabelle/UiDynamischeTabelle/UiDynamischeTabelle.js";
import ModalCreateOrUpdate from "./ModalCreateOrUpdate/ModalCreateOrUpdate.vue";

// @vue/component
export default {
  name: "UiGutscheineErfassen",
  components: {
    ModalCreateOrUpdate,
  },
  mixins: [
    UiDynamischeTabelle,
  ],
};
