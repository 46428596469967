export default {
  home: "/",
  impressum: "/impressum/",
  datenschutz: "/datenschutz/",
  kontakt: "/kontakt/",
  hilfen: "/hilfe/",
  nutzerprofil: "/profil/",
  login: "/login/",
  barrierefreiheit: "/barrierefreiheit/",
};
