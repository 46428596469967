import {
  toRef,
} from "vue";

export default function EventsAPI(props) {
  const blur = toRef(props, "blur");
  const change = toRef(props, "change");
  const focus = toRef(props, "focus");
  const input = toRef(props, "input");
  const model = toRef(props, "model");
  const options = toRef(props, "options");

  const onInputLocal = data => {
    input.value({
      model: data,
      currentModel: data,
      id: options.value.id,
      param: options.value.param,
      options: options.value,
    });
    change.value({
      model: data,
      currentModel: data,
      id: options.value.id,
      param: options.value.param,
      options: options.value,
    });
  };

  const onBlurLocal = data => {
    blur.value({
      id: options.value.id,
      model: model.value,
      $event: data.event,
      param: options.value.param,
      options: options.value,
    });
  };

  const onFocusLocal = data => {
    focus.value({
      id: options.value.id,
      model: model.value,
      $event: data.event,
      param: options.value.param,
      options: options.value,
    });
  };

  return {
    onBlurLocal,
    onFocusLocal,
    onInputLocal,
  };
}
