// @vue/component
export default {
  data() {
    return {
      fields: [
        {
          key: "adr_name",
          label: "_TXT_ADRESSEN_PARAMETER_NAME_",
        },
        {
          key: "adr_namezusatz",
          label: "_TXT_ADRESSEN_PARAMETER_NAMENZUSATZ_",
        },
        {
          key: "adr_namezusatz2",
          label: "_TXT_ADRESSEN_PARAMETER_NAMENZUSATZ2_",
        },
        {
          key: "adr_namezusatz3",
          label: "_TXT_ADRESSEN_PARAMETER_NAMENZUSATZ3_",
        },
        {
          key: "adr_adresszusatz",
          label: "_TXT_ADRESSEN_PARAMETER_ADRESSZUSATZ_",
        },
        {
          key: "adr_postfach",
          label: "_TXT_ADRESSEN_PARAMETER_POSTFACH_",
        },
        {
          key: "adr_strasse",
          label: "_TXT_ADRESSEN_PARAMETER_STRASSE_",
        },
        {
          key: "adr_hsnr",
          label: "_TXT_ADRESSEN_PARAMETER_HAUSNUMMER_",
        },
        {
          key: "adr_plz",
          label: "_TXT_ADRESSEN_PARAMETER_POSTLEITZAHL_",
        },
        {
          key: "adr_ort",
          label: "_TXT_ADRESSEN_PARAMETER_ORT_",
        },
        {
          key: "bundesland",
          label: "_TXT_ADRESSEN_PARAMETER_BUNDESLAND_",
        },
        {
          key: "land",
          label: "_TXT_ADRESSEN_PARAMETER_LAND_",
        },
        {
          key: "kontinent",
          label: "_TXT_ADRESSEN_PARAMETER_KONTINENT_",
        },
        {
          key: "adr_geokoord",
          label: "_TXT_ADRESSEN_PARAMETER_GEOKOORD_",
        },
        {
          key: "adr_bemerkung",
          label: "_TXT_ADRESSEN_PARAMETER_BEMERKUNG_",
        },
      ],
    };
  },
};
