import translate from "../../../../../../global/directives/translate";

// @vue/component
export default {
  name: "UiAdressenTr",
  directives: {
    translate,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    currentModel: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    errors: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      default: undefined,
    },
  },
  emits: [
    "setModel",
  ],
  computed: {
    currentModelLocal() {
      return this.currentModel || {};
    },

    statusCheckedForShow() {
      return !!this.currentModel.show;
    },

    ariaCheckedForShow() {
      return `${ this.statusCheckedForShow }`;
    },

    idForShow() {
      return `checkbox_show_${ this.data.key }`;
    },

    statusCheckedForRequired() {
      return !!this.currentModel.required;
    },

    ariaCheckedForRequired() {
      return `${ this.statusCheckedForRequired }`;
    },

    idForRequired() {
      return `checkbox_required_${ this.data.key }`;
    },

    optionsLabelEditonly() {
      return {
        type: "text",
        attributesHtml: {
          "aria-describedby": "gr_adressen_label_editonly",
        },
        id: "label_editonly",
        htmlId: `${ this.data.key }_label_editonly`,
      };
    },

    optionsLabelReadonly() {
      return {
        type: "text",
        attributesHtml: {
          "aria-describedby": "gr_adressen_label_readonly",
        },
        id: "label_readonly",
        htmlId: `${ this.data.key }_label_readonly`,
      };
    },

    errorsLocal() {
      return this.errors || {};
    },
  },
  beforeCreate() {
    this.$options.components = this.$options.components || {};
    this.$options.components.FormElement = require("../../../../../../global/components/FormElement/FormElement.vue").default;
  },
  methods: {
    setModelLocal($event, id, statusChecked) {
      this.$emit("setModel", { key: this.data.key, id, model: !statusChecked });
    },

    changeModelLabel({ id, model }) {
      this.$emit("setModel", { key: this.data.key, id, model });
    },
  },
};
