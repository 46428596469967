const NachrichtenDetails = () => import(/* webpackChunkName: "NachrichtenDetails" */ "../../views/Nachrichten/NachrichtenDetails/NachrichtenDetails.vue");
const NachrichtenInboxList = () => import(/* webpackChunkName: "NachrichtenInboxList" */ "../../views/Nachrichten/NachrichtenInboxList/NachrichtenInboxList.vue");
const NachrichtenOutboxList = () => import(/* webpackChunkName: "NachrichtenOutboxList" */ "../../views/Nachrichten/NachrichtenOutboxList/NachrichtenOutboxList.vue");

export default [
  {
    path: "/benachrichtigungen/",
    name: "root.benachrichtigungen",
    component: NachrichtenInboxList,
    meta: {
      permissions: [
        "benachrichtigungen.view",
      ],
    },
  },
  {
    path: "/postausgang/",
    name: "root.benachrichtigungen_out",
    component: NachrichtenOutboxList,
    meta: {
      permissions: [
        "benachrichtigungen.view",
      ],
    },
  },
  {
    path: "/benachrichtigungen/:id/",
    name: "root.benachrichtigungen.detail",
    component: NachrichtenDetails,
    meta: {
      permissions: [
        "benachrichtigungen.view",
      ],
    },
    props: {
      baseUrl: "inbox/",
      listUrl: "root.benachrichtigungen",
    },
  },
  {
    path: "/postausgang/:id/",
    name: "root.benachrichtigungen_out.detail",
    component: NachrichtenDetails,
    meta: {
      permissions: [
        "benachrichtigungen.view",
      ],
    },
    props: {
      baseUrl: "outbox/",
      listUrl: "root.benachrichtigungen_out",
    },
  },
];
