import AButton from "aloha-vue/src/AButton/AButton";
import PuxTranslate from "../../../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  assign,
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "UiAbschreibungsratenFormEdit",
  components: {
    AButton,
    PuxTranslate,
  },
  props: {
    modelCurrent: {
      type: Object,
      required: false,
      info: "Das Model bevor es bearbeitet wurde. Wird verwendet um Anfangsdaten zu setzen beim Aktualisieren.",
      default: () => ({})
    },
    close: {
      type: Function,
      required: true,
    },
    save: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      model: {
        obergrenze: null,
        abschreibungsrate: null,
        intervall: null,
      },
      options: {
        obergrenze: {
          type: "currency",
          id: "obergrenze",
          label: "Obergrenze",
          required: true,
          view: "v",
        },
        abschreibungsrate: {
          type: "float",
          id: "abschreibungsrate",
          label: "Abschreibungsrate",
          required: true,
          suffix: "%",
          view: "v",
        },
        intervall: {
          type: "float",
          id: "intervall",
          label: "Intervall (in Jahren)",
          required: true,
          suffix: "Jahre",
          view: "v",
        },
        label_abschreibung: {
          type: "text",
          id: "label_abschreibung",
          label: "Label für Abschreibung des Betrags ohne Einzelbelege",
          required: false,
          suffix: "",
          view: "v",
        },
        beleg_label_abschreibung: {
          type: "text",
          id: "beleg_label_abschreibung",
          label: "Label für Abschreibung des Betrags mit Einzelbelege",
          required: false,
          suffix: "",
          view: "v",
        },
        beleg_obergrenze: {
          type: "float",
          id: "beleg_obergrenze",
          label: "Obergrenze für den Betrag von Einzelbelegen",
          required: false,
          suffix: "",
          view: "v",
        },
        beleg_abschreibungsrate: {
          type: "float",
          id: "beleg_abschreibungsrate",
          label: "Abschreiberate des über Einzelbelegen ermittelten Betrag pro Jahr",
          required: false,
          suffix: "",
          view: "v",
        },
      },
    };
  },
  computed: {
    getHeader() {
      return this.modelCurrent ? "Abschreibungsrat aktualisieren" : "Abschreibungsrat hinzufügen";
    },
    isDisabled() {
      return !this.model.intervall || !this.model.abschreibungsrate || !this.model.obergrenze;
    },
  },
  beforeCreate() {
    this.$options.components.FormElement = require("../../../../../../../global/components/FormElement/FormElement.vue").default;
  },
  created() {
    this.initModel();
  },
  methods: {
    initModel() {
      if (this.modelCurrent) {
        this.model = assign(this.model, this.modelCurrent);
      }
    },

    saveLocal() {
      this.save({ model: cloneDeep(this.model) });
    },
  },
};
