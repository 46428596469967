import * as Sentry from "@sentry/vue";

export function initSentry({ app, router }) {
  if (window.RavenConfig && window.RavenConfig.dsn) {
    Sentry.init({
      app,
      dsn: window.RavenConfig.dsn,
      release: window.RavenConfig.config.release,
      environment: window.RavenConfig.environment,
      denyUrls: window.RavenConfig.config.ignoreUrls,
      integrations: [
        new Sentry.BrowserTracing({
          // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
          // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        // Disabling Promises rejection unhandled
        new Sentry.Integrations.GlobalHandlers({
          onunhandledrejection: false,
        }),
        new Sentry.Replay(),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.0,

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error

      replaysSessionSampleRate: 0.1,

      // https://docs.sentry.io/platforms/javascript/session-replay/understanding-sessions/
      replaysOnErrorSampleRate: 0,
    });
    Sentry.setTags(window.RavenConfig.config.tags);
  }
}
