import {
  computed,
  toRef,
} from "vue";

import FilterAllAPI from "../../../../compositionAPI/FilterAllAPI";

import {
  get,
  isNil,
} from "lodash-es";

export default function ValueAPI(props) {
  const column = toRef(props, "column");
  const row = toRef(props, "row");
  const rowIndex = toRef(props, "rowIndex");

  const {
    hasFilter,
    filterAll,
  } = FilterAllAPI();


  const getValue = computed(() => {
    if (column.value.templateCallback) {
      return column.value.templateCallback({
        row: row.value,
        rowIndex: rowIndex.value,
      });
    }
    if (column.value.filter && hasFilter({ filter: column.value.filter })) {
      return filterAll(get(row.value, column.value.id), {
        filter: column.value.filter,
        filterParameter: column.value.filterParameter,
      });
    }
    return get(row.value, column.value.id);
  });

  const valueAsString = computed(() => {
    return isNil(getValue.value) ? "" : `${ getValue.value }`;
  });

  return {
    valueAsString,
  };
}
