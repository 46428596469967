import UiFloatReadOnly from "../../UiFloat/UiFloatReadOnly/UiFloatReadOnly";

import AFiltersAPI from "aloha-vue/src/compositionAPI/AFiltersAPI";

// @vue/component
export default {
  name: "UiCurrencyReadOnly",
  mixins: [
    UiFloatReadOnly
  ],
  setup() {
    const {
      filterCurrency,
    } = AFiltersAPI();

    return {
      filterCurrency,
    };
  },
  computed: {
    suffixOption_UiFloatMixin() {
      return this.options.suffix || "€";
    },
  },
};
