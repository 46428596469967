import {
  computed,
  toRef,
} from "vue";

import {
  isNil,
} from "lodash-es";

export default function UiReadOnlyAPI(props) {
  const options = toRef(props, "options");
  const model = toRef(props, "model");
  const emptyText = computed(() => {
    return options.value.emptyText || "";
  });

  const isModelDefined = computed(() => {
    return !isNil(model.value) && model.value !== "";
  });

  return {
    emptyText,
    isModelDefined,
  };
}
