export default function ColorsAPI() {
  const padZero = (str, len) => {
    const _len = len || 2;
    const zeros = new Array(_len).join("0");

    return (zeros + str).slice(-_len);
  };

  const makeContrastColor = (hex, blackWhite) => {
    let _hex = hex;
    if (_hex.indexOf("#") === 0) {
      _hex = _hex.slice(1);
    }
    // convert 3-digit _hex to 6-digits.
    if (_hex.length === 3) {
      _hex = _hex[0] + _hex[0] + _hex[1] + _hex[1] + _hex[2] + _hex[2];
    }
    if (_hex.length !== 6) {
      throw new Error("Invalid HEX color.");
    }
    let r = parseInt(_hex.slice(0, 2), 16);
    let g = parseInt(_hex.slice(2, 4), 16);
    let b = parseInt(_hex.slice(4, 6), 16);
    if (blackWhite) {
      return (r * 0.299 + g * 0.587 + b * 0.114) > 186
        ? "#000000"
        : "#FFFFFF";
    }
    r = (255 - r).toString(16);
    g = (255 - g).toString(16);
    b = (255 - b).toString(16);

    return "#" + padZero(r) + padZero(g) + padZero(b);
  };

  return {
    makeContrastColor,
  };
}
