import {
  computed,
} from "vue";

import AMobileAPI from "aloha-vue/src/compositionAPI/AMobileAPI";

export default function MobileAPI() {
  const {
    isMobileWidth,
  } = AMobileAPI();

  const buttonClassDropdown = computed(() => {
    if (isMobileWidth.value) {
      return "a_btn a_btn_secondary";
    }
    return "panel_menu__link_dropdown dropdown-toggle";
  });

  const hasCaretDropdown = computed(() => {
    return !!isMobileWidth.value;
  });

  const triggersDropdown = computed(() => {
    if (isMobileWidth.value) {
      return ["click"];
    }
    return ["click", "hover"];
  });

  const buttonTag = computed(() => {
    return isMobileWidth.value ? "button" : "a";
  });

  const buttonAttributes = computed(() => {
    if (isMobileWidth.value) {
      return undefined;
    }
    return {
      role: "button",
      tabindex: 0,
    };
  });

  return {
    buttonAttributes,
    buttonClassDropdown,
    buttonTag,
    hasCaretDropdown,
    triggersDropdown,
  };
}
