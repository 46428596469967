import AButton from "aloha-vue/src/AButton/AButton";
import PuxIcon from "../../../PuxIcon/PuxIcon.vue";

import UiRatingMixin from "../UiRatingMixin";
import UIReadOnlyMixin from "../../UIReadOnlyMixin";

// @vue/component
export default {
  name: "UiRatingReadOnly",
  components: {
    AButton,
    PuxIcon,
  },
  mixins: [
    UiRatingMixin,
    UIReadOnlyMixin,
  ],
};
