import ADropdown from "aloha-vue/src/ADropdown/ADropdown";
import ASpinner from "aloha-vue/src/ASpinner/ASpinner";
import AngularLink from "../../global/components/AngularLink/AngularLink.vue";
import Permission from "../../global/components/Permission/Permission.vue";
import PuxIcon from "../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../global/components/PuxTranslate/PuxTranslate.vue";
import TheReleaseNotesModal from "../TheReleaseNotesModal/TheReleaseNotesModal.vue";

import ActionsAPI from "./compositionAPI/ActionsAPI";
import MobileAPI from "./compositionAPI/MobileAPI";
import UserAPI from "../../global/compositionAPI/UserAPI";

// @vue/component
export default {
  name: "TheMenuUser",
  components: {
    ADropdown,
    AngularLink,
    ASpinner,
    Permission,
    PuxIcon,
    PuxTranslate,
    TheReleaseNotesModal,
  },
  setup() {
    const {
      fullName,
      isAuthenticated,
    } = UserAPI();

    const {
      closeModal,
      dropdownActions,
      isModalOpen,
    } = ActionsAPI();

    const {
      buttonAttributes,
      buttonClassDropdown,
      buttonTag,
      hasCaretDropdown,
      triggersDropdown,
    } = MobileAPI();

    return {
      buttonAttributes,
      buttonClassDropdown,
      buttonTag,
      closeModal,
      dropdownActions,
      fullName,
      hasCaretDropdown,
      isAuthenticated,
      isModalOpen,
      triggersDropdown,
    };
  },
};
