import ASpinner from "aloha-vue/src/ASpinner/ASpinner";
import PuxIcon from "../../../../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../../../../../global/mixins/HttpMixin";

import {
  indexOf,
} from "lodash-es";

// @vue/component
export default {
  name: "UiDynamischeTabellePeriodenReadOnlyElement",
  components: {
    ASpinner,
    PuxIcon,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    model: {
      type: Object,
      required: true,
    },
    row: {
      type: Object,
      required: true,
    }
  },
  computed: {
    isPeriode() {
      return this.row.id === this.model.periode;
    },

    isAbhaengig() {
      return indexOf(this.model.abhaengige_spalten, this.row.id) !== -1;
    },

    isUnabhaengig() {
      return indexOf(this.model.unabhaengige_spalten, this.row.id) !== -1;
    },

    rowData() {
      return {
        unabhaengige_spalten: {
          id: "unabhaengige_spalten",
          icon: this.getIcon(this.isUnabhaengig),
        },
        periode: {
          id: "periode",
          icon: this.getIcon(this.isPeriode),
        },
        abhaengige_spalten: {
          id: "abhaengige_spalten",
          icon: this.getIcon(this.isAbhaengig),
        },
      };
    },
  },
  methods: {
    getIcon(bool) {
      return bool ? "glyphicon-ok" : "glyphicon-close";
    },
  },
};
