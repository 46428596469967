import { LIST } from "../../../DynamischeTabelle/UiDynamischeTabelle/ModalCreateOrUpdate/Parameters";
import {
  cloneDeep
} from "lodash-es";

export default {
  gutscheincode: {
    label: "Gutscheincode",
    options: {
      label: {
        required: true,
      },
      readonly: {
        required: false,
      },
      required: {
        required: true,
      },
      styles: {
        required: false,
      },
      gutschein_typ: {
        required: true,
      },
    },
  },

  gutscheinwert: {
    label: "Gutscheinwert",
    options: {
      label: {
        required: true,
      },
      readonly: {
        required: false,
      },
      required: {
        required: true,
      },
      styles: {
        required: false,
      },
      gutschein_spalte_id: {
        required: true,
      },
    },
  },
};

const INPUTLIST = cloneDeep(LIST);
INPUTLIST.push(
  {
    label: "Gutscheincode",
    value: "gutscheincode",
    options: [
      {
        id: "id",
      },
      {
        id: "label",
      },
      {
        id: "required",
      },
      {
        id: "gutschein_typ",
      },
    ],
  }
);
INPUTLIST.push(
  {
    label: "Gutscheinwert",
    value: "gutscheinwert",
    options: [
      {
        id: "id",
      },
      {
        id: "label",
      },
      {
        id: "required",
      },
      {
        id: "gutschein_spalte_id",
      },
    ],
  }
);

export const PARAMS = INPUTLIST;
