const ExportauftragJsonWizard = () => import(/* webpackChunkName: "ExportauftragJsonWizard" */ "../../views/Importauftrag/ExportauftragJsonWizard/ExportauftragJsonWizard.vue");
const IjfTemplateDetails = () => import(/* webpackChunkName: "ExportauftragJsonWizard" */ "../../views/Importauftrag/IjfTemplate/IjfTemplateDetails.vue");
const IjfTemplateList = () => import(/* webpackChunkName: "ExportauftragJsonWizard" */ "../../views/Importauftrag/IjfTemplate/IjfTemplateList/IjfTemplateList.vue");
const ImportauftragAntragWizard = () => import(/* webpackChunkName: "ImportauftragAntragWizard" */ "../../views/Importauftrag/ImportauftragAntragWizard/ImportauftragAntragWizard.vue");
const ImportauftragJsonWizard = () => import(/* webpackChunkName: "ImportauftragJsonWizard" */ "../../views/Importauftrag/ImportauftragJsonWizard/ImportauftragJsonWizard.vue");
const ImportauftragList = () => import(/* webpackChunkName: "ImportauftragList" */ "../../views/Importauftrag/ImportauftragList/ImportauftragList.vue");

export default [
  {
    path: "/importauftraege/",
    name: "root.importauftraege",
    component: ImportauftragList,
    meta: {
      permissions: [
        "importauftraege.ui",
      ],
    },
  },
  {
    path: "/antragimport/",
    name: "root.importauftraege_wizard_antrag",
    component: ImportauftragAntragWizard,
    meta: {
      permissions: [
        "importauftraege.antrag.wizard",
      ],
    },
  },
  {
    path: "/jsonimport/",
    name: "root.importauftraege_wizard_json",
    component: ImportauftragJsonWizard,
    meta: {
      permissions: [
        "importauftraege.json.wizard",
      ],
    },
  },
  {
    path: "/jsonexport/",
    name: "root.exportauftraege_wizard_json",
    component: ExportauftragJsonWizard,
    meta: {
      permissions: [
        "importauftraege.json.wizard",
      ],
    },
  },
  {
    path: "/ijf_template_ver/",
    name: "root.ijf_template_ver",
    component: IjfTemplateList,
  },
  {
    path: "/ijf_template_ver/:id/",
    name: "root.ijf_template_ver.detail",
    component: IjfTemplateDetails,
  },
];
