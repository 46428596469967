import UiValidatedJsonReadOnly from "../../../../../global/components/ui/UiValidatedJson/UiValidatedJsonReadOnly/UiValidatedJsonReadOnly.vue";

import JsonOptionsMixin from "../JsonOptionsMixin";
import UIReadOnlyMixin from "../../../../../global/components/ui/UIReadOnlyMixin";

// @vue/component
export default {
  name: "UiBelegpruefartFelderKostenReadOnly",
  components: {
    UiValidatedJsonReadOnly,
  },
  mixins: [
    JsonOptionsMixin,
    UIReadOnlyMixin,
  ],
};
