import UiValidatedJsonMixin from "./UiValidatedJsonMixin";

import {
  cloneDeep,
  forEach,
} from "lodash-es";

export default {
  mixins: [
    UiValidatedJsonMixin,
  ],
  props: {
    model: {
      required: true
    },
    options: {
      type: Object,
      default: () => ({}),
      required: true,
      info: "Optionen für den Parameter"
    },
    idPrefix: {
      type: String,
      reqiured: true
    },
    change: {
      type: Function,
      reqiured: true
    },
    errors: {},
  },
  computed: {
    localModel() {
      const MODEL = cloneDeep(this.model);
      if (this.isModeJson) {
        forEach(MODEL, (el, key) => {
          el[this.keyField] = key;
        });
      }
      return MODEL;
    },
  },
  beforeCreate() {
    this.$options.components = this.$options.components || {};
    this.$options.components.ParameterFormular = require("../../ParameterFormular/ParameterFormular.vue").default;
  },
};
