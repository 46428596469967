import axios from "axios";
import { setUrlForArray } from "../../functions/utils";

import {
  forEach,
  isArray,
  isNil,
  isPlainObject,
} from "lodash-es";

const BASE_URL = "/api/";

const api = axios.create({
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken",
});

export function get({ url, data, urlParams = {}, headerParams }) {
  const urlNew = setUrlWithParams({ url, params: urlParams });

  const promise = new Promise((resolve, reject) => {
    api.get(`${ BASE_URL }${ urlNew }`, data, headerParams).then(
      response => {
        return resolve(response.data);
      },
      error => {
        checkErrorStatus(error.response);
        return reject(error.response.data);
      }
    );
  });
  return promise;
}

export function getList({ url, data, urlParams = {}, headerParams }) {
  const urlNew = setUrlWithParams({ url, params: urlParams });

  const promise = new Promise((resolve, reject) => {
    api.get(`${ BASE_URL }${ urlNew }`, data, headerParams).then(
      response => {
        return resolve(response.data.results ? response.data.results : []);
      },
      error => {
        checkErrorStatus(error.response);
        return reject(error.response.data);
      }
    );
  });
  return promise;
}


export function post({ url, data, urlParams = {}, headerParams }) {
  const urlNew = setUrlWithParams({ url, params: urlParams });

  const promise = new Promise((resolve, reject) => {
    api.post(`${ BASE_URL }${ urlNew }`, data, headerParams).then(
      response => {
        return resolve(response.data);
      },
      error => {
        checkErrorStatus(error.response);
        return reject(error.response.data);
      }
    );
  });
  return promise;
}

export function put({ url, data, urlParams = {}, headerParams }) {
  const urlNew = setUrlWithParams({ url, params: urlParams });

  const promise = new Promise((resolve, reject) => {
    api.put(`${ BASE_URL }${ urlNew }`, data, headerParams).then(
      response => {
        return resolve(response.data);
      },
      error => {
        checkErrorStatus(error.response);
        return reject(error.response.data);
      }
    );
  });
  return promise;
}

export function patch({ url, data, urlParams = {}, headerParams }) {
  const urlNew = setUrlWithParams({ url, params: urlParams });

  const promise = new Promise((resolve, reject) => {
    api.patch(`${ BASE_URL }${ urlNew }`, data, headerParams).then(
      response => {
        return resolve(response.data);
      },
      error => {
        checkErrorStatus(error.response);
        return reject(error.response.data);
      }
    );
  });
  return promise;
}


export function remove({ url, data, urlParams = {}, headerParams }) {
  const urlNew = setUrlWithParams({ url, params: urlParams });

  const promise = new Promise((resolve, reject) => {
    api.delete(`${ BASE_URL }${ urlNew }`, data, headerParams).then(
      response => {
        return resolve(response.data);
      },
      error => {
        checkErrorStatus(error.response);
        return reject(error.response.data);
      }
    );
  });
  return promise;
}

export function getChoices({ id, label, url, params = {} } = {}) {
  const list = [];
  if (id && label) {
    params.fields = [id, label];
  }
  const promise = new Promise((resolve, reject) => {
    get({ url, urlParams: params }).then(
      response => {
        const results = response.results || response;
        if (id && label) {
          forEach(results, item => {
            list.push({
              value: item[id],
              label: item[label],
            });
          });
          return resolve(list);
        }
        return resolve(results);
      },
      error => {
        return reject(error);
      }
    );
  });
  return promise;
}

export function setUrlWithParams({ url, params }) {
  const URL = url || "";
  let urlParams = "";
  forEach(params, (value, key) => {
    if (isArray(value)) {
      urlParams = setUrlForArray({ id: key, array: value, url: urlParams });
    } else if (isPlainObject(value)) {
      forEach(value, (v, k) => {
        if (v) {
          if (urlParams) {
            urlParams += "&";
          }
          urlParams += `${ k }=${ v }`;
        }
      });
    } else if (!isNil(value)) {
      if (urlParams) {
        urlParams += "&";
      }
      urlParams += `${ key }=${ value }`;
    }
  });
  const prefix = urlParams && URL ? URL.indexOf("?") === -1 ? "?" : "&" : "";

  return `${ URL }${ prefix }${ urlParams }`;
}

function checkErrorStatus(error) {
  if (!error) {
    return;
  }

  if (error.status > 500) {
    // Kommt später
  }
}
