import FormElement from "../FormElement/FormElement.vue";
import FormElementReadOnly from "../FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import {
  toFormElementFromParameter,
  initModelFromList,
} from "../../functions/mappingForParameterToFormElement";

import {
  assign,
  forEach,
  isFunction,
} from "lodash-es";

// @vue/component
export default {
  name: "ParameterFormular",
  components: {
    FormElement,
    FormElementReadOnly
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: false,
      default: undefined,
    },
    idPrefix: {
      type: String,
      required: false,
      default: undefined,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    onChange: {
      type: Function,
      required: false,
      default: undefined,
    },
    showReadonly: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    hideHelpText: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      model: {},
      statusCancelUpdate: undefined,
    };
  },
  computed: {
    list() {
      const LIST = toFormElementFromParameter({
        obj: this.config,
        notReadonly: !this.showReadonly,
        showLabel: true,
        htmlIdPrefix: this.idPrefix,
        staticOptionsForId: {
          content_type: {
            view: "v-alt",
          },
        },
        staticOptionsForType: {
          document: {
            saveUrl: "tempdok/",
          },
        }
      }).list;
      if (this.hideHelpText) {
        forEach(LIST, el => el.helpText = undefined);
      }
      return LIST;
    }
  },
  watch: {
    config: {
      deep: true,
      handler() {
        this.initData();
      },
    },
    data() {
      if (this.readonly) {
        this.updateModel();
      }
    }
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.updateModel();
      if (isFunction(this.onChange)) {
        this.onChange({ currentModel: this.model });
      }
    },

    updateModel() {
      this.model = assign(initModelFromList({ list: this.list }), this.data || {});
    },

    onInput(arg) {
      if (this.onChange) {
        arg = arg || {};
        arg.currentModel = this.model;
        this.onChange(arg);
      }
    }
  },
};
