import {
  cloneDeep,
  isFunction,
} from "lodash-es";

export default {
  namespaced: true,
  state: () => {
    return {
      ME: undefined,
    };
  },
  getters: {
    GET_ME: state => {
      return state.ME;
    },
  },
  mutations: {
    MUT_INIT_ME(state, me) {
      if (state.ME) {
        return;
      }
      const ME = isFunction(me.plain) ? me.plain() : me;
      state.ME = cloneDeep(ME);
    },

    MUT_SET_ME(state, me) {
      state.ME = cloneDeep(me);
    },
  },
};
