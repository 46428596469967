import PuxCloak from "../../PuxCloak/PuxCloak.vue";
import UiCheckboxItem from "./UiCheckboxItem/UiCheckboxItem.vue";

import UICheckboxAndRadiobuttonMixin from "../UICheckboxAndRadiobutton.mixin";
import HttpMixin from "../../../mixins/HttpMixin";
import UIComponentMixin from "../UIComponentMixin";

// @vue/component
export default {
  name: "UiCheckbox",
  components: {
    PuxCloak,
    UiCheckboxItem,
  },
  mixins: [
    UICheckboxAndRadiobuttonMixin,
    HttpMixin,
    UIComponentMixin,
  ],
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    model: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    maxSelected() {
      return this.options.maxSelected;
    },

    hasMaxSelected() {
      return this.model && this.maxSelected && this.maxSelected <= this.model.length;
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      if (!this.options.url) {
        this.statusLoading = false;
        return;
      }
      this.getListHttp({ url: this.options.url, apiSaveId: this.options.apiSaveId }).then(
        response => {
          this.dataLocal = response;
          this.statusLoading = false;
        }
      );
    },
  },
};
