import translate from "../../../../../../global/directives/translate";
import HttpMixin from "../../../../../../global/mixins/HttpMixin";
import UiProjektberichteReadOnlyElement from "./UiProjektberichteReadOnlyElement/UiProjektberichteReadOnlyElement.vue";

// @vue/component
export default {
  name: "UiProjektberichteReadOnly",
  components: {
    UiProjektberichteReadOnlyElement,
  },
  directives: {
    translate,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    className: {
      type: String,
      default: "",
    },
    model: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      default: undefined,
    },
  },
  data() {
    return {
      loading: true,
      vorlagenObj: {},
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.getListHttp({
        url: `katalog/`,
        urlParams: {
          key: "projektberichtvorlagen",
        },
        apiSaveId: "projektberichtvorlagen",
        keyId: "id",
      }).then(
        response => {
          this.vorlagenObj = response;
          this.loading = false;
        }
      );
    },
  },
};
