import KontextKonfigReadOnly from "../KontextKonfig/KontextKonfigReadOnly/KontextKonfigReadOnly.vue";
import OptionsListReadOnly from "../OptionsList/OptionsListReadOnly/OptionsListReadOnly.vue";

import UiAntragScoreFieldsMixin from "../UiAntragScoreFieldsMixin";

import translate from "../../../../../../global/directives/translate";

// @vue/component
export default {
  name: "UiAntragScoreReadOnly",
  components: {
    KontextKonfigReadOnly,
    OptionsListReadOnly
  },
  directives: {
    translate,
  },
  mixins: [
    UiAntragScoreFieldsMixin
  ],
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    className: {
      type: String,
      default: "",
    },
    model: {
      type: Object,
      default: () => ({}),
    },
  },
  beforeCreate() {
    this.$options.components.FormElementReadOnly = require("../../../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue").default;
  },
};
