import {
  computed,
  ref,
} from "vue";

import PermissionGlobalAPI from "../../../global/compositionAPI/PermissionGlobalAPI";

export default function ActionsAPI() {
  const isModalOpen = ref(false);

  const {
    hasPermission: hasPermissionReleaseNotesView,
  } = PermissionGlobalAPI({
    permission: "releasenotes.view",
  });

  const openModal = () => {
    isModalOpen.value = true;
  };

  const closeModal = () => {
    isModalOpen.value = false;
  };

  const dropdownActions = computed(() => {
    return [
      {
        type: "link",
        text: "_LINK_PROFIL_",
        to: {
          name: "root.nutzerprofil",
        },
      },
      {
        type: "button",
        text: "_TXT_VERSIONSINFORMATION_",
        class: "test_version",
        isHidden: !hasPermissionReleaseNotesView.value,
        callback: openModal,
      },
      {
        id: "btn_logout",
        type: "link",
        title: "_BTN_ABMELDEN_TITLE_",
        text: "_BTN_ABMELDEN_",
        class: "test_version",
        iconLeft: "logout",
        to: {
          name: "root.logout",
        },
      },
    ];
  });

  return {
    closeModal,
    dropdownActions,
    isModalOpen,
  };
}
