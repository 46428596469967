import UIReadOnlyMixin from "../../UIReadOnlyMixin";
import { JSONEditor } from "vanilla-jsoneditor";
// @vue/component

export default {
  name: "UiJsonReadOnly",
  components: {
  },
  mixins: [
    UIReadOnlyMixin,
  ],
  mounted() {
    this.editor = new JSONEditor({
      target: this.$el,
      props: {
        content: {
          text: undefined,
          json: this.model || {}
        },
        readOnly: true,
        mode: "text",
      }
    });
  }
};
