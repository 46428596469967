import PuxTranslate from "../../PuxTranslate/PuxTranslate.vue";

import UIComponentMixin from "../UIComponentMixin";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "UiNumberRange",
  components: {
    PuxTranslate,
  },
  mixins: [
    UIComponentMixin,
  ],
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    modelMin() {
      return this.model[`${ this.options.id }_min`];
    },

    modelMax() {
      return this.model[`${ this.options.id }_max`];
    },

    idMin() {
      return this.getId;
    },

    idMax() {
      return `${ this.getId }_max`;
    },

    attributesMin() {
      const ATTRIBUTES = cloneDeep(this.attributesLocal);
      if (this.options.placeholderMin) {
        ATTRIBUTES.placeholder = this.options.placeholderMin;
      }
      return ATTRIBUTES;
    },

    attributesMax() {
      const ATTRIBUTES = cloneDeep(this.attributesLocal);
      if (this.options.placeholderMax) {
        ATTRIBUTES.placeholder = this.options.placeholderMax;
      }
      return ATTRIBUTES;
    },

    attributesLocal() {
      const ATTRIBUTES = cloneDeep(this.attributesHtml);
      if (this.options.maxlength) {
        ATTRIBUTES.maxlength = this.options.maxlength;
      }
      return ATTRIBUTES;
    },

    idForLabelMin() {
      return `${ this.getId }_min_label`;
    },

    idForLabelMax() {
      return `${ this.getId }_max_label`;
    },

    ariaDescribedbyMax() {
      return `${ this.getId }_label ${ this.idForLabelMax }`;
    },
  },
  methods: {
    onInputMin($event) {
      this.onInput({ currentModel: $event.target.value, label: "min", $event });
    },

    onInputMax($event) {
      this.onInput({ currentModel: $event.target.value, label: "max", $event });
    },

    onInput({ currentModel, label, $event }) {
      const MODEL = cloneDeep(this.model);
      MODEL[`${ this.options.id }_${ label }`] = currentModel;
      this.input({
        model: MODEL,
        currentModel,
        id: this.options.id,
        param: this.options.param,
        $event,
        options: this.options,
      });
      this.change({
        model: MODEL,
        currentModel,
        id: this.options.id,
        param: this.options.param,
        $event,
        options: this.options,
      });
    },
  },
};
