import {
  computed,
  toRef,
} from "vue";

export default function UiTreeGroupAPI(props) {
  const options = toRef(props, "options");
  const modelItemIndex = toRef(props, "modelItemIndex");

  const labelGroupLocal = computed(() => {
    return options.value.labelGroup;
  });

  const groupNumber = computed(() => {
    return modelItemIndex.value + 1;
  });

  return {
    groupNumber,
    labelGroupLocal,
  };
}
