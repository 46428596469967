import UiValidatedJson from "../UiValidatedJson/UiValidatedJson.vue";

import UIComponentMixin from "../UIComponentMixin";

import OptionsAPI from "./compositionAPI/OptionsAPI";

// @vue/component
export default {
  name: "UiRandomStringParts",
  components: {
    UiValidatedJson,
  },
  mixins: [
    UIComponentMixin,
  ],
  setup() {
    const {
      jsonOptions,
    } = OptionsAPI();

    return {
      jsonOptions,
    };
  },
};
