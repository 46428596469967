import {
  computed,
  nextTick,
  ref,
} from "vue";
import {
  useRouter,
} from "vue-router";

import AHttpAPI from "aloha-vue/src/compositionAPI/AHttpAPI";
import DynamicRootAPI from "./DynamicRootAPI";
import MenuOrderByAPI from "./MenuOrderByAPI";
import PermissionAPI from "../../../global/compositionAPI/PermissionAPI";
import SyConfigAPI from "../../../global/compositionAPI/SyConfigAPI";

import {
  cloneDeep,
  forEach,
} from "lodash-es";

export default function DataAPI() {
  const $router = useRouter();
  const loading = ref(true);
  const menuDefault = ref([
    {
      slot: "loading",
    },
  ]);
  const menuRef = ref(undefined);
  const routesObj = ref(undefined);

  const {
    getListHttp,
  } = AHttpAPI();

  const {
    checkPermissionsSync,
  } = PermissionAPI();

  const {
    syConfigsValue: syConfigsValueInstancePages,
  } = SyConfigAPI({
    value: "instance_pages",
    defaultValue: {},
  });

  const {
    orderByMenu,
  } = MenuOrderByAPI();

  const {
    dynamicRoot,
    dynamicRootLoading,
  } = DynamicRootAPI();

  const filterMenuWithInstancePages = ({ items }) => {
    const ITEMS_NEW = [];
    forEach(items, item => {
      if (item.state) {
        if (!item.state.kru_data.instance_pages ||
          syConfigsValueInstancePages.value[item.state.kru_data.instance_pages]) {
          ITEMS_NEW.push(item);
        }
        return;
      }
      if (item.children?.length) {
        const CHILDREN = filterMenuWithInstancePages({ items: item.children });
        if (CHILDREN?.length) {
          item.children = CHILDREN;
          ITEMS_NEW.push(item);
        }
      }
    });

    return ITEMS_NEW;
  };

  const filterMenuWithPermissions = ({ items }) => {
    const ITEMS_NEW = [];
    forEach(items, item => {
      if (item.permission?.length &&
        !checkPermissionsSync({ perm: item.permission })) {
        return;
      }
      if (item.state?.kru_name) {
        const routesObjItem = routesObj.value[item.state.kru_name];
        if (!routesObjItem?.meta?.permissions?.length) {
          ITEMS_NEW.push(item);
        } else if (checkPermissionsSync({ perm: routesObjItem.meta.permissions })) {
          ITEMS_NEW.push(item);
        }
        return;
      }
      if (item.children?.length) {
        const CHILDREN = filterMenuWithPermissions({ items: item.children });
        if (CHILDREN?.length) {
          item.children = CHILDREN;
          ITEMS_NEW.push(item);
          return;
        }
      }
      if (item.slot === "loading") {
        ITEMS_NEW.push(item);
      }
    });

    return ITEMS_NEW;
  };

  const menuLocal = computed(() => {
    return filterMenuWithPermissions({ items: cloneDeep(menuDefault.value) });
  });

  const initRoutesObj = () => {
    const routesObjLocal = {};
    forEach($router.options.routes, route => {
      routesObjLocal[route.name] = route;
    });
    routesObj.value = routesObjLocal;
  };

  const prepareToForLink = ({ item }) => {
    const NAME = item.state.kru_name;
    const TO = {
      name: NAME,
    };
    if (NAME === "root.kataloge_admin_menu_details") {
      TO.params = {
        id: item.details_kennung,
      };
    }
    if (item.block_first_load) {
      TO.query = {
        blockFirstLoad: true,
      };
    }
    if (NAME === "root.dynamicroot") {
      TO.params = {
        id: item.key,
      };
      dynamicRoot.value[item.key] = item.dynroute_config;
    }

    return TO;
  };

  const prepareLinks = ({ items }) => {
    forEach(items, item => {
      if (item.state) {
        item.to = prepareToForLink({ item });
      }
      if (item.children?.length) {
        item.children = prepareLinks({ items: item.children });
      }
    });

    return items;
  };

  const loadData = () => {
    getListHttp({
      url: "menu_readonly/",
      urlParams: {
        top: true,
      },
      abortable: false,
    }).then(
      response => {
        let menu = prepareLinks({ items: response });
        menu = filterMenuWithInstancePages({ items: menu });
        menuDefault.value = orderByMenu({ menu });
        dynamicRootLoading.value = false;
      }
    ).finally(() => {
      loading.value = false;
      nextTick().then(
        () => {
          menuRef.value?.checkAllRoutes();
        }
      );
    });
  };

  return {
    initRoutesObj,
    loadData,
    loading,
    menuLocal,
    menuRef,
  };
}
