import AFiltersAPI from "aloha-vue/src/compositionAPI/AFiltersAPI";

import {
  camelCase,
  startsWith,
  upperFirst,
} from "lodash-es";

export default function FilterAllAPI() {
  const filters = AFiltersAPI();

  const getFilterFunctionName = ({ filter }) => {
    if (startsWith(filter, "filter")) {
      filter = filter.replace("filter", "");
    }

    return `filter${ upperFirst(camelCase(filter)) }`;
  };

  const hasFilter = ({ filter }) => {
    return !!filters[getFilterFunctionName({ filter })];
  };

  const filterAll = (value, { filter, filterParameter }) => {
    if (hasFilter({ filter })) {
      return filters[getFilterFunctionName({ filter })](value, filterParameter);
    }
    return value;
  };

  return {
    filterAll,
    getFilterFunctionName,
    hasFilter,
  };
}
