import AFiltersAPI from "aloha-vue/src/compositionAPI/AFiltersAPI";

// @vue/component
export default {
  name: "UiZweckbindungReadOnly",
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    className: {
      type: String,
      default: "",
    },
    model: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      default: undefined,
    },
  },
  setup() {
    const {
      filterCurrency,
      filterDate,
    } = AFiltersAPI();

    return {
      filterCurrency,
      filterDate,
    };
  },
};
