import { toFormElementFromParameter } from "../../../../../global/functions/mappingForParameterToFormElement";

export default {
  data() {
    return {
      jsonParam: {
        bez: "_LBL_BESTANDUEBERSICHT_FELDER_",
        default: null,
        help_text: null,
        readonly: false,
        options: {
          allow_null: false,
          required: true
        },
        typ: "validated_json",
        many: true,
        as_json: false,
        unique_fields: [],
        key_field: "id",
        pos_field: null,
        min_length: null,
        max_length: null,
        element_label: "Element",
        element_template: "{{label}} ({{id}})",
        element_errors: true,
        deactivate_ordering: false,
        deactivate_preview: false,
        hide_pos: false,
        fields: {
          id: {
            prio: 0,
            bez: "ID",
            default: null,
            help_text: null,
            readonly: false,
            options: {
              allow_null: false,
              required: true,
              max_length: null,
              min_length: null
            },
            typ: "string"
          },
          label: {
            prio: 10,
            bez: "_LBL_BESTANDZEILEN_FIELD_LABEL_LABEL_",
            default: null,
            help_text: "_TXT_BESTANDZEILEN_FIELD_LABEL_HELP_TEXT_",
            readonly: false,
            options: {
              allow_null: false,
              required: true,
              max_length: null,
              min_length: null
            },
            typ: "string"
          },
          style: {
            prio: 20,
            bez: "_LBL_BESTANDZEILEN_FIELD_STYLE_LABEL_",
            default: "normal",
            help_text: "_TXT_BESTANDZEILEN_FIELD_STYLE_HELP_TEXT_",
            readonly: false,
            options: {
              allow_null: false,
              required: true
            },
            typ: "single_choice",
            choices: [
              ["normal", "_TXT_BESTANDZEILEN_FIELD_STYLE_NORMAL_"],
              ["highlight", "_TXT_BESTANDZEILEN_FIELD_STYLE_HIGHTLIGHT_"]
            ]
          },
          footer: {
            prio: 25,
            bez: "_LBL_BESTANDZEILEN_FIELD_FOOTER_LABEL_",
            default: false,
            help_text: "_TXT_BESTANDZEILEN_FIELD_FOOTER_HELP_TEXT_",
            readonly: false,
            typ: "boolean"
          },
          periode_expression: {
            prio: 30,
            bez: "_LBL_BESTANDZEILEN_FIELD_PERIODE_EXPRESSION_LABEL_",
            default: null,
            help_text: "_TXT_BESTANDZEILEN_FIELD_PERIODE_EXPRESSION_HELP_TEXT_",
            readonly: false,
            options: {
              allow_null: false,
              required: true,
              max_length: null,
              min_length: null
            },
            typ: "string"
          },
          periode_validation_expression: {
            prio: 40,
            bez: "_LBL_BESTANDZEILEN_FIELD_PERIODE_VALIDATION_EXPRESSION_LABEL_",
            default: null,
            help_text: "_TXT_BESTANDZEILEN_FIELD_PERIODE_VALIDATION_EXPRESSION_HELP_TEXT_",
            readonly: false,
            options: {
              allow_null: true,
              required: false,
              max_length: null,
              min_length: null
            },
            typ: "string"
          },
          periode_validation_message: {
            prio: 50,
            bez: "_LBL_BESTANDZEILEN_FIELD_PERIODE_VALIDATION_MESSAGE_LABEL_",
            default: null,
            help_text: "_TXT_BESTANDZEILEN_FIELD_PERIODE_VALIDATION_MESSAGE_HELP_TEXT_",
            readonly: false,
            options: {
              allow_null: true,
              required: false,
              max_length: null,
              min_length: null
            },
            typ: "string"
          },
          gesamt_expression: {
            prio: 60,
            bez: "_LBL_BESTANDZEILEN_FIELD_GESAMT_EXPRESSION_LABEL_",
            default: null,
            help_text: "_TXT_BESTANDZEILEN_FIELD_GESAMT_EXPRESSION_HELP_TEXT_",
            readonly: false,
            options: {
              allow_null: false,
              required: true,
              max_length: null,
              min_lengt: null
            },
            typ: "string"
          },
          gesamt_validation_expression: {
            prio: 70,
            bez: "_LBL_BESTANDZEILEN_FIELD_GESAMT_VALIDATION_EXPRESSION_LABEL_",
            default: null,
            help_text: "_TXT_BESTANDZEILEN_FIELD_GESAMT_VALIDATION_EXPRESSION_HELP_TEXT_",
            readonly: false,
            options: {
              allow_null: true,
              required: false,
              max_length: null,
              min_lengt: null
            },
            typ: "string"
          },
          gesamt_validation_message: {
            prio: 80,
            bez: "_LBL_BESTANDZEILEN_FIELD_GESAMT_VALIDATION_MESSAGE_LABEL_",
            default: null,
            help_text: "_TXT_BESTANDZEILEN_FIELD_GESAMT_VALIDATION_MESSAGE_HELP_TEXT_",
            readonly: false,
            options: {
              allow_null: true,
              required: false,
              max_length: null,
              min_lengt: null
            },
            typ: "string"
          }
        },
        prio: 5,
        wert: null
      }
    };
  },
  computed: {
    jsonOptions() {
      return toFormElementFromParameter({
        obj: { [`${ this.options.id }`]: this.jsonParam },
        showLabel: true,
        staticOptions: {
          required: this.options.required,
          options: this.options.options
        }
      }).list[0];
    }
  }
};
