import {
  computed,
  reactive,
  ref,
} from "vue";

import PermissionAPI from "../../global/compositionAPI/PermissionAPI";
import RouterAPI from "../../global/compositionAPI/RouterAPI";
import SyConfigAPI from "../../global/compositionAPI/SyConfigAPI";

import {
  asideFixedHeight,
  navbarFixedHeight,
} from "../../global/compositionAPI/LayoutAPI";
import {
  cloneDeep,
} from "lodash-es";

export default function TheMenuAPI({
  statusMobileMenuOpen,
}) {
  const {
    checkPermissionsSync,
  } = PermissionAPI();
  const {
    getSyConfigsValue,
  } = SyConfigAPI();

  const stateParentActivePk = ref(undefined);
  const stateChildActivePk = ref(undefined);

  const {
    isRouteActive,
  } = RouterAPI();

  const startOptions = reactive({
    pk: "start",
    titel: "Startseite",
    label: "Start",
    icon: "glyphicon-home",
    key: "start",
    state: {
      kru_name: "root.home",
    },
  });

  const stylesAsidePanel = computed(() => {
    return !statusMobileMenuOpen.value ?
      {
        top: `${ navbarFixedHeight.value }px`,
        height: `${ asideFixedHeight.value }px`,
      } :
      {};
  });

  function filterMenuWithInstancePages({ response }) {
    const INSTANCE_PAGES = getSyConfigsValue("instance_pages", {});
    for (let i = 0; i < response.length; i++) {
      const PARENT = response[i];
      if (PARENT.children && PARENT.children.length) {
        for (let j = 0; j < PARENT.children.length; j++) {
          const CHILD = PARENT.children[j];
          if (CHILD.state &&
            CHILD.state.kru_data &&
            CHILD.state.kru_data.instance_pages &&
            !INSTANCE_PAGES[CHILD.state.kru_data.instance_pages]) {
            PARENT.children.splice(j, 1);
            j--;
          }
        }
        if (!PARENT.children.length) {
          response.splice(i, 1);
          i--;
        }
      } else if (PARENT.state &&
        PARENT.state.kru_data &&
        PARENT.state.kru_data.instance_pages &&
        !INSTANCE_PAGES[PARENT.state.kru_data.instance_pages]) {
        response.splice(i, 1);
        i--;
      }
    }
    return response;
  }

  function filterMenuWithPermissions({ menuDefault }) {
    const MENU_CLONE = cloneDeep(menuDefault);
    for (let i = 0; i < MENU_CLONE.length; i++) {
      const PARENT = MENU_CLONE[i];
      if (PARENT.permission && PARENT.permission.length) {
        if (!checkPermissionsSync({ perm: PARENT.permission })) {
          MENU_CLONE.splice(i, 1);
          i--;
          continue;
        }
      }
      if (PARENT.children && PARENT.children.length) {
        for (let j = 0; j < PARENT.children.length; j++) {
          const CHILD = PARENT.children[j];
          if (CHILD.permission && CHILD.permission.length) {
            if (!checkPermissionsSync({ perm: CHILD.permission })) {
              PARENT.children.splice(j, 1);
              j--;
            }
          }
        }
        if (!PARENT.children.length) {
          MENU_CLONE.splice(i, 1);
          i--;
        }
      }
    }
    return MENU_CLONE;
  }

  function updateState({ menu }) {
    $("#menu_jump").focus();
    checkActiveRouteAndSetPageTitle({ menu });
  }

  function checkActiveRouteAndSetPageTitle({ menu }) {
    stateParentActivePk.value = undefined;
    stateChildActivePk.value = undefined;
    if (isRouteActive({ routeName: startOptions.state.kru_name })) {
      stateParentActivePk.value = startOptions.pk;
      return;
    }

    for (let i = 0; i < menu.length; i++) {
      const PARENT = menu[i];
      if (PARENT.state &&
        PARENT.state.kru_name) {
        if (checkState({ state: PARENT.state.kru_name, key: PARENT.key })) {
          stateParentActivePk.value = PARENT.pk;
          break;
        }
      }
      if (PARENT.children && PARENT.children.length) {
        for (let j = 0; j < PARENT.children.length; j++) {
          const CHILD = PARENT.children[j];
          if (CHILD.state && CHILD.state.kru_name) {
            if (checkState({ state: CHILD.state.kru_name, key: CHILD.key })) {
              stateParentActivePk.value = PARENT.pk;
              stateChildActivePk.value = CHILD.pk;
              break;
            }
          }
        }
        if (stateParentActivePk.value) {
          break;
        }
      }
    }
  }

  function checkState({ state, key }) {
    return isRouteActive({ routeName: state, id: key, checkForSubroute: state !== "root.dynamicroot" });
  }

  return {
    checkActiveRouteAndSetPageTitle,
    filterMenuWithInstancePages,
    filterMenuWithPermissions,
    startOptions,
    stateChildActivePk,
    stateParentActivePk,
    stylesAsidePanel,
    updateState,
  };
}
