import UiDynamischeAdressenCheckbox from "./UiDynamischeAdressenCheckbox/UiDynamischeAdressenCheckbox.vue";
import UIComponentMixin from "../../../../../global/components/ui/UIComponentMixin";

// @vue/component
export default {
  name: "UiDynamischeAdressen",
  components: {
    UiDynamischeAdressenCheckbox,
  },
  mixins: [
    UIComponentMixin,
  ],
  props: {
    model: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {

    };
  },
  computed: {
    getName() {
      return `${ this.options.id }_argument`;
    },
  },
  methods: {
    changeModel({ model }) {
      this.onInput_mixin({ value: model });
    },
  },
};
