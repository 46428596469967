import {
  computed,
  ref,
  toRef,
} from "vue";

export default function FocusAPI(props, {
  statusShowErrors = computed(() => false),
}) {
  const autoFocus = toRef(props, "autoFocus");

  const componentRef = ref(undefined);

  const setFocusToElement = () => {
    if (autoFocus.value && statusShowErrors.value) {
      setTimeout(() => {
        componentRef.value?.$el?.focus();
      });
    }
  };

  return {
    componentRef,
    setFocusToElement,
  };
}
