import UIReadOnlyMixin from "../../UIReadOnlyMixin";

// @vue/component
export default {
  name: "UiTextareaReadOnly",
  mixins: [
    UIReadOnlyMixin,
  ],
  computed: {
    getModel() {
      return this.model;
    },
  },
};
