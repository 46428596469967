import FileUpload from "vue-upload-component";
import PuxTranslate from "../../../PuxTranslate/PuxTranslate.vue";

// @vue/component
export default {
  name: "UiDocumentDrop",
  components: {
    FileUpload,
    PuxTranslate
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    inputId: {
      type: String,
      required: true,
    },
    accept: {
      type: String,
      required: true,
    },
    allowedMimeType: {
      type: String,
      required: true,
    },
    allowedMimeTypeLabel: {
      type: String,
      default: "",
    },
    size: {
      type: Number,
      required: true,
    },
    sizeMB: {
      type: Number,
      required: true,
    },
    files: {
      type: Array,
      required: true,
    },
    saveDocument: {
      type: Function,
      required: true,
    },
    inputFilter: {
      type: Function,
      required: true,
    },
    inputFile: {
      type: Function,
      required: true,
    },
    errors: {
      type: Array,
      required: true,
    },
    uploadBtnLabel: {
      type: String,
      required: false,
      info: "Text oder Placeholder für Upload Button (z.B. Dateien auswählen)",
      default: "_BTN_UI_DOCUMENT_UPLOAD_{{size}}_"
    },
    showMimeType: {
      type: Boolean,
      required: false,
      info: "Erlaubte Dateiendungen dem Nutzer anzeigen",
      default: true
    },
    maximum: {
      type: Number,
      required: false,
      info: "Maximale Anzahl der Dateien",
      default: 0
    }
  },
  computed: {
    sizeExtra() {
      return {
        size: this.sizeMB,
      };
    },

    disabledClass() {
      if (this.disabled) {
        return "disabled";
      }
      return "";
    },
  },
  methods: {
    inputFileLocal(newFile, oldFile) {
      this.inputFile({ newFile, oldFile, $refsUpload: this.$refs.upload });
    }
  },
};
