import translate from "../../../../../../../global/directives/translate";

import {
  cloneDeep,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "AntragScoreOptionListReadOnly",
  directives: {
    translate
  },
  props: {
    model: {
      type: Array,
      required: true,
      default: () => []
    },
  },
  computed: {
    localModel() {
      return cloneDeep(this.model) || [];
    },
    modelSize() {
      return size(this.localModel);
    },
    translationNoEntrey() {
      return { text: "_TXT_UI_ANTRAG_SCORE_OPTIONS_LIST_EMPTY_" };
    },
  },
};
