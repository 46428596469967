const FunktionsGruppenList = () => import(/* webpackChunkName: "FunktionsGruppenList" */ "../../views/Funktionsgruppen/FunktionsgruppenList/FunktionsgruppenList.vue");
const NutzerAnonymisiertList = () => import(/* webpackChunkName: "Contact" */ "../../views/Nutzer/NutzerAnonymisiertList/NutzerAnonymisiertList.vue");
const NutzerDetails = () => import(/* webpackChunkName: "NutzerDetails" */ "../../views/Nutzer/NutzerDetails/NutzerDetails.vue");
const NutzerList = () => import(/* webpackChunkName: "NutzerList" */ "../../views/Nutzer/NutzerList/NutzerList.vue");
const ProfilDetails = () => import(/* webpackChunkName: "Hilfe" */ "../../views/Profil/ProfilDetails/ProfilDetails.vue");
const RollenDetails = () => import(/* webpackChunkName: "RollenDetails" */ "../../views/Rollen/RollenDetails/RollenDetails.vue");
const RollenList = () => import(/* webpackChunkName: "RollenList" */ "../../views/Rollen/RollenList/RollenList.vue");

export default [
  {
    path: "/anonymisierte_nutzer/",
    name: "root.anonymisierte_nutzer",
    component: NutzerAnonymisiertList,
    meta: {
      permissions: [
        "nutzer.anonymise.view",
      ],
    },
  },
  {
    path: "/funktionsgruppen/",
    name: "root.fgruppen",
    component: FunktionsGruppenList,
    meta: {
      permissions: [
        "funktionsgruppen.ui",
      ],
    },
  },
  {
    path: "/nutzerverwaltung/",
    name: "root.nutzerverwaltung",
    component: NutzerList,
    meta: {
      permissions: [
        "nutzer.ui",
      ],
    },
  },
  {
    path: "/nutzerverwaltung/:id/",
    name: "root.nutzerverwaltung.details",
    component: NutzerDetails,
    meta: {
      permissions: [
        "nutzer.ui"
      ],
    },
  },
  {
    path: "/rollenverwaltung/",
    name: "root.rollenverwaltung",
    component: RollenList,
    meta: {
      permissions: [
        "rollen.ui",
      ],
    },
  },
  {
    path: "/rollenverwaltung/:id/",
    name: "root.rollenverwaltung.detail",
    component: RollenDetails,
    meta: {
      permissions: [
        "rollen.ui",
      ],
    },
  },
  {
    path: "/profil/",
    name: "root.nutzerprofil",
    component: ProfilDetails,
  },
];
