import UIReadOnlyMixin from "../../UIReadOnlyMixin";

import AFiltersAPI from "aloha-vue/src/compositionAPI/AFiltersAPI";

import {
  gettext,
} from "../../../../functions/utils";
import {
  isNil,
  size,
  truncate,
} from "lodash-es";

// @vue/component
export default {
  name: "UiInputReadOnly",
  mixins: [
    UIReadOnlyMixin,
  ],
  setup() {
    const {
      filterCurrency,
    } = AFiltersAPI();

    return {
      filterCurrency,
    };
  },
  computed: {
    getModel() {
      if ((isNil(this.model) || this.model === "") && !isNil(this.options.showEmpty)) {
        return gettext(this.options.showEmpty);
      }
      if (!isNil(this.options.decimal_places)) {
        return this.filterCurrency(this.model, { suffix: "", digits: this.options.decimal_places });
      }
      if (!isNil(this.options.max_length)) {
        return truncate(this.model, { length: this.options.max_length });
      }
      return this.model;
    },

    getHref() {
      return this.options.absoluteLink && this.getModel.indexOf("//") === -1 ? `//${ this.getModel }` : this.getModel;
    },

    getClass() {
      return [
        this.className,
        this.options.type === "regex_config" ? "code-content" : "",
      ];
    },

    renderAsLink() {
      return this.options.asLink && size(this.model) > 0;
    },
  },
};
