import AButton from "aloha-vue/src/AButton/AButton";

import translate from "../../../../../../../global/directives/translate";

import {
  cloneDeep,
  isArray,
  isNil,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "AntragScoreKontextKonfigReadOnly",
  components: {
    AButton,
  },
  directives: {
    translate
  },
  props: {
    model: {
      type: Array,
      required: true,
      default: () => []
    },
  },
  data() {
    return {
      showDetails: {},
      fieldList: [
        {
          id: "id",
          htmlId: `antrag_score_kontext_konfig_id`,
          type: "text",
          label: "_LBL_ANTRAG_SCORE_NUM_KONTEXT_ID_",
          helpText: "_TXT_ANTRAG_SCORE_NUM_KONTEXT_ID_HELP_TEXT_",
          showLabel: true,
          translate: true,
          required: true,
          view: "v",
        }, {
          id: "regel",
          htmlId: `antrag_score_kontext_konfig_regel`,
          type: "single_choice",
          label: "_LBL_ANTRAG_SCORE_NUM_KONTEXT_REGEL_",
          helpText: "_TXT_ANTRAG_SCORE_NUM_KONTEXT_REGEL_HELP_TEXT_",
          keyId: "id",
          keyLabel: "re_kbez",
          url: "katalog/?key=foerderregel",
          apiSaveId: "foerderregel_dynamische_zahlen_dynamische_tabelle_dynamische_mehrfachauswahl",
          urlParams: {
            suche: [
              "dynamische_zahlen",
              "dynamische_tabelle",
              "dynamische_mehrfachauswahl",
            ]
          },
          showLabel: true,
          translate: true,
          required: true,
          view: "v",
        }, {
          id: "regex",
          htmlId: `antrag_score_kontext_konfig_regex`,
          type: "text",
          label: "_LBL_ANTRAG_SCORE_NUM_KONTEXT_REGEX_",
          helpText: "_TXT_ANTRAG_SCORE_NUM_KONTEXT_REGEX_HELP_TEXT_",
          showLabel: true,
          translate: true,
          required: true,
          view: "v",
        }, {
          id: "default",
          htmlId: `antrag_score_kontext_konfig_default`,
          type: "float",
          label: "_LBL_ANTRAG_SCORE_NUM_KONTEXT_DEFAULT_",
          helpText: "_TXT_ANTRAG_SCORE_NUM_KONTEXT_DEFAULT_HELP_TEXT_",
          decimal: 6,
          digits: 14,
          showLabel: true,
          translate: true,
          required: false,
          view: "v",
        },
      ],
    };
  },
  computed: {
    localModel() {
      return cloneDeep(this.model) || [];
    },
    modelSize() {
      return size(this.localModel);
    },
    translationNoEntrey() {
      return { text: "_TXT_UI_ANTRAG_SCORE_KONTEXT_KONFIG_EMPTY_" };
    },
  },
  beforeCreate() {
    this.$options.components.FormElementReadOnly = require("../../../../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue").default;
  },

  methods: {
    initData() {
      this.localModel = cloneDeep(this.model) || [];
    },
    toggleDetails({ id }) {
      const DETAILS = cloneDeep(this.showDetails);
      DETAILS[id] = !DETAILS[id];
      this.showDetails = DETAILS;
    },
    showField(value) {
      return !isNil(value) && !(isArray(value) && size(value) === 0);
    }
  }
};
