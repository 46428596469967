import ADropdown from "aloha-vue/src/ADropdown/ADropdown";
import PuxTemplateDownloadElement from "./PuxTemplateDownloadElement/PuxTemplateDownloadElement.vue";
import PuxIcon from "../PuxIcon/PuxIcon.vue";
import ActionsAPI from "./compositionAPI/ActionsAPI";

// @vue/component
export default {
  name: "PuxTemplateDownload",
  components: {
    ADropdown,
    PuxIcon,
    PuxTemplateDownloadElement,
  },
  props: {
    async: {
      type: Boolean,
      default: false,
      info: "Laden Date asynchron oder synchron",
    },
    data: {
      type: Array,
      required: true,
    },
    urlParams: {
      type: Object,
      default: () => ({}),
    },
    action: {
      type: String,
      default: "dokument_async",
    },
    downloadAction: {
      type: String,
      default: "dokument_download",
    },
    btnType: {
      type: String,
      default: "default",
    },
    btnIcon: {
      type: String,
      default: "glyphicon-download",
    },
    btnClass: {
      type: String,
      default: "a_btn a_btn_secondary",
    },
    htmlId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const {
      dropdownActions,
    } = ActionsAPI(props);

    return {
      dropdownActions,
    };
  },
};
