import {
  cloneDeep,
} from "lodash-es";

export default {
  namespaced: true,
  state() {
    return {
      APIS: {},
    };
  },
  getters: {
    GET_SAVED_API: state => ({ apiSaveId }) => {
      return state.APIS[apiSaveId];
    },
  },
  mutations: {
    MUT_INSERT_API(state, { apiSaveId, data, keyData }) {
      const APIS = cloneDeep(state.APIS);
      APIS[apiSaveId].data = data;
      APIS[apiSaveId].keyData = keyData;
      APIS[apiSaveId].loading = false;
      state.APIS = APIS;
    },

    MUT_INSERT_API_KEY_DATA(state, { apiSaveId, keyData }) {
      const APIS = cloneDeep(state.APIS);
      APIS[apiSaveId].keyData = keyData;
      state.APIS = APIS;
    },

    MUT_INSERT_PROMISE_API(state, { apiSaveId, promise }) {
      const APIS = cloneDeep(state.APIS);
      APIS[apiSaveId] = {
        promise,
        loading: true
      };
      state.APIS = APIS;
    },
  },
  actions: {

  },
};
