import PuxIcon from "../../../PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../PuxTranslate/PuxTranslate.vue";

import UIReadOnlyMixin from "../../UIReadOnlyMixin";

const ICONS = {
  true: "glyphicon-check",
  false: "glyphicon-unchecked",
};
const ICONS_EXTRA_CLS = {
  true: "a_color_success",
  false: "a_color_black",
};

// @vue/component
export default {
  name: "UiOneCheckboxReadOnly",
  components: {
    PuxIcon,
    PuxTranslate,
  },
  mixins: [
    UIReadOnlyMixin,
  ],
  computed: {
    value() {
      return this.model === true;
    },
    icon() {
      return ICONS[String(this.value)];
    },
    iconCls() {
      return ICONS_EXTRA_CLS[String(this.value)];
    }
  }
};
