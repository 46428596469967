import {
  setUrl,
} from "../../functions/help";

import {
  isFunction,
  isString,
  isNil,
  isPlainObject,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "AngularLink",
  props: {
    sref: {
      type: String,
      required: false,
      default: undefined,
      info: "State für Angular-Routing",
    },
    parameter: {
      type: Object,
      required: false,
      default: () => ({}),
      info: "Parameter für Angular-Routing",
    },
    options: {
      type: [Object, String],
      required: false,
      default: undefined,
      info: "Get-Parameter für Angular-Routing. Z.B. { blockFirstLoad: 'true' }",
    },
    stop: {
      type: Boolean,
      required: false,
      info: "JS-Funktion: stopPropagation und preventDefault",
    },
    disabled: {
      type: Boolean,
      required: false,
      info: "HTML-Attribut 'disabled', JS-Funktion: stopPropagation und preventDefault",
    },
    click: {
      type: Function,
      required: false,
      default: undefined,
      info: "Funktion 'click'",
    },
    href: {
      type: String,
      required: false,
      default: undefined,
      info: "State Href Parameter"
    },
    extraAttributes: {
      type: Object,
      required: false,
      default: () => ({}),
      info: "Extra attributes für Link"
    },
    target: {
      type: String,
      required: false,
      default: undefined,
      info: "Default link target attribute"
    },
  },
  emits: [
    "blur",
    "focus",
    "mouseenter",
    "mouseleave",
  ],
  data() {
    return {
      hrefMappingMock: {
        "root.home": "/",
        "root.kontakt": "/kontakt/",
        "root.hilfen": "/hilfe/",
        "root.login": "/login/",
        "root.faqs": "/faqs/",
        "root.nutzerprofil": "/profil/",
        "root.impressum": "/impressum/",
        "root.datenschutzeinwilligung": "/datenschutzeinwilligung/",
      },
    };
  },
  computed: {
    hrefLocal() {
      if (this.href) {
        return this.href;
      }
      if (isNil(this.sref)) {
        return "#";
      }
      // temporary solution while angular-link is being used
      const resolvedRoute = this.$router.resolve({
        name: this.sref,
        params: this.parameter,
      });
      return resolvedRoute.fullPath;

      // const SREF = this.$stateHref(this.sref, this.parameter);

      // if (isNil(SREF)) {
      //   console.error(`Das Routing '${ this.sref }' ist nicht implementiert.`);
      //   return "#";
      // }
      // const SREF_PARTS = SREF.split("?");
      // const SREF_WITHOUT_QUESTION = SREF_PARTS[0];
      // const SREF_QUERY_PARAMS = SREF_PARTS[1];
      // let queryParams = "";
      // let queryPrefix = "?";
      // if (size(this.optionsInString) > 0) {
      //   queryParams = this.optionsInString;
      //   queryPrefix = "&";
      // }
      // if (size(SREF_QUERY_PARAMS) > 0) {
      //   queryParams = queryParams + queryPrefix + SREF_QUERY_PARAMS;
      // }
      // return `${ SREF_WITHOUT_QUESTION }${ queryParams }`;
    },

    hrefMock() {
      return this.hrefMappingMock[this.sref];
    },

    optionsInString() {
      if (!this.options) {
        return "";
      }
      if (isString(this.options)) {
        return `?${ this.options }`;
      }
      if (isPlainObject(this.options) && size(this.options)) {
        return setUrl(this.options, "?");
      }
      return "";
    },
  },
  methods: {
    clickLocal($event) {
      if (this.disabled) {
        $event.stopPropagation();
        $event.preventDefault();
        return;
      }
      if ($event.ctrlKey) {
        return;
      }
      if (isFunction(this.click)) {
        this.click($event);
      }
      if (this.stop) {
        $event.stopPropagation();
      }
    },

    focus($event) {
      this.$emit("focus", $event);
    },

    blur($event) {
      this.$emit("blur", $event);
    },

    mouseenter($event) {
      this.$emit("mouseenter", $event);
    },

    mouseleave($event) {
      this.$emit("mouseleave", $event);
    },
  },
};
