import {
  computed,
  toRef,
} from "vue";

import {
  replaceText,
} from "../../../../functions/utils";
import {
  assign,
  forEach, forOwn, get,
  isArray,
  isFunction,
  isString,
} from "lodash-es";

export default function LinkAPI(props) {
  const column = toRef(props, "column");
  const row = toRef(props, "row");
  const rowIndex = toRef(props, "rowIndex");
  // optionsAngularLink() {
  //       return Object.assign({}, this.col.linkTo, { label: this.getValueAsString });
  //     }
  const linkTo = computed(() => {
    return column.value.linkTo || {};
  });

  const href = computed(() => {
    if (isString(linkTo.value.url)) {
      return replaceText({
        text: linkTo.value.url,
        object: row.value,
      });
    }
    if (isFunction(linkTo.value.href)) {
      return linkTo.value.href({
        row: row.value,
        rowIndex: rowIndex.value,
      });
    }
    return undefined;
  });

  const routeLocal = computed(() => {
    if (isFunction(linkTo.value.route)) {
      return linkTo.value.route({
        row: row.value,
        rowIndex: rowIndex.value,
      });
    }
    return linkTo.value.route || linkTo.value.name;
  });

  const paramsLink = computed(() => {
    const EXISTING_PARAMS = linkTo.value.routeParameters || linkTo.value.params;
    let parameter = {};
    if (isArray(EXISTING_PARAMS) && EXISTING_PARAMS.length) {
      forEach(EXISTING_PARAMS, item => {
        if (item.rowPathObject) {
          parameter = assign({}, parameter, get(row.value, item.rowPathObject));
        }
        const KEY = item.routeId;
        if (KEY) {
          let value = "";
          if (item.rowPath) {
            value = get(row.value, item.rowPath);
          }
          parameter[KEY] = value;
        }
      });
    } else {
      forOwn(EXISTING_PARAMS, (value, key) => {
        parameter[key] = get(row.value, value);
      });
    }
    return parameter;
  });

  const queryLink = computed(() => {
    const OPTIONS = {};
    if (linkTo.value.routeOptions && linkTo.value.routeOptions.length) {
      forEach(linkTo.value.routeOptions, item => {
        const KEY = item.routeId;
        let value = "";
        if (item.rowPath) {
          value = get(row.value, item.rowPath);
        }
        OPTIONS[KEY] = value;
      });
    }
    return OPTIONS;
  });

  const toLocal = computed(() => {
    if (href.value) {
      return undefined;
    }
    return {
      name: routeLocal.value,
      params: paramsLink.value,
      query: queryLink.value,
    };
  });

  const target = computed(() => {
    return linkTo.value.target;
  });

  return {
    href,
    target,
    toLocal,
  };
}
