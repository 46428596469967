import {
  computed,
  toRef,
} from "vue";

export default function IdAPI(props) {
  const idPrefix = toRef(props, "idPrefix");
  const options = toRef(props, "options");

  const htmlId = computed(() => {
    // querySelector / querySelectorAll used by TinyMce,
    // querySelector method uses CSS3 selectors for querying the DOM
    // and CSS3 doesn't support ID selectors that start with a number
    if (options.value.htmlId) {
      return `tinymce_${ options.value.htmlId }`;
    }
    return `tinymce_${ idPrefix.value || "" }${ options.value.id }`;
  });


  return {
    htmlId,
  };
}
