export default [
  {
    value: "antragsteller.antragstellerorganisation",
    label: "_LBL_CALENDAR_FILTER_OBJEKT_TYP_ANTRAGSTELLERORGANISATION_",
  },
  {
    value: "workflow.aufgabe",
    label: "_LBL_CALENDAR_FILTER_OBJEKT_TYP_AUFGABE_",
  },
  {
    value: "vertrag.auszahlung",
    label: "_LBL_CALENDAR_FILTER_OBJEKT_TYP_AUSZAHLUNG_",
  },
  {
    value: "vertrag.auszahlungsplan",
    label: "_LBL_CALENDAR_FILTER_OBJEKT_TYP_AUSZAHLUNGSPLAN_",
  },
  {
    value: "foerderantrag.foerderantrag",
    label: "_LBL_CALENDAR_FILTER_OBJEKT_TYP_ANTRAG_",
  },
  {
    value: "sitzungen.sitzung",
    label: "_LBL_CALENDAR_FILTER_OBJEKT_TYP_SITZUNG_",
  },
  {
    value: "vertrag.vertrag",
    label: "_LBL_CALENDAR_FILTER_OBJEKT_TYP_VERTRAG_",
  },
  {
    value: "gutachten.gutachten",
    label: "_LBL_CALENDAR_FILTER_OBJEKT_TYP_GUTACHTEN_",
  },
  {
    value: "formular.formular",
    label: "_LBL_CALENDAR_FILTER_OBJEKT_TYP_FORMULAR_",
  },
  {
    value: "projektberichte.projektbericht",
    label: "_LBL_CALENDAR_FILTER_OBJEKT_TYP_PROJEKTBERICHT_",
  },
];

export const CalendarObjectTypesObj = {
  "antragsteller.antragstellerorganisation": {
    label: "_LBL_CALENDAR_FILTER_OBJEKT_TYP_ANTRAGSTELLERORGANISATION_",
    getUrl: ({ id }) => ({
      root: "root.astorganisationen.details",
      parameter: { id: id },
    }),
    permission: "antragstellerorg.view",
  },
  "workflow.aufgabe": {
    label: "_LBL_CALENDAR_FILTER_OBJEKT_TYP_AUFGABE_",
    getUrl: () => ({
      root: "root.aufgabe",
    }),
    permission: "aufgabe.view",
  },
  "vertrag.auszahlung": {
    label: "_LBL_CALENDAR_FILTER_OBJEKT_TYP_AUSZAHLUNG_",
    getUrl: ({ id, parentId }) => ({
      root: "root.vertrag.details.auszahlung",
      parameter: { id: parentId, aus_id: id },
    }),
    permission: "auszahlung.view",
  },
  "vertrag.auszahlungsplan": {
    label: "_LBL_CALENDAR_FILTER_OBJEKT_TYP_AUSZAHLUNGSPLAN_",
    getUrl: ({ parentId }) => ({
      root: "root.vertrag.details",
      parameter: { id: parentId },
    }),
    permission: "vertrag.view",
  },
  "foerderantrag.foerderantrag": {
    label: "_LBL_CALENDAR_FILTER_OBJEKT_TYP_ANTRAG_",
    getUrl: ({ id }) => ({
      root: "root.foerderantrag.details",
      parameter: { id: id },
    }),
    permission: "foerderantrag.view",
  },
  "sitzungen.sitzung": {
    label: "_LBL_CALENDAR_FILTER_OBJEKT_TYP_SITZUNG_",
    getUrl: ({ id }) => ({
      root: "root.sitzungen.details",
      parameter: { id: id },
    }),
    permission: "sitzungen.view",
  },
  "vertrag.vertrag": {
    label: "_LBL_CALENDAR_FILTER_OBJEKT_TYP_VERTRAG_",
    getUrl: ({ id }) => ({
      root: "root.vertrag.details",
      parameter: { id: id },
    }),
    permission: "vertrag.view",
  },
  "gutachten.gutachten": {
    label: "_LBL_CALENDAR_FILTER_OBJEKT_TYP_GUTACHTEN_",
    getUrl: ({ id }) => ({
      root: "root.gutachten.detail",
      parameter: { id: id },
    }),
    permission: "gutachten.view",
  },
  "formular.formular": {
    label: "_LBL_CALENDAR_FILTER_OBJEKT_TYP_FORMULAR_",
    getUrl: ({ id }) => ({
      root: "root.basisformularcortex.detail",
      parameter: { id: id },
    }),
    permission: "basisformularcortex.view",
  },
  "projektberichte.projektbericht": {
    label: "_LBL_CALENDAR_FILTER_OBJEKT_TYP_PROJEKTBERICHT_",
    getUrl: ({ id }) => ({
      root: "root.projektberichte.detail",
      parameter: { id: id },
    }),
    permission: "projektbericht.view",
  }
};
