const GutachtenDetails = () => import(/* webpackChunkName: "GutachtenDetails" */ "../../views/Gutachten/GutachtenDetails/GutachtenDetails.vue");
const GutachtenList = () => import(/* webpackChunkName: "GutachtenList" */ "../../views/Gutachten/GutachtenList/GutachtenList.vue");
const GutachtenVorlagenDetails = () => import(/* webpackChunkName: "GutachtenVorlagenDetails" */ "../../views/Gutachten/GutachtenVorlagenDetails/GutachtenVorlagenDetails.vue");
const GutachtenVorlagenList = () => import(/* webpackChunkName: "GutachtenVorlagen" */ "../../views/Gutachten/GutachtenVorlagenList/GutachtenVorlagenList.vue");

export default [
  {
    path: "/gutachten/",
    name: "root.gutachten",
    component: GutachtenList,
    meta: {
      permissions: [
        "gutachten.list",
      ],
    },
  },
  {
    path: "/gutachten/:id/",
    name: "root.gutachten.detail",
    component: GutachtenDetails,
    meta: {
      permissions: [
        "gutachten.list",
      ],
    },
  },
  {
    path: "/gutachtenvorlagen/",
    name: "root.gutachtenvorlagen",
    component: GutachtenVorlagenList,
    meta: {
      permissions: [
        "gutachtenvorlage.view",
      ],
    },
  },
  {
    path: "/gutachtenvorlagen/:id/",
    name: "root.gutachtenvorlagen.detail",
    component: GutachtenVorlagenDetails,
    meta: {
      permissions: [
        "gutachtenvorlage.view",
      ],
    },
  },
];
