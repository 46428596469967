import translate from "../../../../directives/translate";

import UIReadOnlyMixin from "../../UIReadOnlyMixin";

// @vue/component
export default {
  name: "UiDynamischeFeldwertlisteReadOnly",
  directives: {
    translate,
  },
  mixins: [
    UIReadOnlyMixin,
  ],
};
