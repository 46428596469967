import PuxCloak from "../../../../../../global/components/PuxCloak/PuxCloak.vue";
import UiDynamischeTabelleReadOnlyElement
  from "./UiDynamischeTabelleReadOnlyElement/UiDynamischeTabelleReadOnlyElement.vue";
import HttpMixin from "../../../../../../global/mixins/HttpMixin";
import {
  LIST,
} from "../ModalCreateOrUpdate/Parameters";
import {
  keyBy,
  orderBy,
} from "lodash-es";

// @vue/component
export default {
  name: "UiDynamischeTabelleReadOnly",
  components: {
    PuxCloak,
    UiDynamischeTabelleReadOnlyElement,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    className: {
      type: String,
      default: "",
    },
    model: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      default: undefined,
    },
  },
  data() {
    return {
      loading: true,
      katalogeByPk: {},
      objektkatalogeByPk: {},
      kdokartByPk: {},
      typeByKey: {},
    };
  },
  computed: {
    modelList() {
      return orderBy(this.model, ["pos"]);
    },
  },
  created() {
    this.loadData();
    this.setTypeByKey();
  },
  methods: {
    loadData() {
      Promise.all([
        this.getListHttp({
          url: "katalog/",
          urlParams: {
            key: "katalog_mit_dynkatalog",
          },
          apiSaveId: "katalog_mit_dynkatalog",
        }),
        this.getListHttp({
          url: "katalog/",
          urlParams: {
            key: "kobjektkatalog",
          },
          apiSaveId: "kobjektkatalog",
        }),
        this.getListHttp({
          url: "kdokart/",
          apiSaveId: "kdokart",
        }),
      ]).then(
        responses => {
          this.katalogeByPk = keyBy(responses[0], "pk");
          this.objektkatalogeByPk = keyBy(responses[1], "pk");
          this.kdokartByPk = keyBy(responses[2], "pk");
          this.loading = false;
        }
      );
    },

    setTypeByKey() {
      this.typeByKey = keyBy(LIST, "value");
    },
  },
};
