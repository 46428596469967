import UIReadOnlyMixin from "../../UIReadOnlyMixin";

import AFiltersAPI from "aloha-vue/src/compositionAPI/AFiltersAPI";

// @vue/component
export default {
  name: "UiIbanReadOnly",
  mixins: [
    UIReadOnlyMixin,
  ],
  setup() {
    const {
      filterIban,
    } = AFiltersAPI();

    return {
      filterIban,
    };
  },
  computed: {
    getModel() {
      return this.filterIban(this.model);
    },
  },
};
