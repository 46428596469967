import AButton from "aloha-vue/src/AButton/AButton";

import {
  gettext,
} from "../../../../../functions/utils";

import {
  get,
} from "lodash-es";


// @vue/component
export default {
  name: "UiSelectOrderedElement",
  components: {
    AButton,
  },
  props: {
    id: {
      type: String,
      required: true
    },
    index: {
      type: Number,
      required: true,
    },
    first: {
      type: Boolean,
      required: true,
    },
    last: {
      type: Boolean,
      required: true,
    },
    currentModel: {
      type: [String, Number],
      required: true,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
    },
    collection: {
      type: Object,
      required: true,
    },
    moveUp: {
      type: Function,
      required: true,
    },
    moveDown: {
      type: Function,
      required: true,
    },
    deleteElement: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      confirmShow: undefined,
      confirmOptions: undefined,
    };
  },
  computed: {
    label() {
      return gettext(get(this.collection[this.currentModel], this.keyLabelLocal));
    },

    pos() {
      return this.index + 1;
    },

    translateExtra() {
      return {
        label: this.label,
        pos: this.pos,
      };
    },

    keyLabelLocal() {
      return this.options.keyLabel || "label";
    },

    btnMoveUpId() {
      return `${ this.id }_moveup_${ this.index }`;
    },

    btnMoveUpDisabled() {
      return this.disabled || this.first;
    },

    btnMoveDownId() {
      return `${ this.id }_movedown_${ this.index }`;
    },

    btnMoveDownDisabled() {
      return this.disabled || this.last;
    },

    btnRemoveId() {
      return `${ this.id }_remove_${ this.index }`;
    },
  },
  beforeCreate() {
    this.$options.components.Modal = require("../../../../Modal/Modal.vue").default;
  },
  methods: {
    openConfirmDelete() {
      this.confirmOptions = {
        okClass: "a_btn a_btn_danger",
        title: "_TXT_UI_SELECT_ORDERED_REMOVE_ITEM_MODAL_TITLE_{{label}}_{{pos}}_",
        msg: "_HTML_UI_SELECT_ORDERED_REMOVE_ITEM_MODAL_MSG_{{label}}_{{pos}}_",
        extra: this.translateExtra,
        okLabel: "_BTN_UI_SELECT_ORDERED_REMOVE_ITEM_MODAL_CONFIRM_",
        okCallback: () => {
          this.deleteElement({ index: this.index });
          this.closeConfirm();
        },
        cancelCallback: this.closeConfirm,
        loading: false,
      };
      this.confirmShow = true;
    },

    closeConfirm() {
      this.confirmShow = false;
    },
  },
};
