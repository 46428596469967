import SyConfigMixin from "../../../../mixins/SyConfigMixin";

import ShowMore from "../../ShowMore/ShowMore.vue";

// @vue/component
export default {
  name: "UiTextbausteinReadOnly",
  components: {
    ShowMore,
  },
  mixins: [
    SyConfigMixin,
  ],
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    className: {
      type: String,
      default: "",
    },
    model: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      default: undefined,
    },
  },
  computed: {
    getModel() {
      return this.model;
    },
    maxHeightReadOnly() {
      return this.config.max_height_textbaustein_readonly;
    }
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.config = this.getSyConfigsValue("richtext");
    }
  }
};
