import PuxPopover from "../../PuxPopover/PuxPopover.vue";

import UIComponentMixin from "../UIComponentMixin";
import {
  toNumber,
  isNaN,
} from "lodash-es";

import L from "leaflet";
import "leaflet/dist/leaflet.css";

const ICON_CIRCLE = L.divIcon({ className: "ui_coordinates__icon_circle" });

const MAX_NORTH_COORDINATE_DE = 55.0591667;
const MAX_SOUTH_COORDINATE_DE = 47.2708333;
const MAX_WEST_COORDINATE_DE = 5.866944444444445;
const MAX_EAST_COORDINATE_DE = 15.043611111111112;
const CENTER_DE = [
  53.54728069829341,
  9.994812011718752
];

// @vue/component
export default {
  components: {
    PuxPopover,
  },
  mixins: [
    UIComponentMixin,
  ],
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    className: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      map: undefined,
      marker: undefined,
      isOpen: undefined,
      timerDestroyMap: undefined,
      statusHideFirst: undefined,
      zoom: 14,
      autoHide: true,
      autoHideTimer: undefined,
    };
  },
  computed: {
    idForLat() {
      return `${ this.getId }_lat`;
    },

    idForLng() {
      return `${ this.getId }_lng`;
    },

    placement() {
      return this.options.placement || "bottom-start"; // bottom-start, top-start, top-end, left, right
    },

    idForButtonPopover() {
      return `${ this.getId }_btn_popover`;
    },

    idForPopover() {
      return `${ this.getId }_popover`;
    },
  },
  methods: {
    toggleMap() {
      this.isOpen = !this.isOpen;
    },

    closeMap() {
      this.isOpen = undefined;
    },

    onShow() {
      this.statusHideFirst = false;
      clearTimeout(this.timerDestroyMap);
      setTimeout(() => {
        this.showMap();
      });
    },

    onHide() {
      if (this.statusHideFirst) {
        return;
      }
      this.statusHideFirst = true;
      this.timerDestroyMap = setTimeout(() => this.destroyMap(), 4500);
      this.destroyMap();
      this.isOpen = undefined;
    },

    destroyMap() {
      if (this.map) {
        this.map.remove();
      }
      this.map = undefined;
      this.marker = undefined;
    },

    showMap() {
      if (!this.map) {
        const MAP_ELEMENT = this.$refs.map;
        const CENTER = this.getCenter();
        this.map = L.map(MAP_ELEMENT, {
          center: CENTER,
          zoom: this.zoom,
          minZoom: 6,
          maxZoom: 18,
          maxBounds: [
            [MAX_NORTH_COORDINATE_DE, MAX_WEST_COORDINATE_DE],
            [MAX_SOUTH_COORDINATE_DE, MAX_EAST_COORDINATE_DE]
          ]
        });
        L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
          attribution: `&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors`
        }).addTo(this.map);
        this.initMapEvents();
      }
      this.addOderChangeMarker({ model: this.modelLocal });
    },

    initMapEvents() {
      this.map.on("movestart", this.movestart);
      this.map.on("moveend", this.moveend);
    },

    moveend() {
      setTimeout(() => {
        this.autoHide = true;
      }, 1000);
    },

    movestart() {
      clearTimeout(this.autoHideTimer);
      this.autoHide = false;
    },

    addOderChangeMarker({ model }) {
      if (!this.map || !this.isOpen) {
        return;
      }
      if (!model || model.length !== 2) {
        return;
      }
      const MODEL_NUMBER_0 = toNumber(model[0]);
      const MODEL_NUMBER_1 = toNumber(model[1]);
      if (isNaN(MODEL_NUMBER_0) || isNaN(MODEL_NUMBER_1)) {
        return;
      }
      this.checkMarker({ model });
    },

    checkMarker({ model }) {
      if (this.marker) {
        this.changeMarkerCoordinates({ model });
      } else {
        this.addMarker({ model });
      }
    },

    addMarker({ model } = {}) {
      this.marker = L.marker(model, { icon: ICON_CIRCLE }).addTo(this.map);
    },

    getCenter() {
      if (this.modelLocal.length === 2) {
        const MODEL_NUMBER_0 = toNumber(this.modelLocal[0]);
        const MODEL_NUMBER_1 = toNumber(this.modelLocal[1]);
        if (!isNaN(MODEL_NUMBER_0) && !isNaN(MODEL_NUMBER_1)) {
          return [MODEL_NUMBER_0, MODEL_NUMBER_1];
        }
      }
      return this.options.center || CENTER_DE;
    },
  },
};
