import {
  isNil,
  isNumber,
  size,
} from "lodash-es";

export default {
  computed: {

    showClearButton() {
      return this.statusClearModelButton && !this.isDisabled;
    },

    statusClearModel() {
      return isNil(this.options.clearModel) ? true : this.options.clearModel;
    },

    statusClearModelButton() {
      if (isNil(this.model) || this.options.type === "decimal" || this.options.type === "number") {
        return false;
      }
      return this.statusClearModel && (size(this.model) || isNumber(this.model));
    },

    containerCls() {
      return this.showClearButton ? "has_button_clear" : "";
    },
  },
};
