import OptionsMixin from "../../../DynamischeTabelle/UiDynamischeTabelle/ModalCreateOrUpdate/OptionsMixin";
import { PARAMS } from "./Parameters";

// @vue/component
export default {
  name: "OptionsMixin",
  mixins: [
    OptionsMixin,
  ],
  methods: {
    getParamList() {
      return PARAMS;
    },
    onPrepareData() {
      this.gutschein_typ = {
        type: "select",
        id: "gutschein_typ",
        htmlId: "modal_gutschein_typ",
        label: "Gutscheintyp",
        required: true,
        view: "v",
        keyId: "pk",
        keyLabel: "bez",
        search: true,
        searchList: ["bez"],
        url: "katalog/?key=gutscheintypen",
        apiSaveId: "gutscheintypen",
        deselect: false,
      };
      this.gutschein_spalte_id = {
        type: "text",
        id: "gutschein_spalte_id",
        label: "Gutscheinspalte-ID",
        required: false,
        view: "v",
      };
    },
  }
};
