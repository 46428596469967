import HttpMixin from "../../../../../../global/mixins/HttpMixin";
import PuxCloak from "../../../../../../global/components/PuxCloak/PuxCloak.vue";
import PuxTranslate from "../../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import UiAntragstellerRechtsformZusatzReadOnlyElement from "./UiAntragstellerRechtsformZusatzReadOnlyElement/UiAntragstellerRechtsformZusatzReadOnlyElement.vue";

import {
  orderBy,
  reduce,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "UiAntragstellerRechtsformZusatzReadOnly",
  components: {
    PuxCloak,
    PuxTranslate,
    UiAntragstellerRechtsformZusatzReadOnlyElement,
  },
  mixins: [
    HttpMixin
  ],
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    className: {
      type: String,
      default: "",
    },
    model: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      katalogLoading: {
        krechtsform: true,
        elementfoerderregel: true
      },
      katalogData: {
        krechtsform: [],
        elementfoerderregel: [],
      },
    };
  },
  computed: {
    modelSize() {
      return size(this.model);
    },
    modelList() {
      return orderBy(this.model, ["pos"]);
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loadKatalogData("krechtsform");
      this.loadKatalogData("elementfoerderregel");
    },
    loadKatalogData(key) {
      this.katalogLoading[key] = true;
      this.getListHttp({
        url: `katalog/`,
        urlParams: {
          key: key,
        },
      }).then(
        response => {
          this.katalogData[key] = response;
        }
      ).finally(() => {
        this.katalogLoading[key] = false;
        this.loading = reduce(this.katalogLoading, (val, el) => (val || el), false);
      });
    },
  },
};
