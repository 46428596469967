import {
  forEach,
  isArray,
  isNil,
  isString,
  toString,
  toNumber,
  floor,
  forEachRight,
  isPlainObject,
} from "lodash-es";
import { setUrlForArray } from "../../functions/utils";

import {
  navbarFixedHeight,
} from "../compositionAPI/LayoutAPI";

export function scrollToGlobal(selector, timeoutTime = 0, time = 500, parentSelector = "body,html", offset = 0) {
  const headerHeight = navbarFixedHeight.value || 0;
  const actionsAlwaysVisibleHeight = $("#always-visible").outerHeight(true) || 0;
  const OFFSET = headerHeight + actionsAlwaysVisibleHeight + offset;
  scrollToRelative(selector, timeoutTime, time, parentSelector, OFFSET);
}

export function scrollToRelative(selector, timeoutTime = 0, time = 500, parentSelector = "body,html", offset = 0) {
  setTimeout(() => {
    $(parentSelector).stop();
    const SELECTOR = $(selector);
    if (!SELECTOR.length) {
      return;
    }
    const SELECTOR_TOP = $(selector).offset().top;
    const DESTINATION = SELECTOR_TOP - offset;
    $(parentSelector).animate({ scrollTop: DESTINATION }, time, "linear");
  }, timeoutTime);
}

export function setUrl(params, prefix = "") {
  let url = "";
  forEach(params, (value, key) => {
    if (isArray(value)) {
      forEach(value, v => {
        if (url) {
          url += "&";
        }
        url += `${ key }=${ v }`;
      });
    } else {
      if (!isNil(value)) {
        if (url) {
          url += "&";
        }
        url += `${ key }=${ value }`;
      }
    }
  });
  return url ? `${ prefix }${ url }` : url;
}

export function setMoneyFormat(value) {
  if (isNil(value)) {
    return "";
  }
  let valString = toString(value);
  const countCommas = valString.split(",").length - 1;
  if (countCommas > 1) {
    return value;
  }

  valString = valString.replace(",", ".").replace(/[.](?=.*[.])/g, "");
  const valNumber = toNumber(valString);
  if (!valNumber) {
    return value;
  }
  const valArr = valString.split(".");
  let fractVal = "00";
  if (valArr[1]) {
    fractVal = valArr[1].slice(0, 2);
    if (fractVal.length === 1) {
      fractVal += "0";
    }
  }
  const floorVal = floor(valNumber);
  const floorValString = floorVal + "";

  let count = 0;
  let antwort = "";
  forEachRight(floorValString, val => {
    if (count > 0 && count % 3 === 0) {
      antwort += ".";
    }
    count++;
    antwort += val;
  });
  return `${ antwort.split("").reverse().join("") },${ fractVal }`;
}

export function getUrlParams({ url } = {}) {
  let urlParamsString = url || window.location.search.substring(1);
  try {
    urlParamsString = decodeURI(urlParamsString);
  } catch (e) {
    console.error(e);
  }
  const urlParamsArray = urlParamsString.split("&");
  const urlParamsObj = {};
  forEach(urlParamsArray, item => {
    const pair = item.split("=");
    if (pair && pair.length === 2) {
      if (pair[1].indexOf("[") !== -1 || pair[1].indexOf("%5B") !== -1) { // Wenn in URL array steht. Z.B.: doc_type1=%5B%22uuid1%22%5D oder doc_type1=["uuid1"]
        const stringValue = pair[1].replace("%5B", "[").replace("%5D", "]").replace(/%22/g, `"`);
        urlParamsObj[pair[0]] = JSON.parse(stringValue);
      } else if (urlParamsObj[pair[0]]) {
        if (isString(urlParamsObj[pair[0]])) {
          urlParamsObj[pair[0]] = [urlParamsObj[pair[0]]];
          urlParamsObj[pair[0]].push(pair[1]);
        } else if (isArray(urlParamsObj[pair[0]])) {
          urlParamsObj[pair[0]].push(pair[1]);
        }
      } else {
        urlParamsObj[pair[0]] = pair[1];
      }
    }
  });
  return urlParamsObj;
}

export function setUrlWithParams({ url, params }) {
  const URL = url || "";
  let urlParams = "";
  forEach(params, (value, key) => {
    if (isArray(value)) {
      urlParams = setUrlForArray({ id: key, array: value, url: urlParams });
    } else if (isPlainObject(value)) {
      forEach(value, (v, k) => {
        if (v) {
          if (urlParams) {
            urlParams += "&";
          }
          urlParams += `${ k }=${ v }`;
        }
      });
    } else if (!isNil(value) && value !== "") {
      if (urlParams) {
        urlParams += "&";
      }
      urlParams += `${ key }=${ value }`;
    }
  });
  const prefix = urlParams && URL ? URL.indexOf("?") === -1 ? "?" : "&" : "";

  return `${ URL }${ prefix }${ urlParams }`;
}
