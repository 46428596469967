import AButton from "aloha-vue/src/AButton/AButton";
import UIComponentMixin from "../UIComponentMixin";
import translate from "../../../directives/translate";

import {
  isNil,
  cloneDeep,
  assign,
  isEqual,
  unset,
  keys,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "UiStrMapping",
  components: {
    AButton,
  },
  directives: {
    translate,
  },
  mixins: [
    UIComponentMixin,
  ],
  data() {
    return {
      fields: {
        from: {
          value: undefined,
          focus: false
        },
        to: {
          value: undefined,
          focus: false,
        },
      },
      okToAdd: false
    };
  },
  computed: {
    translationLabelFrom() {
      return {
        text: "_TXT_UI_STR_MAPPING_INPUT_FROM_LABEL_",
      };
    },

    translationLabelTo() {
      return {
        text: "_TXT_UI_STR_MAPPING_INPUT_TO_LABEL_",
      };
    },

    translationBtnAdd() {
      return {
        title: "_BTN_UI_STR_MAPPING_ADD_",
      };
    },

    translationBtnRemove() {
      return {
        title: "_BTN_UI_STR_MAPPING_REMOVE_TITLE_",
      };
    },

    transaltionEmpty() {
      return {
        text: "_TXT_UI_STR_MAPPING_EMPTY_",
      };
    },

    orderedKeys() {
      return keys(this.model).sort((a, b) => a.localeCompare(b));
    },

    idFrom() {
      return `${ this.getId }_from`;
    },

    idTo() {
      return `${ this.getId }_to`;
    },
  },
  methods: {
    onAdd($event) {
      const FROM = this.fields.from.value;
      const TO = this.fields.to.value;
      if (isNil(FROM) || isNil(TO)) {
        return;
      }
      const VALUE_LOCAL = assign({}, this.model, { [FROM]: TO });
      if (isEqual(VALUE_LOCAL, this.model)) { // Workaround für ie11
        return;
      }
      this.fields.from.value = undefined;
      this.fields.to.value = undefined;
      this.input({
        currentModel: VALUE_LOCAL,
        model: VALUE_LOCAL,
        id: this.options.id,
        $event: $event,
        param: this.options.param,
        options: this.options,
      });
      this.change({
        currentModel: VALUE_LOCAL,
        model: VALUE_LOCAL,
        id: this.options.id,
        $event: $event,
        param: this.options.param,
        options: this.options,
      });
    },

    onRemove($event, key) {
      const VALUE_LOCAL = cloneDeep(this.model);
      unset(VALUE_LOCAL, key);

      if (isEqual(VALUE_LOCAL, this.model)) { // Workaround für ie11
        return;
      }
      this.input({
        currentModel: VALUE_LOCAL,
        model: VALUE_LOCAL,
        id: this.options.id,
        $event: $event,
        param: this.options.param,
        options: this.options,
      });
      this.change({
        currentModel: VALUE_LOCAL,
        model: VALUE_LOCAL,
        id: this.options.id,
        $event: $event,
        param: this.options.param,
        options: this.options,
      });
    },

    onInput(feld, $event) {
      this.okToAdd = !isNil(this.fields.from.value) && !isNil(this.fields.to.value);
      this.fields[feld].value = $event.target.value;
    },

    onFocus(feld) {
      this.fields[feld].focus = true;
    },

    onBlur(feld) {
      this.fields[feld].focus = false;
    },

    getFormClass(feld) {
      const VAL = this.fields[feld].value;
      return {
        form_vertical: true,
        form_vertical_filled: !isNil(VAL) && size(VAL) !== 0,
        form_vertical_focus: this.fields[feld].focus,
        form_vertical_not_focus: !this.fields[feld].focus,
      };
    },
  },
};
