import {
  cloneDeep,
} from "lodash-es";

const NOTIFICATION_TIMEOUT_DEFAULT = 5000;

export default {
  namespaced: true,
  state() {
    return {
      NOTIFICATION: {},
      NOTIFICATION_COUNT: 0,
      NOTIFICATION_TIMEOUT: NOTIFICATION_TIMEOUT_DEFAULT,
    };
  },
  getters: {
    GET_SAVED_API: state => ({ apiSaveId }) => {
      return state.APIS[apiSaveId];
    },
  },
  mutations: {
    ADD_NOTIFICATION_MUT(state, { text, type }) {
      const notificationClone = cloneDeep(state.NOTIFICATION);
      notificationClone[state.NOTIFICATION_COUNT] = { text, type };
      state.NOTIFICATION = notificationClone;
      state.NOTIFICATION_COUNT++;
    },

    REMOVE_NOTIFICATION_MUT(state, index) {
      const notificationClone = cloneDeep(state.NOTIFICATION);
      if (notificationClone[index]) {
        delete notificationClone[index];
      }
      state.NOTIFICATION = notificationClone;
    },

    SET_NOTIFICATION_TIMEOUT_MUT(state, timeout) {
      state.NOTIFICATION_TIMEOUT = timeout;
    },
  },
  actions: {
    ADD_NOTIFICATION_ACT({ commit, state }, { text, type, timeout }) {
      const TIMEOUT_LOCAL = timeout || state.NOTIFICATION_TIMEOUT;
      const currentIndex = state.NOTIFICATION_COUNT;
      commit("ADD_NOTIFICATION_MUT", { text, type });
      if (TIMEOUT_LOCAL) {
        setTimeout(() => {
          commit("REMOVE_NOTIFICATION_MUT", currentIndex);
        }, TIMEOUT_LOCAL);
      }
    },
  },
};
