// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form_control.textarea_scalable {
    resize: none;
    overflow: hidden;
    height: calc(2.25rem + 2px);
    max-height: none;
    min-height: auto;
}
`, "",{"version":3,"sources":["webpack://./client/src/global/components/ui/UiTextarea/UiTextarea.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,gBAAgB;IAChB,2BAA2B;IAC3B,gBAAgB;IAChB,gBAAgB;AACpB","sourcesContent":[".form_control.textarea_scalable {\n    resize: none;\n    overflow: hidden;\n    height: calc(2.25rem + 2px);\n    max-height: none;\n    min-height: auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
