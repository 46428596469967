import AButton from "aloha-vue/src/AButton/AButton";
import PuxTranslate from "../../../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import UiAbschreibungsraten from "../../UiAbschreibungsraten/UiAbschreibungsraten.vue";

import HttpMixin from "../../../../../../../global/mixins/HttpMixin";

import ZweckbindungUUIDs from "../../../../../../../const/ZweckbindungUUIDs";

import {
  assign,
  cloneDeep, isNil,
} from "lodash-es";

// @vue/component
export default {
  name: "UiZweckbindungFormEdit",
  components: {
    AButton,
    PuxTranslate,
    UiAbschreibungsraten,
  },
  mixins: [
    HttpMixin
  ],
  props: {
    modelCurrent: {
      type: Object,
      required: false,
      info: "Das Model bevor es bearbeitet wurde. Wird verwendet um Anfangsdaten zu setzen beim Aktualisieren.",
      default: () => ({})
    },
    errors: {
      type: [Object, Array],
      required: false,
      default: () => ({})
    },
    close: {
      type: Function,
      required: true,
    },
    save: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      model: {
        bewilligt_von: null,
        bewilligt_bis: null,
        datum_typ: null,
        auszahlungdatumsbezug: null,
        regel_key: null,
        regel_param: null,
        abschreibungsraten: [],
      },
      uuids: ZweckbindungUUIDs,
    };
  },
  computed: {
    getHeader() {
      return this.modelCurrent ? "Bewilligungszeitraum aktualisieren" : "Bewilligungszeitraum hinzufügen";
    },

    isDisabled() {
      return !this.model.bewilligt_von || !this.model.bewilligt_bis || isNil(this.model.datum_typ)
        || !(this.model.regel_key || this.model.auszahlungdatumsbezug
        || this.model.datum_typ === this.uuids.TAGESDATUM) || !this.model.abschreibungsraten
        || (this.model.datum_typ === this.uuids.GESCHAEFTSREGEL && isNil(this.model.regel_param));
    },

    options() {
      return {
        bewilligt_von: {
          type: "date",
          id: "bewilligt_von",
          label: "Bewilligt ab",
          required: true,
          view: "v",
        },
        bewilligt_bis: {
          type: "date",
          id: "bewilligt_bis",
          label: "Bewilligt bis",
          required: true,
          view: "v",
        },
        datum_typ: {
          type: "select",
          id: "datum_typ",
          label: "Art der Datumswahl",
          required: true,
          view: "v",
          keyId: "value",
          keyLabel: "bez",
          data: [
            {
              bez: "Tagesdatum",
              value: this.uuids.TAGESDATUM,
            },
            {
              bez: "Auszahlungsbezugsdatum",
              value: this.uuids.SYAUSZAHLUNGBEZUGSDATUM,
            },
            {
              bez: "Geschäftsregel",
              value: this.uuids.GESCHAEFTSREGEL,
            },
          ]
        },
        auszahlungdatumsbezug: {
          type: "select",
          id: "auszahlungdatumsbezug",
          label: "Bezungsdatum für Auszahlungen",
          required: true,
          view: "v",
          keyId: "id",
          keyLabel: "abd_kbez",
          translate: true,
          url: "katalog/?key=auszahlungdatumsbezug",
        },
        regelKey: {
          type: "select",
          id: "regel_key",
          label: "Regelschlüssel",
          keyId: "id",
          search: true,
          searchList: ["rek_kbez"],
          keyLabel: "rek_kbez",
          url: "katalog/?key=regelkeys",
          translate: true,
          view: "v",
          required: true,
        },
        regelParam: {
          type: "text",
          id: "regel_param",
          label: "Parameter der Geschäftsregel",
          view: "v",
          required: true,
          translate: true,
        },
        abschreibungsraten: {
          type: "dhw_abschreibungsraten",
          id: "abschreibungsraten",
          view: "v-alt",
        }
      };
    },

    datumTyp() {
      return this.model ? this.model.datum_typ : undefined;
    },
  },
  watch: {
    datumTyp(newValue) {
      this.model.auszahlungdatumsbezug = undefined;
      this.model.foerderregel = undefined;
      this.model.regel_key = undefined;
      if (!isNil(newValue)) {
        if (this.modelCurrent) {
          if (this.model.datum_typ === this.uuids.GESCHAEFTSREGEL) {
            this.model.foerderregel = this.modelCurrent.foerderregel;
            this.model.regel_key = this.modelCurrent.regel_key;
          } else if (this.model.datum_typ === this.uuids.SYAUSZAHLUNGBEZUGSDATUM) {
            this.model.auszahlungdatumsbezug = this.modelCurrent.auszahlungdatumsbezug;
          }
        }
      }
    }
  },
  beforeCreate() {
    this.$options.components.FormElement = require("../../../../../../../global/components/FormElement/FormElement.vue").default;
  },
  created() {
    this.initModel();
  },
  methods: {
    initModel() {
      if (this.modelCurrent) {
        this.model = assign(this.model, this.modelCurrent);
      }
    },

    saveLocal() {
      this.save({ model: cloneDeep(this.model) });
    },
  },
};
