import {
  inject,
} from "vue";

import UIComponentMixin from "../UIComponentMixin";

import { JSONEditor } from "vanilla-jsoneditor";

import {
  get,
  isString,
} from "lodash-es";

// @vue/component
export default {
  name: "UiJsonEditor",
  mixins: [
    UIComponentMixin,
  ],
  setup() {
    const setDisabledFromChild = inject("setDisabledFromChild", () => {});

    return {
      setDisabledFromChild,
    };
  },
  data() {
    return {
      editor: undefined,
    };
  },
  mounted() {
    this.$el.style.maxHeight = `${ this.options.maxEditorHeight || 300 }px`;
    this.editor = new JSONEditor({
      target: this.$el,
      props: {
        content: {
          text: undefined,
          json: this.model || {}
        },
        onChange: (updatedContent, previousContent, { contentErrors }) => {
          if (!get(contentErrors, "parseError")) {
            let data = updatedContent.text || updatedContent.json;
            if (!data) {
              data = this.options.defaultEmpty || "{}";
              this.editor.updateProps({ text: data });
            }
            if (isString(data)) {
              data = JSON.parse(data);
            }
            this.onInput_mixin({ value: data });
            this.setDisabledFromChild({ id: "templateinhalt_save", disabled: false });
          } else {
            this.setDisabledFromChild({ id: "templateinhalt_save", disabled: true });
          }
        },
        mode: "text"
      }
    });
  },
  beforeUnmount() {
    this.editor.destroy();
  },
};
