import AButton from "aloha-vue/src/AButton/AButton";
import PuxIcon from "../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import UiBelegeFieldBaseMixin from "./UiBelegeFieldMixin";
import UIComponentMixin from "../../../../global/components/ui/UIComponentMixin";


import {
  assign,
  cloneDeep,
  findIndex,
  findKey,
  forEach,
  get,
  isNil,
  keys,
} from "lodash-es";

// @vue/component
export default {
  name: "UiBelegeField",
  components: {
    AButton,
    PuxIcon,
    PuxTranslate,
  },
  mixins: [
    UiBelegeFieldBaseMixin,
    UIComponentMixin,
  ],
  data() {
    return {
      belegFieldsFormTemplates: undefined
    };
  },
  computed: {
    localErrors() {
      const ERR = {
        non_field_errors: []
      };
      forEach(keys(this.belegFields), belegField => {
        ERR[belegField] = {};
      });
      assign(ERR, this.errors || {});
      return ERR;
    },
  },
  beforeCreate() {
    this.$options.components.FormElement = require("../../../../global/components/FormElement/FormElement.vue").default;
  },
  created() {
    this.initForm();
  },
  methods: {
    initBelegFieldsFormTemplates() {
      this.belegFieldsFormTemplates = {
        show: {
          type: "one_checkbox",
          required: false,
          showLabel: false,
          classLabel: "w-auto",
          className: "a_width_100 a_justify_content_center",
          anzeigemodus: 1,
          default: false,
          change: this.changeShow,
        },
        required: {
          type: "one_checkbox",
          required: false,
          showLabel: false,
          classLabel: "w-auto",
          className: "a_width_100 a_justify_content_center",
          anzeigemodus: 1,
          default: false,
          change: this.onInput,
        },
        help_text: {
          type: "text",
          label: "_LBL_BELEG_FELDER_CONFIG_HELP_TEXT_",
          required: false,
          view: "v",
          default: undefined,
          change: this.onInput,
        },
        objektkatalog: {
          type: "select",
          label: "_LBL_BELEG_FELDER_CONFIG_OBJEKTKATALOG_",
          required: false,
          view: "v",
          url: "katalog/?key=kobjektkatalog",
          keyId: "pk",
          keyLabel: "bez",
          search: true,
          deselect: true,
          default: undefined,
          change: this.onInput,
        },
      };
    },
    initForm() {
      forEach(this.belegFieldsForm, conf => {
        const STATUS_SHOW = get(this.localModel, [conf.id, "show"], false);
        forEach(keys(this.belegFieldsFormTemplates), k => {
          if (!isNil(conf[k]) && k !== "show") {
            conf[k].disabled = !STATUS_SHOW;
          }
        });
      });
    },
    changeShow({ model, id, options, $event }) {
      this.onInput({ model, options, $event });
      forEach(keys(this.belegFieldsFormTemplates), k => {
        const belegFeldIdx = findIndex(this.belegFieldsForm, belegFeld => {
          return belegFeld.id === options.belegField;
        });
        const FELD = get(this.belegFieldsForm, [belegFeldIdx, k]);
        if (!isNil(FELD) && FELD.id !== id) {
          const NEW_FELD = cloneDeep(FELD);
          NEW_FELD.disabled = !model;
          this.belegFieldsForm[belegFeldIdx][k] = NEW_FELD;
        }
      });
    },
    onInput({ model, options, $event }) {
      const MODEL = cloneDeep(this.localModel);
      MODEL[options.belegField][options.id] = model;
      this.onInput_mixin({ value: MODEL, $event });
    },
    moveUp({ id }) {
      if (this.localModel[id].pos < 1) {
        return;
      }
      const MODEL = cloneDeep(this.localModel);
      const POS = MODEL[id].pos - 1;
      const KEY = findKey(MODEL, ["pos", POS]);
      MODEL[id].pos--;
      MODEL[KEY].pos++;
      this.onInput_mixin({ value: MODEL });
    },
    moveDown({ id }) {
      if (this.localModel[id].pos >= this.getModelSize - 1) {
        return;
      }
      const MODEL = cloneDeep(this.localModel);
      const POS = MODEL[id].pos + 1;
      const KEY = findKey(MODEL, ["pos", POS]);
      MODEL[id].pos++;
      MODEL[KEY].pos--;
      this.onInput_mixin({ value: MODEL });
    },
  }
};
