import AButton from "aloha-vue/src/AButton/AButton";
import Modal from "../../Modal/Modal.vue";
import translate from "../../../directives/translate";

// @vue/component
export default {
  name: "TableModalWorkflowMassActionsConfirm",
  components: {
    AButton,
    Modal,
  },
  directives: {
    translate,
  },
  props: {
    close: {
      type: Function,
      default: () => {},
    },
    confirm: {
      type: Function,
      default: () => {},
    },
    label: {
      type: String,
      default: "",
    },
    count: {
      type: Number,
      default: 0
    },
    selectorClose: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      model: {},
      options: {
        showCloseButton: true,
        required: false,
        list: [],
      },
      status: {
        loading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
      title: "",
      buttonName: "",
      buttonIcon: "",
      buttonClassName: "",
    };
  },
};
