import {
  isNil,
} from "lodash-es";

export default {
  data() {
    return {
      modelLocal: true,
    };
  },
  computed: {
    maxValue() {
      return this.options.maxValue || this.options.max_value;
    }
  },
  methods: {
    checkMaxValue({ value }) {
      if (!isNil(this.maxValue) && this.maxValue < value) {
        return +this.maxValue;
      }
      return value;
    },
  },
};
