import PuxTranslate from "../../../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import UIReadOnlyMixin from "../../../../../../../global/components/ui/UIReadOnlyMixin";

import {
  find, isEmpty,
} from "lodash-es";

// @vue/component
export default {
  name: "UiDynamischeTabelleSpaltenAuswahlRadioReadOnly",
  components: {
    PuxTranslate,
  },
  mixins: [
    UIReadOnlyMixin,
  ],
  computed: {
    hasNoSpalten() {
      return isEmpty(this.dependencyValue);
    },

    hasNoSpaltenLabel() {
      return "_TXT_UI_DYNAMISCHE_TABELLE_SPALTEN_AUSWAHL_RADIO_READ_ONLY_NO_SPALTEN_";
    },

    label() {
      const ITEM = find(this.dependencyValue || [], ["id", this.model]);
      if (ITEM) {
        return ITEM.label;
      }
      return "_TXT_UI_DYNAMISCHE_TABELLE_SPALTEN_AUSWAHL_RADIO_READ_ONLY_NO_SELECTION_";
    },
  },
};
