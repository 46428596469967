import {
  cloneDeep,
  endsWith,
  isNil,
  reduce,
} from "lodash-es";

export default {
  namespaced: true,
  state() {
    return {
      STATUS_DIFF_FOR_REGELS: {},
    };
  },
  getters: {
    GET_STATUS_DIFF_FOR_REGEL: state => regelId => {
      return state.STATUS_DIFF_FOR_REGELS[regelId];
    },
    GET_STATUS_DIFF_FOR_CHILD_SUFFIX: state => childSuffix => {
      return reduce(state.STATUS_DIFF_FOR_REGELS, (result, value, key) => {
        return result || (endsWith(key, childSuffix) && value);
      }, false);
    },
  },
  mutations: {
    MUT_SET_STATUS_DIFF_FOR_REGEL(state, { regelId, statusDiff }) {
      const STATUS_DIFF_FOR_REGELS = cloneDeep(state.STATUS_DIFF_FOR_REGELS);
      STATUS_DIFF_FOR_REGELS[regelId] = statusDiff;
      state.STATUS_DIFF_FOR_REGELS = STATUS_DIFF_FOR_REGELS;
    },

    MUT_DELETE_STATUS_DIFF_FOR_REGEL(state, { regelId }) {
      const STATUS_DIFF_FOR_REGELS = cloneDeep(state.STATUS_DIFF_FOR_REGELS);
      if (!isNil(STATUS_DIFF_FOR_REGELS[regelId])) {
        delete STATUS_DIFF_FOR_REGELS[regelId];
      }
      state.STATUS_DIFF_FOR_REGELS = STATUS_DIFF_FOR_REGELS;
    },

    MUT_RESET_STATUS_DIFF(state) {
      state.STATUS_DIFF_FOR_REGELS = {};
    },
  },
};
