import UiAntragslistePdfConfig from "./UiAntragslistePdfConfig/UiAntragslistePdfConfig.vue";

// // ReadOnly
import UiAntragslistePdfConfigReadOnly from "./UiAntragslistePdfConfig/UiAntragslistePdfConfigReadOnly/UiAntragslistePdfConfigReadOnly.vue";

export default {
  UiAntragslistePdfConfig
};

export const READ_ONLY = {
  UiAntragslistePdfConfigReadOnly
};

export const MAPPING = {
  sitzung_antragsliste_pdf_config: "UiAntragslistePdfConfig",
};

export const MAPPING_READONLY = {
  sitzung_antragsliste_pdf_config: "UiAntragslistePdfConfigReadOnly",
};

export const MODEL = {};

export const EXTRA_OPTIONS_FOR_TYPE = {};
