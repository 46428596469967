import UIReadOnlyMixin from "../../UIReadOnlyMixin";

// @vue/component
export default {
  name: "UiDocumentReadOnly",
  mixins: [
    UIReadOnlyMixin,
  ],
  computed: {
    getModel() {
      if (this.model && this.model.titel) {
        return this.model.titel;
      }
      return "";
    },

    getHref() {
      if (this.model && this.model.url) {
        return this.model.url;
      }
      return "#";
    },

    getSrc() {
      if (this.model && this.model.url) {
        return this.model.url.replace("dokumente", "preview");
      }
      return "";
    },
  },
  methods: {
    showLink() {
      return !this.options.hideLink && this.model.url;
    }
  },
};
