const NutzerAnonymisiertList = () => import(/* webpackChunkName: "NutzerAnonymisiertList" */ "../../views/Nutzer/NutzerAnonymisiertList/NutzerAnonymisiertList.vue");

export default [
  {
    path: "/anonymisierte_nutzer/",
    name: "root.anonymisierte_nutzer",
    component: NutzerAnonymisiertList,
    meta: {
      permissions: [
        "nutzer.anonymise.view",
      ],
    },
  },
];
