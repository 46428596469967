import UiDynamischeTabelleReadOnly from "../../../DynamischeTabelle/UiDynamischeTabelle/UiDynamischeTabelleReadOnly/UiDynamischeTabelleReadOnly.js";

import {
  PARAMS,
} from "../ModalCreateOrUpdate/Parameters";
import {
  keyBy,
} from "lodash-es";

// @vue/component
export default {
  name: "UiGutscheineErfassenReadOnly",
  mixins: [
    UiDynamischeTabelleReadOnly,
  ],
  methods: {
    setTypeByKey() {
      this.typeByKey = keyBy(PARAMS, "value");
    },
  },
};
