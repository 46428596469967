import UiMultiselectReadOnly
  from "../../../../../../global/components/ui/UiSelect/UiMultiselectReadOnly/UiMultiselectReadOnly";
import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "UiDynamischeTabelleObjektkatalogDataReadOnly",
  mixins: [
    UiMultiselectReadOnly,
  ],
  props: {
    dependencyValue: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      default: undefined,
    },
  },
  computed: {
    getCollection() {
      return this.dependencyValue || {};
    },

    optionsLocal() {
      const OPTIONS = cloneDeep(this.options);
      OPTIONS.keyId = "id";
      OPTIONS.keyLabel = "label";
      return OPTIONS;
    },
  },
};
