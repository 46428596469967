import DokumentMassActionSammeldokument from "../../../views/Dokumente/DokumentMassActionSammeldokument/DokumentMassActionSammeldokument.vue";

// Liste der von ./TableMassActionModal.mixin.js abgeleiteten Komponenten
export default {
  DokumentMassActionSammeldokument
};

// Liste der von ./TableMassActionModal.mixin.js abgeleiteten Komponenten
// für die Auswahl in einer UiSelect
export const CHOICES = [
  { id: DokumentMassActionSammeldokument.name, label: "Sammeldokument erstellen" }
];

// Mapping der zusätzlich Konfigurierbaren Attribute für
// von ./TableMassActionModal.mixin.js abgeleiteten Komponenten
export const EXTRA_PARAMETER = {
  [DokumentMassActionSammeldokument.name]: DokumentMassActionSammeldokument.extraPropParams
};
