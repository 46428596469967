import PuxDatepicker from "../../PuxDatepicker/PuxDatepicker.vue";

import UIComponentMixin from "../UIComponentMixin";

import moment from "moment";
import {
  gettext,
} from "../../../functions/utils";
import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "UiDatepicker",
  components: {
    PuxDatepicker,
  },
  mixins: [
    UIComponentMixin,
  ],
  props: {
    selected: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      mod: undefined,
      datePopupStyles: {},
      timePopupStyles: {},
    };
  },
  computed: {
    dateInputAttributes() {
      return {
        "aria-required": this.options.required ? "true" : "false",
        "aria-invalid": this.isError ? "true" : "false",
      };
    },

    dateInputAttributesForTime() {
      const ATTRIBUTES = cloneDeep(this.dateInputAttributes);
      ATTRIBUTES["aria-labelledby"] = `${ this.getId }_label`;
      return ATTRIBUTES;
    },

    inputClass() {
      return [
        this.className,
        "form-control form_control",
        this.options.classInput,
        {
          "is-invalid": this.isError,
        }
      ];
    },

    statusShowDate() {
      return this.options.type === "date" || this.options.type === "datetime";
    },

    statusShowTime() {
      return this.options.type === "datetime" || this.options.type === "time";
    },

    timeInputAttributes() {
      return {
        id: `${ this.idPrefix }${ this.options.attrId || this.options.key || this.options.id }_time`,
        "aria-required": this.options.required ? "true" : "false",
        "aria-invalid": this.isError ? "true" : "false",
      };
    },

    dateFormat() {
      return this.options.format || "DD.MM.YYYY";
    },

    timeFormat() {
      return this.options.formatTime || "HH:mm";
    },

    dateFormatSave() {
      return this.options.formatSaveDate || "YYYY-MM-DD";
    },

    timeFormatSave() {
      return this.options.formatSaveTime || "HH:mm";
    },

    timeMinuteStep() {
      return this.options.minuteStep || 5;
    },

    dateWidth() {
      if (this.options.type === "datetime") {
        return this.options.width || "200px";
      }
      return this.options.width || "100%";
    },

    timeWidth() {
      if (this.options.type === "datetime") {
        return this.options.widthTime || "150px";
      }
      return this.options.widthTime || "100%";
    },

    datePlaceholder() {
      const {
        placeholder = "",
      } = this.options;
      if (placeholder) {
        return gettext(placeholder);
      }
      return "";
    },

    timePlaceholder() {
      const {
        placeholderTime = "",
      } = this.options;
      if (placeholderTime) {
        return gettext(placeholderTime);
      }
      return "";
    },

    timeStyles() {
      return ({
        marginLeft: this.statusShowDate ? "20px" : 0,
      });
    },

    statusTimeDisabled() {
      if (this.statusShowDate) {
        return !this.model || this.options.disabledTime;
      }
      return this.isDisabled || this.options.disabledTime;
    },

    idForDate() {
      return this.getId;
    },

    idForTime() {
      return `${ this.getId }_time`;
    },
  },
  methods: {
    onBlurDate($event) {
      this.onBlur({ $event, key: "date" });
    },

    onBlurTime($event) {
      this.onBlur({ $event, key: "time" });
    },

    onBlur({ $event, key }) {
      setTimeout(() => { // Workaround, damit blur nach input ist.
        this.blur({
          id: this.options.id,
          model: this.model,
          $event: $event,
          param: this.options.param,
          key,
          options: this.options,
        });
      }, 400);
    },

    onFocusDate($event) {
      this.onFocus({ $event, key: "date" });
    },

    onFocusTime($event) {
      this.onFocus({ $event, key: "time" });
    },

    onFocus({ $event, key }) {
      this.closeAnotherDropdownWindows();
      this.setPositionLeft({ key });
      this.focus({
        id: this.options.id,
        model: this.model,
        $event: $event,
        param: this.options.param,
        key,
        options: this.options,
      });
    },

    setPositionLeft({ key }) {
      const INPUT_RECT = this.$refs[key].$el.getBoundingClientRect();
      this[`${ key }PopupStyles`].left = `${ INPUT_RECT.left }px`;
    },

    onSelectDate(arg) {
      if (!arg) {
        this.onSelect("");
        return;
      }
      let date = "";
      if (this.statusShowTime) {
        const DATE = this.parseDate(arg, this.dateFormatSave);
        const CURRENT_TIME = this.getTimeWithFormat({ date: this.model });
        date = `${ DATE } ${ CURRENT_TIME }`;
      } else {
        date = this.parseDate(arg, this.dateFormatSave);
      }
      this.onSelect(date);
    },

    onSelectTime(arg) {
      let date = "";
      if (this.statusShowDate) {
        const TODAY = this.model ? moment(this.model).format(this.dateFormatSave) : "";
        const TIME = this.getTimeWithFormat({ date: arg });
        date = `${ TODAY }${ TIME ? ` ${ TIME }` : "" }`;
      } else {
        date = arg ? moment(arg).format(this.timeFormatSave) : arg;
      }
      this.onSelect(date);
    },

    getTimeWithFormat({ date }) {
      if (!date) {
        return moment().startOf("day").format(this.timeFormatSave);
      }
      return date ? moment(date).format(this.timeFormatSave) : "";
    },

    onSelect(date) {
      this.selected({
        model: date,
        currentModel: date,
        id: this.options.id,
        param: this.options.param,
      });
      this.onInput_mixin({ value: date });
    },

    closeAnotherDropdownWindows() {
      const BODY = document.querySelector("body");
      if (BODY.click) {
        BODY.click();
      }
    },

    parseDate(date, format) {
      try {
        return moment(new Date(date)).format(format) || null;
      } catch (e) {
        return null;
      }
    },
  },
};
