import {
  ref,
} from "vue";

import AHttpAPI from "aloha-vue/src/compositionAPI/AHttpAPI";

const TEN_MINUTES = 1000 * 60 * 10;

export default function CheckAPI() {
  const hasUpdate = ref(false);
  const revision = ref(undefined);

  const {
    getHttp,
  } = AHttpAPI();

  const checkVersion = response => {
    if (revision.value && response?.revision !== revision.value) {
      hasUpdate.value = true;
    }
  };

  const loadSysinfo = () => {
    getHttp({
      url: "meta/sysinfo/",
      abortGroup: "global",
    }).then(
      response => {
        checkVersion(response);
        revision.value = response.revision;
        if (!hasUpdate.value) {
          setTimeout(() => {
            loadSysinfo();
          }, TEN_MINUTES);
        }
      }
    );
  };

  return {
    hasUpdate,
    loadSysinfo,
    revision,
  };
}
