import CalendarObjectTypes from "../CalendarObjectTypes";

export default [
  {
    id: "suche",
    label: "_LBL_CALENDAR_FILTER_SUCHE_",
    type: "text",
    view: "v",
    translate: true,
  },
  {
    id: "typ",
    label: "_LBL_CALENDAR_FILTER_TERMIN_TYP_",
    type: "checkbox",
    url: "katalog/?key=termintyp",
    apiSaveId: "termintyp",
    keyLabel: "tmt_kbez",
    keyId: "pk",
    view: "v-alt",
    change: undefined,
    translate: true,
  },
  {
    id: "objekt_typ",
    label: "_LBL_CALENDAR_FILTER_OBJEKT_TYP_",
    type: "checkbox",
    keyId: "value",
    keyLabel: "label",
    view: "v-alt",
    data: CalendarObjectTypes,
    change: undefined,
    translate: true,
  },
  {
    id: "private",
    label: "_LBL_CALENDAR_FILTER_PRIVATE_",
    type: "boolean",
    trueValue: "True",
    falseValue: "False",
    trueLabel: "_LBL_CALENDAR_FILTER_PRIVATE_TRUE_",
    falseLabel: "_LBL_CALENDAR_FILTER_PRIVATE_FALSE_",
    defaultLabel: "_LBL_CALENDAR_FILTER_PRIVATE_DEFAULT_",
    view: "v-alt",
    translate: true,
  },
];
