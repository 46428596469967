import {
  forEach,
} from "lodash-es";

export function setUrlForArray({ array, url, id }) {
  if (!array.length) {
    return url;
  }
  let URL = url || "";
  forEach(array, v => {
    if (URL) {
      URL += "&";
    }
    URL += `${ id }=${ v }`;
  });
  return URL;
}
