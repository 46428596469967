import {
  watch,
} from "vue";

import AAlert from "aloha-vue/src/AAlert/AAlert";
import PuxErrorsElement from "./PuxErrorsElement/PuxErrorsElement.vue";
import PuxTranslate from "../PuxTranslate/PuxTranslate.vue";

import BtnCloseAPI from "./compositionAPI/BtnCloseAPI";
import CloseAPI from "./compositionAPI/CloseAPI";
import ErrorsAPI from "./compositionAPI/ErrorsAPI";
import FocusAPI from "./compositionAPI/FocusAPI";
import LabelsAPI from "./compositionAPI/LabelsAPI";

import {
  uniqueId,
} from "lodash-es";


// @vue/component
export default {
  name: "PuxErrors",
  components: {
    AAlert,
    PuxErrorsElement,
    PuxTranslate,
  },
  props: {
    autoFocus: {
      type: Boolean,
      required: false,
      default: true,
      info: "Fokus setzen, wenn this.statusShowErrors === true",
    },
    errors: {
      type: Object,
      required: false,
      default: undefined,
    },
    extra: {
      type: Object,
      required: false,
      default: undefined,
    },
    goToError: {
      type: Function,
      required: false,
      default: undefined,
    },
    header: {
      type: String,
      required: false,
      default: "_MSG_ERROR_",
    },
    id: {
      type: String,
      required: false,
      default: () => uniqueId("pux_errors_"),
    },
    idPrefix: {
      type: String,
      required: false,
      default: "",
    },
    isDismissible: {
      type: Boolean,
      required: false,
      default: false,
    },
    isErrorLink: {
      type: Boolean,
      required: false,
      default: true,
    },
    labels: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    optionsList: {
      type: Array,
      required: false,
      default: () => [],
      info: "Die Liste mit Options für FormElement",
    },
  },
  emits: [
    "onDismiss",
  ],
  setup(props, context) {
    const {
      labelsLocal,
    } = LabelsAPI(props);

    const {
      statusErrorsObject,
      statusShowErrors,
    } = ErrorsAPI(props);

    const {
      btnCloseAttributes,
    } = BtnCloseAPI(props);

    const {
      closeLocal,
    } = CloseAPI(context);

    const {
      componentRef,
      setFocusToElement,
    } = FocusAPI(props, {
      statusShowErrors,
    });

    watch(statusShowErrors, () => {
      setFocusToElement();
    }, {
      deep: true,
    });

    return {
      btnCloseAttributes,
      closeLocal,
      componentRef,
      labelsLocal,
      statusErrorsObject,
      statusShowErrors,
    };
  },
};
