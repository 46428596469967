import AButton from "aloha-vue/src/AButton/AButton";

import translate from "../../../../directives/translate";
import NotificationMixin from "../../../../mixins/NotificationMixin";
import { initModelFromList, toFormElementFromParameter } from "../../../../functions/mappingForParameterToFormElement";
import {
  cloneDeep,
  forEach,
  get,
  isNil,
  map,
  orderBy,
  pick,
  size,
  toLower,
} from "lodash-es";

// @vue/component
export default {
  name: "ModalCreateOrUpdate",
  components: {
    AButton,
  },
  directives: {
    translate,
  },
  mixins: [
    NotificationMixin,
  ],
  props: {
    formFields: {
      type: Object,
      required: true,
    },
    model: {
      type: Object,
      default: undefined,
    },
    currentModel: {
      type: Object,
      default: undefined,
    },
    idPrefix: {
      type: String,
      default: undefined,
    },
    errors: {
      type: Object,
      default: undefined,
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      modelLocal: {},
      idPlaceholder: undefined
    };
  },
  computed: {
    getHeader() {
      return this.currentModel
      ? "_TXT_UI_DYNAMISCHE_FORMDEFINITION_MODAL_UPDATE_TITLE_"
      : "_TXT_UI_DYNAMISCHE_FORMDEFINITION_MODAL_CREATE_TITLE_";
    },
    baseList() {
      const FORM = cloneDeep(this.formFields.base);
      FORM.type.change = this.onChangeType;
      FORM.label.change = this.onChangeLabel;
      FORM.id.placeholder = this.idPlaceholder;
      FORM.id.options.required = false;
      return toFormElementFromParameter({
        obj: FORM,
        showLabel: true,
        htmlIdPrefix: this.idPrefix,
      }).list;
    },
    typeList() {
      const FORM = cloneDeep(get(this.formFields, `typed.${ this.modelLocal.type }`, {}));
      this.prepareFormForType(this.modelLocal.type, FORM);
      return toFormElementFromParameter({
        obj: FORM,
        showLabel: true,
        htmlIdPrefix: this.idPrefix,
      }).list;
    },
    options() {
      return {
        showCloseButton: true,
        required: true,
        list: [
          ...this.baseList,
          ...this.typeList
        ],
      };
    },
  },
  beforeCreate() {
    this.$options.components = this.$options.components || {};
    this.$options.components.Modal = require("../../../Modal/Modal.vue").default;
  },
  created() {
    this.initModel();
  },
  methods: {
    initModel() {
      if (this.currentModel) {
        this.modelLocal = cloneDeep(this.currentModel);
        this.idPlaceholder = this.tokenize(get(this.modelLocal, "label"));
      } else {
        this.modelLocal.pos = size(this.model);
      }
      const DEFAULT_MODEL = initModelFromList({ list: this.baseList });
      forEach(DEFAULT_MODEL, (v, id) => {
        this.modelLocal[id] = get(this.modelLocal, id);
        if (isNil(this.modelLocal[id])) {
          this.modelLocal[id] = cloneDeep(v);
        }
      });
    },
    tokenize(str) {
      if (isNil(str)) {
        return;
      }
      return toLower(str).replace(/[^a-zA-Z0-9]/g, "");
    },
    prepareFormForType(type, form) {
      if (type === "select") {
        this.prepareKatalogauswahl(form);
      }
    },
    prepareKatalogauswahl(form) {
      if (size(this.modelLocal.katalog) > 0 && !this.modelLocal.komplett) {
        form.ids.choices = undefined;
        form.ids.katalog = this.modelLocal.katalog;
        form.ids.selectAll = true;
        form.ids.deselectAll = true;
      } else {
        form.ids.disabled = true;
        if (size(this.modelLocal.ids) > 0) {
          this.modelLocal.ids.splice(0);
        }
      }
    },
    onChangeLabel({ model }) {
      this.idPlaceholder = this.tokenize(model);
    },
    onChangeType() {
      const NEW_MODEL = pick(this.modelLocal, map(this.baseList, field => field.id));
      this.modelLocal = NEW_MODEL;
      const DEFAULT_MODEL = initModelFromList({ list: this.typeList });
      forEach(DEFAULT_MODEL, (v, id) => {
        this.modelLocal[id] = get(this.modelLocal, id);
        if (isNil(this.modelLocal[id])) {
          this.modelLocal[id] = cloneDeep(v);
        }
      });
    },
    save() {
      if (this.isDisabledSaveButton) {
        return;
      }
      const DATA = cloneDeep(this.modelLocal) || {};
      if (!DATA.id) {
        DATA.id = this.tokenize(DATA.label);
      }
      const MODEL = cloneDeep(this.model) || {};

      if (this.currentModel) {
        delete MODEL[this.currentModel.id];
      }
      MODEL[DATA.id] = DATA;
      forEach(MODEL, el => {
        el.pos = el.pos * 10;
        if (el.id === DATA.id) {
          el.pos -= 1;
        }
      });
      forEach(orderBy(MODEL, ["pos"]), (el, idx) => {
        el.pos = idx;
      });
      this.close({ model: MODEL });
    },
  },
};
