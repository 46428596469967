import PuxTranslate from "../../../PuxTranslate/PuxTranslate.vue";

import UiTreeGroupAPI from "../compositionAPI/UiTreeGroupAPI";

// @vue/component
export default {
  name: "UiTreeReadOnlyGroup",
  components: {
    PuxTranslate,
  },
  inheritAttrs: false,
  props: {
    modelItem: {
      type: Object,
      required: true,
    },
    modelItemIndex: {
      type: Number,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const {
      groupNumber,
      labelGroupLocal,
    } = UiTreeGroupAPI(props);

    return {
      groupNumber,
      labelGroupLocal,
    };
  },
  beforeCreate() {
    this.$options.components.FormElementReadOnly = require("../../../FormElement/FormElementReadOnly/FormElementReadOnly.vue").default;
  },
};
