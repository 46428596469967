import translate from "../../../../../../global/directives/translate";
import UiAdressenMixin from "../UiAdressenMixin";
import PuxIcon from "../../../../../../global/components/PuxIcon/PuxIcon.vue";

// @vue/component
export default {
  name: "UiAdressenReadOnly",
  components: {
    PuxIcon,
  },
  directives: {
    translate,
  },
  mixins: [
    UiAdressenMixin,
  ],
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    className: {
      type: String,
      default: "",
    },
    model: {
      type: Object,
      required: true,
    },
  },
};
