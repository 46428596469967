import {
  computed,
  toRef,
} from "vue";

export default function ExtraAPI(props) {
  const column = toRef(props, "column");
  const row = toRef(props, "row");
  const rowIndex = toRef(props, "rowIndex");

  const extra = computed(() => {
    return column.value.extraCallback ?
      column.value.extraCallback({
        row: row.value,
        rowIndex: rowIndex.value,
      }) :
      column.value.extra;
  });

  return {
    extra,
  };
}
