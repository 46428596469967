import {
  replaceText,
  gettext,
  isTranslatePlaceholder,
} from "../functions/utils";

import {
  addNotification as addNotificationFromAloha
} from "aloha-vue/src/compositionAPI/ANotificationAPI";

export default {
  methods: {
    addNotification({ text, type = "success", timeout, extra }) {
      let textLocal = text;
      const TYPE = type === "error" ? "danger" : type;
      if (isTranslatePlaceholder(text)) {
        textLocal = replaceText({ text: gettext(textLocal), object: extra });
      }
      addNotificationFromAloha({ text: textLocal, type: TYPE, timeout });
    },
  },
};
