import {
  computed,
  toRef,
} from "vue";

import {
  get,
} from "lodash-es";

export default function HasElementErrorsAPI(props) {
  const errorLabels = toRef(props, "errorLabels");

  const hasElementErrors = computed(() => {
    return get(errorLabels.value, "_hasElementErrors");
  });

  return {
    hasElementErrors,
  };
}
