import {
  get,
} from "lodash-es";

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
    options: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    value() {
      if (this.options.keyId) {
        return this.item[this.options.keyId];
      }
      return this.item.value;
    },

    label() {
      if (this.options.keyLabel) {
        return get(this.item, this.options.keyLabel);
      }
      return this.item.labelHtml || this.item.label;
    },

    idForInput() {
      return `${ this.options.id }_${ this.value }`;
    },

    idForButton() {
      return `${ this.options.id }_${ this.value }_info`;
    },

    inputName() {
      return `${ this.options.id }_name`;
    },

    columnCountClass() {
      if (this.options.columnCount === 2) {
        return "column-count-lg-2";
      }
      return "";
    },
  },
};
