const FoerderfinderantwortenDetails = () => import(/* webpackChunkName: "FoerderfinderantwortenDetails" */ "../../views/Foerderfinder/FoerderfinderantwortDetails/FoerderfinderantwortDetails.vue");
const FoerderfinderfragenDetails = () => import(/* webpackChunkName: "FoerderfinderfragenDetails" */ "../../views/Foerderfinder/FoerderfinderfragenDetails/FoerderfinderfragenDetails.vue");
const FoerderfinderFragenList = () => import(/* webpackChunkName: "FoerderfinderFragenList" */ "../../views/Foerderfinder/FoerderfinderFragenList/FoerderfinderFragenList.vue");

export default [
  {
    path: "/foerderfinderfragen/",
    name: "root.foerderfinderfragen",
    component: FoerderfinderFragenList,
    meta: {
      permissions: [
        "foerderfinderfrageantwort.view",
      ],
    },
  },
  {
    path: "/foerderfinderfragen/:id/",
    name: "root.foerderfinderfragen.detail",
    component: FoerderfinderfragenDetails,
    meta: {
      permissions: [
        "foerderfinderfrageantwort.view",
      ],
    },
  },
  {
    path: "/foerderfinderfragen/:id/antworten/:ffa_id/",
    name: "root.foerderfinderfragen.detail.antwort",
    component: FoerderfinderantwortenDetails,
    meta: {
      permissions: [
        "foerderfinderfrageantwort.view",
      ],
    },
  },
];
