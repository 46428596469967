import ADropdown from "aloha-vue/src/ADropdown/ADropdown";
import TableModalWorkflowMassActionsClientFunction from "../../table/TableModalWorkflowMassActionsClientFunction/TableModalWorkflowMassActionsClientFunction.vue";
import TableModalWorkflowMassActionsConfirm from "../../table/TableModalWorkflowMassActionsConfirm/TableModalWorkflowMassActionsConfirm.vue";
import TableModalWorkflowMassActionsTaskLog from "../../table/TableModalWorkflowMassActionsTaskLog/TableModalWorkflowMassActionsTaskLog.vue";

import ActionsAPI from "./compositionAPI/ActionsAPI";
import PksAPI from "./compositionAPI/PksAPI";

// @vue/component
export default {
  name: "PuxTableMultipleActionsWorkflowfolgen",
  components: {
    ADropdown,
    TableModalWorkflowMassActionsClientFunction,
    TableModalWorkflowMassActionsConfirm,
    TableModalWorkflowMassActionsTaskLog,
  },
  props: {
    action: {
      type: Object,
      required: true,
    },
    areAllRowsSelected: {
      type: Boolean,
      required: false,
    },
    close: {
      type: Function,
      required: false,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    id: {
      type: String,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    text: {
      type: String,
      required: false,
      default: "",
    },
    url: {
      type: String,
      required: false,
      default: "",
    },
    workflowfolgen: {
      type: Array,
      required: true,
    },
    updateTable: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const {
      pks,
    } = PksAPI(props);

    const {
      closeModalWorkflowMassActions,
      closeModalWorkflowMassActionsConfirm,
      closeModalWorkflowMassActionsTaskLog,
      continueMassActions,
      dropdownActions,
      modalWorkflowMassActionsObj,
      multipleActionObj,
      multipleActionObjData,
      status,
    } = ActionsAPI(props, {
      pks,
    });

    return {
      closeModalWorkflowMassActions,
      closeModalWorkflowMassActionsConfirm,
      closeModalWorkflowMassActionsTaskLog,
      continueMassActions,
      dropdownActions,
      modalWorkflowMassActionsObj,
      multipleActionObj,
      multipleActionObjData,
      status,
      pks,
    };
  },
};
