export default {
  namespaced: true,
  state() {
    return {
      MODEL: "",
    };
  },
  mutations: {
    MUT_CHANGE_MODEL(state, model) {
      state.MODEL = model;
    },
  },
};
