const FormulareList = () => import(/* webpackChunkName: "FormulareList" */ "../../views/Formulare/FormulareList/FormulareList.vue");
const BasisFormularCortex = () => import(/* webpackChunkName: "BasisFormularCortex" */ "../../views/Formulare/FormularDetails/BasisFormularCortex/BasisFormularCortex.vue");
const UmfrageList = () => import(/* webpackChunkName: "UmfrageList" */ "../../views/Umfrage/UmfrageList/UmfrageList.vue");
const UmfrageCortex = () => import(/* webpackChunkName: "UmfrageDetails" */ "../../views/Formulare/FormularDetails/UmfrageCortex/UmfrageCortex.vue");

export default [
  {
    path: "/formulare/",
    name: "root.basisformularcortex",
    component: FormulareList,
    meta: {
      permissions: [
        "basisformularcortex.list",
      ],
    },
  },
  {
    path: "/formulare/:id/",
    name: "root.basisformularcortex.detail",
    component: BasisFormularCortex,
    meta: {
      permissions: [
        "basisformularcortex.list",
      ],
    },
  },
  {
    path: "/umfrage/",
    name: "root.umfragecortex",
    component: UmfrageList,
  },
  {
    path: "/umfrage/:id/",
    name: "root.umfragecortex.detail",
    component: UmfrageCortex,
  },
];
