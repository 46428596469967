import AButton from "aloha-vue/src/AButton/AButton";
import UiZweckbindungFormEdit from "../UiZweckbindungFormEdit/UiZweckbindungFormEdit.vue";

import AFiltersAPI from "aloha-vue/src/compositionAPI/AFiltersAPI";

// @vue/component
export default {
  name: "UiZweckbindungElement",
  components: {
    AButton,
    UiZweckbindungFormEdit
  },
  props: {
    index: {
      type: Number,
      required: true
    },
    editIndex: {
      type: [Number, String],
      required: true
    },
    model: {
      type: Object,
      required: false,
      info: "Daten eines Modelelementes von UiZweckbindung.",
      default: () => ({})
    },
    errors: {
      type: [Object, Array],
      required: false,
      default: () => ({})
    },
    close: {
      type: Function,
      required: true,
    },
    save: {
      type: Function,
      required: true,
    },
    moveUp: {
      type: Function,
      required: true,
    },
    moveDown: {
      type: Function,
      required: true,
    },
    openEdit: {
      type: Function,
      required: true,
    },
    openDelete: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const {
      filterDate,
    } = AFiltersAPI();

    return {
      filterDate,
    };
  },
};
