import SyConfigMixin from "../../../../../../global/mixins/SyConfigMixin";

import {
  cloneDeep,
  forEach,
  toLower,
  isNil,
  isNull,
  findIndex,
  get,
  find,
} from "lodash-es";
import { LIST } from "./Parameters";

// @vue/component
export default {
  mixins: [
    SyConfigMixin,
  ],
  data() {
    return {
      allowedMimeTypeList: [],
      currentOptionsList: [],
      type: {
        type: "select",
        id: "type",
        label: "Typ",
        required: true,
        view: "v",
        keyLabel: "label",
        keyId: "value",
        data: [],
        change: this.changeType,
      },
      label: {
        type: "text",
        id: "label",
        label: "Label",
        required: true,
        view: "v",
        change: this.changeLabel,
      },
      readonly: {
        type: "boolean",
        id: "readonly",
        label: "_LBL_DYNAMISCHE_TABELLE_UI_MODAL_OPTION_READONLY_",
        required: false,
        default: false,
        view: "v-alt",
      },
      required: {
        type: "boolean",
        id: "required",
        label: "Pflicht",
        required: false,
        view: "v-alt",
      },
      show_mime_type: {
        type: "boolean",
        id: "show_mime_type",
        label: "Anzeige der erlaubten Dateiformate im Upload Button",
        required: false,
        view: "v-alt",
      },
      buttonText: {
        type: "text",
        id: "buttonText",
        label: "Button Text",
        required: true,
        view: "v",
        change: this.changeButtonText,
      },
      styles: {
        type: "multiselect",
        id: "styles",
        label: "Styles",
        required: false,
        data: ["a_text_right", "a_text_left", "a_text_center", "a_text_nowrap"],
        keyArray: true,
        view: "v",
      },
      katalog: {
        type: "select",
        id: "katalog",
        label: "Katalog",
        required: true,
        view: "v",
        keyId: "pk",
        keyLabel: "bez",
        url: "katalog/?key=katalog_mit_dynkatalog",
        apiSaveId: "katalog_mit_dynkatalog",
        deselect: false,
        search: true,
        searchList: ["bez"],
        change: this.changeKatalog,
      },
      komplett: {
        type: "boolean",
        id: "komplett",
        label: "Alle Katalogeinträge",
        required: false,
        default: true,
        view: "v-alt",
        change: this.changeKomplett,
        hideIf: true,
      },
      ids: {
        type: "multiselect",
        id: "ids",
        label: "Einzelne Katalogeinträge",
        required: false,
        view: "v",
        keyId: "pk",
        keyLabel: "bez",
        url: undefined,
        apiSaveId: "",
        hideIf: true,
        search: true,
        searchList: ["bez"],
      },
      max_length: {
        type: "integer",
        id: "max_length",
        label: "Maximale Länge",
        required: false,
        view: "v",
      },
      decimal_places: {
        type: "integer",
        id: "decimal_places",
        label: "Nachkommastellen",
        required: true,
        view: "v",
        default: 2,
      },
      max_digits: {
        type: "integer",
        id: "max_digits",
        label: "Max Ziffern",
        required: true,
        default: 14,
        view: "v",
      },
      min_value: {
        type: "float",
        id: "min_value",
        label: "Min",
        required: false,
        view: "v",
        min_value: null,
      },
      max_value: {
        type: "float",
        id: "max_value",
        label: "Max",
        required: false,
        view: "v",
        min_value: null,
      },
      min_value_int: {
        type: "integer",
        id: "min_value",
        label: "Min",
        required: false,
        view: "v",
        min_value: null,
      },
      max_value_int: {
        type: "integer",
        id: "max_value",
        label: "Max",
        required: false,
        view: "v",
        min_value: null,
      },
      dokart: {
        type: "select",
        id: "dokart",
        label: "Dokart",
        required: true,
        view: "v",
        keyId: "pk",
        keyLabel: "name",
        url: "kdokart/",
        apiSaveId: "kdokart",
        change: this.changeSelectKatalog,
      },
      isPreview: {
        type: "boolean",
        id: "isPreview",
        label: "Preview anzeigen",
        required: false,
        view: "v-alt",
      },
      defaultValue: {
        type: "boolean",
        id: "defaultValue",
        label: "Vorbelegung",
        required: false,
        view: "v-alt",
        trueLabel: "Aktiv",
        falseLabel: "Inaktiv",
      },
      sum: {
        type: "boolean",
        id: "sum",
        label: "Summe anzeigen",
        required: false,
        view: "v-alt",
      },
      anzeigemodus: {
        type: "single_choice",
        id: "anzeigemodus",
        label: "Anzeigemodus",
        required: false,
        view: "v",
        data: [
          {
            id: 0,
            label: "Nur Text"
          }, {
            id: 1,
            label: "Nur Icon"
          }, {
            id: 2,
            label: "Icon & Text"
          },
        ]
      },
      trueLabel: {
        type: "text",
        id: "trueLabel",
        label: "Label aktiv",
        required: false,
        view: "v",
      },
      falseLabel: {
        type: "text",
        id: "falseLabel",
        label: "Label inaktiv",
        required: false,
        view: "v",
      },
      expression: {
        type: "text",
        id: "expression",
        label: "Berechnungsformel",
        required: false,
        view: "v",
      },
      berechnung_expression: {
        type: "text",
        id: "berechnung_expression",
        label: "Berechnetes Datum",
        required: false,
        view: "v",
      },
      validation_expression: {
        type: "text",
        id: "validation_expression",
        label: "Validierungsformel",
        required: false,
        view: "v",
      },
      validation_error_message: {
        type: "text",
        id: "validation_error_message",
        label: "Fehlermeldung der Validierung",
        required: false,
        view: "v",
      },
      uhrzeit: {
        type: "boolean",
        id: "uhrzeit",
        label: "_LBL_DYNAMISCHE_TABELLE_UI_MODAL_DATUM_OPTION_UHRZEIT_",
        required: false,
        default: false,
        view: "v-alt",
      },
      signal_regex: {
        type: "text",
        id: "signal_regex",
        label: "Regulärer Ausdruck für Signalempfang",
        required: false,
        view: "v",
      },
      signal_filter: {
        type: "text",
        id: "signal_filter",
        label: "Filterausdruck für Signalwerte",
        helpText: "Sie haben Zugriff auf andere nicht automatisch ermittelten Spalten über deren technische ID. Außerdem kann auf die Werte des Senders über 'sender#technische_id' zugegriffen werden.",
        required: false,
        view: "v",
      },
      id: {
        type: "text",
        id: "id",
        label: "Technische ID",
        required: true,
        view: "v",
        change: this.changeId,
      },
      searchGlobal: {
        type: "boolean",
        id: "searchGlobal",
        label: "Serverseitige Suche",
        required: false,
        view: "v-alt",
        change: this.updateOptiosList
      },
      katalog_data_id: {
        type: "text",
        id: "katalog_data_id",
        label: "Katalogdaten-ID",
        required: false,
        view: "v",
      },
      objektkatalog: {
        type: "select",
        id: "objektkatalog",
        htmlId: "modal_objektkatalog",
        label: "Objektbezogener Katalog",
        required: true,
        view: "v",
        keyId: "pk",
        keyLabel: "bez",
        search: true,
        searchList: ["bez"],
        url: "katalog/?key=kobjektkatalog",
        apiSaveId: "kobjektkatalog",
        deselect: false,
      },
      objektkatalog_data_id: {
        type: "text",
        id: "objektkatalog_data_id",
        label: "Katalogdaten-ID",
        required: false,
        view: "v",
      },
      hide: {
        type: "boolean",
        id: "hide",
        label: "Ausblenden",
        required: false,
        view: "v-alt",
      },
      pdf_width: {
        type: "float",
        id: "pdf_width",
        label: "PDF: Spaltenbreite",
        required: false,
        view: "v",
        min_value: 0,
        decimal_places: 0,
        suffix: "%"
      },
      pdf_header_alignment: {
        type: "single_choice",
        id: "pdf_header_alignment",
        label: "PDF: Ausrichtung der Kopfzeile",
        required: false,
        default: "left",
        view: "v",
        data: [
          {
            id: "left",
            label: "Links"
          }, {
            id: "right",
            label: "Rechts"
          }, {
            id: "center",
            label: "Zentriert"
          }
        ]
      }
    };
  },
  computed: {
    allowed_mime_type() {
      return {
        type: "multiselect",
        id: "allowed_mime_type",
        label: "Dateiformate",
        required: true,
        view: "v",
        keyArray: true,
        data: this.allowedMimeTypeList,
      };
    },

    searchParameter() {
      // Beim initialisieren ist modelLocal noch nicht gesetzt, aber currentModel (bei Edit)
      const CUR_MODEL = !get(this.modelLocal, "type") ? this.currentModel : this.modelLocal;
      return {
        type: "text",
        id: "searchParameter",
        label: "Parameter für die Serverseitige Suche",
        disabled: !get(CUR_MODEL, "searchGlobal"),
        required: false,
        default: "suche",
        view: "v-alt"
      };
    },

    katalog_spalte_id() {
      return {
        type: "select",
        id: "katalog_spalte_id",
        label: "Spalte mit Auswahl aus Katalog",
        required: true,
        view: "v",
        keyId: "id",
        keyLabel: "label",
        data: this.KatalogFieldsList,
        search: true,
        searchList: ["label"],
        deselect: false,
      };
    },

    KatalogFieldsList() {
      const KATALOG_FIELD_LIST = [];
      forEach(this.model, item => {
        if (item.type === "single_choice") {
          KATALOG_FIELD_LIST.push({
            label: item.label,
            id: item.id,
          });
        }
      });
      return KATALOG_FIELD_LIST;
    },

    objektkatalog_spalte_id() {
      return {
        type: "select",
        id: "objektkatalog_spalte_id",
        label: "Spalte mit objektbezogener Auswahlliste",
        required: true,
        view: "v",
        keyId: "id",
        keyLabel: "label",
        data: this.objectCatalogList,
        search: true,
        searchList: ["label"],
        deselect: false,
      };
    },

    objectCatalogList() {
      const OBJECT_CATALOG_LIST = [];
      forEach(this.model, item => {
        if (item.type === "single_choice_objektkatalog") {
          OBJECT_CATALOG_LIST.push({
            label: item.label,
            id: item.id,
          });
        }
      });
      return OBJECT_CATALOG_LIST;
    },
  },
  created() {
    this.prepareData();
  },
  methods: {
    prepareData() {
      this.type.data = this.getParamList();
      this.onPrepareData();
    },
    getParamList() {
      return LIST;
    },
    onPrepareData() {
      // to overwrite in subclasses
    },
    initAllowedMimeTypeList() {
      this.allowedMimeTypeList = this.getSyConfigsValue("allowed_mime_type");
    },
    changeType({ model, item }) {
      if (model) {
        this.currentOptionsList = this.setCurrentOptionsList({ options: item.options });
      } else {
        this.currentOptionsList = [];
      }
      this.setModel();
    },

    updateOptiosList() {
      const CUR = find(this.getParamList(), el => el.value === this.modelLocal.type) || {};
      if (!CUR) {
        return;
      }
      this.currentOptionsList = this.setCurrentOptionsList({ options: CUR.options });
    },

    setCurrentOptionsList({ options }) {
      const OPTIONS_NEW = [];
      forEach(options, item => {
        OPTIONS_NEW.push(cloneDeep(this[item.id]));
      });
      return OPTIONS_NEW;
    },

    changeLabel() {
      if (isNil(this.currentModel)) {
        if (isNull(this.modelLocal.label)) {
          this.modelLocal.id = null;
        } else {
          this.modelLocal.id = toLower(this.modelLocal.label).replace(/[^a-zA-Z0-9]/g, "");
        }
      }
    },

    changeId() {
      this.modelLocal.id = toLower(this.modelLocal.id).replace(/[^a-zA-Z0-9]/g, "");
    },

    changeKatalog() {
      const INDEX_KOMPLETT = findIndex(this.currentOptionsList, ["id", "komplett"]);
      const INDEX_IDS = findIndex(this.currentOptionsList, ["id", "ids"]);
      this.currentOptionsList[INDEX_KOMPLETT].hideIf = false;
      this.currentOptionsList[INDEX_IDS].hideIf = true;
      this.modelLocal.komplett = true;
      this.modelLocal.ids = [];
    },

    changeKomplett() {
      const INDEX_IDS = findIndex(this.currentOptionsList, ["id", "ids"]);
      this.currentOptionsList[INDEX_IDS].hideIf = this.modelLocal.komplett;
      if (!this.modelLocal.komplett) {
        this.changeIdsUrl({ index: INDEX_IDS, katalog: this.modelLocal.katalog });
        this.modelLocal.ids = [];
      }
    },

    changeIdsUrl({ index, katalog }) {
      this.currentOptionsList[index].url = `katalog/?key=${ katalog }`;
      this.currentOptionsList[index].apiSaveId = katalog;
    },
  },
};
