import AButton from "aloha-vue/src/AButton/AButton";

import UIComponentMixin from "../UIComponentMixin";

import EventBus from "aloha-vue/src/utils/EventBus";
import {
  cloneDeep,
  isFunction,
} from "lodash-es";

// @vue/component
export default {
  name: "UiJson",
  components: {
    AButton,
  },
  mixins: [
    UIComponentMixin,
  ],
  data() {
    return {
      status: {
        loading: true,
      },
      jsonString: undefined,
      jsonStringInput: undefined,
      parseError: undefined,
      initialValue: undefined,
      timer: undefined,
      selectionStart: undefined,
      selectionEnd: undefined,
    };
  },
  computed: {
    isDoubleClickInsert() {
      return !!this.options.doubleClickInsert;
    },
  },
  created() {
    this.initInitialValue();
    this.JSONtoString();
  },
  beforeUnmount() {
    this.destroyDoubleClickInsert();
  },
  methods: {
    initInitialValue() {
      this.initialValue = this.model || "";
    },

    JSONtoString() {
      if (this.model) {
        this.jsonString = JSON.stringify(this.model, undefined, 2);
      } else {
        this.jsonString = "";
      }
      this.jsonStringInput = this.jsonString;
      this.parseError = undefined;
    },

    stringToJSON($event) {
      if (!this.jsonStringInput || this.jsonStringInput.length === 0) {
        this.onInput({ $event, model: null });
      } else {
        try {
          const MODEL = JSON.parse(this.jsonStringInput);
          this.onInput({ $event, model: MODEL });
          this.jsonString = JSON.stringify(MODEL, undefined, 2);
          this.parseError = null;
        } catch (e) {
          this.parseError = e.toString();
        }
      }
    },

    format() {
      try {
        this.jsonStringInput = JSON.stringify(JSON.parse(this.jsonStringInput), undefined, 2);
      } catch (e) {
        this.parseError = e.toString();
      }
    },

    reset() {
      this.onInput({ model: this.initialValue });
      setTimeout(() => this.JSONtoString());
    },

    onBlur($event) {
      this.blur({
        id: this.options.id,
        model: $event.target.value,
        $event: $event,
        param: this.options.param,
      });
      this.destroyDoubleClickInsertWithTimeout();
    },

    onFocus($event) {
      this.focus({
        id: this.options.id,
        model: $event.target.value,
        $event: $event,
        param: this.options.param,
      });
      this.initDoubleClickInsert();
    },

    onInput({ $event, model }) {
      const MODEL = cloneDeep(model);
      this.onInput_mixin({ value: MODEL, $event });
      this.setSelectionStartWithTimeout();
    },

    clickLocal() {
      this.setSelectionStart();
    },

    keydownLocal() {
      this.setSelectionStart();
    },

    initDoubleClickInsert() {
      if (this.isDoubleClickInsert) {
        clearTimeout(this.timer);
        EventBus.$on("textDoubleClick", this.doubleClickOutsideEvent);
        this.setSelectionStart();
      }
    },

    destroyDoubleClickInsertWithTimeout() {
      this.timer = setTimeout(() => {
        this.destroyDoubleClickInsert();
      }, 500);
    },

    destroyDoubleClickInsert() {
      EventBus.$off("textDoubleClick", this.doubleClickOutsideEvent);
    },

    doubleClickOutsideEvent({ text } = {}) {
      if (isFunction(this.options.doubleClickInsertCallback)) {
        text = this.options.doubleClickInsertCallback({ text });
      }
      let model = this.jsonStringInput;
      if (model) {
        model = `${ model.slice(0, this.selectionStart) }${ text }${ model.slice(this.selectionEnd) }`;
        this.jsonStringInput = model;
        this.stringToJSON();
      }
    },

    setSelectionStartWithTimeout() {
      setTimeout(() => {
        this.setSelectionStart();
      });
    },

    setSelectionStart() {
      if (this.isDoubleClickInsert) {
        const TEXTAREA = this.$refs.textarea;
        this.selectionStart = TEXTAREA.selectionStart;
        this.selectionEnd = TEXTAREA.selectionEnd;
      }
    },
  },
};
