import {
  computed,
  ref,
} from "vue";

import {
  getHttp,
} from "aloha-vue/src/compositionAPI/AHttpAPI";

import {
  forEach,
  isEmpty,
  isNil,
} from "lodash-es";

export const syConfig = ref({});

const hasSyConfig = computed(() => {
  return !isEmpty(syConfig.value);
});

export default function SyConfigAPI({
  value = "",
  defaultValue = undefined,
} = {}) {
  const syConfigsValue = computed(() => {
    return getSyConfigsValue(value, defaultValue);
  });

  return {
    getSyConfigsValue,
    loadSyConfig,
    setSyConfig,
    syConfigsValue,
  };
}

function setSyConfig(syConfigList) {
  const SY_CONFIG = {};
  forEach(syConfigList, item => {
    SY_CONFIG[item.syc_kennung] = item.syc_wert;
  });
  syConfig.value = SY_CONFIG;
}

export function loadSyConfig({ isReload = false } = {}) {
  if (!isReload.value && hasSyConfig.value) {
    return Promise.resolve();
  }
  return getHttp({
    url: "konfigurationen/",
  }).then(
    response => {
      setSyConfig(response);
    }
  );
}

export function getSyConfigsValue(value, defaultValue) {
  const SY_CONFIG_VALUE = syConfig.value?.[value];

  return isNil(SY_CONFIG_VALUE) ? defaultValue : SY_CONFIG_VALUE;
}
