import UiDynamischeMehrfachauswahlReadOnlyElement from "./UiDynamischeMehrfachauswahlReadOnlyElement/UiDynamischeMehrfachauswahlReadOnlyElement.vue";

// @vue/component
export default {
  name: "UiDynamischeMehrfachauswahlReadOnly",
  components: {
    UiDynamischeMehrfachauswahlReadOnlyElement,
  },
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    className: {
      type: String,
      default: "",
    },
    model: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      default: undefined,
    },
  },
};
