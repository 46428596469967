import translate from "../../../directives/translate";

// @vue/component
export default {
  name: "UiReadOnly",
  directives: {
    translate,
  },
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    className: {
      type: String,
      default: "",
    },
  },
};
