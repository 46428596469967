import AMenu2 from "aloha-vue/src/AMenu2/AMenu2";
import PuxCloak from "../../global/components/PuxCloak/PuxCloak.vue";
import ASpinner from "aloha-vue/src/ASpinner/ASpinner";

import CompareUrlAPI from "./compositionAPI/CompareUrlAPI";
import DataAPI from "./compositionAPI/DataAPI";
import IsMenuOpenInitialAPI from "./compositionAPI/IsMenuOpenInitialAPI";
import StylesAPI from "./compositionAPI/StylesAPI";

// @vue/component
export default {
  name: "TheMenuNew",
  components: {
    AMenu2,
    ASpinner,
    PuxCloak,
  },
  setup() {
    const {
      initRoutesObj,
      loadData,
      loading,
      menuLocal,
      menuRef,
    } = DataAPI();

    const {
      isMenuOpenInitial,
    } = IsMenuOpenInitialAPI();

    const {
      stylesLocal,
    } = StylesAPI();

    const {
      compareUrlCallback,
    } = CompareUrlAPI();

    initRoutesObj();
    loadData();

    return {
      compareUrlCallback,
      isMenuOpenInitial,
      loading,
      menuLocal,
      menuRef,
      stylesLocal,
    };
  },
};
