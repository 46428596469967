// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ui-tinymce .a_form_element__invalid_text {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./client/src/global/components/ui/UiTinymce/UiTinymce.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf","sourcesContent":[".ui-tinymce .a_form_element__invalid_text {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
