import AButton from "aloha-vue/src/AButton/AButton";
import Modal from "../../Modal/Modal.vue";
import TaskLog from "../../TaskLog/TaskLog.vue";
import PuxCloak from "../../PuxCloak/PuxCloak.vue";
import HttpMixin from "../../../mixins/HttpMixin";
import SyConfigMixin from "../../../mixins/SyConfigMixin";
import PuxIcon from "../../PuxIcon/PuxIcon.vue";
import moment from "moment";
import {
  assign,
  cloneDeep,
  isUndefined,
} from "lodash-es";
import {
  setUrlWithParams,
} from "../../../functions/http";
import {
  getUrlParams,
} from "../../../functions/help";

// @vue/component
export default {
  name: "PuxTemplateDownloadElement",
  components: {
    AButton,
    Modal,
    PuxIcon,
    TaskLog,
    PuxCloak,
  },
  directives: {

  },
  mixins: [
    HttpMixin,
    SyConfigMixin,
  ],
  props: {
    htmlId: {
      type: String,
      required: true,
    },
    async: {
      type: Boolean,
      required: false,
      info: "Laden Date asynchron oder synchron",
    },
    data: {
      type: Object,
      required: true,
      info: "Hauptinformation { url, label, title }",
    },
    urlParams: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    index: {
      type: Number,
      required: false,
      default: undefined,
    },
    btnClass: {
      type: String,
      required: false,
      default: undefined,
    },
    btnIcon: {
      type: String,
      required: false,
      default: undefined,
    },
    action: {
      type: String,
      default: "dokument_async",
    },
    downloadAction: {
      type: String,
      default: "dokument_download",
    },
  },
  data() {
    return {
      modalOptions: {
        showCloseButton: true,
      },
      statusDisabled: false,
      statusModal: false,
      modal: {
        configTimeToInvalid: undefined,
        timeToInvalid: undefined,
        error: undefined,
        taskId: undefined,
        dateiUrl: undefined,
        dateiName: undefined,
        gueltigBis: undefined,
        dateiGueltig: undefined,
        toInvalidPromise: undefined,
        requestingDokument: undefined,
      },
    };
  },
  computed: {
    htmlIdLocal() {
      if (isUndefined(this.index)) {
        return this.htmlId;
      }
      return `${ this.htmlId }_${ this.index }`;
    },

    hrefLocal() {
      return setUrlWithParams({
        url: `/api/${ this.baseUrl }`,
        params: this.urlParamsLocal,
      });
    },

    selectorClose() {
      return `#${ this.htmlIdLocal }`;
    },

    urlParamsLocal() {
      let urlParams = cloneDeep(this.urlParams);
      const URL_ARR = this.urlLocal.split("?");
      if (URL_ARR[1]) {
        urlParams = assign({}, urlParams, getUrlParams({ url: URL_ARR[1] }));
      }
      return urlParams;
    },

    urlLocal() {
      return this.data.url;
    },

    baseUrl() {
      return this.urlLocal.split("?")[0];
    },

    getAsyncDokumentLink() {
      return `/api/${ this.combinePaths(this.baseUrl, this.downloadAction || "dokument_download/") }?task_id=${ this.modal.taskId }`;
    },
  },
  methods: {
    downloadAsync() {
      this.statusModal = true;
      this.statusDisabled = true;

      this.modal.configTimeToInvalid = this.getSyConfigsValue("cache_time") ? Math.floor(this.getSyConfigsValue("cache_time") / 60) : undefined; // in Minutes
      this.dokumentOnInit();
    },

    combinePaths(path1, path2) {
      let trenner1 = "";
      let trenner2 = "";
      if (path1.length > 0 && path1[path1.length - 1] !== "/" &&
          path2.length > 0 && path2[0] !== "/") {
        trenner1 = "/";
      }
      if (path2.length > 0 && path2[path2.length - 1] !== "/") {
        trenner2 = "/";
      }
      return path1 + trenner1 + path2 + trenner2;
    },

    dokumentOnInit() {
      this.modal.taskId = undefined;
      this.modal.dateiUrl = undefined;
      this.modal.dateiName = undefined;
      this.modal.gueltigBis = undefined;
      this.modal.toInvalidPromise = undefined;
      this.modal.requestingDokument = true;
      this.getHttp({
        url: this.combinePaths(this.baseUrl, this.action),
        urlParams: assign({}, this.urlParams, this.urlParamsLocal),
        showError: true,
      }).then(
        response => {
          this.modal.taskId = response.task_id;
        },
        error => {
          if (error.status !== 429) {
            this.modal.error = error.data || "Fehler beim Anfragen des Dokument";
          }
          this.modal.error = "Fehler beim Anfragen des Dokuments";
        }
      ).then(
        () => this.modal.requestingDokument = false
      );
    },

    onSuccess({ result }) {
      this.modal.dateiUrl = this.getAsyncDokumentLink;
      this.modal.dateiName = result.name;
      this.modal.gueltigBis = moment(result.cached_until);
      this.calcTimeToInvalid();
      this.modal.toInvalidPromise = setInterval(this.calcTimeToInvalid, 1000);
    },

    onFailure({ result }) {
      this.modal.error = result.data || "Fehler beim Erstellen des Dokuments";
    },

    calcTimeToInvalid() {
      this.modal.dateiGueltig = moment().isBefore(this.modal.gueltigBis);
      this.modal.timeToInvalid = this.modal.gueltigBis ? this.modal.gueltigBis.diff(moment(), "minutes") : undefined;
      if (!this.modal.dateiGueltig && this.modal.toInvalidPromise) {
        clearInterval(this.modal.toInvalidPromise);
        this.modal.toInvalidPromise = undefined;
      }
    },

    closeModal() {
      this.statusModal = false;
      this.statusDisabled = false;
      clearInterval(this.modal.toInvalidPromise);
    },
  },
};
