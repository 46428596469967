import AButton from "aloha-vue/src/AButton/AButton";
import PuxTranslate from "../../PuxTranslate/PuxTranslate.vue";

import AttributesAPI from "./compositionAPI/AttributesAPI";
import ErrorTypesAPI from "./compositionAPI/ErrorTypesAPI";
import HasElementErrorsAPI from "./compositionAPI/HasElementErrorsAPI";
import IdAPI from "./compositionAPI/IdAPI";
import LabelAPI from "./compositionAPI/LabelAPI";
import LevelAPI from "./compositionAPI/LevelAPI";
import LinkAPI from "./compositionAPI/LinkAPI";

// @vue/component
export default {
  name: "PuxErrorsElement",
  components: {
    AButton,
    PuxTranslate,
  },
  props: {
    id: {
      type: [String, Number],
      required: false,
      default: undefined,
    },
    error: {
      type: [Object, Array, String],
      required: false,
      default: undefined,
    },
    errorKeyOrIndex: {
      type: [String, Number],
      required: false,
      default: undefined,
    },
    errorLabels: {
      type: [String, Object, Array],
      required: true,
    },
    level: {
      type: Number,
      required: true,
    },
    goToError: {
      type: Function,
      required: false,
      default: undefined,
    },
    isErrorLink: {
      type: Boolean,
      required: true,
    },
    shouldReplaceLabel: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const {
      childLevel,
    } = LevelAPI(props);

    const {
      idLocal,
    } = IdAPI(props);

    const {
      isErrorArray,
      isErrorArrayOfStrings,
      isErrorLabelsArrayOrObject,
      isErrorObject,
      isErrorString,
    } = ErrorTypesAPI(props);

    const {
      currentLabel,
    } = LabelAPI(props, {
      idLocal,
    });

    const {
      hasElementErrors,
    } = HasElementErrorsAPI(props);

    const {
      ariaDescribedby,
      classForErrorsWrapper,
    } = AttributesAPI(props, {
      currentLabel,
      hasElementErrors,
      idLocal,
      isErrorObject,
    });

    const {
      goToErrorLocal,
      isErrorLinkLocal,
    } = LinkAPI(props, {
      idLocal
    });

    return {
      ariaDescribedby,
      childLevel,
      classForErrorsWrapper,
      currentLabel,
      goToErrorLocal,
      hasElementErrors,
      idLocal,
      isErrorArray,
      isErrorArrayOfStrings,
      isErrorLabelsArrayOrObject,
      isErrorLinkLocal,
      isErrorObject,
      isErrorString,
    };
  },
};
