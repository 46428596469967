import filterDate from "aloha-vue/src/filters/filterDate";
import filterCurrency from "aloha-vue/src/filters/filterCurrency";
import filterFileSize from "aloha-vue/src/filters/filterFileSize";
import FromNow from "./FromNow";
import filterKeyValue from "aloha-vue/src/filters/filterKeyValue";
import filterBoolean from "aloha-vue/src/filters/filterBoolean";
import filterLimitTo from "aloha-vue/src/filters/filterLimitTo";
import iban from "./iban";
import filterEmail from "aloha-vue/src/filters/filterEmail";
import filterLink from "aloha-vue/src/filters/filterLink";
import filterDefaultForEmpty from "aloha-vue/src/filters/filterDefaultForEmpty";
import spaced from "./spaced";
import filterList from "../../views/Calendar/CalendarFilters/filterList";

const FILTERS = {
  date: filterDate,
  currency: filterCurrency,
  boolean: filterBoolean,
  trunc: filterLimitTo,
  iban,
  "file-size": filterFileSize,
  "from-now": FromNow,
  "key-value": filterKeyValue,
  "limit-to": filterLimitTo,
  email: filterEmail,
  link: filterLink,
  "default-for-empty": filterDefaultForEmpty,
  spaced,
  list: filterList,
};

export default function(value, filterName, param) {
  if (!filterName || !FILTERS[filterName]) {
    return value;
  }

  return FILTERS[filterName](value, param);
}
