import {
  computed,
  toRef,
} from "vue";

export default function BtnCloseAPI(props) {
  const id = toRef(props, "id");

  const idForButtonClose = computed(() => {
    return `${ id.value }_btn_close`;
  });

  const btnCloseAttributes = computed(() => {
    return {
      id: idForButtonClose.value,
      title: "_TXT_CLOSE_MESSAGE_",
      textScreenReader: "_TXT_CLOSE_MESSAGE_",
    };
  });

  return {
    btnCloseAttributes,
  };
}
