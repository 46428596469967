// @vue/component
export default {
  name: "TheGlobal",
  setup() {
    const createContainerById = id => {
      let container = document.getElementById(id);
      if (!container) {
        container = document.createElement("div");
        container.id = id;
        container.className = "popup_container";
        container.ariaHidden = "true";
        document.body.appendChild(container);
      }
    };

    createContainerById("tooltip_container");
    createContainerById("select_container");
    return {};
  },
  render() {
    return "";
  },
};
