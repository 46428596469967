import translate from "../../../global/directives/translate";

import AFiltersAPI from "aloha-vue/src/compositionAPI/AFiltersAPI";

// @vue/component
export default {
  name: "ReleaseNotesModalElement",
  directives: {
    translate,
  },
  props: {
    note: {
      type: Object,
      required: true,
      info: "Hauptinformation",
    },
  },
  setup() {
    const {
      filterDate,
    } = AFiltersAPI();

    return {
      filterDate,
    };
  },
  computed: {
    vinDatum() {
      return this.filterDate(this.note.vin_datum);
    },
  },
};
