import GlobalOptions from "../../../const/GlobalOptions";
import UIComponentMixin from "../UIComponentMixin";
import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "UiDayMonth",
  mixins: [
    UIComponentMixin,
  ],
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      UiInputIcon: GlobalOptions.UiInputIcon,
    };
  },
  computed: {
    getModelDay() {
      return this.model.day;
    },

    getModelMonth() {
      return this.model.month;
    },

    getIdDay() {
      return `${ this.getId }_day`;
    },

    getIdMonth() {
      return `${ this.getId }_month`;
    },
  },
  methods: {
    onInputDay($event) {
      this.onInput({ currentModel: $event.target.value, label: "day", $event });
    },

    onInputMonth($event) {
      this.onInput({ currentModel: $event.target.value, label: "month", $event });
    },

    onInput({ currentModel, label, $event }) {
      const MODEL = cloneDeep(this.model);
      MODEL[label] = +currentModel;

      this.input({
        model: MODEL,
        currentModel,
        id: this.options.id,
        param: this.options.param,
        option: this.options,
        $event,
      });
      this.change({
        model: MODEL,
        currentModel,
        id: this.options.id,
        param: this.options.param,
        option: this.options,
        $event,
      });
    },
  },
};
