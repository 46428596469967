import {
  computed,
  toRef,
} from "vue";

export default function ToolbarAPI(props) {
  const options = toRef(props, "options");

  const toolbar = computed(() => {
    const TOOLBAR = [
      { name: "Formatierung", items: ["bold", "italic", "underline"] },
      { name: "Ausrichtung", items: ["alignleft", "aligncenter", "alignright", "alignjustify"] },
      { name: "Liste", items: ["bullist", "numlist", "default"] },
      { name: "Einrücken", items: ["indent", "outdent"] },
      { name: "Link", items: ["link", "unlink"] },
      { name: "Tabelle", items: ["table"] },
      { name: "Sprache", items: ["language"] },
      { name: "Historie", items: ["undo", "redo"] },
      { name: "Hilfe", items: ["help"] },
    ];
    if (!options.value.useExtendedConfig) {
      TOOLBAR.splice(5, 1);
    }

    return TOOLBAR;
  });

  return {
    toolbar,
  };
}
