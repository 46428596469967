import AButton from "aloha-vue/src/AButton/AButton";
import ALink from "aloha-vue/src/ALink/ALink";
import Modal from "../Modal/Modal.vue";
import PuxCloak from "../PuxCloak/PuxCloak.vue";
import PuxIcon from "../PuxIcon/PuxIcon.vue";
import PuxTranslate from "../PuxTranslate/PuxTranslate.vue";
import TaskLog from "../TaskLog/TaskLog.vue";

import HttpMixin from "../../mixins/HttpMixin";
import SyConfigMixin from "../../mixins/SyConfigMixin";

import moment from "moment";
import { setUrlWithParams } from "../../functions/http";
import { getUrlParams } from "../../functions/help";
import {
  assign,
} from "lodash-es";

// @vue/component
export default {
  name: "CsvDownload",
  components: {
    AButton,
    ALink,
    Modal,
    PuxCloak,
    PuxIcon,
    PuxTranslate,
    TaskLog,
  },
  mixins: [
    HttpMixin,
    SyConfigMixin,
  ],
  props: {
    async: {
      type: Boolean,
    },
    baseUrl: {
      type: String,
      default: "",
    },
    urlParams: {
      type: Object,
      default: () => ({}),
    },
    action: {
      type: String,
      default: "csv_async",
    },
    downloadAction: {
      type: String,
      default: "csv_download",
    },
    btnType: {
      type: String,
      default: "default",
    },
    btnIcon: {
      type: String,
      default: "download",
    },
    btnLabel: {
      type: String,
      default: "CSV generieren",
    },
    title: {
      type: String,
      default: "",
    },
    btnClass: {
      type: String,
      default: "a_btn a_btn_secondary",
    },
    htmlId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modalOptions: {
        showCloseButton: true,
      },
      status: {
        modal: undefined,
        disabled: undefined,
      },
      csvModal: {
        configTimeToInvalid: undefined,
        timeToInvalid: undefined,
        error: undefined,
        taskId: undefined,
        dateiUrl: undefined,
        dateiName: undefined,
        gueltigBis: undefined,
        dateiGueltig: undefined,
        toInvalidPromise: undefined,
        requestingCsv: undefined,
      },
    };
  },
  computed: {
    baseUrlLocal() {
      return this.baseUrl.split("?")[0];
    },

    urlParamsLocal() {
      const URL_ARR = this.baseUrl.split("?");
      if (URL_ARR[1]) {
        return getUrlParams({ url: URL_ARR[1] });
      }
      return {};
    },

    getCsvLink() {
      return setUrlWithParams({
        url: this.combinePaths(this.baseUrlLocal, "csv"),
        params: assign({}, this.urlParams, this.urlParamsLocal),
      });
    },

    getAsyncCsvLink() {
      return `/api/${ this.combinePaths(this.baseUrlLocal, this.downloadAction || "csv_download/") }?task_id=${ this.csvModal.taskId }`;
    },

    selectorClose() {
      return `#${ this.htmlId }`;
    },
  },
  methods: {
    downloadAsyncCsv() {
      this.status.modal = true;
      this.status.disabled = true;

      this.csvModal.configTimeToInvalid = this.getSyConfigsValue("cache_time") ? Math.floor(this.getSyConfigsValue("cache_time") / 60) : undefined, // in Minutes
      this.csvOnInit();
    },

    csvOnInit() {
      this.csvModal.taskId = undefined;
      this.csvModal.dateiUrl = undefined;
      this.csvModal.dateiName = undefined;
      this.csvModal.gueltigBis = undefined;
      this.csvModal.toInvalidPromise = undefined;
      this.csvModal.requestingCsv = true;
      this.getHttp({
        url: this.combinePaths(this.baseUrlLocal, this.action),
        urlParams: assign({}, this.urlParams, this.urlParamsLocal),
        showError: true,
      }).then(
        response => {
          this.csvModal.taskId = response.task_id;
        },
        error => {
          if (error.status !== 429) {
            this.csvModal.error = error.data || "Fehler beim Anfragen der CSV-Datei";
          }
          this.csvModal.error = "Fehler beim Anfragen der CSV-Datei";
        }
      ).then(
        () => this.csvModal.requestingCsv = false
      );
    },

    onSuccess({ result }) {
      this.csvModal.dateiUrl = this.getAsyncCsvLink;
      this.csvModal.dateiName = result.name;
      this.csvModal.gueltigBis = moment(result.cached_until);
      this.calcTimeToInvalid();
      this.csvModal.toInvalidPromise = setInterval(this.calcTimeToInvalid, 1000);
    },

    onFailure({ result }) {
      this.csvModal.error = result.data || "Fehler beim Erstellen der CSV-Datei";
    },

    calcTimeToInvalid() {
      this.csvModal.dateiGueltig = moment().isBefore(this.csvModal.gueltigBis);
      this.csvModal.timeToInvalid = this.csvModal.gueltigBis ? this.csvModal.gueltigBis.diff(moment(), "minutes") : undefined;
      if (!this.csvModal.dateiGueltig && this.csvModal.toInvalidPromise) {
        clearInterval(this.csvModal.toInvalidPromise);
        this.csvModal.toInvalidPromise = undefined;
      }
    },

    combinePaths(path1, path2) {
      let trenner1 = "";
      let trenner2 = "";
      if (path1.length > 0 && path1[path1.length - 1] !== "/" &&
        path2.length > 0 && path2[0] !== "/") {
        trenner1 = "/";
      }
      if (path2.length > 0 && path2[path2.length - 1] !== "/") {
        trenner2 = "/";
      }
      return path1 + trenner1 + path2 + trenner2;
    },

    closeModal() {
      this.status.modal = false;
      this.status.disabled = false;
    },
  },
};
