const ErrorsPage = () => import(/* webpackChunkName: "ErrorsPage" */ "../../views/Errors/ErrorsPage/ErrorsPage.vue");
const ErrorsNotFoundPage = () => import(/* webpackChunkName: "ErrorsNotFoundPage" */ "../../views/Errors/ErrorsNotFoundPage/ErrorsNotFoundPage.vue");

export default [
  {
    path: "/404/",
    name: "root.errors.404",
    component: ErrorsNotFoundPage,
  },
  {
    path: "/500/",
    name: "root.errors.500",
    component: ErrorsPage,
    props: {
      title: "_LBL_ERROR_PAGES_UNEXPECTED_SERVER_ERROR_",
    },
  },
  {
    path: "/501/",
    name: "root.errors.501",
    component: ErrorsPage,
    props: {
      title: "_LBL_ERROR_PAGES_UNEXPECTED_SERVER_ERROR_",
    },
  },
  {
    path: "/502/",
    name: "root.errors.502",
    component: ErrorsPage,
    props: {
      title: "_LBL_ERROR_PAGES_UNEXPECTED_SERVER_ERROR_",
    },
  },
  {
    path: "/503/",
    name: "root.errors.503",
    component: ErrorsPage,
    props: {
      title: "_LBL_ERROR_PAGES_PAGE_TEMPORARY_UNAVAILABLE_",
    },
  },
  {
    path: "/504/",
    name: "root.errors.504",
    component: ErrorsPage,
    props: {
      title: "_LBL_ERROR_PAGES_NO_CONNECTION_TO_SERVER_",
    },
  },
  {
    path: "/40*/",
    name: "root.errors.40*",
    component: ErrorsPage,
    props: {
      title: "_LBL_ERROR_PAGES_UNEXPECTED_SERVER_ERROR_",
    },
  },
  {
    path: "/50*/",
    name: "root.errors.50*",
    component: ErrorsPage,
    props: {
      title: "_LBL_ERROR_PAGES_UNEXPECTED_SERVER_ERROR_",
    },
  },
  {
    path: "/500js/",
    name: "root.errors.js",
    component: ErrorsPage,
    props: {
      title: "_LBL_ERROR_PAGES_UNEXPECTED_JS_ERROR_",
    },
  },
];
