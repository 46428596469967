import UiDynamischeAdressenReadOnlyElement
  from "./UiDynamischeAdressenReadOnlyElement/UiDynamischeAdressenReadOnlyElement.vue";
import translate from "../../../../../../global/directives/translate";
import {
  keyBy,
} from "lodash-es";

// @vue/component
export default {
  name: "UiDynamischeAdressenReadOnly",
  components: {
    UiDynamischeAdressenReadOnlyElement,
  },
  directives: {
    translate,
  },
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    className: {
      type: String,
      default: "",
    },
    model: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      default: undefined,
    },
  },
  computed: {
    getModel() {
      return this.model;
    },

    getDataById() {
      return keyBy(this.options.data, "id");
    },
  },
};
