import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTooltip from "../../../../../global/components/PuxTooltip/PuxTooltip.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import UiBelegeFieldMixin from "../UiBelegeFieldMixin";
import UIReadOnlyMixin from "../../../../../global/components/ui/UIReadOnlyMixin";

// @vue/component
export default {
  name: "UiBelegeFieldReadOnly",
  components: {
    PuxIcon,
    PuxTooltip,
    PuxTranslate,
  },
  mixins: [
    UiBelegeFieldMixin,
    UIReadOnlyMixin,
  ],
  beforeCreate() {
    this.$options.components.FormElementReadOnly = require("../../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue").default;
  },
  methods: {
    initBelegFieldsFormTemplates() {
      this.belegFieldsFormTemplates = {
        show: {
          type: "boolean",
          readonly: true,
          required: false,
          showLabel: false,
          anzeigemodus: 1,
          default: false,
        },
        required: {
          type: "boolean",
          readonly: true,
          required: false,
          showLabel: false,
          anzeigemodus: 1,
          default: false,
        },
        help_text: {
          type: "text",
          label: "_LBL_BELEG_FELDER_CONFIG_HELP_TEXT_",
          view: "v",
          readonly: true,
          required: false,
          showLabel: true,
          default: undefined,
        },
        objektkatalog: {
          type: "select",
          label: "_LBL_BELEG_FELDER_CONFIG_OBJEKTKATALOG_",
          view: "v",
          readonly: true,
          required: false,
          showLabel: true,
          default: undefined,
          url: "katalog/?key=kobjektkatalog",
          keyId: "pk",
          keyLabel: "bez",
        },
      };
    }
  },
};
