export default {
  UiInputIcon: "glyphicon-close",
  getListHttpKeyList: "results",
  tableOptions: {
    pagination: {
      limitName: "limit",
      offsetName: "offset",
      offsetCallback: offset => offset,
    },
    idLabel: "pk",
    configurationSave: true,
    filterSave: true,
    csv: true,
    buttonSorting: false,
    keyCount: "count",
    dynamicConfigBaseUrl: () => "publictableconfigs/",
    dynamicConfigUrl: name => `publictableconfigs/?bez=${ name }`,
  },
};
