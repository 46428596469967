import AutoFocus from "../../directives/AutoFocus";

import {
  cloneDeep,
  isNaN,
  isNil,
} from "lodash-es";

// @vue/component
export default {
  directives: {
    AutoFocus,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    idPrefix: {
      type: String,
      default: "",
    },
    isError: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: "",
    },
    model: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      default: undefined,
    },
    // Functions
    blur: {
      type: Function,
      default: () => {},
    },
    focus: {
      type: Function,
      default: () => {},
    },
    input: {
      type: Function,
      default: () => {},
    },
    change: {
      type: Function,
      default: () => {},
    },
    errors: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      default: undefined,
    },
    data: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      default: undefined,
    },
    extraStatic: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  computed: {
    getId() {
      if (this.options.htmlId) {
        return this.options.htmlId;
      }
      return `${ this.idPrefix }${ this.options.attrId || this.options.key || this.options.id }`;
    },

    htmlIdLocal() {
      return this.getId;
    },

    getAriaRequired() {
      return this.options.required ? "true" : "false";
    },

    getAriaInvalid() {
      return this.isError ? "true" : "false";
    },

    getName() {
      return this.options.name || "";
    },

    getInputClass() {
      return [
        {
          "is-invalid": this.isError
        },
        this.options.classInput,
        this.className,
      ];
    },

    isDisabled() {
      return this.options.disabled || this.disabled;
    },

    nullValue() {
      if (Object.getOwnPropertyDescriptor(this.options, "nullValue")) {
        return this.options.nullValue;
      }
      return null;
    },

    statusInputByBlur() {
      return this.options.statusInputByBlur || undefined;
    },

    attributesHtml() {
      const ATTRIBUTES_HTML = cloneDeep(this.options.attributesHtml) || {};
      if (this.ariaDescribedby) {
        ATTRIBUTES_HTML["aria-describedby"] = this.ariaDescribedby;
      }
      if (this.getName) {
        ATTRIBUTES_HTML.name = this.getName;
      }
      if (!isNil(this.options.autocomplete)) {
        ATTRIBUTES_HTML.autocomplete = this.options.autocomplete;
      }
      return ATTRIBUTES_HTML;
    },

    ariaDescribedby() {
      const ARIA_DESCRIBEDBY_LIST = [];
      if (this.options.addonBack) {
        ARIA_DESCRIBEDBY_LIST.push(`${ this.htmlIdLocal }_addon_back`);
      }
      if (this.isError) {
        ARIA_DESCRIBEDBY_LIST.push(`${ this.htmlIdLocal }_errors`);
      }
      if (this.options.helpText) {
        ARIA_DESCRIBEDBY_LIST.push(`${ this.htmlIdLocal }_help_text`);
      }
      return ARIA_DESCRIBEDBY_LIST.join(" ");
    },
  },
  methods: {
    onInput_mixin({ $event, value }) {
      const VALUE_LOCAL = this.checkUndefinedValue({ value });
      this.input({
        currentModel: VALUE_LOCAL,
        model: VALUE_LOCAL,
        id: this.options.id,
        $event: $event,
        param: this.options.param,
        options: this.options,
      });
      this.change({
        currentModel: VALUE_LOCAL,
        model: VALUE_LOCAL,
        id: this.options.id,
        $event: $event,
        param: this.options.param,
        options: this.options,
      });
    },

    checkUndefinedValue({ value }) {
      if (value === "" || isNaN(value) || isNil(value)) {
        return this.nullValue;
      }
      return value;
    },

    onBlur($event) {
      this.blur({
        id: this.options.id,
        model: $event.target.value,
        $event: $event,
        param: this.options.param,
        options: this.options,
      });
      if (this.statusInputByBlur && this.onInput) {
        this.onInput($event);
      }
    },

    onFocus($event) {
      this.focus({
        id: this.options.id,
        model: $event.target.value,
        $event: $event,
        param: this.options.param,
        options: this.options,
      });
    },

    clearModel() {
      if (this.$refs.input) {
        this.$refs.input.focus();
      }
      this.input({
        currentModel: null,
        model: null,
        id: this.options.id,
        param: this.options.param,
        options: this.options,
      });
      this.change({
        currentModel: null,
        model: null,
        id: this.options.id,
        param: this.options.param,
        options: this.options,
      });
    },

    mockFunction() {

    },
  },
};
