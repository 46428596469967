import UIReadOnlyMixin from "../../UIReadOnlyMixin";

// @vue/component
export default {
  name: "UiDayMonthReadOnly",
  mixins: [
    UIReadOnlyMixin,
  ],
  computed: {
    getModel() {
      if (!this.model || (!this.model.day && !this.model.month)) {
        return this.options.showEmpty || "";
      }
      return `${ this.model.day }.${ this.model.month }`;
    },
  },
};
