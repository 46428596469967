import {
  cloneDeep,
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "UiZielgruppenAuswahlCheckbox",
  props: {
    data: {
      type: Object,
      required: true,
    },
    model: {
      type: Array,
      required: true,
    },
    parent: {
      type: Object,
      default: undefined,
    },
    onInput: {
      type: Function,
      required: true,
    },
  },
  computed: {
    getIndexFromModel() {
      return this.model.indexOf(this.data.pk);
    },
    isChecked() {
      return this.getIndexFromModel !== -1;
    },
    getAriaChecked() {
      return `${ this.isChecked }`;
    },
  },
  methods: {
    updateSelection() {
      const MODEL = cloneDeep(this.model);
      if (this.isChecked) {
        const INDEX = this.getIndexFromModel;
        MODEL.splice(INDEX, 1);
        if (!this.parent && this.data.untergruppen && this.data.untergruppen.length) {
          forEach(this.data.untergruppen, child => {
            const CHILD_INDEX = MODEL.indexOf(child.pk);
            if (CHILD_INDEX !== -1) {
              MODEL.splice(CHILD_INDEX, 1);
            }
          });
        }
      } else {
        if (this.parent && MODEL.indexOf(this.parent.pk) === -1) {
          MODEL.push(this.parent.pk);
        }
        MODEL.push(this.data.pk);
      }

      this.onInput({ value: MODEL });
    },
  },
};
