import {
  computed,
} from "vue";

import AMobileAPI from "aloha-vue/src/compositionAPI/AMobileAPI";
import LayoutAPI from "../../../global/compositionAPI/LayoutAPI";

export default function StylesAPI() {
  const {
    isMobileWidth,
  } = AMobileAPI();

  const {
    asideFixedHeight,
    navbarFixedHeight,
  } = LayoutAPI();

  const stylesLocal = computed(() => {
    if (isMobileWidth.value) {
      return;
    }
    return {
      top: `${ navbarFixedHeight.value || 0 }px`,
      bottom: "auto",
      height: `${ asideFixedHeight.value }px`,
    };
  });

  return {
    stylesLocal,
  };
}
