import AModalConfirm from "aloha-vue/src/AModalConfirm/AModalConfirm";
import ANotification from "aloha-vue/src/ANotification/ANotification";
import PuxCloak from "../global/components/PuxCloak/PuxCloak.vue";
import TheMenu from "../SingleUsageComponents/TheMenu/TheMenu.vue";
import TheMenuJump from "../SingleUsageComponents/TheMenuJump/TheMenuJump.vue";
import TheMessagePanel from "../SingleUsageComponents/TheMessagePanel/TheMessagePanel.vue";
import TheNavbar from "../SingleUsageComponents/TheNavbar/TheNavbar.vue";
import TheProandiFooter from "../SingleUsageComponents/TheProandiFooter/TheProandiFooter.vue";
import TheVersionCheck from "../SingleUsageComponents/TheVersionCheck/TheVersionCheck.vue";
import TheMenuNew from "../SingleUsageComponents/TheMenuNew/TheMenuNew.vue";

import AOnHooks from "aloha-vue/src/directives/AOnHooks";

import LayoutAPI from "../global/compositionAPI/LayoutAPI";
import LoadingAPI from "./compositionAPI/LoadingAPI";
import IframeAPI from "./compositionAPI/IframeAPI";
import RouterAPI from "../global/compositionAPI/RouterAPI";
import UserAPI from "../global/compositionAPI/UserAPI";
import StyleAPI from "./compositionAPI/StyleAPI";

export default {
  name: "App",
  components: {
    AModalConfirm,
    ANotification,
    PuxCloak,
    TheMenu,
    TheMenuJump,
    TheMessagePanel,
    TheNavbar,
    TheProandiFooter,
    TheVersionCheck,
    TheMenuNew,
  },
  directives: {
    AOnHooks,
  },
  setup() {
    const {
      isIframe,
    } = IframeAPI();

    const {
      loadAll,
      loading,
    } = LoadingAPI();

    const {
      isAuthenticated,
    } = UserAPI();

    const {
      routerKeyComputed,
    } = RouterAPI();

    const {
      pageWrapperStyle,
      wrapperContentStyle,
    } = StyleAPI();

    const {
      footer,
      initEventListener,
      initResizeObserver,
      navbarFixedHeight,
      navbarFixedTop,
    } = LayoutAPI();

    initEventListener();
    loadAll();

    return {
      footer,
      initResizeObserver,
      isAuthenticated,
      isIframe,
      loading,
      navbarFixedHeight,
      navbarFixedTop,
      pageWrapperStyle,
      routerKeyComputed,
      wrapperContentStyle,
    };
  },
};
