import {
  isEqual,
} from "lodash-es";

export default function CompareUrlAPI() {
  const compareUrlCallback = ({
    item,
    currentRoute,
  }) => {
    const ITEM_PARAMS = item.to?.params || {};
    const CURRENT_ROUTE_PARAMS = currentRoute.params || {};

    return item.to.name === currentRoute.name &&
      isEqual(ITEM_PARAMS, CURRENT_ROUTE_PARAMS);
  };

  return {
    compareUrlCallback,
  };
}
