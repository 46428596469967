import UIComponentMixin from "../../../../../global/components/ui/UIComponentMixin";
import {
  cloneDeep,
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "UiDynamischeTabelleObjektkatalogData",
  mixins: [
    UIComponentMixin,
  ],
  props: {
    model: {
      type: Array,
      required: true,
    },
    dependencyValue: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      default: undefined,
    },
  },
  data() {
    return {
      list: [],
      idsInList: {},
    };
  },
  watch: {
    dependencyValue: {
      immediate: true,
      handler() {
        this.setList();
        this.filterModel();
      },
    },
  },
  methods: {
    setList() {
      const LIST = [];
      const IDS_IN_LIST = {};
      if (this.dependencyValue) {
        forEach(this.dependencyValue, item => {
          LIST.push({
            id: item.id,
            label: item.label,
          });
          IDS_IN_LIST[item.id] = true;
        });
      }
      this.list = LIST;
      this.idsInList = IDS_IN_LIST;
    },

    filterModel() {
      const MODEL = cloneDeep(this.model) || [];
      for (let i = 0; i < MODEL.length; i++) {
        if (!this.idsInList[MODEL[i]]) {
          MODEL.splice(i, 1);
          i--;
        }
      }
      this.onInput_mixin({ value: MODEL });
    },

    toggleModel(id, $event) {
      const MODEL = cloneDeep(this.model) || [];
      const INDEX = MODEL.indexOf(id);
      if (INDEX !== -1) {
        MODEL.splice(INDEX, 1);
      } else {
        MODEL.push(id);
      }
      this.onInput_mixin({ value: MODEL, $event });
    },
  },
};
