import Modal from "../Modal/Modal.vue";

export default {
  // Explizites Setzen des Namen erzwingen
  name: null,
  // Extra-Parameter für Konfiguration der Komponente. Sollte alle `props`
  // enthalten, die über die im Mixin definierten hinaus definiert werden
  extraPropParams: [],
  info: "Mixin für modale Dialoge, die als konfigurierbare Massenaktion in der Tabelle verwendet werden können.",
  tags: ["Table", "Massenaktion", "Modal"],
  components: {
    Modal,
  },
  props: {
    rows: {
      type: Array,
      required: true,
    },
    close: {
      type: Function,
      required: true,
    },
    selectorClose: {
      type: [String, Array],
      required: true,
    },
    massAction: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      modalOptions: {
        showCloseButton: true
      }
    };
  },
  methods: {
    abort() {
      this.close({ status: false });
    },
    finish() {
      this.close({ status: true });
    },
  }
};
