import {
  computed,
  toRef,
} from "vue";

import {
  isEmpty,
  isPlainObject,
} from "lodash-es";

export default function ErrorsAPI(props) {
  const errors = toRef(props, "errors");

  const errorsLocal = computed(() => {
    return errors.value || {};
  });

  const statusShowErrors = computed(() => {
    return !isEmpty(errorsLocal.value);
  });

  const statusErrorsObject = computed(() => {
    return isPlainObject(errors.value);
  });

  return {
    statusErrorsObject,
    statusShowErrors,
  };
}
