import AButton from "aloha-vue/src/AButton/AButton";

import translate from "../../../../directives/translate";

import UiCoordinatesMixin from "../UiCoordinatesMixin";
import UIReadOnlyMixin from "../../UIReadOnlyMixin";

import {
  isNil,
  size,
  isArray,
} from "lodash-es";

// @vue/component
export default {
  name: "UiCoordinatesReadOnly",
  components: {
    AButton,
  },
  directives: {
    translate,
  },
  mixins: [
    UiCoordinatesMixin,
    UIReadOnlyMixin,
  ],
  props: {
    model: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      required: false,
      default: undefined,
    },
  },
  computed: {
    getKey() {
      return (item, index) => `${ index }_${ item }`;
    },

    modelLocal() {
      return isArray(this.model) ? this.model : [];
    },
  },
  methods: {
    checkMarker({ model }) {
      if (!this.marker) {
        this.addMarker({ model });
      }
    },

    showBtn({ model }) {
      return !isNil(model) && size(model) === 2;
    },
  },
};
