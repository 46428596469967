import ModalCreateOrUpdate from "../../../DynamischeTabelle/UiDynamischeTabelle/ModalCreateOrUpdate/ModalCreateOrUpdate.js";
import OptionsMixin from "./OptionsMixin";

// @vue/component
export default {
  name: "ModalCreateOrUpdate",
  mixins: [
    ModalCreateOrUpdate,
    OptionsMixin,
  ],
};
