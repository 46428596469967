import {
  computed,
  ref,
} from "vue";
import {
  useRouter,
} from "vue-router";

import {
  get,
  startsWith,
  uniqueId,
} from "lodash-es";

const routerKey = ref(uniqueId("router_key_"));

export default function RouterAPI() {
  const $router = useRouter();

  const isRouteActive = ({ routeName, id, checkForSubroute = false }) => {
    const currentRoute = $router.currentRoute.value;
    const currentId = get(currentRoute, "params.id");
    if (checkForSubroute) {
      return startsWith(currentRoute.name, routeName);
    }
    if (currentId) {
      return currentRoute.name === routeName && currentId === id;
    }
    return currentRoute.name === routeName;
  };

  const goTo = ({ to, refresh = false }) => {
    $router.push(to);
    if (refresh) {
      routerKey.value = uniqueId("router_key_");
    }
  };

  const reloadPage = () => {
    routerKey.value = uniqueId("router_key_");
  };

  const routerKeyComputed = computed(() => {
    return `${ routerKey.value }`;
  });

  return {
    goTo,
    isRouteActive,
    reloadPage,
    routerKey,
    routerKeyComputed,
  };
}
