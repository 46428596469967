import UIComponentMixin from "../../../../../global/components/ui/UIComponentMixin";
import UiZahlungsverkehrMixin from "./UiZahlungsverkehrMixin";
import translate from "../../../../../global/directives/translate";

import {
  gettext,
} from "../../../../../global/functions/utils";
import {
  cloneDeep,
  forEach,
  isUndefined,
} from "lodash-es";

// @vue/component
export default {
  name: "UiZahlungsverkehr",
  directives: {
    translate,
  },
  mixins: [
    UIComponentMixin,
    UiZahlungsverkehrMixin,
  ],
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.initModel();
  },
  methods: {
    initModel() {
      const MODEL = cloneDeep(this.model) || {};
      forEach(this.fields, field => {
        MODEL[field.key] = MODEL[field.key] || {
          show: true
        };
        if (isUndefined(MODEL[field.key].label_editonly)) {
          MODEL[field.key].label_editonly = gettext(field.label);
        }
        if (isUndefined(MODEL[field.key].label_readonly)) {
          MODEL[field.key].label_readonly = gettext(field.label);
        }
        if (isUndefined(MODEL[field.key].show)) {
          MODEL[field.key].show = true;
        }
      });
      this.onInput_mixin({ value: MODEL });
    },

    setModel($event, key, type) {
      const MODEL = cloneDeep(this.model);
      MODEL[key][type] = !MODEL[key][type];
      this.onInput_mixin({ value: MODEL, $event });
    },

    setModelLabel($event, key, name) {
      const VALUE = $event.target.value;
      const MODEL = cloneDeep(this.model);
      MODEL[key][name] = VALUE;
      this.onInput_mixin({ value: MODEL, $event });
    },
  },
};
