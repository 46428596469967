import {
  computed,
} from "vue";

import SyConfigAPI from "../../../global/compositionAPI/SyConfigAPI";

export default function IsMenuOpenInitialAPI() {
  const {
    syConfigsValue: syConfigsMenuConfig,
  } = SyConfigAPI({
    value: "menue_config",
    defaultValue: {},
  });

  const isMenuOpenInitial = computed(() => {
    return !!syConfigsMenuConfig.value?.active;
  });

  return {
    isMenuOpenInitial,
  };
}
