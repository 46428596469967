import PuxTranslate from "../../../PuxTranslate/PuxTranslate.vue";
import ShowMore from "../../ShowMore/ShowMore.vue";

import UIReadOnlyMixin from "../../UIReadOnlyMixin";

import UiReadOnlyAPI from "../../compositionAPI/UiReadOnlyAPI";
import UiTinymceReadOnlyAPI from "./compositionAPI/UiTinymceReadOnlyAPI";

// @vue/component
export default {
  name: "UiTinymceReadOnly",
  components: {
    PuxTranslate,
    ShowMore,
  },
  mixins: [
    UIReadOnlyMixin,
  ],
  setup(props) {
    const {
      isShowMoreVisible,
      maxHeightReadOnly,
    } = UiTinymceReadOnlyAPI(props);

    const {
      emptyText,
      isModelDefined,
    } = UiReadOnlyAPI(props);

    return {
      emptyText,
      isModelDefined,
      isShowMoreVisible,
      maxHeightReadOnly,
    };
  },
};
