import {
  computed,
  toRef,
} from "vue";

import LoginLinkBehaviourAPI from "./LoginLinkBehaviourAPI";
import SyConfigAPI from "../../../global/compositionAPI/SyConfigAPI";
import UserAPI from "../../../global/compositionAPI/UserAPI";

import {
  filter,
  get,
} from "lodash-es";

export default function ConfigAPI({
  props,
}) {
  const isMock = toRef(props, "isMock");
  const isAuthenticatedProps = toRef(props, "isAuthenticated");
  const config = toRef(props, "config");

  const {
    isAuthenticated,
  } = UserAPI();

  const {
    getSyConfigsValue,
  } = SyConfigAPI();

  const {
    isLoginLinkVisible,
  } = LoginLinkBehaviourAPI();

  const isAuthenticatedLocal = computed(() => {
    return isMock.value ?
      isAuthenticatedProps.value :
      isAuthenticated.value;
  });

  const navbarSyConfig = computed(() => {
    return isMock.value ?
      config.value :
      getSyConfigsValue("navbar", {}).config || {};
  });

  const configLocalLeft = computed(() => {
    return navbarSyConfig.value.left;
  });
  const configLocalCenter = computed(() => {
    return navbarSyConfig.value.center;
  });

  const configLocalRight = computed(() => {
    return navbarSyConfig.value.right;
  });

  const configLocalRightChildren = computed(() => {
    return configLocalRight.value.children || [];
  });

  const configLocalRightChildrenFiltered = computed(() => {
    return filter(configLocalRightChildren.value, item => {
      const isLoginLink = get(item, "attributes.to.name") === "root.login";

      if (isLoginLink && !isLoginLinkVisible.value) {
        return false;
      }
      if (item.statusAuthenticated === true) {
        return isAuthenticatedLocal.value;
      }
      if (item.statusAuthenticated === false) {
        return !isAuthenticatedLocal.value;
      }

      return true;
    });
  });

  return {
    configLocalCenter,
    configLocalLeft,
    configLocalRight,
    configLocalRightChildrenFiltered,
  };
}
