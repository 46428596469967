// @vue/component
export default {
  data() {
    return {
      fields: [
        {
          key: "aov_inhaber",
          label: "_TXT_GR_ZAHLUNGSVERKEHR_PARAMETER_KONTOINHABER_",
        },
        {
          key: "aov_iban",
          label: "_TXT_GR_ZAHLUNGSVERKEHR_PARAMETER_IBAN_",
        },
        {
          key: "aov_bic",
          label: "_TXT_GR_ZAHLUNGSVERKEHR_PARAMETER_BIC_",
        },
        {
          key: "aov_bank",
          label: "_TXT_GR_ZAHLUNGSVERKEHR_PARAMETER_GELDINSTITUT_",
        },
        {
          key: "externe_referenz",
          label: "_TXT_GR_ZAHLUNGSVERKEHR_PARAMETER_EXTERNE_REFERENZ_",
        },
      ],
    };
  },
};
