import UiValidatedJson from "../../../../../global/components/ui/UiValidatedJson/UiValidatedJson.vue";

import JsonOptionsMixin from "./JsonOptionsMixin";
import UIComponentMixin from "../../../../../global/components/ui/UIComponentMixin";

// @vue/component
export default {
  name: "UiAntragBudgetkontoKonto",
  components: {
    UiValidatedJson
  },
  mixins: [
    JsonOptionsMixin,
    UIComponentMixin,
  ],
};
