const AngebotDetails = () => import(/* webpackChunkName: "AngebotDetails" */ "../../views/Angebot/AngebotDetails/AngebotDetails.vue");
const AngebotList = () => import(/* webpackChunkName: "AngebotList" */ "../../views/Angebot/AngebotList/AngebotList.vue");
const GeschaeftsregelDetails = () => import(/* webpackChunkName: "GeschaeftsregelDetails" */ "../../views/Geschaeftsregel/GeschaeftsregelDetails/GeschaeftsregelDetails.vue");
const GeschaeftsregelList = () => import(/* webpackChunkName: "GeschaeftsregelList" */ "../../views/Geschaeftsregel/GeschaeftsregelList/GeschaeftsregelList.vue");
const InstrumentDetails = () => import(/* webpackChunkName: "InstrumentDetails" */ "../../views/Instrument/InstrumentDetails/InstrumentDetails.vue");
const InstrumentList = () => import(/* webpackChunkName: "InstrumentList" */ "../../views/Instrument/InstrumentList/InstrumentList.vue");
const InstrumentVariantenDetails = () => import(/* webpackChunkName: "InstrumentVariantenDetails" */ "../../views/Instrument/InstrumentvariantenDetails/InstrumentvariantenDetails.vue");
const InstrumentVariantenList = () => import(/* webpackChunkName: "InstrumentVariantenList" */ "../../views/Instrument/InstrumentvariantenList/InstrumentvariantenList.vue");
const ProgrammeDetails = () => import(/* webpackChunkName: "ProgrammeDetails" */ "../../views/Programme/ProgrammeDetails/ProgrammeDetails.vue");
const ProgrammeList = () => import(/* webpackChunkName: "ProgrammeList" */ "../../views/Programme/ProgrammeList/ProgrammeList.vue");

export default [
  {
    path: "/foerderangebote/",
    name: "root.foerderangebote",
    component: AngebotList,
    meta: {
      permissions: [
        "foerderangebot.view.admin",
      ],
    },
  },
  {
    path: "/foerderangebote/:id/",
    name: "root.foerderangebote.detail",
    component: AngebotDetails,
    meta: {
      permissions: [
        "foerderangebot.view.admin",
      ],
    },
  },
  {
    path: "/foerderprogramme/",
    name: "root.foerderprogramme",
    component: ProgrammeList,
    meta: {
      permissions: [
        "foerderprogramm.update",
      ],
    },
  },
  {
    path: "/foerderprogramme/:id/",
    name: "root.foerderprogramme.detail",
    component: ProgrammeDetails,
    meta: {
      permissions: [
        "foerderprogramm.update",
      ],
    },
  },
  {
    path: "/foerderregeln/",
    name: "root.foerderregeln",
    component: GeschaeftsregelList,
    meta: {
      permissions: [
        "foerderregel.update",
      ],
    },
  },
  {
    path: "/foerderregeln/:id/",
    name: "root.foerderregeln.detail",
    component: GeschaeftsregelDetails,
    meta: {
      permissions: [
        "foerderregel.update",
      ],
    },
  },
  {
    path: "/foerderinstrumente/",
    name: "root.foerderinstrumente",
    component: InstrumentList,
    meta: {
      permissions: [
        "foerderinstrument.update",
      ],
    },
  },
  {
    path: "/foerderinstrumente/:id/",
    name: "root.foerderinstrumente.detail",
    component: InstrumentDetails,
    meta: {
      permissions: [
        "foerderinstrument.update",
      ],
    },
  },
  {
    path: "/foerderinstrumentvarianten/",
    name: "root.foerderinstrumentvarianten",
    component: InstrumentVariantenList,
    meta: {
      permissions: [
        "foerderinstrument.update",
      ],
    },
  },
  {
    path: "/foerderinstrumentvarianten/:id/",
    name: "root.foerderinstrumentvarianten.detail",
    component: InstrumentVariantenDetails,
    meta: {
      permissions: [
        "foerderinstrument.update",
      ],
    },
  },
];
