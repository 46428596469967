import {
  computed,
} from "vue";

import {
  uniqueId,
} from "lodash-es";

export default function AttributesAPI(props, {
  currentLabel = computed(() => ""),
  hasElementErrors = computed(() => false),
  idLocal = computed(() => ""),
  isErrorObject = computed(() => false),
}) {
  const ariaDescribedby = computed(() => {
    if (idLocal.value) {
      return `pux_errors_${ idLocal.value }`;
    }
    return uniqueId("pux_errors_");
  });

  const classForErrorsWrapper = computed(() => {
    if (!hasElementErrors.value) {
      if (isErrorObject.value) {
        return "a_pl_2";
      }
    }
    if (currentLabel.value) {
      return "a_pl_5";
    }
    return "a_pl_2";
  });

  return {
    ariaDescribedby,
    classForErrorsWrapper,
    idLocal,
  };
}
