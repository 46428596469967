import {
  computed,
  toRef,
} from "vue";

import {
  get,
  isNil,
} from "lodash-es";

export default function IdAPI(props) {
  const errorKeyOrIndex = toRef(props, "errorKeyOrIndex");
  const errorLabels = toRef(props, "errorLabels");
  const id = toRef(props, "id");

  const idLocal = computed(() => {
    const ID = get(errorLabels.value, "_id", id.value);
    const _usePureIdForLink = get(errorLabels.value, "_usePureIdForLink");

    if (!isNil(errorKeyOrIndex.value) && !_usePureIdForLink) {
      return `${ ID }_${ errorKeyOrIndex.value }`;
    }
    return ID;
  });

  return {
    idLocal,
  };
}
