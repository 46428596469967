const FoerdermittelList = () => import(/* webpackChunkName: "FoerdermittelList" */ "../../views/Foerdermittel/FoerdermittelList/FoerdermittelList.vue");
const SitzungAntraegeDetails = () => import(/* webpackChunkName: "SitzungAntraegeDetails" */ "../../views/Sitzungsantraege/SitzungAntraegeDetails/SitzungAntraegeDetails.vue");
const SitzungAntraegeList = () => import(/* webpackChunkName: "SitzungAntraegeList" */ "../../views/Sitzungsantraege/SitzungAntraegeList/SitzungAntraegeList.vue");
const SitzungDetails = () => import(/* webpackChunkName: "SitzungDetails" */ "../../views/Sitzung/SitzungDetails/SitzungDetails.vue");
const SitzungList = () => import(/* webpackChunkName: "SitzungList" */ "../../views/Sitzung/SitzungList/SitzungList.vue");
const SitzungsartDetails = () => import(/* webpackChunkName: "SitzungsartDetails" */ "../../views/Sitzung/SitzungsartDetails/SitzungsartDetails.vue");
const SitzungsartenList = () => import(/* webpackChunkName: "SitzungsartenList" */ "../../views/Sitzung/SitzungsartenList/SitzungsartenList.vue");
const TagesordnungspunktDetails = () => import(/* webpackChunkName: "TagesordnungspunktDetails" */ "../../views/Tagesordnungspunkt/TagesordnungspunktDetails/TagesordnungspunktDetails.vue");

export default [
  {
    path: "/sitzungen/",
    name: "root.sitzungen",
    component: SitzungList,
    meta: {
      permissions: [
        "sitzungen.view",
      ],
    },
  },
  {
    path: "/sitzungen/:id/",
    name: "root.sitzungen.details",
    component: SitzungDetails,
    meta: {
      permissions: [
        "sitzungen.view",
      ],
    },
  },
  {
    path: "/sitzungen/:id/top/:tos_id/",
    name: "root.sitzungen.details.top",
    component: TagesordnungspunktDetails,
    meta: {
      permissions: [
        "sitzungen.view",
      ],
    },
  },
  {
    path: "/sitzungsantraege/",
    name: "root.sitzungsantraege",
    component: SitzungAntraegeList,
    meta: {
      permissions: [
        "sitzungen.antrag.view",
      ],
    },
  },
  {
    path: "/sitzungsantraege/:id/",
    name: "root.sitzungsantraege.details",
    component: SitzungAntraegeDetails,
    meta: {
      permissions: [
        "sitzungen.antrag.view",
      ],
    },
  },
  {
    path: "/foerdermittel/",
    name: "root.mittel",
    component: FoerdermittelList,
    meta: {
      permissions: [
        "mittel.view",
      ],
    },
  },
  {
    path: "/sitzungsarten/",
    name: "root.sitzungsarten",
    component: SitzungsartenList,
    meta: {
      permissions: [
        "sitzungsarten.admin.view",
      ],
    },
  },
  {
    path: "/sitzungsarten/:id/",
    name: "root.sitzungsarten.details",
    component: SitzungsartDetails,
    meta: {
      permissions: [
        "sitzungsarten.admin.view",
      ],
    },
  },
];
