import {
  cloneDeep,
  forEach,
} from "lodash-es";

export default {
  namespaced: true,
  state() {
    return {
      RUBRICS_MAP_BY_PK_FILTERED_WITH_MEDIA_TYPES: {},
      MODEL_MEDIA_TYPES: [],
      MODEL_SEARCH: undefined,
      KEYWORDS_MAP_BY_PK: {},
    };
  },
  getters: {
    GET_ALL_FAQ_EINTRAEGE: state => {
      const EINTRAEGE = [];
      forEach(cloneDeep(state.RUBRICS_MAP_BY_PK_FILTERED_WITH_MEDIA_TYPES), rubric => {
        EINTRAEGE.push(...rubric.faq_eintraege);
      });
      return EINTRAEGE;
    },

    GET_RUBRICS: state => state.RUBRICS_MAP_BY_PK_FILTERED_WITH_MEDIA_TYPES,

    GET_MODEL_MEDIA_TYPES: state => state.MODEL_MEDIA_TYPES,

    GET_MODEL_SEARCH: state => state.MODEL_SEARCH,

    GET_KEYWORDS_MAP_BY_PK: state => state.KEYWORDS_MAP_BY_PK,
  },
  mutations: {
    MUT_SET_RUBRICS(state, rubrics) {
      state.RUBRICS_MAP_BY_PK_FILTERED_WITH_MEDIA_TYPES = cloneDeep(rubrics);
    },

    MUT_SET_MODEL_MEDIA_TYPES(state, model) {
      state.MODEL_MEDIA_TYPES = cloneDeep(model);
    },

    MUT_SET_MODEL_SEARCH(state, model) {
      state.MODEL_SEARCH = model;
    },

    MUT_SET_KEYWORDS_MAP_BY_PK(state, keywordsMapByPk) {
      state.KEYWORDS_MAP_BY_PK = keywordsMapByPk;
    },

    MUT_RESET(state) {
      state.RUBRICS_MAP_BY_PK_FILTERED_WITH_MEDIA_TYPES = {};
      state.MODEL_MEDIA_TYPES = [];
      state.MODEL_SEARCH = undefined;
      state.KEYWORDS_MAP_BY_PK = {};
    },
  },
};
