export default {
  ANTRAGSTELLUNG: 1,
  FOERDERORG: 2,
  SYSTEM: 3
};

export const KontextTypesList = [
  "ANTRAGSTELLUNG",
  "FOERDERORG",
  "SYSTEM",
];
