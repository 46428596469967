import PuxTranslate from "../../PuxTranslate/PuxTranslate.vue";

import UIComponentMixin from "../UIComponentMixin";

import {
  cloneDeep,
  get,
  isUndefined,
} from "lodash-es";

// @vue/component
export default {
  name: "UiBoolean",
  components: {
    PuxTranslate,
  },
  mixins: [
    UIComponentMixin
  ],
  computed: {
    isChecked() {
      if (this.isModelTrue) {
        return true;
      }
      if (this.isModelFalse) {
        return false;
      }
      return undefined;
    },

    labelLocal() {
      if (this.isModelTrue) {
        return this.trueLabel;
      }
      if (this.isModelFalse) {
        return this.falseLabel;
      }
      return this.defaultLabel;
    },

    isModelTrue() {
      return this.model === this.trueValue || this.model === "True" || this.model === "true";
    },

    isModelFalse() {
      return this.model === this.falseValue || this.model === "False" || this.model === "false";
    },

    isModelDefault() {
      return !this.isModelTrue && !this.isModelFalse;
    },

    trueValue() {
      return isUndefined(this.options.trueValue) ? true : this.options.trueValue;
    },

    falseValue() {
      return isUndefined(this.options.falseValue) ? false : this.options.falseValue;
    },

    defaultValue() {
      return this.options.defaultValue;
    },

    trueLabel() {
      return this.options.trueLabel || "_LBL_UI_BOOLEAN_DEFAULT_TRUE_LABEL_";
    },

    falseLabel() {
      return this.options.falseLabel || "_LBL_UI_BOOLEAN_DEFAULT_FALSE_LABEL_";
    },

    defaultLabel() {
      return this.options.defaultLabel || "_LBL_UI_BOOLEAN_DEFAULT_DEFAUL_LABEL_";
    },

    triStateActive() {
      return this.options.triState || false;
    },

    switchButtonClass() {
      return {
        switch_button_undefined: this.isModelDefault,
        "is-invalid": this.isError,
      };
    },

    classForInputLocal() {
      const CLASS_FOR_INPUT = cloneDeep(this.getInputClass) || [];
      CLASS_FOR_INPUT.push(this.checkedClassForInput);
      return CLASS_FOR_INPUT;
    },

    checkedClassForInput() {
      return this.isChecked ?
        "test_checked" :
        "test_unchecked";
    },

    isLabelVisible() {
      const IS_LABEL_VISIBLE = get(this.extraStatic, "isLabelVisible");
      return this.options.view || IS_LABEL_VISIBLE;
    },

    ariaDescribedbyForLabel() {
      return `${ this.getId }_label`;
    },

    attributesHtmlLocal() {
      const ATTRIBUTES_HTML = cloneDeep(this.attributesHtml);
      if (this.isLabelVisible) {
        if (ATTRIBUTES_HTML["aria-describedby"]) {
          ATTRIBUTES_HTML["aria-describedby"] += ` ${ this.ariaDescribedbyForLabel }`;
        } else {
          ATTRIBUTES_HTML["aria-describedby"] = this.ariaDescribedbyForLabel;
        }
      }
      return ATTRIBUTES_HTML;
    },
  },
  methods: {
    onInput($event) {
      let model;
      if (this.isModelTrue) {
        model = this.falseValue;
      } else if (this.triStateActive && this.isModelFalse) {
        model = this.defaultValue;
      } else {
        model = this.trueValue;
      }
      this.onInput_mixin({ value: model, $event });
    },
  },
};
