const DokumenteSuchen = () => import(/* webpackChunkName: "DokumenteSuchen" */ "../../views/Dokumente/DokumenteSuchen/DokumenteSuchen.vue");
const DokumenteUpload = () => import(/* webpackChunkName: "DokumenteUpload" */ "../../views/Dokumente/DokumenteUpload/DokumenteUpload.vue");
const FoerderdokumenteDetails = () => import(/* webpackChunkName: "FoerderdokumenteDetails" */ "../../views/Dokumente/FoerderdokumenteDetails/FoerderdokumenteDetails.vue");
const FoerderdokumenteList = () => import(/* webpackChunkName: "FoerderdokumenteList" */ "../../views/Dokumente/FoerderdokumenteList/FoerderdokumenteList.vue");

export default [
  {
    path: "/dokumentenupload/",
    name: "root.dokumentenupload",
    component: DokumenteUpload,
    meta: {
      permissions: [
        "dokumentimport.view",
      ],
    },
  },
  {
    path: "/dokumentsuche/",
    name: "root.dokumentensuchen",
    component: DokumenteSuchen,
    meta: {
      permissions: [
        "dokumentsuche.view",
      ],
    },
  },
  {
    path: "/foerderdokumente/",
    name: "root.foerderdokumente",
    component: FoerderdokumenteList,
    meta: {
      permissions: [
        "foerderdokument.management",
      ],
    },
  },
  {
    path: "/foerderdokumente/:id/",
    name: "root.foerderdokumente.details",
    component: FoerderdokumenteDetails,
    meta: {
      permissions: [
        "foerderdokument.management",
      ],
    },
  },
];
