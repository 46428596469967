import AButton from "aloha-vue/src/AButton/AButton";
import Modal from "../../global/components/Modal/Modal.vue";
import PuxCloak from "../../global/components/PuxCloak/PuxCloak.vue";
import ReleaseNotesModalElement from "./Element/Element.vue";

import translate from "../../global/directives/translate";

import AHttpAPI from "aloha-vue/src/compositionAPI/AHttpAPI";

// @vue/component
export default {
  name: "TheReleaseNotesModal",
  components: {
    AButton,
    Modal,
    PuxCloak,
    ReleaseNotesModalElement,
  },
  directives: {
    translate,
  },
  props: {
    close: {
      type: Function,
      required: true,
      info: "Schießen Modal-Fenster",
    },
    selectorCloseIds: {
      type: String,
      required: true,
    },
  },
  setup() {
    const {
      getListHttp,
    } = AHttpAPI();

    return {
      getListHttp,
    };
  },
  data() {
    return {
      statusLoading: true,
      releaseNotes: [],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.getListHttp({
        url: "releasenotes/",
      }).then(
        response => {
          this.releaseNotes = response;
          this.statusLoading = false;
        }
      );
    },
  },
};
