import { toFormElementFromParameter } from "../../../../global/functions/mappingForParameterToFormElement";

export default {
  data() {
    return {
      jsonParam: {
        bez: "_LBL_KOFI_ZUSATZPROZENT_",
        default: null,
        help_text: "_TXT_KOFI_ZUSAZTPROZENT_HELP_TEXT_",
        readonly: false,
        options: {
          allow_null: true,
          required: false
        },
        typ: "validated_json",
        many: false,
        as_json: true,
        unique_fields: [],
        key_field: null,
        pos_field: null,
        min_length: null,
        max_length: null,
        element_label: "Element",
        element_template: "{{element_label}}",
        element_errors: false,
        deactivate_ordering: false,
        deactivate_preview: false,
        hide_pos: false,
        fields: {
          regeln_kosten: {
            prio: 0,
            bez: "_LBL_ZUSATZPROZENT_FELDER_REGELN_KOSTEN_",
            default: null,
            help_text: "_TXT_ZUSATZPROZENT_FELDER_REGELN_KOSTEN_HELP_TEXT_",
            readonly: false,
            options: {
              allow_null: false,
              required: false
            },
            typ: "multi_select",
            katalog: "foerderregel",
            katalog_filter: {
              suche: [
                "perioden_signal_kosten",
                "kosten_summenzeile_periode"
              ]
            }
          },
          regeln_finanzierung: {
            prio: 1,
            bez: "_LBL_ZUSATZPROZENT_FELDER_REGELN_FINANZIERUNG_",
            default: null,
            help_text: "_TXT_ZUSATZPROZENT_FELDER_REGELN_FINANZIERUNG_HELP_TEXT_",
            readonly: false,
            options: {
              allow_null: false,
              required: false
            },
            typ: "multi_select",
            katalog: "foerderregel",
            katalog_filter: {
              suche: [
                "perioden_signal_finanzierung",
                "finanzierung_summenzeile_periode"
              ]
            }
          },
          label_zusatz: {
            prio: 2,
            bez: "_LBL_ZUSATZPROZENT_FELDER_LABEL_ZUSATZ_",
            default: null,
            help_text: "_TXT_ZUSATZPROZENT_FELDER_LABEL_ZUSATZ_HELP_TEXT_",
            readonly: false,
            options: {
              allow_null: false,
              required: true,
              max_length: 200,
              min_length: null
            },
            typ: "string"
          },
          label_gesamt: {
            prio: 3,
            bez: "_LBL_ZUSATZPROZENT_FELDER_LABEL_GESAMT_",
            default: null,
            help_text: "_TXT_ZUSATZPROZENT_FELDER_LABEL_GESAMT_HELP_TEXT_",
            readonly: false,
            options: {
              allow_null: false,
              required: true,
              max_length: 200,
              min_length: null
            },
            typ: "string"
          }
        },
        prio: 5,
        wert: null
      }
    };
  },
  computed: {
    jsonOptions() {
      return toFormElementFromParameter({
        obj: { zusatzprozent: this.jsonParam },
        showLabel: true,
        staticOptions: {
          required: this.options.required,
          options: this.options.options
        }
      }).list[0];
    }
  }
};
