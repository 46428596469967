export default {
  mounted(el, bindings) {
    if (!bindings.value) {
      return;
    }
    const container = bindings.value !== "body" ?
      document.getElementById(bindings.value) :
      document.body;
    if (container) {
      if (bindings.modifiers.after) {
        container.insertAdjacentElement("afterend", el);
      } else {
        (bindings.modifiers.prepend && container.firstChild)
          ? container.insertBefore(el, container.firstChild) // top of target
          :	container.appendChild(el); // bottom of target
      }
    } else {
      console.warn(
        `v-teleport target element id ${ bindings.value } not found.`
      );
    }
  },
  unmounted(el) {
    if (el.parentNode) {
      el.parentNode.removeChild(el);
    }
  },
};
