import {
  ref,
} from "vue";

const isLoginLinkVisible = ref(true);

export default function LoginLinkBehaviourAPI() {
  const hideLoginLink = () => {
    isLoginLinkVisible.value = false;
  };

  const showLoginLink = () => {
    isLoginLinkVisible.value = true;
  };

  return {
    hideLoginLink,
    isLoginLinkVisible,
    showLoginLink,
  };
}
