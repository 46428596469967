import AButton from "aloha-vue/src/AButton/AButton";

import translate from "../../../../../../../global/directives/translate";

import {
  toLower,
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "UiAntragstellerRechtsformZusatzReadOnlyElement",
  components: {
    AButton,
  },
  directives: {
    translate,
  },
  props: {
    model: {
      type: Array,
      required: true,
    },
    katalogData: {
      type: Object,
      required: true,
    },
    currentIndex: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      showMore: false
    };
  },
  computed: {
    currentModel() {
      return get(this.model, this.currentIndex, {});
    },
    currentOptions() {
      return [
        {
          label: "_LBL_PARAM_RECHTSFORM_ZUSATZ_FIELD_LABEL_",
          showLabel: true,
          id: "label",
          type: "text",
          required: false,
          view: "v",
        }, {
          label: "_LBL_PARAM_RECHTSFORM_ZUSATZ_FIELD_ID_",
          showLabel: true,
          id: "id",
          type: "text",
          required: true,
          view: "v",
          placeholder: this.tokenize(get(this.model, "label", null)),
        }, {
          label: "_LBL_PARAM_RECHTSFORM_ZUSATZ_FIELD_RECHTSFORMEN_",
          showLabel: true,
          id: "rechtsformen",
          type: "multiselect",
          keyId: "pk",
          keyLabel: "bez",
          data: this.katalogData.krechtsform,
          required: true,
          view: "v",
        }, {
          label: "_LBL_PARAM_RECHTSFORM_ZUSATZ_FIELD_REGELN_",
          showLabel: true,
          id: "regeln",
          type: "multiselect",
          keyId: "pk",
          keyLabel: "bez",
          data: this.katalogData.elementfoerderregel,
          required: true,
          view: "v",
        },
      ];
    },
    translationShowMore() {
      return {
        text: (this.showMore ? "_BTN_SHOW_MORE_WENIGER_" : "_BTN_SHOW_MORE_MEHR_"),
        title: (this.showMore ? "_BTN_SHOW_MORE_WENIGER_" : "_BTN_SHOW_MORE_MEHR_")
      };
    },
  },
  beforeCreate() {
    this.$options.components.FormElementReadOnly = require("../../../../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue").default;
  },
  created() {
  },
  methods: {
    tokenize(str) {
      return toLower(str).replace(/[^a-zA-Z0-9]/g, "");
    },
    toggleShowMore() {
      this.showMore = !this.showMore;
    }
  },
};
