import AButton from "aloha-vue/src/AButton/AButton";
import UiElementverzweigungElement from "./UiElementverzweigungElement/UiElementverzweigungElement.vue";

import HttpMixin from "../../../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../../../global/mixins/NotificationMixin";
import UIComponentMixin from "../../../../../global/components/ui/UIComponentMixin";

import {
  cloneDeep,
  keyBy,
  findIndex,
} from "lodash-es";

// @vue/component
export default {
  name: "UiElementverzweigung",
  components: {
    AButton,
    UiElementverzweigungElement,
  },
  mixins: [
    HttpMixin,
    UIComponentMixin,
    NotificationMixin,
  ],
  props: {
    model: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      optionsLabel: {
        type: "text",
        id: "label",
        view: "v",
        required: true,
        label: "Antwortmöglichkeit hinzufügen:",
      },
      modelLocal: {},
      loading: true,
      dataForSelectDefault: [],
      regelnByPK: {},
    };
  },
  beforeCreate() {
    this.$options.components.FormElement = require("../../../../../global/components/FormElement/FormElement.vue").default;
  },
  created() {
    this.setDefaultModelLocal();
    this.loadData();
  },
  methods: {
    loadData() {
      this.getListHttp({
        url: `katalog/`,
        urlParams: {
          fields: ["pk", "re_nummer", "re_kbez", "bez"],
          key: "elementfoerderregel",
        },
      }).then(
        response => {
          this.regelnByPK = keyBy(response, "pk");
          this.dataForSelectDefault = response;
          this.loading = false;
        }
      );
    },

    setDefaultModelLocal() {
      this.modelLocal = {
        label: null,
      };
    },

    addFeld() {
      if (!this.modelLocal.label) {
        return;
      }
      if (this.isLabelInModel({ label: this.modelLocal.label })) {
        this.addNotification({ text: "Element existiert bereits in dieser Antwormöglichkeit", type: "error" });
        return;
      }
      const MODEL = cloneDeep(this.model) || [];
      MODEL.push({
        label: this.modelLocal.label,
        children: [],
      });
      this.onInput_mixin({ value: MODEL });
      this.setDefaultModelLocal();
    },

    isLabelInModel({ label }) {
      return findIndex(this.model, ["label", label]) !== -1;
    },
  },
};
