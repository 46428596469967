import AButton from "aloha-vue/src/AButton/AButton";
import PuxIcon from "../../PuxIcon/PuxIcon.vue";

import UIComponentMixin from "../UIComponentMixin";
import UiRatingMixin from "./UiRatingMixin";

// @vue/component
export default {
  name: "UiRating",
  components: {
    AButton,
    PuxIcon,
  },
  mixins: [
    UIComponentMixin,
    UiRatingMixin,
  ],
  methods: {
    setSelectedModel(id) {
      if (this.model === id) {
        id = undefined;
      }
      this.onInput_mixin({ value: id });
    },

    setHoverIndex(index) {
      if (this.disabled) {
        return;
      }

      this.hoverIndex = index + 1;
    },
  },
};
