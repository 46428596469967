const AntraegeGeloeschteList = () => import(/* webpackChunkName: "AntraegeGeloeschteList" */ "../../views/Antraege/AntraegeGeloeschteList/AntraegeGeloeschteList.vue");
const AntraegeList = () => import(/* webpackChunkName: "AntraegeList" */ "../../views/Antraege/AntraegeList/AntraegeList.vue");
const AntragCreateExtern = () => import(/* webpackChunkName: "AntragCreateExtern" */ "../../views/Antraege/AntragCreateExtern/AntragCreateExtern.vue");
const AntragDetails = () => import(/* webpackChunkName: "AntragDetails" */ "../../views/Antraege/AntragDetails/AntragDetails.vue");
const AntraglisteList = () => import(/* webpackChunkName: "AntraglisteList" */ "../../views/Antragsliste/AntragslisteList/AntragslisteList.vue");
const AntragslisteDetails = () => import(/* webpackChunkName: "AntraglisteDetails" */ "../../views/Antragsliste/AntragslisteDetails/AntragslisteDetails.vue");
const FoerderfinderFoerderangeboteList = () => import(/* webpackChunkName: "FoerderfinderFoerderangeboteList" */ "../../views/Foerderfinder/FoerderfinderFoerderangeboteList/FoerderfinderFoerderangeboteList.vue");

export default [
  {
    path: "/foerderantrag-erstellen/",
    name: "root.foerderantrag-add",
    component: FoerderfinderFoerderangeboteList,
    meta: {
      permissions: [
        "foerderantrag.create",
      ],
    },
  },
  {
    path: "/foerderantrag/",
    name: "root.foerderantrag",
    component: AntraegeList,
    meta: {
      permissions: [
        "foerderantrag.view",
      ],
    },
  },
  {
    path: "/foerderantrag/:id/",
    name: "root.foerderantrag.details",
    component: AntragDetails,
    meta: {
      permissions: [
        "foerderantrag.view",
      ],
    },
  },
  {
    path: "/foerderantrag/neu_mit_foerderangebot/",
    name: "root.foerderantrag.add_extern",
    component: AntragCreateExtern,
    meta: {
      permissions: [
        "foerderantrag.create",
      ],
    },
  },
  {
    path: "/antragslisten/",
    name: "root.antragslisten",
    component: AntraglisteList,
    meta: {
      permissions: [
        "foerderantrag.cortex.antragslisten.ui",
      ],
    },
  },
  {
    path: "/antragslisten/:id/",
    name: "root.antragslisten.details",
    component: AntragslisteDetails,
    meta: {
      permissions: [
        "foerderantrag.cortex.antragslisten.ui",
      ],
    },
  },
  {
    path: "/geloeschte_antraege/",
    name: "root.geloeschte_antraege",
    component: AntraegeGeloeschteList,
    meta: {
      permissions: [
        "geloeschte_antraege.view",
      ],
    },
  },
];
