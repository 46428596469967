import {
  computed,
  toRef,
} from "vue";

export default function LevelAPI(props) {
  const level = toRef(props, "level");

  const childLevel = computed(() => {
    return level.value + 1;
  });

  return {
    childLevel,
  };
}
