import PuxCloak from "../../../../../../global/components/PuxCloak/PuxCloak.vue";
import HttpMixin from "../../../../../../global/mixins/HttpMixin";
import {
  cloneDeep,
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "UiZielgruppenAuswahlReadOnly",
  components: {
    PuxCloak,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    className: {
      type: String,
      default: "",
    },
    model: {
      type: Array,
      default: undefined,
    },
  },
  data() {
    return {
      zielgruppen: [],
      loading: true,
    };
  },
  computed: {
    getGruppen() {
      const GRUPPEN = [];
      forEach(cloneDeep(this.zielgruppen), gruppe => {
        const UNTERGRUPPEN = [];
        if (gruppe.untergruppen && gruppe.untergruppen.length) {
          forEach(gruppe.untergruppen, untergruppe => {
            if (this.model.indexOf(untergruppe.pk) !== -1) {
              UNTERGRUPPEN.push(untergruppe);
            }
          });
        }
        if (this.model.indexOf(gruppe.pk) !== -1) {
          const GRUPPE = cloneDeep(gruppe);
          GRUPPE.untergruppen = UNTERGRUPPEN;
          GRUPPEN.push(GRUPPE);
        }
      });
      return GRUPPEN;
    },
  },
  created() {
    this.loadDataForZielGruppen();
  },
  methods: {
    loadDataForZielGruppen() {
      this.getListHttp({
        url: "zielgruppen/",
        apiSaveId: "zielgruppen",
      }).then(
        response => {
          this.zielgruppen = response;
          this.loading = false;
        }
      );
    },
  },
};
