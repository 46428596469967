import {
  computed,
  toRef,
} from "vue";

export default function TitleAPI(props) {
  const column = toRef(props, "column");
  const row = toRef(props, "row");
  const rowIndex = toRef(props, "rowIndex");

  const title = computed(() => {
    return column.value.titleCallback ?
      column.value.titleCallback({
        row: row.value,
        rowIndex: rowIndex.value,
      }) :
      column.value.title;
  });

  return {
    title,
  };
}
