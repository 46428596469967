import {
  isNil,
} from "lodash-es";

export default {
  data() {
    return {
      modelLocal: true,
    };
  },
  computed: {
    minValue() {
      return this.options.minValue || this.options.min_value;
    }
  },
  methods: {
    checkMinValue({ value }) {
      if (!isNil(this.minValue) && this.minValue > value) {
        return +this.minValue;
      }
      return value;
    },
  },
};
