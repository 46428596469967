import translate from "../../../../../../global/directives/translate";

// @vue/component
export default {
  name: "UiAmpelReadOnly",
  directives: {
    translate,
  },
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    className: {
      type: String,
      default: "",
    },
    model: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      default: undefined,
    },
  },
};
