import {
  additionFilters,
} from "aloha-vue/src/compositionAPI/AFiltersAPI";

import FromNow from "./global/filters/FromNow";
import iban from "./global/filters/iban";
import spaced from "./global/filters/spaced";
import searchFormat from "./global/filters/searchFormat";

export function addFilters() {
  additionFilters.value = {
    filterFromNow: FromNow,
    filterIban: iban,
    filterSpaced: spaced,
    filterSearchFormat: searchFormat,
  };
}
