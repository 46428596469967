import AButton from "aloha-vue/src/AButton/AButton";
import PuxCloak from "../../../PuxCloak/PuxCloak.vue";
import PuxTranslate from "../../../PuxTranslate/PuxTranslate.vue";
import UiSelectOrderedElement from "./UiSelectOrderedElement/UiSelectOrderedElement.vue";

import HttpMixin from "../../../../mixins/HttpMixin";
import UIComponentMixin from "../../UIComponentMixin";

import {
  gettext
} from "../../../../functions/utils";

import {
  cloneDeep,
  filter,
  find,
  get,
  isNil,
  keyBy,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "UiSelectOrdered",
  components: {
    AButton,
    PuxCloak,
    PuxTranslate,
    UiSelectOrderedElement,
  },
  mixins: [
    HttpMixin,
    UIComponentMixin,
  ],
  data() {
    return {
      selectModel: undefined,
      loading: undefined,
      list: [],
    };
  },
  computed: {
    isEmpty() {
      return size(this.model) === 0;
    },

    isDisabledButtonAdd() {
      return this.isDisabled || isNil(this.selectModel);
    },

    emptyPlaceholder() {
      return this.options.orderedSelectionEmptyPlaceholder || "_TXT_UI_SELECT_ORDERED_SELECTION_EMPTY_";
    },

    selectOptions() {
      const OPTIONS_CLONE = cloneDeep(this.options);
      OPTIONS_CLONE.type = "select";
      OPTIONS_CLONE.view = "v-alt";
      OPTIONS_CLONE.label = this.options.orderedSelectionLabel || "_LBL_UI_SELECT_ORDERED_SELECTION_";
      OPTIONS_CLONE.showLabel = size(OPTIONS_CLONE.label) > 0;
      OPTIONS_CLONE.translate = true;
      OPTIONS_CLONE.dataTranslate = true;
      OPTIONS_CLONE.data = this.getListFiltered;
      OPTIONS_CLONE.placeholder = this.options.orderedSelectionPlaceholder || "_TXT_UI_SELECT_ORDERED_SELECTION_PLACEHOLDER_";
      OPTIONS_CLONE.deselect = true;
      OPTIONS_CLONE.helpText = undefined;
      OPTIONS_CLONE.change = undefined;
      return OPTIONS_CLONE;
    },

    getList() {
      return find([this.options.data, this.data, this.list], list => size(list) > 0) || [];
    },

    getCollection() {
      return keyBy(this.getList, this.options.keyId);
    },

    getListFiltered() {
      return filter(this.getList, item => {
        return this.model.indexOf(get(item, this.options.keyId)) === -1;
      });
    },

    selectionLabel() {
      const SELECTED = get(this.getCollection, this.selectModel);
      if (isNil(SELECTED)) {
        return;
      }
      return gettext(SELECTED.label);
    },

    idForButtonAdd() {
      return `${ this.getId }_add`;
    },

    btnAddTooltip() {
      if (isNil(this.selectModel)) {
        return "_BTN_UI_SELECT_ORDERED_ADD_TITLE_EMPTY_";
      }
      return "_BTN_UI_SELECT_ORDERED_ADD_TITLE_{{selected}}_";
    },

    translateExtra() {
      return {
        selected: this.selectionLabel
      };
    }
  },
  beforeCreate() {
    this.$options.components.FormElement = require("../../../FormElement/FormElement.vue").default;
  },
  created() {
    this.loadData();
  },
  methods: {
    isFirst({ index }) {
      return index === 0;
    },

    isLast({ index }) {
      return index >= (size(this.model) - 1);
    },

    moveUp({ index }) {
      if (this.isFirst({ index })) {
        return;
      }
      const CURRENT = this.model[index];
      const PREVIOUS = this.model[index - 1];
      const MODEL_CLONE = cloneDeep(this.model) || [];
      MODEL_CLONE.splice(index - 1, 2, CURRENT, PREVIOUS);
      this.onInput_mixin({ value: MODEL_CLONE });
    },

    moveDown({ index }) {
      if (this.isLast({ index })) {
        return;
      }
      const CURRENT = this.model[index];
      const NEXT = this.model[index + 1];
      const MODEL_CLONE = cloneDeep(this.model) || [];
      MODEL_CLONE.splice(index, 2, NEXT, CURRENT);
      this.onInput_mixin({ value: MODEL_CLONE });
    },

    orderedAdd() {
      if (!this.selectModel) {
        return;
      }
      const MODEL_CLONE = cloneDeep(this.model) || [];
      MODEL_CLONE.push(this.selectModel);
      this.onInput_mixin({ value: MODEL_CLONE });
      this.selectModel = undefined;
    },

    deleteElement({ index }) {
      const MODEL_CLONE = cloneDeep(this.model) || [];
      MODEL_CLONE.splice(index, 1);
      this.onInput_mixin({ value: MODEL_CLONE });
    },

    loadData() {
      if (this.options.url && !this.options.searchGlobal) {
        this.loading = true;
        this.getListHttp({ url: this.options.url, apiSaveId: this.options.apiSaveId, urlParams: this.options.urlParams }).then(
          response => {
            this.list = response;
          }
        ).then(
          () => {
            this.loading = false;
          }
        );
      }
    },
  },
};
