import {
  computed,
  toRef,
} from "vue";

import SyConfigAPI from "../../../../../compositionAPI/SyConfigAPI";

export default function UiTinymceReadOnlyAPI(props) {
  const options = toRef(props, "options");

  const {
    getSyConfigsValue,
  } = SyConfigAPI();

  const config = computed(() => {
    return getSyConfigsValue("richtext", {});
  });

  const maxHeightReadOnly = computed(() => {
    return config.value.max_height_readonly;
  });

  const isShowMoreVisible = computed(() => {
    return !options.value.hideShowMore && maxHeightReadOnly.value;
  });

  return {
    isShowMoreVisible,
    maxHeightReadOnly,
  };
}
