import {
  computed,
} from "vue";
import {
  footerHeight,
  navbarFixedHeight,
} from "../../global/compositionAPI/LayoutAPI";

export default function StyleAPI() {
  const wrapperContentStyle = computed(() => {
    return {
      "padding-top": `${ navbarFixedHeight.value }px`,
    };
  });
  const pageWrapperStyle = computed(() => {
    return {
      "min-height": `calc(100vh - ${ footerHeight.value }px)`,
    };
  });

  return {
    pageWrapperStyle,
    wrapperContentStyle,
  };
}
