const AzureAnmeldungPage = () => import(/* webpackChunkName: "ResetPasswordPage" */ "../../views/Registration/Login/AzureAnmeldungPage/AzureAnmeldungPage.vue");
const DatenschutzEinwilligung = () => import(/* webpackChunkName: "DatenschutzEinwilligung" */ "../../views/Datenschutz/DatenschutzEinwilligung/DatenschutzEinwilligung.vue");
const EmailChangePage = () => import(/* webpackChunkName: "EmailChangePage" */ "../../views/Registration/EmailChangePage/EmailChangePage.vue");
const LoginPage = () => import(/* webpackChunkName: "LoginPage" */ "../../views/Registration/Login/LoginPage/LoginPage.vue");
const LogoutPage = () => import(/* webpackChunkName: "LogoutPage" */ "../../views/Registration/LogoutPage/LogoutPage.vue");
const MfaPage = () => import(/* webpackChunkName: "MfaPage" */ "../../views/Mfa/MfaPage/MfaPage.vue");
const NewLoginPage = () => import(/* webpackChunkName: "NewLoginPage" */ "../../views/Registration/Login/NewLoginPage/NewLoginPage.vue");
const RegistrationFinishPage = () => import(/* webpackChunkName: "RegistrationFinishPage" */ "../../views/Registration/RegistrationFinishPage/RegistrationFinishPage.vue");
const RegistrationPage = () => import(/* webpackChunkName: "RegistrationPage" */ "../../views/Registration/RegistrationPage/RegistrationPage.vue");
const RequiredNutzerdatenPage = () => import("../../views/Nutzer/RequiredNutzerdatenPage/RequiredNutzerdatenPage.vue");
const ResetPasswordFinishPage = () => import(/* webpackChunkName: "ResetPasswordFinishPage" */ "../../views/Registration/ResetPasswordFinishPage/ResetPasswordFinishPage.vue");
const ResetPasswordPage = () => import(/* webpackChunkName: "ResetPasswordPage" */ "../../views/Registration/ResetPasswordPage/ResetPasswordPage.vue");

export default [
  {
    path: "/login/",
    name: "root.login",
    component: NewLoginPage,
    meta: {
      isAuthenticated: false,
    },
  },
  {
    path: "/login1/",
    name: "root.login1",
    component: LoginPage,
    meta: {
      isAuthenticated: false,
    },
  },
  {
    path: "/zuruecksetzen/",
    name: "root.reset_password",
    component: ResetPasswordPage,
    meta: {
      isAuthenticated: false,
    },
  },
  {
    path: "/zuruecksetzen/:uuid/:tok/",
    name: "root.reset_password.details",
    component: ResetPasswordFinishPage,
    meta: {
      isAuthenticated: false,
    },
  },
  {
    path: "/registrieren/",
    name: "root.registration",
    component: RegistrationPage,
    meta: {
      isAuthenticated: false,
    },
  },
  {
    path: "/registrieren/:uuid/:tok/",
    name: "root.registration.details",
    component: RegistrationFinishPage,
    meta: {
      isAuthenticated: false,
    },
  },
  {
    path: "/email_aendern/:uuid/:tok/",
    name: "root.email_change",
    component: EmailChangePage,
  },
  {
    path: "/datenschutzeinwilligung/",
    name: "root.datenschutzeinwilligung",
    component: DatenschutzEinwilligung,
  },
  {
    path: "/anmeldung/:id/",
    name: "root.anmeldung",
    component: AzureAnmeldungPage,
    meta: {
      isAuthenticated: false,
    },
  },
  {
    path: "/logout/",
    name: "root.logout",
    component: LogoutPage,
  },
  {
    path: "/mfa/",
    name: "root.mfa",
    component: MfaPage,
  },
  {
    path: "/complete_profile/",
    name: "root.required_nutzerdaten",
    component: RequiredNutzerdatenPage,
  },
];
