import { toFormElementFromParameter } from "../../../../global/functions/mappingForParameterToFormElement";

export default {
  data() {
    return {
      jsonParam: {
        bez: "_LBL_BELEGPRUEFART_FELDER_",
        default: null,
        help_text: "_TXT_BELEGPRUEFART_FELDER_HELP_TEXT_",
        readonly: false,
        options: {
          allow_null: true,
          required: false
        },
        typ: "belegpruefart_felder_kosten",
        many: true,
        as_json: true,
        unique_fields: [],
        key_field: "pruefart",
        pos_field: null,
        min_length: null,
        max_length: null,
        element_label: "_LBL_BELEGPRUEFART_FELDER_ELEMENT_LABEL_",
        element_template: "{{pruefart}}",
        element_errors: true,
        deactivate_ordering: true,
        deactivate_preview: false,
        hide_pos: true,
        fields: {
          pruefart: {
            prio: 0,
            bez: "_LBL_BELEGPRUEFART_FELDER_PRUEFART_",
            default: null,
            help_text: "_TXT_BELEGPRUEFART_FELDER_PRUEFART_HELP_TEXT_",
            readonly: false,
            options: {
              allow_null: false,
              required: true,
              displaymode: "select2"
            },
            typ: "single_choice",
            katalog: "kbelegpruefarten",
            katalog_filter: {
              konfigurierbar: true
            },
            urlRetrieveParams: {
              konfigurierbar: true
            },
            keyLabel: "bpa_kbez_admin"
          },
          beleg_hinweis: {
            prio: 1,
            bez: "_LBL_BELEGPRUEFART_FELDER_BELEG_HINWEIS_",
            default: null,
            help_text: "_TXT_BELEGPRUEFART_FELDER_BELEG_HINWEIS_HELP_TEXT_",
            readonly: false,
            options: {
              allow_null: true,
              required: false,
              max_length: null,
              min_length: null
            },
            typ: "richtext"
          },
          beleg_fields: {
            prio: 2,
            bez: "_LBL_BELEGPRUEFART_FELDER_BELEG_FIELDS_",
            default: null,
            help_text: "_TXT_BELEGPRUEFART_FELDER_BELEG_FIELDS_HELP_TEXT_",
            readonly: false,
            options: {
              allow_null: true,
              required: false
            },
            typ: "belege_field",
            many: true,
            as_json: true,
            unique_fields: [
              "field"
            ],
            key_field: "field",
            pos_field: null,
            min_length: null,
            max_length: null,
            element_label: "Feld",
            element_template: "{{element_label}}",
            element_errors: true,
            deactivate_ordering: false,
            deactivate_preview: false,
            hide_pos: false,
            fields: {
              show: {
                prio: 0,
                bez: "_LBL_BELEG_FELDER_CONFIG_SHOW_",
                default: true,
                help_text: "_TXT_BELEG_FELDER_CONFIG_SHOW_HELP_TEXT_",
                readonly: false,
                options: {
                  allow_null: false,
                  required: true
                },
                typ: "boolean"
              },
              field: {
                prio: 1,
                bez: "_LBL_BELEG_FELDER_CONFIG_FIELD_",
                default: null,
                help_text: "_TXT_BELEG_FELDER_CONFIG_FIELD_HELP_TEXT_",
                readonly: false,
                options: {
                  allow_null: false,
                  required: true
                }
              },
              help_text: {
                prio: 2,
                bez: "_LBL_BELEG_FELDER_CONFIG_HELP_TEXT_",
                default: null,
                help_text: "_TXT_BELEG_FELDER_CONFIG_HELP_TEXT_HELP_TEXT_",
                readonly: false,
                options: {
                  allow_null: true,
                  required: false,
                  max_length: 400,
                  min_length: null
                },
                typ: "string"
              },
              objektkatalog: {
                prio: 3,
                bez: "_LBL_BELEG_FELDER_CONFIG_OBJEKTKATALOG_",
                default: null,
                help_text: "_TXT_BELEG_FELDER_CONFIG_OBJEKTKATALOG_HELP_TEXT_",
                readonly: false,
                options: {
                  allow_null: false,
                  required: false,
                  displaymode: "select2"
                },
                typ: "single_choice",
                katalog: "kobjektkatalog"
              },
              required: {
                prio: 4,
                bez: "_LBL_BELEG_FELDER_CONFIG_REQUIRED_",
                default: true,
                help_text: "_TXT_BELEG_FELDER_CONFIG_REQUIRED_HELP_TEXT_",
                readonly: false,
                options: {
                  allow_null: false,
                  required: true
                },
                typ: "boolean"
              },
              pos: {
                prio: 5,
                bez: "_LBL_BELEG_FELDER_CONFIG_POS",
                default: null,
                help_text: "_TXT_BELEG_FELDER_CONFIG_POS_HELP_TEXT_",
                readonly: false,
                options: {
                  allow_null: true,
                  required: false,
                  min_value: null,
                  max_value: null
                },
                typ: "integer"
              }
            }
          },
          beleg_extra_fields: {
            prio: 3,
            bez: "_LBL_BELEGPRUEFART_FELDER_EXTRA_BELEG_FIELDS_",
            default: null,
            help_text: "_TXT_BELEGPRUEFART_FELDER_EXTRA_BELEG_FIELDS_HELP_TEXT_",
            readonly: false,
            options: {
              allow_null: true,
              required: false
            },
            typ: "form_definition",
            field_form: {
              base: {
                label: {
                  bez: "_LBL_FIELD_DEFINITION_BASE_LABEL_",
                  default: null,
                  help_text: "_TXT_FIELD_DEFINITION_BASE_LABEL_HELP_TEXT_",
                  readonly: false,
                  options: {
                    allow_null: false,
                    required: true,
                    max_length: null,
                    min_length: null
                  },
                  typ: "string",
                  prio: 0
                },
                id: {
                  bez: "_LBL_FIELD_DEFINITION_BASE_ID_",
                  default: null,
                  help_text: "_TXT_FIELD_DEFINITION_BASE_ID_HELP_TEXT_",
                  readonly: false,
                  options: {
                    allow_null: false,
                    required: true,
                    max_length: null,
                    min_length: null
                  },
                  typ: "string",
                  prio: 1
                },
                help_text: {
                  bez: "_LBL_FIELD_DEFINITION_BASE_HELP_TEXT_",
                  default: null,
                  help_text: "_TXT_FIELD_DEFINITION_BASE_HELP_TEXT_HELP_TEXT_",
                  readonly: false,
                  options: {
                    allow_null: true,
                    required: false,
                    max_length: null,
                    min_length: null
                  },
                  typ: "string",
                  prio: 2
                },
                tooltip: {
                  bez: "_LBL_FIELD_DEFINITION_BASE_TOOLTIP_",
                  default: null,
                  help_text: "_TXT_FIELD_DEFINITION_BASE_TOOLTIP_HELP_TEXT_",
                  readonly: false,
                  options: {
                    allow_null: true,
                    required: false,
                    max_length: null,
                    min_length: null
                  },
                  typ: "string",
                  prio: 3
                },
                required: {
                  bez: "_LBL_FIELD_DEFINITION_BASE_REQUIRED_",
                  default: null,
                  help_text: "_TXT_FIELD_DEFINITION_BASE_REQUIRED_HELP_TEXT_",
                  readonly: false,
                  options: {
                    allow_null: false,
                    required: true
                  },
                  typ: "boolean",
                  prio: 4
                },
                styles: {
                  bez: "_LBL_FIELD_DEFINITION_BASE_STYLES_",
                  default: null,
                  help_text: "_TXT_FIELD_DEFINITION_BASE_STYLES_HELP_TEXT_",
                  readonly: false,
                  options: {
                    allow_null: true,
                    required: false,
                    translate: true,
                    displaymode: "select2"
                  },
                  typ: "multi_select",
                  choices: [
                    [
                      "text-left",
                      "_LBL_FIELD_DEFINITION_STYLE_TEXT_LEFT_"
                    ],
                    [
                      "text-right",
                      "_LBL_FIELD_DEFINITION_STYLE_TEXT_RIGHT_"
                    ],
                    [
                      "text-center",
                      "_LBL_FIELD_DEFINITION_STYLE_TEXT_CENTER_"
                    ],
                    [
                      "text-nowrap",
                      "_LBL_FIELD_DEFINITION_STYLE_TEXT_NOWRAP_"
                    ]
                  ],
                  prio: 5
                },
                pos: {
                  bez: "_LBL_FIELD_DEFINITION_BASE_POSITION_",
                  default: null,
                  help_text: "_TXT_FIELD_DEFINITION_BASE_POSITION_HELP_TEXT_",
                  readonly: false,
                  options: {
                    allow_null: false,
                    required: true,
                    min_value: 0,
                    max_value: null
                  },
                  typ: "integer",
                  prio: 6
                },
                type: {
                  bez: "_LBL_FIELD_DEFINITION_BASE_TYPE_",
                  default: null,
                  help_text: "_TXT_FIELD_DEFINITION_BASE_TYPE_HELP_TEXT_",
                  readonly: false,
                  options: {
                    allow_null: false,
                    required: true,
                    translate: true
                  },
                  typ: "single_choice",
                  choices: [
                    [
                      "select",
                      "_LBL_FIELD_DEFINITION_SELECT_"
                    ],
                    [
                      "select_objektkatalog",
                      "Auswahlliste (objektbezogener Katalog)"
                    ],
                    [
                      "boolean",
                      "_LBL_FIELD_DEFINITION_BOOLEAN_"
                    ],
                    [
                      "date",
                      "_LBL_FIELD_DEFINITION_DATE_"
                    ],
                    [
                      "text",
                      "_LBL_FIELD_DEFINITION_TEXT_"
                    ],
                    [
                      "richtext",
                      "_LBL_FIELD_DEFINITION_RICHTEXT_"
                    ],
                    [
                      "float",
                      "_LBL_FIELD_DEFINITION_FLOAT_"
                    ],
                    [
                      "confirm",
                      "_LBL_FIELD_DEFINITION_CONFIRM_"
                    ],
                    [
                      "email",
                      "_LBL_FIELD_DEFINITION_EMAIL_"
                    ],
                    [
                      "rating",
                      "_LBL_FIELD_DEFINITION_RATING_"
                    ],
                    [
                      "document",
                      "Beleg-Dokument"
                    ]
                  ],
                  prio: 7
                }
              },
              typed: {
                select: {
                  katalog: {
                    bez: "_LBL_FIELD_DEFINITION_SELECT_KATALOG_",
                    default: null,
                    help_text: "_TXT_FIELD_DEFINITION_SELECT_KATALOG_HELP_TEXT_",
                    readonly: false,
                    options: {
                      allow_null: false,
                      required: true,
                      displaymode: "select2"
                    },
                    typ: "single_choice",
                    katalog: "katalog_mit_dynkatalog",
                    prio: 8
                  },
                  komplett: {
                    bez: "_LBL_FIELD_DEFINITION_SELECT_KOMPLETT_",
                    default: true,
                    help_text: "_TXT_FIELD_DEFINITION_SELECT_KOMPLETT_HELP_TEXT_",
                    readonly: false,
                    options: {
                      allow_null: false,
                      required: true
                    },
                    typ: "one_checkbox",
                    prio: 9
                  },
                  ids: {
                    bez: "_LBL_FIELD_DEFINITION_SELECT_IDS_",
                    default: null,
                    help_text: "_TXT_FIELD_DEFINITION_SELECT_IDS_HELP_TEXT_",
                    readonly: false,
                    options: {
                      allow_null: true,
                      required: false
                    },
                    typ: "multi_select",
                    prio: 10
                  },
                  many: {
                    bez: "_LBL_FIELD_DEFINITION_SELECT_MANY_",
                    default: false,
                    help_text: "_TXT_FIELD_DEFINITION_SELECT_MANY_HELP_TEXT_",
                    readonly: false,
                    options: {
                      allow_null: false,
                      required: true
                    },
                    typ: "boolean",
                    prio: 11
                  }
                },
                select_objektkatalog: {
                  objektkatalog: {
                    bez: "_LBL_FIELD_DEFINITION_SELECT_OBJEKTKATALOG_OBJEKTKATALOG_",
                    default: null,
                    help_text: "_TXT_FIELD_DEFINITION_SELECT_OBJEKTKATALOG_OBJEKTKATALOG_HELP_TEXT_",
                    readonly: false,
                    options: {
                      allow_null: false,
                      required: true,
                      displaymode: "select2"
                    },
                    typ: "single_choice",
                    katalog: "kobjektkatalog",
                    prio: 8
                  },
                  many: {
                    bez: "_LBL_FIELD_DEFINITION_SELECT_OBJEKTKATALOG_MANY_",
                    default: false,
                    help_text: "_TXT_FIELD_DEFINITION_SELECT_OBJEKTKATALOG_MANY_HELP_TEXT_",
                    readonly: false,
                    options: {
                      allow_null: false,
                      required: true
                    },
                    typ: "boolean",
                    prio: 9
                  }
                },
                boolean: {
                  true_label: {
                    bez: "_LBL_FIELD_DEFINITION_BOOLEAN_TRUE_LABEL_",
                    default: "Ja",
                    help_text: "_TXT_FIELD_DEFINITION_BOOLEAN_TRUE_LABEL_HELP_TEXT_",
                    readonly: false,
                    options: {
                      allow_null: false,
                      required: true,
                      max_length: null,
                      min_length: null
                    },
                    typ: "string",
                    prio: 8
                  },
                  false_label: {
                    bez: "_LBL_FIELD_DEFINITION_BOOLEAN_FALSE_LABEL_",
                    default: "Nein",
                    help_text: "_TXT_FIELD_DEFINITION_BOOLEAN_FALSE_LABEL_HELP_TEXT_",
                    readonly: false,
                    options: {
                      allow_null: false,
                      required: true,
                      max_length: null,
                      min_length: null
                    },
                    typ: "string",
                    prio: 9
                  },
                  default_value: {
                    bez: "_LBL_FIELD_DEFINITION_BOOLEAN_DEFAULT_VALUE_",
                    default: null,
                    help_text: "_TXT_FIELD_DEFINITION_BOOLEAN_DEFAULT_VALUE_HELP_TEXT_",
                    readonly: false,
                    options: {
                      allow_null: true,
                      required: false
                    },
                    typ: "boolean",
                    prio: 10
                  }
                },
                date: {
                  past_days: {
                    bez: "_LBL_FIELD_DEFINITION_DATE_PAST_DAYS_",
                    default: null,
                    help_text: "_TXT_FIELD_DEFINITION_DATE_PAST_DAYS_HELP_TEXT_",
                    readonly: false,
                    options: {
                      allow_null: true,
                      required: false,
                      min_value: null,
                      max_value: null
                    },
                    typ: "integer",
                    prio: 8
                  },
                  future_days: {
                    bez: "_LBL_FIELD_DEFINITION_DATE_FUTURE_DAYS_",
                    default: null,
                    help_text: "_TXT_FIELD_DEFINITION_DATE_FUTURE_DAYS_HELP_TEXT_",
                    readonly: false,
                    options: {
                      allow_null: true,
                      required: false,
                      min_value: null,
                      max_value: null
                    },
                    typ: "integer",
                    prio: 9
                  }
                },
                text: {
                  min_length: {
                    bez: "_LBL_FIELD_DEFINITION_TEXT_MIN_LENGTH_",
                    default: null,
                    help_text: "_TXT_FIELD_DEFINITION_TEXT_MIN_LENGTH_HELP_TEXT_",
                    readonly: false,
                    options: {
                      allow_null: true,
                      required: false,
                      min_value: 0,
                      max_value: null
                    },
                    typ: "integer",
                    prio: 8
                  },
                  max_length: {
                    bez: "_LBL_FIELD_DEFINITION_TEXT_MAX_LENGTH_",
                    default: null,
                    help_text: "_TXT_FIELD_DEFINITION_TEXT_MAX_LENGTH_HELP_TEXT_",
                    readonly: false,
                    options: {
                      allow_null: true,
                      required: false,
                      min_value: 0,
                      max_value: null
                    },
                    typ: "integer",
                    prio: 9
                  },
                  regex: {
                    bez: "_LBL_FIELD_DEFINITION_TEXT_REGEX_LABEL_",
                    default: null,
                    help_text: "_TXT_FIELD_DEFINITION_TEXT_REGEX_LABEL_HELP_TEXT_",
                    readonly: false,
                    options: {
                      allow_null: true,
                      required: false,
                      max_length: null,
                      min_length: null
                    },
                    typ: "string",
                    prio: 10
                  },
                  regex_error_msg: {
                    bez: "_LBL_FIELD_DEFINITION_TEXT_REGEX_ERROR_MSG_LABEL_",
                    default: null,
                    help_text: "_TXT_FIELD_DEFINITION_TEXT_REGEX_ERROR_MSG_LABEL_HELP_TEXT_",
                    readonly: false,
                    options: {
                      allow_null: true,
                      required: false,
                      max_length: null,
                      min_length: null
                    },
                    typ: "string",
                    prio: 11
                  }
                },
                richtext: {
                  min_length: {
                    bez: "_LBL_FIELD_DEFINITION_RICHTEXT_MIN_LENGTH_",
                    default: null,
                    help_text: "_TXT_FIELD_DEFINITION_RICHTEXT_MIN_LENGTH_HELP_TEXT_",
                    readonly: false,
                    options: {
                      allow_null: true,
                      required: false,
                      min_value: 0,
                      max_value: null
                    },
                    typ: "integer",
                    prio: 8
                  },
                  max_length: {
                    bez: "_LBL_FIELD_DEFINITION_RICHTEXT_MAX_LENGTH_",
                    default: null,
                    help_text: "_TXT_FIELD_DEFINITION_RICHTEXT_MAX_LENGTH_HELP_TEXT_",
                    readonly: false,
                    options: {
                      allow_null: true,
                      required: false,
                      min_value: 0,
                      max_value: null
                    },
                    typ: "integer",
                    prio: 9
                  }
                },
                float: {
                  min_value: {
                    bez: "_LBL_FIELD_DEFINITION_FLOAT_MIN_VALUE_",
                    default: null,
                    help_text: "_TXT_FIELD_DEFINITION_FLOAT_MIN_VALUE_HELP_TEXT_",
                    readonly: false,
                    options: {
                      allow_null: true,
                      required: false,
                      min_value: null,
                      max_value: null
                    },
                    typ: "integer",
                    prio: 8
                  },
                  max_value: {
                    bez: "_LBL_FIELD_DEFINITION_FLOAT_MAX_VALUE_",
                    default: null,
                    help_text: "_TXT_FIELD_DEFINITION_FLOAT_MAX_VALUE_HELP_TEXT_",
                    readonly: false,
                    options: {
                      allow_null: true,
                      required: false,
                      min_value: null,
                      max_value: null
                    },
                    typ: "integer",
                    prio: 9
                  },
                  max_digits: {
                    bez: "_LBL_FIELD_DEFINITION_FLOAT_MAX_DIGITS_",
                    default: 14,
                    help_text: "_TXT_FIELD_DEFINITION_FLOAT_MAX_DIGITS_HELP_TEXT_",
                    readonly: false,
                    options: {
                      allow_null: false,
                      required: true,
                      min_value: null,
                      max_value: null
                    },
                    typ: "integer",
                    prio: 10
                  },
                  decimal_places: {
                    bez: "_LBL_FIELD_DEFINITION_FLOAT_DECIMAL_PLACES_",
                    default: 2,
                    help_text: "_TXT_FIELD_DEFINITION_FLOAT_DECIMAL_PLACES_HELP_TEXT_",
                    readonly: false,
                    options: {
                      allow_null: false,
                      required: true,
                      min_value: null,
                      max_value: null
                    },
                    typ: "integer",
                    prio: 11
                  },
                  suffix: {
                    bez: "_LBL_FIELD_DEFINITION_FLOAT_SUFFIX_",
                    default: null,
                    help_text: "_TXT_FIELD_DEFINITION_FLOAT_SUFFIX_HELP_TEXT_",
                    readonly: false,
                    options: {
                      allow_null: true,
                      required: false,
                      max_length: null,
                      min_length: null
                    },
                    typ: "string",
                    prio: 12
                  },
                  no_separator: {
                    bez: "_LBL_FIELD_DEFINITION_FLOAT_NO_SEPARATOR_",
                    default: null,
                    help_text: "_TXT_FIELD_DEFINITION_FLOAT_NO_SEPARATOR_HELP_TEXT_",
                    readonly: false,
                    options: {
                      allow_null: true,
                      required: false
                    },
                    typ: "boolean",
                    prio: 13
                  }
                },
                confirm: {
                  true_label: {
                    bez: "_LBL_FIELD_DEFINITION_CONFIRM_TRUE_LABEL_",
                    default: "Ja",
                    help_text: "_TXT_FIELD_DEFINITION_CONFIRM_TRUE_LABEL_HELP_TEXT_",
                    readonly: false,
                    options: {
                      allow_null: false,
                      required: true,
                      max_length: null,
                      min_length: null
                    },
                    typ: "string",
                    prio: 8
                  },
                  false_label: {
                    bez: "_LBL_FIELD_DEFINITION_CONFIRM_FALSE_LABEL_",
                    default: "Nein",
                    help_text: "_TXT_FIELD_DEFINITION_CONFIRM_FALSE_LABEL_HELP_TEXT_",
                    readonly: false,
                    options: {
                      allow_null: false,
                      required: true,
                      max_length: null,
                      min_length: null
                    },
                    typ: "string",
                    prio: 9
                  },
                  default_value: {
                    bez: "_LBL_FIELD_DEFINITION_CONFIRM_DEFAULT_VALUE_",
                    default: null,
                    help_text: "_TXT_FIELD_DEFINITION_CONFIRM_DEFAULT_VALUE_HELP_TEXT_",
                    readonly: false,
                    options: {
                      allow_null: true,
                      required: false
                    },
                    typ: "boolean",
                    prio: 10
                  }
                },
                email: {
                  min_length: {
                    bez: "_LBL_FIELD_DEFINITION_EMAIL_MIN_LENGTH_",
                    default: null,
                    help_text: "_TXT_FIELD_DEFINITION_EMAIL_MIN_LENGTH_HELP_TEXT_",
                    readonly: false,
                    options: {
                      allow_null: true,
                      required: false,
                      min_value: 0,
                      max_value: null
                    },
                    typ: "integer",
                    prio: 8
                  },
                  max_length: {
                    bez: "_LBL_FIELD_DEFINITION_EMAIL_MAX_LENGTH_",
                    default: null,
                    help_text: "_TXT_FIELD_DEFINITION_EMAIL_MAX_LENGTH_HELP_TEXT_",
                    readonly: false,
                    options: {
                      allow_null: true,
                      required: false,
                      min_value: 0,
                      max_value: null
                    },
                    typ: "integer",
                    prio: 9
                  }
                },
                rating: {
                  data: {
                    bez: "_LBL_FIELD_DEFINITION_RATING_DATA_",
                    default: null,
                    help_text: "Format: [{\"id: int, \"bez: string}] \nBeispiel: [{\"id: 1, \"bez: \"Sehr gut\"}, {\"id: 2, \"bez: \"Gut\"} ]",
                    readonly: false,
                    options: {
                      allow_null: false,
                      required: true
                    },
                    typ: "validated_json",
                    many: true,
                    as_json: false,
                    unique_fields: [],
                    key_field: null,
                    pos_field: null,
                    min_length: null,
                    max_length: null,
                    element_label: "Element",
                    element_template: "{{id}}: {{bez}}",
                    element_errors: true,
                    deactivate_ordering: false,
                    deactivate_preview: false,
                    hide_pos: true,
                    fields: {
                      bez: {
                        prio: 0,
                        bez: "Bezeichnung",
                        default: null,
                        help_text: null,
                        readonly: false,
                        options: {
                          allow_null: false,
                          required: true,
                          max_length: null,
                          min_length: null
                        },
                        typ: "string"
                      },
                      id: {
                        prio: 1,
                        bez: "ID",
                        default: null,
                        help_text: null,
                        readonly: false,
                        options: {
                          allow_null: false,
                          required: true,
                          min_value: null,
                          max_value: null
                        },
                        typ: "integer"
                      }
                    },
                    prio: 8
                  }
                },
                document: {}
              }
            }
          }
        },
        prio: 5,
        wert: null
      }
    };
  },
  computed: {
    jsonOptions() {
      return toFormElementFromParameter({
        obj: { [`${ this.options.id }`]: this.jsonParam },
        showLabel: true,
        staticOptions: {
          required: this.options.required,
          options: this.options.options
        }
      }).list[0];
    }
  }
};
