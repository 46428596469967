export default {
  data() {
    return {
      clickEventName: "ontouchend" in document ? "touchstart" : "click",
    };
  },
  beforeUnmount() {
    this.destroyEventClickOutside();
  },
  methods: {
    setEventClickOutside() {
      document.addEventListener(this.clickEventName, this.clickOutsideEvent);
    },

    destroyEventClickOutside() {
      document.removeEventListener(this.clickEventName, this.clickOutsideEvent);
    },

    clickOutsideEvent($event) {
      // here I check that click was outside the el and his children
      if (!(this.$el === $event.target || this.$el.contains($event.target))) {
        // and if it did, call method provided in attribute value
        this.clickOutsideCallback();
      }
    },
  },
};
