import {
  createStore,
} from "vuex";

import apis from "./apis";
import faq from "./faq";
import notification from "./notification";
import notizen from "./notizen";
import profile from "./profile";
import searchGlobal from "./searchGlobal";
import snapshot from "./snapshot";
import snapshotKF from "./snapshotKF";
import syConfigs from "./syConfigs";
import table from "./table";

import {
  assign,
  cloneDeep,
  findIndex,
  forEach,
  size,
} from "lodash-es";


export default createStore({
  state() {
    return {
      ERROR: {
        tan_data: {},
      },
      KOSTENART: [],
      KOSTENART_OBJ: {},
      REGELN: {},
      TEMP_ANTRAG: {
        regelsatz: undefined,
        tan_email: undefined,
        tan_data: {},
      },
      SY_CONFIGS: undefined,
    };
  },
  getters: {
    GET_ERROR_BY_ID: state => id => {
      return state.ERROR.tan_data[id];
    },

    GET_ERROR_BY_ID_AND_KEY: state => (id, key) => {
      if (state.ERROR.tan_data[id] && state.ERROR.tan_data[id][key]) {
        return state.ERROR.tan_data[id][key];
      }
      return [];
    },

    GET_ERROR_KOSTENDETAILS: state => (regId, kartId, key) => {
      if (state.ERROR.tan_data[regId] && state.ERROR.tan_data[regId].kostendetails) {
        const index = findIndex(state.ERROR.tan_data[regId].kostendetails, ["kostenartdetail", kartId]);
        if (index !== -1) {
          return state.ERROR.tan_data[regId].kostendetails[index].argument[key];
        }
      }
      return [];
    },

    GET_ERROR_KOSTENDETAILS_WITHOUT_KART: state => (regId, key) => {
      if (state.ERROR.tan_data[regId] && state.ERROR.tan_data[regId].kostendetails && state.ERROR.tan_data[regId].kostendetails.length) {
        return state.ERROR.tan_data[regId].kostendetails[0].argument[key];
      }
      return [];
    },
  },
  mutations: {
    MUT_ADD_REGEL(state, { regel, id }) {
      state.REGELN[id] = regel;
    },
    MUT_KOSTENART(state, list) {
      state.KOSTENART = list;
      const dataObj = {};
      forEach(list, item => {
        dataObj[item.pk] = item;
        dataObj[item.pk].detailsObj = {};
        forEach(item.details, child => {
          dataObj[item.pk].detailsObj[child.pk] = child;
        });
      });
      state.KOSTENART_OBJ = dataObj;
    },
    MUT_TEMP_ANTRAG(state, { key, value }) {
      if (key) {
        state.TEMP_ANTRAG[key] = value;
      }
    },
    MUT_TAN_DATA(state, { far_id, userInput }) {
      if (far_id) {
        state.TEMP_ANTRAG.tan_data[far_id] = userInput;
      }
    },
    MUT_SET_DEFAULT_TEMP_ANTRAG(state) {
      state.TEMP_ANTRAG = {
        regelsatz: undefined,
        tan_email: undefined,
        tan_data: {},
      };
    },
    MUT_REMOVE_ALL_ERRORS(state) {
      state.ERROR = {
        tan_data: {},
      };
    },
    MUT_ERROR(state, data) {
      state.ERROR = assign({}, state.ERROR, data);
    },
    MUT_REMOVE_ERROR_BY_ID(state, id) {
      const errorTemp = cloneDeep(state.ERROR);
      if (errorTemp.tan_data[id]) {
        delete errorTemp.tan_data[id];
      }
      state.ERROR = assign({}, state.ERROR, errorTemp);
    },
    MUT_UPDATE_ERROR_BY_ID(state, { id, error }) {
      const errorTemp = cloneDeep(state.ERROR);
      if (!size(error)) {
        if (errorTemp.tan_data[id]) {
          delete errorTemp.tan_data[id];
        }
      } else {
        errorTemp.tan_data[id] = error;
      }
      state.ERROR = assign({}, state.ERROR, errorTemp);
    },
    MUT_UPDATE_ERROR_BY_ID_KF(state, { id, error }) {
      const errorTemp = cloneDeep(state.ERROR);
      if (error && !size(error.kostendetails)) {
        if (errorTemp.tan_data[id]) {
          delete errorTemp.tan_data[id];
        }
      } else {
        errorTemp.tan_data[id] = error;
      }
      state.ERROR = assign({}, state.ERROR, errorTemp);
    },
  },
  modules: {
    apis,
    faq,
    notification,
    notizen,
    profile,
    searchGlobal,
    snapshot,
    snapshotKF,
    syConfigs,
    table,
  },
});
