import AIcon from "aloha-vue/src/AIcon/AIcon";

import translate from "../../directives/translate";

// @vue/component
export default {
  name: "PuxIcon",
  components: {
    AIcon,
  },
  directives: {
    translate,
  },
  props: {
    icon: {
      type: String,
      required: false,
      default: undefined,
      info: "Icon",
    },
    iconClass: {
      type: String,
      required: false,
      default: undefined,
      info: "Zusätzliche CSS-Klasse",
    },
    iconAriaLabel: {
      type: String,
      required: false,
      default: undefined,
      info: "HTML-Attribut 'aria-label'",
    },
    iconAttributes: {
      type: Object,
      required: false,
      default: () => ({}),
      info: "Weitere HTML-Attributes",
    },
    iconTooltip: {
      type: String,
      required: false,
      default: undefined,
      info: "Tooltip",
    },
    iconExtra: {
      type: Object,
      required: false,
      default: undefined,
      info: "Extra-Objekt für Übersetzung(tooltip oder/und aria-label)",
    },
    iconTag: {
      type: String,
      required: false,
      default: "i",
      info: "Semantisch-relevanter HTML-Tag.(i, span, ...)",
    },
  },
  computed: {
    iconLocal() {
      // Workaround für alte Icons
      if (this.icon.startsWith("glyphicon-")) {
        return this.icon.replace("glyphicon-", "");
      }
      return this.icon;
    },

    translateOptions() {
      const OPTIONS = {
        extra: this.iconExtra,
      };
      if (this.iconAriaLabel) {
        OPTIONS["aria-label"] = this.iconAriaLabel;
      }
      if (this.iconTooltip) {
        OPTIONS.title = this.iconTooltip;
      }
      return OPTIONS;
    },
  },
};
