import {
  cloneDeep,
  findIndex,
} from "lodash-es";

// @vue/component
export default {
  name: "UiDynamischeAdressenCheckbox",
  props: {
    data: {
      type: Object,
      required: true,
    },
    prefix: {
      type: String,
      required: true,
    },
    model: {
      type: Array,
      required: true,
    },
    changeModel: {
      type: Function,
      required: true,
    },
  },
  computed: {
    getId() {
      return `${ this.prefix }_${ this.data.id }`;
    },

    getIdRequired() {
      return `${ this.prefix }_${ this.data.id }_required`;
    },

    getName() {
      return `${ this.prefix }_argument`;
    },

    getCheckedIndex() {
      const ID = this.data.id;
      return findIndex(this.model, item => item[ID]);
    },

    getChecked() {
      return this.getCheckedIndex !== -1;
    },

    getCheckedRequired() {
      if (!this.getChecked) {
        return false;
      }
      const INDEX = this.getCheckedIndex;

      return this.model[INDEX] &&
        this.model[INDEX][this.data.id] &&
        this.model[INDEX][this.data.id].required;
    },

    getAriaChecked() {
      return `${ this.getChecked }`;
    },

    getAriaCheckedRequired() {
      return `${ this.getCheckedRequired }`;
    },
  },
  methods: {
    onClick() {
      const MODEL = cloneDeep(this.model);
      if (!this.getChecked) {
        MODEL.push({
          [this.data.id]: {
            required: false,
          }
        });
      } else {
        const INDEX = this.getCheckedIndex;
        MODEL.splice(INDEX, 1);
      }

      this.changeModel({ model: MODEL });
    },

    onClickRequired() {
      const MODEL = cloneDeep(this.model);
      const INDEX = this.getCheckedIndex;
      MODEL[INDEX][this.data.id].required = !MODEL[INDEX][this.data.id].required;
      this.changeModel({ model: MODEL });
    },
  },
};
