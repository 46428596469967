import {
  computed,
  toRef,
} from "vue";

export default function InDropdownAPI(props) {
  const inDropdown = toRef(props, "inDropdown");

  const tagRoot = computed(() => {
    return inDropdown.value ? "li" : "div";
  });

  const classRoot = computed(() => {
    return inDropdown.value ? "" : "a_d_inline_block";
  });

  const btnClass = computed(() => {
    return inDropdown.value ? "a_dropdown__item" : "a_btn a_btn_primary";
  });

  const btnIcon = computed(() => {
    return inDropdown.value ? undefined : "accessibility";
  });

  const btnIconClass = computed(() => {
    return inDropdown.value ? undefined : "icon_large a_m_0";
  });

  const btnIsTitleHtml = computed(() => {
    return !inDropdown.value;
  });

  const btnText = computed(() => {
    return inDropdown.value ? "_TXT_ACCESSIBILITY_BTN_MOBILE_" : undefined;
  });

  return {
    btnClass,
    btnIcon,
    btnIconClass,
    btnIsTitleHtml,
    btnText,
    classRoot,
    tagRoot,
  };
}
