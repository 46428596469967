import AButton from "aloha-vue/src/AButton/AButton";
import ModalCreateOrUpdate from "./ModalCreateOrUpdate/ModalCreateOrUpdate.vue";

import UIComponentMixin from "../../../../../global/components/ui/UIComponentMixin";

import translate from "../../../../../global/directives/translate";

import {
  cloneDeep,
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "UiDynamischeZahlen",
  components: {
    AButton,
    ModalCreateOrUpdate,
  },
  directives: {
    translate,
  },
  mixins: [
    UIComponentMixin,
  ],
  props: {
    model: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      confirmShow: undefined,
      confirmOptions: undefined,
      statusModal: undefined,
      currentModel: undefined,
      currentIndex: undefined,
    };
  },
  beforeCreate() {
    this.$options.components.Modal = require("../../../../../global/components/Modal/Modal.vue").default;
  },
  methods: {
    openModalCreate() {
      this.statusModal = true;
    },
    openModalEdit({ item, index }) {
      this.currentModel = item;
      this.currentIndex = index;
      this.statusModal = true;
    },
    closeModal({ model } = {}) {
      if (model) {
        this.onInput_mixin({ value: this.setPositionInModel({ model }) });
      }
      this.currentModel = undefined;
      this.currentIndex = undefined;
      this.statusModal = false;
    },

    moveUpAntwort(index) {
      if (index === 0) {
        return;
      }
      let model = cloneDeep(this.model);

      const ELEM = model[index];
      model.splice(index, 1);
      model.splice(index - 1, 0, ELEM);
      model = this.setPositionInModel({ model });
      this.onInput_mixin({ value: model });
    },

    moveDownAntwort(index) {
      if (index >= this.model.length - 1) {
        return;
      }
      let model = cloneDeep(this.model);

      const ELEM = model[index];
      model.splice(index, 1);
      model.splice(index + 1, 0, ELEM);
      model = this.setPositionInModel({ model });
      this.onInput_mixin({ value: model });
    },

    setPositionInModel({ model }) {
      const MODEL = cloneDeep(model);
      forEach(MODEL, (item, index) => {
        MODEL[index].pos = `${ index + 1 }`;
      });
      return MODEL;
    },

    openConfirmDelete(index) {
      this.confirmOptions = {
        okClass: "a_btn a_btn_primary",
        title: "Antwortmöglichkeit entfernen",
        msg: "<p>Sind Sie sicher, dass sie die Antwortmöglichkeit von dieser Regel entfernen wollen?</p>",
        okLabel: "Fortfahren",
        okCallback: () => this.deleteAntwort(index),
        cancelCallback: this.closeConfirmDelete,
        loading: false,
      };
      this.confirmShow = true;
    },

    deleteAntwort(index) {
      let model = cloneDeep(this.model);
      model.splice(index, 1);
      model = this.setPositionInModel({ model });
      this.onInput_mixin({ value: model });
      this.closeConfirmDelete();
    },

    closeConfirmDelete() {
      this.confirmShow = false;
    },
  },
};
