import UiDynamischeFormDefinition from "../../../../global/components/ui/UiDynamischeFormDefinition/UiDynamischeFormDefinition.js";
import SyConfigMixin from "../../../../global/mixins/SyConfigMixin";

import {
  cloneDeep,
  isNil,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "UiBelegeExtraFieldFinanzierung",
  mixins: [
    SyConfigMixin,
    UiDynamischeFormDefinition,
  ],
  created() {
    this.initModelFromSyConf();
  },
  methods: {
    initModelFromSyConf() {
      if (isNil(this.model)) {
        const CONF = this.getSyConfigsValue("finanzbelege_extra") || {};
        if (size(CONF) > 0) {
          this.onInput_mixin({ value: cloneDeep(CONF) });
        }
      }
    }
  },
};
