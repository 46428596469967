import {
  computed,
  ref,
} from "vue";

import PermissionAPI from "./PermissionAPI";

export default function PermissionGlobalAPI({
  object = ref({}),
  permission = "",
  permissionRef = ref(""),
  useOnlyObjectPermission = false,
}) {
  const {
    checkPermissionsSync,
  } = PermissionAPI();

  const hasPermission = computed(() => {
    return checkPermissionsSync({
      perm: permission,
    });
  });

  const hasPermissionFromObject = computed(() => {
    return checkPermissionsSync({
      perm: permission,
      permArray: object.value.user_permissions,
      useOnlyObjectPermission,
    });
  });

  // TODO: Ilia item muss gelöscht werden, wenn wir a-table haben
  const hasPermissionCallback = ({ row, item } = {}) => {
    if (row) {
      return checkPermissionsSync({
        perm: permission,
        permArray: row.user_permissions,
        useOnlyObjectPermission,
      });
    }
    return checkPermissionsSync({
      perm: permission,
      permArray: item.user_permissions,
      useOnlyObjectPermission,
    });
  };

  const hasPermissionRef = computed(() => {
    return checkPermissionsSync({
      perm: permissionRef.value,
    });
  });

  const hasPermissionRefFromObject = computed(() => {
    return checkPermissionsSync({
      perm: permissionRef.value,
      permArray: object.value.user_permissions,
      useOnlyObjectPermission,
    });
  });

  const hasPermissionRefCallback = ({ row, item }) => {
    if (row) {
      return checkPermissionsSync({
        perm: permissionRef.value,
        permArray: row.user_permissions,
        useOnlyObjectPermission,
      });
    }
    return checkPermissionsSync({
      perm: permissionRef.value,
      permArray: item.user_permissions,
      useOnlyObjectPermission,
    });
  };

  return {
    hasPermission,
    hasPermissionCallback,
    hasPermissionFromObject,
    hasPermissionRef,
    hasPermissionRefCallback,
    hasPermissionRefFromObject,
  };
}
