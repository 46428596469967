import UiRadiobuttonItem from "./UiRadiobuttonItem/UiRadiobuttonItem.vue";

import UICheckboxAndRadiobuttonMixin from "../UICheckboxAndRadiobutton.mixin";
import UIComponentMixin from "../UIComponentMixin";

// @vue/component
export default {
  name: "UiRadiobutton",
  components: {
    UiRadiobuttonItem,
  },
  mixins: [
    UIComponentMixin,
    UICheckboxAndRadiobuttonMixin,
  ],
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    model: {
      type: [String, Array, Boolean],
      default: undefined,
    },
  },
  data() {
    return {
      status: {
        loading: true,
      },
    };
  },
};
