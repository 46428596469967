import PuxDatepicker from "../../PuxDatepicker/PuxDatepicker.vue";
import PuxTranslate from "../../PuxTranslate/PuxTranslate.vue";

import UIComponentMixin from "../UIComponentMixin";

import moment from "moment";
import {
  cloneDeep,
  isDate,
} from "lodash-es";

// @vue/component
export default {
  name: "UiDatepickerRange",
  components: {
    PuxDatepicker,
    PuxTranslate,
  },
  mixins: [
    UIComponentMixin,
  ],
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
    // Functions
    selected: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      popupStyles: {
        min: {},
        max: {},
      },
    };
  },
  computed: {
    inputClass() {
      return [
        "form-control form_control",
        this.options.classInput,
        {
          "is-invalid": this.isError,
        }
      ];
    },

    modelMin() {
      return this.model[`${ this.options.id }_after`];
    },

    modelMax() {
      return this.model[`${ this.options.id }_before`];
    },

    idMin() {
      return this.getId;
    },

    idMax() {
      return `${ this.getId }_von`;
    },

    idForLabelMin() {
      return `${ this.getId }_min_label`;
    },

    idForLabelMax() {
      return `${ this.getId }_max_label`;
    },

    inputAttributesMin() {
      const ATTRIBUTES = cloneDeep(this.inputAttributes);
      ATTRIBUTES["aria-describedby"] = this.idForLabelMin;
      return ATTRIBUTES;
    },

    inputAttributesMax() {
      const ATTRIBUTES = cloneDeep(this.inputAttributes);
      ATTRIBUTES["aria-describedby"] = `${ this.getId }_label ${ this.idForLabelMax }`;
      return ATTRIBUTES;
    },

    inputAttributes() {
      const ATTRIBUTES = {
        "aria-required": this.ariaRequired,
        "aria-invalid": this.ariaInvalid,
      };
      if (this.options.name) {
        ATTRIBUTES.name = this.options.name;
      }
      return ATTRIBUTES;
    },

    ariaRequired() {
      return `${ this.options.required || false }`;
    },

    ariaInvalid() {
      return this.isError ? "true" : "false";
    },

    placeholderMin() {
      return this.options.placeholderMin || "";
    },

    placeholderMax() {
      return this.options.placeholderMax || "";
    },

    minuteStep() {
      return this.options.minuteStep || 5;
    },

    type() {
      return this.options.type || "date";
    },

    format() {
      return this.options.format || "DD.MM.YYYY";
    },

    width() {
      return this.options.width || "100%";
    },
  },
  methods: {
    onFocusMin($event) {
      this.setPositionLeft({ ref: "min" });
      this.onFocus($event);
    },

    onFocusMax($event) {
      this.setPositionLeft({ ref: "max" });
      this.onFocus($event);
    },

    setPositionLeft({ ref }) {
      const INPUT_RECT = this.$refs[ref].$el.getBoundingClientRect();
      this.popupStyles[ref].left = `${ INPUT_RECT.left }px`;
    },

    formatDate(date) {
      return isDate(date) ? moment(date).format("YYYY-MM-DD") : date;
    },

    onSelectMin(arg) {
      this.onSelect({ arg, label: "after" });
    },

    onSelectMax(arg) {
      this.onSelect({ arg, label: "before" });
    },

    onSelect({ arg, label }) {
      const data = this.formatDate(arg);
      const MODEL = cloneDeep(this.model);
      MODEL[`${ this.options.id }_${ label }`] = data;

      this.input({
        model: MODEL,
        currentModel: data,
        id: this.options.id,
        param: this.options.param,
        options: this.options,
      });
      this.change({
        model: MODEL,
        currentModel: data,
        id: this.options.id,
        param: this.options.param,
        options: this.options,
      });
      this.selected({
        model: MODEL,
        currentModel: data,
        id: this.options.id,
        param: this.options.param,
      });
    },
  },
};
