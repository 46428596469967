import UiZielgruppenAuswahlCheckbox from "./UiZielgruppenAuswahlCheckbox/UiZielgruppenAuswahlCheckbox.vue";
import PuxCloak from "../../../../../global/components/PuxCloak/PuxCloak.vue";
import UIComponentMixin from "../../../../../global/components/ui/UIComponentMixin";
import HttpMixin from "../../../../../global/mixins/HttpMixin";

// @vue/component
export default {
  name: "UiZielgruppenAuswahl",
  components: {
    PuxCloak,
    UiZielgruppenAuswahlCheckbox,
  },
  mixins: [
    UIComponentMixin,
    HttpMixin,
  ],
  props: {
    model: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      zielgruppen: [],
      loading: true,
    };
  },
  created() {
    this.loadDataForZielGruppen();
  },
  methods: {
    loadDataForZielGruppen() {
      this.getListHttp({
        url: "zielgruppen/",
        apiSaveId: "zielgruppen",
      }).then(
        response => {
          this.zielgruppen = response;
          this.loading = false;
        }
      );
    },
  },
};
