import AButton from "aloha-vue/src/AButton/AButton";
import UiAbschreibungsratenElement from "./UiAbschreibungsratenElement/UiAbschreibungsratenElement.vue";
import UiAbschreibungsratenFormEdit from "./UiAbschreibungsratenFormEdit/UiAbschreibungsratenFormEdit.vue";
import UIComponentMixin from "../../../../../../global/components/ui/UIComponentMixin";

import AFiltersAPI from "aloha-vue/src/compositionAPI/AFiltersAPI";

import {
  cloneDeep,
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "UiAbschreibungsraten",
  components: {
    AButton,
    UiAbschreibungsratenElement,
    UiAbschreibungsratenFormEdit,
  },
  mixins: [
    UIComponentMixin,
  ],
  props: {
    model: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const {
      filterCurrency,
    } = AFiltersAPI();

    return {
      filterCurrency,
    };
  },
  data() {
    return {
      editIndex: undefined,
      confirmShow: undefined,
      confirmOptions: undefined,
    };
  },
  computed: {

    addButtonHtmlId() {
      return `ui${ this.htmlIdLocal }_addantwort`;
    },

    selectorClose() {
      return `#${ this.addButtonHtmlId }`;
    },
  },
  beforeCreate() {
    this.$options.components.Modal = require("../../../../../../global/components/Modal/Modal.vue").default;
  },
  methods: {
    addAntwort() {
      this.editIndex = "new";
    },

    closeEditMode() {
      this.editIndex = undefined;
    },

    openEditMode(index) {
      this.editIndex = index;
    },

    saveEditMode({ model }) {
      let model_local = cloneDeep(this.model);
      if (this.editIndex === "new") {
        model_local.push(model);
      } else {
        model_local.splice(this.editIndex, 1, model);
      }
      model_local = this.setPositionInModel({ model: model_local });
      this.onInput_mixin({ value: model_local });
      this.closeEditMode();
    },

    moveUpAntwort(index) {
      if (index === 0) {
        return;
      }
      let model = cloneDeep(this.model);

      const ELEM = model[index];
      model.splice(index, 1);
      model.splice(index - 1, 0, ELEM);
      model = this.setPositionInModel({ model });
      this.onInput_mixin({ value: model });
    },

    moveDownAntwort(index) {
      if (index >= this.model.length - 1) {
        return;
      }
      let model = cloneDeep(this.model);

      const ELEM = model[index];
      model.splice(index, 1);
      model.splice(index + 1, 0, ELEM);
      model = this.setPositionInModel({ model });
      this.onInput_mixin({ value: model });
    },

    setPositionInModel({ model }) {
      const MODEL = cloneDeep(model);
      forEach(MODEL, (item, index) => {
        MODEL[index].pos = `${ index + 1 }`;
      });
      return MODEL;
    },

    openConfirmDelete(index) {
      this.confirmOptions = {
        okClass: "a_btn a_btn_primary",
        title: "Antwortmöglichkeit entfernen",
        msg: "<p>Sind Sie sicher, dass sie die Antwortmöglichkeit von dieser Regel entfernen wollen?</p>",
        okLabel: "Fortfahren",
        okCallback: () => this.deleteAntwort(index),
        cancelCallback: this.closeConfirmDelete,
        loading: false,
      };
      this.confirmShow = true;
    },

    deleteAntwort(index) {
      let model = cloneDeep(this.model);
      model.splice(index, 1);
      model = this.setPositionInModel({ model });
      this.onInput_mixin({ value: model });
      this.closeConfirmDelete();
    },

    closeConfirmDelete() {
      this.confirmShow = false;
    },
  },
};
