import ASpinner from "aloha-vue/src/ASpinner/ASpinner";
import PuxIcon from "../../PuxIcon/PuxIcon.vue";

// @vue/component
export default {
  name: "TaskLogStatus",
  components: {
    ASpinner,
    PuxIcon,
  },
  props: {
    status: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      default: undefined,
    },
    animated: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      statusLabel: {
        PENDING: "in Warteschlange",
        STARTED: "gestartet",
        RUNNING: "in Bearbeitung",
        SUCCESS: "erfolgreich beendet",
        PARTIAL_SUCCESS: "teilweise erfolgreich beendet",
        FAILURE: "mit Fehler beendet",
      },
      statusIcon: {
        PENDING: "glyphicon-time",
        STARTED: "glyphicon-ellipsis-h",
        RUNNING: "glyphicon-ellipsis-h",
        SUCCESS: "glyphicon-ok",
        PARTIAL_SUCCESS: "glyphicon-ok",
        FAILURE: "glyphicon-close"
      },
      statusColor: {
        PENDING: "a_color_black",
        STARTED: "a_color_black",
        RUNNING: "a_color_black",
        SUCCESS: "a_color_success",
        PARTIAL_SUCCESS: "a_color_warning",
        FAILURE: "a_color_danger"
      },
    };
  },
  computed: {
    isRunning() {
      return this.status === "RUNNING" || this.status === "STARTED";
    },

    showSpinner() {
      return this.animated && this.isRunning;
    },

    showIcon() {
      return !this.showSpinner;
    },

  },
};
