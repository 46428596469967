import translate from "../../../../../directives/translate";

// @vue/component
export default {
  name: "UiDocumentErrorCurrent",
  directives: {
    translate,
  },
  props: {
    error: {
      type: String,
      required: true,
    },
    errorPlaceholders: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getTranslateError() {
      return {
        text: this.errorPlaceholders[this.error] || this.error,
        extra: this.errorPlaceholders.extra,
      };
    },
  },
};
