import {
  computed,
} from "vue";

import {
  toFormElementFromParameter,
} from "../../../../functions/mappingForParameterToFormElement";

export default function OptionsAPI() {
  const jsonParam = {
    bez: "_LBL_UMFRAGEPLAN_ZUFALLSBLOECKE_",
    default: null,
    help_text: "_TXT_UMFRAGEPLAN_ZUFALLSBLOECKE_HELP_TEXT_",
    readonly: false,
    options: {
      allow_null: true,
      required: false
    },
    typ: "validated_json",
    many: true,
    as_json: true,
    unique_fields: [],
    key_field: "id",
    pos_field: null,
    min_length: null,
    max_length: null,
    element_label: "_LBL_RANDOM_STRING_PARTS_ELEMENT_LABEL_",
    element_template: "_LBL_RANDOM_STRING_PARTS_ELEMENT_TEMPLATE_{{id}}_",
    element_errors: true,
    deactivate_ordering: false,
    deactivate_preview: false,
    hide_pos: false,
    fields: {
      id: {
        prio: 0,
        bez: "_LBL_RANDOM_STRING_PARTS_FIELD_ID_LABEL_",
        default: null,
        help_text: "_TXT_RANDOM_STRING_PARTS_FIELD_ID_HELP_TEXT_",
        readonly: false,
        options: {
          allow_null: false,
          required: true,
          max_length: null,
          min_length: null
        },
        typ: "string"
      },
      length: {
        prio: 1,
        bez: "_LBL_RANDOM_STRING_PARTS_FIELD_LENGTH_LABEL_",
        default: null,
        help_text: "_TXT_RANDOM_STRING_PARTS_FIELD_LENGTH_HELP_TEXT_",
        readonly: false,
        options: {
          allow_null: false,
          required: true,
          min_value: 1,
          max_value: null
        },
        typ: "integer"
      },
      lower: {
        prio: 2,
        bez: "_LBL_RANDOM_STRING_PARTS_FIELD_LOWER_LABEL_",
        default: null,
        help_text: "_TXT_RANDOM_STRING_PARTS_FIELD_LOWER_HELP_TEXT_",
        readonly: false,
        options: {
          allow_null: false,
          required: true
        },
        typ: "boolean"
      },
      upper: {
        prio: 3,
        bez: "_LBL_RANDOM_STRING_PARTS_FIELD_UPPER_LABEL_",
        default: null,
        help_text: "_TXT_RANDOM_STRING_PARTS_FIELD_UPPER_HELP_TEXT_",
        readonly: false,
        options: {
          allow_null: false,
          required: true
        },
        typ: "boolean"
      },
      numbers: {
        prio: 4,
        bez: "_LBL_RANDOM_STRING_PARTS_FIELD_NUMBERS_LABEL_",
        default: null,
        help_text: "_TXT_RANDOM_STRING_PARTS_FIELD_NUMBERS_HELP_TEXT_",
        readonly: false,
        options: {
          allow_null: false,
          required: true
        },
        typ: "boolean"
      },
      other_chars: {
        prio: 5,
        bez: "_LBL_RANDOM_STRING_PARTS_FIELD_OTHER_CHARS_LABEL_",
        default: null,
        help_text: "_TXT_RANDOM_STRING_PARTS_FIELD_OTHER_CHARS_HELP_TEXT_",
        readonly: false,
        options: {
          allow_null: true,
          required: false,
          max_length: null,
          min_length: null
        },
        typ: "string"
      }
    },
    prio: 5,
    wert: null
  };

  const jsonOptions = computed(() => {
    return toFormElementFromParameter({
      obj: {
        modalitaet: jsonParam,
      },
      showLabel: true,
    }).list[0];
  });

  return {
    jsonOptions,
  };
}
