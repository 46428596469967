import {
  cloneDeep,
  reduce,
  size,
} from "lodash-es";

export default {
  namespaced: true,
  state() {
    return {
      SY_CONFIGS: undefined,
    };
  },
  getters: {
    GET_VALUE: state => value => {
      const syConfigs = state.SY_CONFIGS;
      return syConfigs && syConfigs[value] ? syConfigs[value] : undefined;
    },
  },
  mutations: {
    MUT_ADD_SY_CONFIGS(state, list) {
      state.SY_CONFIGS = reduce(list, (result, item) => {
        result[item.syc_kennung] = item.syc_wert;
        return result;
      }, {});
    },
    MUT_ADD_SY_CONFIGS_OBJ(state, obj) {
      if (state.SY_CONFIGS && size(state.SY_CONFIGS)) {
        return;
      }
      state.SY_CONFIGS = cloneDeep(obj);
    },
  },
};
