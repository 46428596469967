import {
  computed,
  toRef,
} from "vue";

import {
  sortBy,
} from "lodash-es";

export default function UiTreeAPI(props) {
  const options = toRef(props, "options");
  const model = toRef(props, "model");

  const fieldsLocal = computed(() => {
    const FIELDS = options.value.fields || [];
    return sortBy(FIELDS, ["prio"]);
  });

  const isArrayLocal = computed(() => {
    return options.value.isArray || false;
  });

  const modelLocal = computed(() => {
    if (isArrayLocal.value) {
      const MODEL = model.value || [];
      return sortBy(MODEL, ["index"]);
    }
    return model.value || {};
  });

  return {
    fieldsLocal,
    isArrayLocal,
    modelLocal,
  };
}
