import UIReadOnlyMixin from "../../UIReadOnlyMixin";

import AFiltersAPI from "aloha-vue/src/compositionAPI/AFiltersAPI";

// @vue/component
export default {
  name: "UiDatepickerReadOnly",
  mixins: [
    UIReadOnlyMixin,
  ],
  setup() {
    const {
      filterDate,
    } = AFiltersAPI();

    return {
      filterDate,
    };
  },
  data() {
    return {
      format: {
        date: "DD.MM.YYYY",
        datetime: "DD.MM.YYYY HH:mm",
        time: "HH:mm",
      },
    };
  },
  computed: {
    getFormat() {
      return this.options.format || this.format[this.options.type];
    },

    getModel() {
      return this.filterDate(this.model, { format: this.getFormat });
    },
  },
};
