import {
  toFormElementFromParameter,
} from "../../../../../global/functions/mappingForParameterToFormElement";

import {
  cloneDeep,
  map,
} from "lodash-es";

export default {
  data() {
    return {
      jsonParam: {
        bez: "_LBL_ANTRAGLIST_MODUL_PDF_FIELD_",
        default: null,
        help_text: "_TXT_ANTRAGLIST_MODUL_PDF_FIELD_HELP_TEXT_",
        readonly: false,
        options: {
          allow_null: false,
          required: true
        },
        typ: "validated_json",
        many: false,
        as_json: true,
        unique_fields: [],
        key_field: null,
        pos_field: null,
        min_length: null,
        max_length: null,
        element_label: "Element",
        element_template: "{{element_label}}",
        element_errors: false,
        deactivate_ordering: false,
        deactivate_preview: false,
        hide_pos: false,
        fields: {
          columns: {
            prio: 0,
            bez: "_LBL_ANTRAGLIST_MODUL_PDF_FIELD_COLUMNS_",
            default: null,
            help_text: "_TXT_ANTRAGLIST_MODUL_PDF_FIELD_COLUMNS_HELP_TEXT_",
            readonly: false,
            options: {
              allow_null: false,
              required: true,
              displaymode: "ordered"
            },
            typ: "multi_select",
            choices: [
              [
                "nr",
                "Nr."
              ],
              [
                "a_nr",
                "Antrags-Nr."
              ],
              [
                "a_nr_extern",
                "Externe Antrags-Nr."
              ],
              [
                "a_titel",
                "Antragstitel"
              ],
              [
                "ars_kbez",
                "Förderangebot"
              ],
              [
                "ast_name",
                "Antragsteller"
              ],
              [
                "entscheidung",
                "Entscheidung"
              ],
              [
                "a_zuschussbeantragt",
                "Beantragter Zuschuss in €"
              ],
              [
                "a_zuschusseingereicht",
                "Eingereichter Zuschuss in €"
              ],
              [
                "sla_protokoll",
                "Protokoll"
              ],
              [
                "sla_entscheidung_anmerkung",
                "Auflage"
              ],
              [
                "beantragt_summe_kosten",
                "_LBL_ANTRAGLIST_MODUL_PDF_BEANTRAGT_SUMME_KOSTEN_"
              ],
              [
                "beantragt_max_foerdersatz",
                "_LBL_ANTRAGLIST_MODUL_PDF_BEANTRAGT_MAX_FOERDERSATZ_"
              ],
              [
                "beantragt_max_foerdersumme",
                "_LBL_ANTRAGLIST_MODUL_PDF_BEANTRAGT_MAX_FOERDERSUMME_"
              ],
              [
                "beantragt_summe_eigenmittel",
                "_LBL_ANTRAGLIST_MODUL_PDF_BEANTRAGT_SUMME_EIGENMITTEL_"
              ],
              [
                "beantragt_summe_finanzierungsmittel",
                "_LBL_ANTRAGLIST_MODUL_PDF_BEANTRAGT_SUMME_FINANZIERUNGSMITTEL_"
              ],
              [
                "beantragt_summe_foerderfaehige_kosten",
                "_LBL_ANTRAGLIST_MODUL_PDF_BEANTRAGT_SUMME_FOERDERFAEHIGE_KOSTEN_"
              ],
              [
                "beantragt_summe_benoetigte_eigenmittel",
                "_LBL_ANTRAGLIST_MODUL_PDF_BEANTRAGT_SUMME_BENOETIGTE_EIGENMITTEL_"
              ],
              [
                "eingereicht_summe_kosten",
                "_LBL_ANTRAGLIST_MODUL_PDF_EINGEREICHT_SUMME_KOSTEN_"
              ],
              [
                "eingereicht_max_foerdersatz",
                "_LBL_ANTRAGLIST_MODUL_PDF_EINGEREICHT_MAX_FOERDERSATZ_"
              ],
              [
                "eingereicht_max_foerdersumme",
                "_LBL_ANTRAGLIST_MODUL_PDF_EINGEREICHT_MAX_FOERDERSUMME_"
              ],
              [
                "eingereicht_summe_eigenmittel",
                "_LBL_ANTRAGLIST_MODUL_PDF_EINGEREICHT_SUMME_EIGENMITTEL_"
              ],
              [
                "eingereicht_summe_finanzierungsmittel",
                "_LBL_ANTRAGLIST_MODUL_PDF_EINGEREICHT_SUMME_FINANZIERUNGSMITTEL_"
              ],
              [
                "eingereicht_summe_foerderfaehige_kosten",
                "_LBL_ANTRAGLIST_MODUL_PDF_EINGEREICHT_SUMME_FOERDERFAEHIGE_KOSTEN_"
              ],
              [
                "eingereicht_summe_benoetigte_eigenmittel",
                "_LBL_ANTRAGLIST_MODUL_PDF_EINGEREICHT_SUMME_BENOETIGTE_EIGENMITTEL_"
              ],
            ]
          },
          max_freitext_zeichen: {
            prio: 1,
            bez: "_LBL_ANTRAGLIST_MODUL_PDF_FIELD_MAX_FREITEXT_ZEICHEN_",
            default: 100,
            help_text: "_TXT_ANTRAGLIST_MODUL_PDF_FIELD_MAX_FREITEXT_ZEICHEN_HELP_TEXT_",
            readonly: false,
            options: {
              allow_null: false,
              required: true,
              min_value: null,
              max_value: null
            },
            typ: "integer"
          },
          auflagen_detail: {
            prio: 2,
            bez: "_LBL_ANTRAGLIST_MODUL_PDF_FIELD_AUFLAGEN_DETAIL_",
            default: null,
            help_text: "_TXT_ANTRAGLIST_MODUL_PDF_FIELD_AUFLAGEN_DETAIL_HELP_TEXT_",
            readonly: false,
            options: {
              allow_null: false,
              required: true
            },
            typ: "boolean"
          },
          protokoll_detail: {
            prio: 3,
            bez: "_LBL_ANTRAGLIST_MODUL_PDF_FIELD_PROTOKOLL_DETAIL_",
            default: null,
            help_text: "_TXT_ANTRAGLIST_MODUL_PDF_FIELD_PROTOKOLL_DETAIL_HELP_TEXT_",
            readonly: false,
            options: {
              allow_null: false,
              required: true
            },
            typ: "boolean"
          },
          zusatz_regeln: {
            prio: 4,
            bez: "_LBL_ANTRAGLIST_MODUL_PDF_FIELD_ZUSATZ_REGELN_",
            default: null,
            help_text: "_TXT_ANTRAGLIST_MODUL_PDF_FIELD_ZUSATZ_REGELN_HELP_TEXT_",
            readonly: false,
            options: {
              allow_null: true,
              required: false
            },
            typ: "validated_json",
            many: true,
            as_json: false,
            unique_fields: [],
            key_field: "key",
            pos_field: null,
            min_length: null,
            max_length: null,
            element_label: "_LBL_ANTRAGLIST_MODUL_PDF_FIELD_ZUSATZ_REGELN_ELEMENT_LABEL_",
            element_template: "{{titel}} ({{key}})",
            element_errors: true,
            deactivate_ordering: false,
            deactivate_preview: false,
            hide_pos: false,
            fields: {
              key: {
                prio: 0,
                bez: "_LBL_ANTRAGLIST_MODUL_PDF_FIELD_ZUSATZ_REGELN_KEY_",
                default: null,
                help_text: "_TXT_ANTRAGLIST_MODUL_PDF_FIELD_ZUSATZ_REGELN_KEY_HELP_TEXT_",
                readonly: false,
                options: {
                  allow_null: false,
                  required: true,
                  displaymode: "select2"
                },
                typ: "single_choice",
                katalog: "regelkeys"
              },
              regexp: {
                prio: 1,
                bez: "_LBL_ANTRAGLIST_MODUL_PDF_FIELD_ZUSATZ_REGELN_REGEXP_",
                default: null,
                help_text: "_TXT_ANTRAGLIST_MODUL_PDF_FIELD_ZUSATZ_REGELN_REGEXP_HELP_TEXT_",
                readonly: false,
                options: {
                  allow_null: false,
                  required: true,
                  max_length: null,
                  min_length: null
                },
                typ: "string"
              },
              titel: {
                prio: 2,
                bez: "_LBL_ANTRAGLIST_MODUL_PDF_FIELD_ZUSATZ_REGELN_TITEL_",
                default: null,
                help_text: "_TXT_ANTRAGLIST_MODUL_PDF_FIELD_ZUSATZ_REGELN_TITEL_HELP_TEXT_",
                readonly: false,
                options: {
                  allow_null: false,
                  required: true,
                  max_length: null,
                  min_length: null
                },
                typ: "string"
              },
              render_empty: {
                prio: 3,
                bez: "_LBL_ANTRAGLIST_MODUL_PDF_FIELD_ZUSATZ_REGELN_RENDER_EMPTY_",
                default: true,
                help_text: "_TXT_ANTRAGLIST_MODUL_PDF_FIELD_ZUSATZ_REGELN_RENDER_EMPTY_HELP_TEXT_",
                readonly: false,
                options: {
                  allow_null: false,
                  required: true
                },
                typ: "boolean"
              }
            }
          },
          zusatzinfos_inline: {
            prio: 5,
            bez: "_LBL_ANTRAGLIST_MODUL_PDF_FIELD_ZUSATZINFOS_INLINE_",
            default: null,
            help_text: "_TXT_ANTRAGLIST_MODUL_PDF_FIELD_ZUSATZINFOS_INLINE_HELP_TEXT_",
            readonly: false,
            options: {
              allow_null: false,
              required: true
            },
            typ: "boolean"
          }
        },
        prio: 90,
        wert: null
      }
    };
  },
  created() {
    this.init();
  },
  computed: {
    jsonOptions() {
      return toFormElementFromParameter({
        obj: { modalitaet: this.jsonParam },
        showLabel: true,
        staticOptions: {
          required: this.options.required,
          options: this.options.options
        }
      }).list[0];
    }
  },
  methods: {
    init() {
      this.getListHttp({
        url: "katalog/",
        urlParams: {
          key: "foerderorgtyp",
        },
        apiSaveId: "foerderorgtyp"
      }).then(
        response => {
          const OT_CHOICES = map(response, ot => {
            return [
              `ot__${ ot.pk }`,
              ot.bez
            ];
          });
          const COPY_JSON_PARAM = cloneDeep(this.jsonParam);
          COPY_JSON_PARAM.fields.columns.choices.push(...OT_CHOICES);
          this.jsonParam = COPY_JSON_PARAM;
        }
      );
    }
  }
};
