import PuxTranslate from "../../PuxTranslate/PuxTranslate.vue";

import {
  TYPES_LABEL_WITHOUT_FOR,
} from "../../../functions/mappingForParameterToFormElement";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "PuxLabel",
  components: {
    PuxTranslate,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    isTitle: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    idLocal() {
      return `${ this.id }_label`;
    },

    attributesLocal() {
      const ATTRIBUTES = cloneDeep(this.$attrs) || {};
      if (!this.isNotForAttribute) {
        ATTRIBUTES.for = this.id;
      }
      if (this.isTitle) {
        ATTRIBUTES.title = this.options.labelTitle || this.options.label;
        ATTRIBUTES.extra = this.options.extra;
      }
      return ATTRIBUTES;
    },

    typeLocal() {
      return this.options.type;
    },

    isNotForAttribute() {
      return TYPES_LABEL_WITHOUT_FOR[this.typeLocal];
    },
  },
};
