import translate from "../../../global/directives/translate";

import AHttpAPI from "aloha-vue/src/compositionAPI/AHttpAPI";
import PermissionAPI from "../../../global/compositionAPI/PermissionAPI";

import EventBus from "aloha-vue/src/utils/EventBus";

// @vue/component
export default {
  name: "MenuElementNachrichten",
  directives: {
    translate,
  },
  setup() {
    const {
      getHttp,
    } = AHttpAPI();

    const {
      checkPermissionsSync,
    } = PermissionAPI();

    return {
      checkPermissionsSync,
      getHttp,
    };
  },
  data() {
    return {
      numUnread: 0,
      pullInterval: 10000 * 60, // alle 10 Minuten
      interval: undefined,
    };
  },
  computed: {
    translateTitleOptions() {
      return {
        title: "_TXT_MENU_NACHRICHTEN_BADGE_TITLE_{{numUnread}}_",
        extra: {
          numUnread: this.numUnread,
        },
      };
    },
  },
  created() {
    this.loadNachrichten();
    this.setIntervalUpdateNacrichten();
    this.setEventListener();
  },
  beforeUnmount() {
    clearInterval(this.interval);
    this.destroyEventListener();
  },
  methods: {
    setIntervalUpdateNacrichten() {
      this.interval = setInterval(this.loadNachrichten, this.pullInterval);
    },

    setEventListener() {
      EventBus.$on("loadNachrichten", this.loadNachrichten);
    },

    destroyEventListener() {
      EventBus.$off("loadNachrichten", this.loadNachrichten);
    },

    loadNachrichten() {
      if (!this.checkPermissionsSync({ perm: "benachrichtigungen.view" })) {
        return;
      }
      this.getHttp({
        url: "inbox/count/",
        urlParams: {
          bnn_gelesen: false,
          limit: 1,
        },
      }).then(
        response => {
          this.numUnread = response;
          EventBus.$emit("updateNachrichten", { numUnread: this.numUnread });
        }
      );
    },
  },
};
