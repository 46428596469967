export default {
  externe_nr: {
    label: "_LBL_BELEG_STANDARD_FELD_EXTERNE_NR_",
    helpText: "_TXT_BELEG_STANDARD_FELD_EXTERNE_NR_HELP_TEXT_",
    show: true,
    required: true,
    help_text: true,
    objektkatalog: false,
    prio: 0,
    type: "text",
  },
  verausgabung_az: {
    label: "_LBL_BELEG_STANDARD_FELD_VERAUSGABUNG_AZ_",
    helpText: "_TXT_BELEG_STANDARD_FELD_VERAUSGABUNG_AZ_HELP_TEXT_",
    show: true,
    required: true,
    help_text: true,
    objektkatalog: false,
    prio: 10,
    type: "select",
    keyId: "pk",
    keyLabel: "bez",
    url: "/vertraege/{{ v_id }}/auszahlungen/",
    apiSaveId: "vertragbeleg__{{v_id}}__auszahlungen",
  },
  aufforderung: {
    label: "_LBL_BELEG_STANDARD_FELD_AUFFORDERUNG_",
    helpText: "_TXT_BELEG_STANDARD_FELD_AUFFORDERUNG_HELP_TEXT_",
    show: true,
    required: true,
    help_text: true,
    objektkatalog: false,
    prio: 20,
    type: "document",
  },
  aufforderung_datum: {
    label: "_LBL_BELEG_STANDARD_FELD_AUFFORDERUNG_DATUM_",
    helpText: "_TXT_BELEG_STANDARD_FELD_AUFFORDERUNG_DATUM_HELP_TEXT_",
    show: true,
    required: true,
    help_text: true,
    objektkatalog: false,
    prio: 30,
    type: "date",
  },
  zahlung: {
    label: "_LBL_BELEG_STANDARD_FELD_ZAHLUNG_",
    helpText: "_TXT_BELEG_STANDARD_FELD_ZAHLUNG_HELP_TEXT_",
    show: true,
    required: true,
    help_text: true,
    objektkatalog: false,
    prio: 40,
    type: "document",
  },
  datum: {
    label: "_LBL_BELEG_STANDARD_FELD_DATUM_",
    helpText: "_TXT_BELEG_STANDARD_FELD_DATUM_HELP_TEXT_",
    show: true,
    required: true,
    help_text: true,
    objektkatalog: false,
    prio: 50,
    type: "date",
  },
  sender: {
    label: "_LBL_BELEG_STANDARD_FELD_SENDER_",
    helpText: "_TXT_BELEG_STANDARD_FELD_SENDER_HELP_TEXT_",
    show: true,
    required: true,
    help_text: true,
    objektkatalog: false,
    prio: 60,
    type: "text",
  },
  empfaenger: {
    label: "_LBL_BELEG_STANDARD_FELD_EMPFAENGER_",
    helpText: "_TXT_BELEG_STANDARD_FELD_EMPFAENGER_HELP_TEXT_",
    show: true,
    required: true,
    help_text: true,
    objektkatalog: false,
    prio: 70,
    type: "text",
  },
  bemerkung: {
    label: "_LBL_BELEG_STANDARD_FELD_BEMERKUNG_",
    helpText: "_TXT_BELEG_STANDARD_FELD_BEMERKUNG_HELP_TEXT_",
    show: true,
    required: true,
    help_text: true,
    objektkatalog: false,
    prio: 80,
    type: "richtext",
  },
  waehrung: {
    label: "_LBL_BELEG_STANDARD_FELD_WAEHRUNG_",
    helpText: "_TXT_BELEG_STANDARD_FELD_WAEHRUNG_HELP_TEXT_",
    show: true,
    required: true,
    help_text: true,
    objektkatalog: false,
    prio: 90,
    type: "select",
    keyId: "pk",
    keyLabel: "bez",
    search: true,
    searchList: ["bez"],
    url: "/katalog/?key=waehrungen",
    apiSaveId: "waehrungen",
  },
  landeswaehrung_kurs: {
    label: "_LBL_BELEG_STANDARD_FELD_LANDESWAEHRUNG_KURS_",
    helpText: "_TXT_BELEG_STANDARD_FELD_LANDESWAEHRUNG_KURS_HELP_TEXT_",
    show: true,
    required: true,
    help_text: true,
    objektkatalog: false,
    prio: 100,
    type: "float",
    max_digits: 14,
    decimal_places: 5,
  },
  landeswaehrung_betrag: {
    label: "_LBL_BELEG_STANDARD_FELD_LANDESWAEHRUNG_BETRAG_",
    helpText: "_TXT_BELEG_STANDARD_FELD_LANDESWAEHRUNG_BETRAG_HELP_TEXT_",
    show: true,
    required: true,
    help_text: true,
    objektkatalog: false,
    prio: 110,
    type: "float",
    max_digits: 14,
    decimal_places: 2,
  },
  verausgabungsort: {
    label: "_LBL_BELEG_STANDARD_FELD_VERAUSGABUNGSORT_",
    helpText: "_TXT_BELEG_STANDARD_FELD_VERAUSGABUNGSORT_HELP_TEXT_",
    show: true,
    required: true,
    help_text: true,
    objektkatalog: false,
    prio: 120,
    type: "select",
    keyId: "pk",
    keyLabel: "bez",
    search: true,
    searchList: ["bez"],
    url: "/katalog/?key=verausgabungsorte",
    apiSaveId: "verausgabungsorte",
  },
  objektkatalogwert: {
    label: "_LBL_BELEG_STANDARD_OBJEKTKATALOG_",
    helpText: "_TXT_BELEG_STANDARD_OBJEKTKATALOG_HELP_TEXT_",
    show: true,
    required: true,
    help_text: true,
    objektkatalog: true,
    prio: 130,
    type: "select",
    keyId: "pk",
    keyLabel: "bez",
    search: true,
    searchList: ["bez"],
    url: "/generics/{{okw_ctid}}/objekte/{{okw_objid}}/katalogwerte/?katalog={{okw_okaid}}",
    apiSaveId: "objektkatalog__{{okw_ctid}}__{{okw_objid}}__{{okw_okaid}}",
  },
};
