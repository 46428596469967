import MenuElementMixin from "../MenuElementMixin";

// @vue/component
export default {
  name: "MenuElementLevel2",
  mixins: [
    MenuElementMixin,
  ],
  props: {
    stateChildActivePk: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  methods: {
    onClickLink() {
      this.setStatusLinkDisabled();
    },
  },
};
