import AAlert from "aloha-vue/src/AAlert/AAlert";
import AButton from "aloha-vue/src/AButton/AButton";
import PuxTranslate from "../../global/components/PuxTranslate/PuxTranslate.vue";

import CheckAPI from "./compositionAPI/CheckAPI";
import ReloadAPI from "./compositionAPI/ReloadAPI";

// @vue/component
export default {
  name: "TheVersionCheck",
  components: {
    AAlert,
    AButton,
    PuxTranslate,
  },
  setup() {
    const {
      hasUpdate,
      loadSysinfo,
      revision,
    } = CheckAPI();

    const {
      reloadPage,
    } = ReloadAPI();

    setTimeout(() => {
      loadSysinfo();
    }, 300);

    return {
      hasUpdate,
      loadSysinfo,
      reloadPage,
      revision,
    };
  },
};
