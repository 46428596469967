import AButton from "aloha-vue/src/AButton/AButton";
import ModalCreateOrUpdate from "./ModalCreateOrUpdate/ModalCreateOrUpdate.vue";

import translate from "../../../../../../global/directives/translate";

import {
  cloneDeep,
  indexOf,
  reject,
  size,
  sortBy,
} from "lodash-es";


// @vue/component
export default {
  name: "AntragScoreKontextKonfig",
  components: {
    AButton,
    ModalCreateOrUpdate,
  },
  directives: {
    translate
  },
  props: {
    model: {
      type: Array,
      required: true,
      default: () => [],
    },
    onUpdate: {
      type: Function,
      required: false,
      default: undefined,
    }
  },
  data() {
    return {
      localModel: undefined,
      statusModal: false,
      confirmShow: false,
      currentModel: undefined,
      selectorClose: undefined,
    };
  },
  computed: {
    createBtnID() {
      return `ui_antrag_score_kontext_konfig_openmodalcreate`;
    },
    modelSize() {
      return size(this.localModel);
    },
    translationNoEntrey() {
      return { text: "_TXT_UI_ANTRAG_SCORE_KONTEXT_KONFIG_EMPTY_" };
    },
  },
  beforeCreate() {
    this.$options.components.Modal = require("../../../../../../global/components/Modal/Modal.vue").default;
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.localModel = cloneDeep(this.model) || [];
    },

    getEditBtnID(idx) {
      return `ui_antrag_score_kontext_konfig_openmodalupdate_${ idx }`;
    },

    openModalCreate() {
      this.selectorClose = "#" + this.createBtnID;
      this.statusModal = true;
    },

    openModalUpdate({ item, idx }) {
      this.selectorClose = "#" + this.getEditBtnID(idx);
      this.currentModel = item;
      this.statusModal = true;
    },

    closeModal({ model } = []) {
      if (model) {
        this.updateModel({ model: model });
      }
      this.currentModel = undefined;
      this.statusModal = false;
    },

    moveUp({ idx }) {
      if (idx < 1) {
        return;
      }
      const MODEL = sortBy(this.localModel, el => {
        const list_idx = indexOf(this.localModel, el);
        return list_idx === idx ? (idx - 1.5) : list_idx;
      });
      this.updateModel({ model: MODEL });
    },

    moveDown({ idx }) {
      if (idx >= (this.modelSize - 1)) {
        return;
      }
      const MODEL = sortBy(this.localModel, el => {
        const list_idx = indexOf(this.localModel, el);
        return list_idx === idx ? (idx + 1.5) : list_idx;
      });
      this.updateModel({ model: MODEL });
    },

    openConfirmDelete({ item }) {
      this.confirmOptions = {
        okClass: "a_btn a_btn_primary",
        title: "_TXT_UI_ANTRAG_SCORE_KONTEXT_KONFIG_REMOVE_DIALOG_TITLE_{{field_id}}_",
        msg: "_HTML_UI_ANTRAG_SCORE_KONTEXT_KONFIG_REMOVE_DIALOG_BODY_{{field_id}}_",
        okLabel: "_BTN_UI_ANTRAG_SCORE_KONTEXT_KONFIG_REMOVE_DIALOG_OK_",
        okCallback: () => this.deleteItem({ id: item.id }),
        cancelCallback: this.closeConfirmDelete,
        loading: false,
        extra: {
          field_id: item.id
        }
      };
      this.confirmShow = true;
    },

    deleteItem({ id }) {
      const MODEL = reject(this.localModel, el => el.id === id);

      this.updateModel({ model: MODEL });
      this.closeConfirmDelete();
    },

    closeConfirmDelete() {
      this.confirmShow = false;
    },

    updateModel({ model }) {
      this.localModel = cloneDeep(model);
      this.onUpdate({ model: cloneDeep(this.localModel) });
    }
  }
};
