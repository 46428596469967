import UiValidatedJsonModeMixin from "./UiValidatedJsonModeMixin";

import {
  isNil,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "UiValidatedJsonModeSingleMixin",
  mixins: [
    UiValidatedJsonModeMixin
  ],
  data() {
    return {
      localShowForm: undefined,
    };
  },
  computed: {
    paramFormClass() {
      return {
        "pt-3": this.showForm,
      };
    },

    showFormField() {
      return {
        type: "one_checkbox",
        label: "_LBL_UI_VALIDATED_JSON_SINGLE_TOGGLE_FORM_",
        id: `${ this.idPrefix }__show_form_checkbox`
      };
    },

    showForm() {
      return (isNil(this.localShowForm) && size(this.model) > 0 || this.isRequired
              || this.localShowForm);
    }

  },
  methods: {
    toggleShowForm({ model }) {
      if (model) {
        this.localShowForm = true;
        this.change({ currentModel: this.model });
      } else {
        this.localShowForm = false;
        this.change({ currentModel: null });
      }
    }
  }
};
