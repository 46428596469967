import { toFormElementFromParameter } from "../../../../../global/functions/mappingForParameterToFormElement";

export default {
  data() {
    return {
      jsonParam: {
        bez: "_LBL_ANTRAG_BUDGETKONTO_KONTO_",
        default: null,
        help_text: "_TXT_ANTRAG_BUDGETKONTO_KONTO_HELP_TEXT_",
        readonly: false,
        options: {
          allow_null: true,
          required: false
        },
        typ: "validated_json",
        many: false,
        as_json: true,
        unique_fields: [],
        key_field: null,
        pos_field: null,
        min_length: null,
        max_length: null,
        element_label: "Element",
        element_template: "{{element_label}}",
        element_errors: false,
        fields: {
          ermittlung: {
            prio: 0,
            bez: "_LBL_ANTRAG_BUDGETKONTO_KONTO_ERMITTLUNG_",
            default: null,
            help_text: "_TXT_ANTRAG_BUDGETKONTO_KONTO_ERMITTLUNG_HELP_TEXT_",
            readonly: false,
            options: {
              allow_null: true,
              required: false,
              displaymode: "select2"
            },
            typ: "single_choice",
            katalog: "fibu_kontomodul"
          },
          bearbeitbar: {
            prio: 1,
            bez: "_LBL_ANTRAG_BUDGETKONTO_KONTO_BEARBEITBAR_",
            default: false,
            help_text: "_TXT_ANTRAG_BUDGETKONTO_KONTO_BEARBEITBAR_HELP_TEXT_",
            readonly: false,
            options: {
              allow_null: false,
              required: true
            },
            typ: "boolean"
          }
        },
        prio: 5,
        wert: null
      },
    };
  },
  computed: {
    jsonOptions() {
      return toFormElementFromParameter({
        obj: { dimensionen: this.jsonParam },
        showLabel: true,
        staticOptions: {
          required: this.options.required,
          options: this.options.options
        }
      }).list[0];
    }
  }
};
