// @vue/component
export default {
  data() {
    return {
      formFields: {
        text_aktiv: {
          id: "text_aktiv",
          htmlId: `${ this.htmlRegelNummer }_text_aktiv`,
          type: "boolean",
          label: "_LBL_ANTRAG_SCORE_CONFIG_PARAM_TEXT_AKTIV_",
          helpText: "_TXT_ANTRAG_SCORE_CONFIG_PARAM_TEXT_AKTIV_HELP_TEXT_",
          showLabel: true,
          translate: true,
          required: true,
          view: "v-alt",
        },
        text_label: {
          id: "text_label",
          htmlId: `${ this.htmlRegelNummer }_text_label`,
          type: "text",
          label: "_LBL_ANTRAG_SCORE_CONFIG_PARAM_TEXT_LABEL_",
          helpText: "_TXT_ANTRAG_SCORE_CONFIG_PARAM_TEXT_LABEL_HELP_TEXT_",
          showLabel: true,
          translate: true,
          required: true,
          view: "v",
        },
        text_variant: {
          id: "text_variant",
          htmlId: `${ this.htmlRegelNummer }_text_variant`,
          type: "select",
          label: "_LBL_ANTRAG_SCORE_CONFIG_PARAM_TEXT_VARIANT_",
          helpText: "_TXT_ANTRAG_SCORE_CONFIG_PARAM_TEXT_VARIANT_HELP_TEXT_",
          keyId: "value",
          keyLabel: "label",
          showLabel: true,
          dataTranslate: true,
          translate: true,
          required: true,
          view: "v",
          data: [
            { value: 0, label: "_LBL_ANTRAG_SCORE_CONFIG_PARAM_TEXT_VARIANTE_CHOICE_MANUELLE_EINGABE_" },
            { value: 1, label: "_LBL_ANTRAG_SCORE_CONFIG_PARAM_TEXT_VARIANTE_CHOICE_AUSWAHLLISTE_" },
          ]
        },
        text_max: {
          id: "text_max",
          htmlId: `${ this.htmlRegelNummer }_text_max`,
          type: "integer",
          label: "_LBL_ANTRAG_SCORE_CONFIG_PARAM_TEXT_MAX_",
          helpText: "_TXT_ANTRAG_SCORE_CONFIG_PARAM_TEXT_MAX_HELP_TEXT_",
          min_value: 0,
          max_value: 60,
          default: 10,
          showLabel: true,
          translate: true,
          required: false,
          view: "v",
        },
        num_aktiv: {
          id: "num_aktiv",
          htmlId: `${ this.htmlRegelNummer }_num_aktiv`,
          type: "boolean",
          label: "_LBL_ANTRAG_SCORE_CONFIG_PARAM_NUM_AKTIV_",
          helpText: "_TXT_ANTRAG_SCORE_CONFIG_PARAM_NUM_AKTIV_HELP_TEXT_",
          showLabel: true,
          translate: true,
          required: true,
          view: "v-alt",
        },
        num_label: {
          id: "num_label",
          htmlId: `${ this.htmlRegelNummer }_num_label`,
          type: "text",
          label: "_LBL_ANTRAG_SCORE_CONFIG_PARAM_NUM_LABEL_",
          helpText: "_TXT_ANTRAG_SCORE_CONFIG_PARAM_NUM_LABEL_HELP_TEXT_",
          showLabel: true,
          translate: true,
          required: true,
          view: "v",
        },
        num_variant: {
          id: "num_variant",
          htmlId: `${ this.htmlRegelNummer }_num_variant`,
          type: "single_choice",
          label: "_LBL_ANTRAG_SCORE_CONFIG_PARAM_NUM_VARIANT_",
          helpText: "_TXT_ANTRAG_SCORE_CONFIG_PARAM_NUM_VARIANT_HELP_TEXT_",
          keyId: "value",
          keyLabel: "label",
          showLabel: true,
          dataTranslate: true,
          translate: true,
          required: true,
          view: "v",
          data: [
            { value: 0, label: "_LBL_ANTRAG_SCORE_CONFIG_PARAM_NUM_VARIANTE_CHOICE_MANUELLE_EINGABE_" },
            { value: 1, label: "_LBL_ANTRAG_SCORE_CONFIG_PARAM_NUM_VARIANTE_CHOICE_AUSWAHLLISTE_" },
            { value: 2, label: "_LBL_ANTRAG_SCORE_CONFIG_PARAM_NUM_VARIANTE_CHOICE_AUTOMATISCH_ERMITTELT_" },
          ]
        },
        num_min: {
          id: "num_min",
          htmlId: `${ this.htmlRegelNummer }_num_min`,
          type: "integer",
          label: "_LBL_ANTRAG_SCORE_CONFIG_PARAM_NUM_MIN_",
          helpText: "_TXT_ANTRAG_SCORE_CONFIG_PARAM_NUM_MIN_HELP_TEXT_",
          min_value: 0,
          showLabel: true,
          translate: true,
          required: false,
          view: "v",
        },
        num_max: {
          id: "num_max",
          htmlId: `${ this.htmlRegelNummer }_num_max`,
          type: "integer",
          label: "_LBL_ANTRAG_SCORE_CONFIG_PARAM_NUM_MAX_",
          helpText: "_TXT_ANTRAG_SCORE_CONFIG_PARAM_NUM_MAX_HELP_TEXT_",
          min_value: 1,
          showLabel: true,
          translate: true,
          required: false,
          view: "v",
        },
        num_decimal: {
          id: "num_decimal",
          htmlId: `${ this.htmlRegelNummer }_num_decimal`,
          type: "integer",
          label: "_LBL_ANTRAG_SCORE_CONFIG_PARAM_NUM_DECIMAL_",
          helpText: "_TXT_ANTRAG_SCORE_CONFIG_PARAM_NUM_DECIMAL_HELP_TEXT_",
          min_value: 0,
          max_value: 6,
          default: 0,
          showLabel: true,
          translate: true,
          required: false,
          view: "v",
        },
        num_expression: {
          id: "num_expression",
          htmlId: `${ this.htmlRegelNummer }_num_expression`,
          type: "text",
          label: "_LBL_ANTRAG_SCORE_CONFIG_PARAM_NUM_EXPRESSION_",
          helpText: "_TXT_ANTRAG_SCORE_CONFIG_PARAM_NUM_EXPRESSION_HELP_TEXT_",
          showLabel: true,
          translate: true,
          required: true,
          view: "v",
        },
      }
    };
  },

};
