import {
  computed,
} from "vue";
import {
  useRouter,
} from "vue-router";
import {
  useStore,
} from "vuex";

import AButton from "aloha-vue/src/AButton/AButton";
import FormElement from "../../global/components/FormElement/FormElement.vue";
import Permission from "../../global/components/Permission/Permission.vue";

import RouterAPI from "../../global/compositionAPI/RouterAPI";

import EventBus from "aloha-vue/src/utils/EventBus";

// @vue/component
export default {
  name: "TheSearchGlobal",
  components: {
    AButton,
    FormElement,
    Permission,
  },
  setup() {
    const $router = useRouter();
    const store = useStore();

    const model = computed(() => {
      return store.getters["searchGlobal/MODEL"];
    });

    const {
      isRouteActive,
    } = RouterAPI();

    const options = {
      type: "text",
      view: "v",
      label: "_BTN_SEARCH_GLOBAL_",
      id: "the_search_global",
      nullValue: "",
    };

    const changeModel = ({ model }) => {
      store.commit("searchGlobal/MUT_CHANGE_MODEL", model);
      EventBus.$emit("onChangeSearchGlobalModel", model);
    };

    const onSearch = () => {
      if (!isRouteActive({ routeName: "root.suche" })) {
        $router.push({ name: "root.suche", });
      }
      EventBus.$emit("onSearchGlobal");
    };

    return {
      changeModel,
      model,
      onSearch,
      options,
    };
  },
};
