import UIComponentMixin from "../UIComponentMixin";

import translate from "../../../directives/translate";

import autosize from "autosize";

// @vue/component
export default {
  name: "UiTextarea",
  directives: {
    translate,
  },
  mixins: [
    UIComponentMixin,
  ],
  data() {
    return {
      isAutosizeStarted: false,
    };
  },
  computed: {
    classLocal() {
      return [
        this.getInputClass,
        {
          textarea_scalable: this.options.isScalable,
        }
      ];
    },
  },
  mounted() {
    this.initAutosize();
  },
  beforeUnmount() {
    this.destroyAutosize();
  },
  methods: {
    initAutosize() {
      if (this.options.isScalable) {
        autosize(this.$el);
        this.isAutosizeStarted = true;
      }
    },

    destroyAutosize() {
      if (this.isAutosizeStarted) {
        autosize.destroy(this.$el);
      }
    },

    onInput($event) {
      this.onInput_mixin({ value: $event.target.value, $event });
    },
  },
};
