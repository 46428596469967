import UIComponentMixin from "../../../../../global/components/ui/UIComponentMixin";

// @vue/component
export default {
  name: "UiAmpel",
  mixins: [
    UIComponentMixin,
  ],
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      optionsRed: {
        type: "text",
        id: "rot",
        label: "Rot",
        translate: true,
        view: "v",
        required: true,
      },
      optionsYellow: {
        type: "text",
        id: "gelb",
        label: "Gelb",
        translate: true,
        view: "v",
        required: true,
      },
      optionsGreen: {
        type: "text",
        id: "gruen",
        label: "Gruen",
        translate: true,
        view: "v",
        required: true,
      },
    };
  },
  beforeCreate() {
    this.$options.components = this.$options.components || {};
    this.$options.components.FormElement = require("../../../../../global/components/FormElement/FormElement.vue").default;
  },
  methods: {
    onInput($event) {
      this.onInput_mixin({ value: $event.target.value, $event });
    },
  },
};
