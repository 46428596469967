import {
  computed,
  toRef,
} from "vue";

import {
  map,
} from "lodash-es";

export default function PksAPI(props) {
  const rows = toRef(props, "rows");

  const pks = computed(() => {
    return map(rows.value, "pk");
  });

  return {
    pks,
  };
}
