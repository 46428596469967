import {
  ref,
} from "vue";

const dynamicRoot = ref({});
const dynamicRootLoading = ref(true);

export default function DynamicRootAPI() {
  return {
    dynamicRoot,
    dynamicRootLoading,
  };
}
