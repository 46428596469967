import {
  computed,
  toRef,
} from "vue";

import {
  forEach,
} from "lodash-es";

export default function ActionsAPI(props) {
  const data = toRef(props, "data");

  const dropdownActions = computed(() => {
    const ACTIONS = [];
    forEach(data.value, (item, index) => {
      ACTIONS.push({
        type: "template",
        slotName: `download_item_${ index }`,
        class: "aloha_btn a_dropdown__item",
        item: item,
      });
    });
    return ACTIONS;
  });

  return {
    dropdownActions,
  };
}
