import {
  ref,
} from "vue";

import AButton from "aloha-vue/src/AButton/AButton";
import FormElement from "../../global/components/FormElement/FormElement.vue";
import Modal from "../../global/components/Modal/Modal.vue";
import PuxTranslate from "../../global/components/PuxTranslate/PuxTranslate.vue";

import ANotificationAPI from "aloha-vue/src/compositionAPI/ANotificationAPI";
import InDropdownAPI from "./compositionAPI/InDropdownAPI";
import NotificationAPI from "../../global/compositionAPI/NotificationAPI";

import Cookies from "js-cookie";

const MILLISECONDS_IN_SECOND = 1000;
const ONE_YEAR = 365;

// @vue/component
export default {
  name: "TheAccessibilityButton",
  components: {
    AButton,
    FormElement,
    Modal,
    PuxTranslate,
  },
  props: {
    id: {
      type: String,
      required: false,
      default: "btn_accessibility_modal",
    },
    inDropdown: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const {
      addNotification,
    } = NotificationAPI();

    const {
      setNotificationTimeout,
    } = ANotificationAPI();

    const {
      btnClass,
      btnIcon,
      btnIconClass,
      btnIsTitleHtml,
      btnText,
      classRoot,
      tagRoot,
    } = InDropdownAPI(props);

    const model = ref({
      contrast_button: false,
      pop_up_message_timeout: 5,
    });
    const cookieNames = {
      contrast: "accessibility_contrast",
      popUpMessageTimeout: "accessibility_pop_up_message_timeout",
    };
    const setModelFromCookie = () => {
      model.value = {
        contrast_button: Cookies.get(cookieNames.contrast) === "true",
        pop_up_message_timeout: +(Cookies.get(cookieNames.popUpMessageTimeout) || 5),
      };
    };
    const setCookieContrastButtonActive = () => {
      Cookies.set(cookieNames.contrast, true, { expires: ONE_YEAR });
    };
    const setCookieContrastButtonInactive = () => {
      Cookies.set(cookieNames.contrast, false, { expires: ONE_YEAR });
    };
    const serCookieForPopUpMessageTimeout = () => {
      Cookies.set(cookieNames.popUpMessageTimeout, model.value.pop_up_message_timeout, { expires: ONE_YEAR });
    };

    const classForBody = "contrast_accessibility";
    const addContrast = () => {
      document.body.classList.add(classForBody);
    };
    const removeContrast = () => {
      document.body.classList.remove(classForBody);
    };
    const initContrast = () => {
      if (model.value.contrast_button) {
        addContrast();
      }
    };
    const setContrast = () => {
      if (model.value.contrast_button) {
        addContrast();
        setCookieContrastButtonActive();
      } else {
        removeContrast();
        setCookieContrastButtonInactive();
      }
    };

    const isModalOpen = ref(false);
    const openModal = () => {
      setModelFromCookie();
      isModalOpen.value = true;
    };
    const closeModal = () => {
      isModalOpen.value = false;
    };

    const setPopUpMessageTimeout = () => {
      setNotificationTimeout(model.value.pop_up_message_timeout * MILLISECONDS_IN_SECOND);
    };

    const save = () => {
      setContrast();
      setPopUpMessageTimeout();
      serCookieForPopUpMessageTimeout();

      closeModal();
      addNotification({
        text: "_MSG_ACCESSIBILITY_MODAL_SAVE_SUCCESS_",
      });
    };

    const optionsForContrast = {
      id: "contrast_button",
      type: "boolean",
      view: "v-alt",
      label: "_LBL_ACCESSIBILITY_CONTRAST_",
      trueLabel: "_LBL_ACCESSIBILITY_CONTRAST_TRUE_",
      falseLabel: "_LBL_ACCESSIBILITY_CONTRAST_FALSE_",
      helpText: "_TXT_ACCESSIBILITY_CONTRAST_HELP_TEXT_",
    };
    const optionsForPopUpMessageTimeout = {
      id: "pop_up_message_timeout",
      type: "integer",
      view: "v-alt",
      label: "_LBL_ACCESSIBILITY_POP_UP_MESSAGE_TIMOUT_",
      helpText: "_TXT_ACCESSIBILITY_POP_UP_MESSAGE_TIMOUT_HELP_TEXT_",
      addonBack: "_LBL_SECONDS_",
      nullValue: 0,
    };

    setModelFromCookie();
    initContrast();
    setPopUpMessageTimeout();

    return {
      closeModal,
      classRoot,
      isModalOpen,
      btnIcon,
      btnIconClass,
      btnIsTitleHtml,
      btnText,
      model,
      openModal,
      optionsForContrast,
      optionsForPopUpMessageTimeout,
      save,
      btnClass,
      tagRoot,
    };
  },
};
