import AdminRoutes from "./Admin";
import AngebotRoutes from "./Angebot";
import AntragRoutes from "./Antrag";
import AntragstellerRoutes from "./Antragsteller";
import DefaultRoutes from "./Default";
import DokumenteRoutes from "./Dokumente";
import ErrorRoutes from "./Error";
import FoerderfinderRoutes from "./Foerderfinder";
import FormularRoutes from "./Formular";
import GalerieRoutes from "./Galerie";
import GutachtenRoutes from "./Gutachten";
import GutscheineRoutes from "./Gutscheine";
import ImportauftraegeRoutes from "./Importauftraege";
import Mandanten from "./Mandanten/index";
import NachrichtRoutes from "./Nachricht";
import NutzerRoutes from "./Nutzer";
import RegistrationRoutes from "./Registration";
import SitzungenRouten from "./Sitzungen";
import Test from "./Test";
import Umfragen from "./Umfragen";
import VertragRoutes from "./Vertrag";
import WorkflowRoutes from "./Workflow";

export default [
  ...DefaultRoutes,
  ...RegistrationRoutes,
  ...AntragRoutes,
  ...AntragstellerRoutes,
  ...AdminRoutes,
  ...NachrichtRoutes,
  ...VertragRoutes,
  ...WorkflowRoutes,
  ...NutzerRoutes,
  ...DokumenteRoutes,
  ...AngebotRoutes,
  ...FoerderfinderRoutes,
  ...FormularRoutes,
  ...GalerieRoutes,
  ...GutachtenRoutes,
  ...GutscheineRoutes,
  ...ImportauftraegeRoutes,
  ...SitzungenRouten,
  ...Umfragen,
  ...ErrorRoutes,
  ...Test,
  ...Mandanten,
];
