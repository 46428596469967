import translate from "../../../../directives/translate";

import UIReadOnlyMixin from "../../UIReadOnlyMixin";

import {
  keys,
} from "lodash-es";

// @vue/component
export default {
  name: "UiStrMappingReadOnly",
  directives: {
    translate,
  },
  mixins: [
    UIReadOnlyMixin,
  ],
  computed: {
    translationLabelFrom() {
      return {
        text: "_TXT_UI_STR_MAPPING_INPUT_FROM_LABEL_",
      };
    },

    translationLabelTo() {
      return {
        text: "_TXT_UI_STR_MAPPING_INPUT_TO_LABEL_",
      };
    },

    translationEmpty() {
      return {
        text: "_TXT_UI_STR_MAPPING_EMPTY_",
      };
    },

    orderedKeys() {
      return keys(this.model).sort();
    },
  },
};
