import {
  ref,
} from "vue";

import SyConfigAPI from "../../global/compositionAPI/SyConfigAPI";
import TranslateAPI from "./TranslateAPI";
import GlobalJSAPI from "./GlobalJsAPI";
import UserAPI from "../../global/compositionAPI/UserAPI";

import {
  addStylesInHeader,
} from "../../global/classes/Styles";

export default function LoadingAPI() {
  const loading = ref(true);

  const {
    loadSyConfig,
    getSyConfigsValue,
  } = SyConfigAPI();

  const {
    executeGlobalJsFragment,
  } = GlobalJSAPI();

  const {
    loadTranslate,
  } = TranslateAPI();

  const {
    getUser,
  } = UserAPI();

  const loadAll = () => {
    Promise.all([
      getUser(),
      loadSyConfig(),
      loadTranslate(),
    ]).then(
      () => {
        executeGlobalJsFragment();
        addStylesInHeader({
          config: getSyConfigsValue("styles").config,
        });
        loading.value = false;
      }
    );
  };

  return {
    loadAll,
    loading,
  };
}
