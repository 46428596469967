const AntragstellerDetails = () => import(/* webpackChunkName: "AntragstellerDetails" */ "../../views/Antragsteller/AntragstellerDetails/AntragstellerDetails.vue");
const AntragstellerList = () => import(/* webpackChunkName: "AntragstellerList" */ "../../views/Antragsteller/AntragstellerList/AntragstellerList.vue");
const AntragstellerTraegerwechsel = () => import(/* webpackChunkName: "AntragstellerTraegerwechsel" */ "../../views/Antragsteller/AntragstellerTraegerwechsel/AntragstellerTraegerwechsel.vue");
const FoerderorganisationBearbeitenList = () => import(/* webpackChunkName: "FoerderorganisationBearbeitenList" */ "../../views/Foerderorganisation/FoerderorganisationBearbeitenList/FoerderorganisationBearbeitenList.vue");
const FoerderorganisationList = () => import(/* webpackChunkName: "FoerderorganisationList" */ "../../views/Foerderorganisation/FoerderorganisationList/FoerderorganisationList.vue");
const FoerderorganisationDetails = () => import(/* webpackChunkName: "FoerderorganisationDetails" */ "../../views/Foerderorganisation/FoerderorganisationDetails/FoerderorganisationDetails.vue");
const FoerderorganisationDetailsAnsprechpartner = () => import(/* webpackChunkName: "FoerderorganisationDetailsAnsprechpartner" */ "../../views/Foerderorganisation/FoerderorganisationDetailsAnsprechpartner/FoerderorganisationDetailsAnsprechpartner.vue");
const FoerderorganisationKennenlernenDetails = () => import(/* webpackChunkName: "FoerderorganisationDetails" */ "../../views/Foerderorganisation/FoerderorganisationKennenlernenDetails/FoerderorganisationKennenlernenDetails.vue");

export default [
  {
    path: "/astorganisationen/",
    name: "root.astorganisationen",
    component: AntragstellerList,
    meta: {
      permissions: [
        "antragstellerorg.view",
      ],
    },
  },
  {
    path: "/astorganisationen/:id/",
    name: "root.astorganisationen.details",
    component: AntragstellerDetails,
    meta: {
      permissions: [
        "antragstellerorg.view",
      ],
    },
  },
  {
    path: "/foerderorganisationen/",
    name: "root.foerderorganisationen",
    component: FoerderorganisationBearbeitenList,
    meta: {
      permissions: [
        "foerderorg.view",
      ],
    },
  },
  {
    path: "/foerderorganisationen/:id/",
    name: "root.foerderorganisationen.details",
    component: FoerderorganisationDetails,
    meta: {
      permissions: [
        "foerderorg.view",
      ],
    },
  },
  {
    path: "/foerderorganisationen/:id/ansprechpartner/:ap_id/",
    name: "root.foerderorganisationen.details.ansprechpartner",
    component: FoerderorganisationDetailsAnsprechpartner,
    meta: {
      permissions: [
        "foerderorg.view",
      ],
    },
  },
  {
    path: "/astorganisationen/:id/traegerwechsel/",
    name: "root.astorganisationen.details.traegerwechsel",
    component: AntragstellerTraegerwechsel,
    meta: {
      permissions: [
        "antragstellerorg.traegerwechsel",
      ],
    },
  },
  {
    path: "/foerderorganisationen_kennenlernen/",
    name: "root.foerderorganisationen_kennenlernen",
    component: FoerderorganisationList,
    meta: {
      instance_pages: "foerderorganisation"
    },
  },
  {
    path: "/foerderorganisationen_kennenlernen/:id/",
    name: "root.foerderorganisationen_kennenlernen.details",
    component: FoerderorganisationKennenlernenDetails,
  },
];
