import UiAuswahlKatalogElement from "./UiAuswahlKatalogElement/UiAuswahlKatalogElement.vue";
import PuxCloak from "../../../../../global/components/PuxCloak/PuxCloak.vue";
import UIComponentMixin from "../../../../../global/components/ui/UIComponentMixin";
import translate from "../../../../../global/directives/translate";
import HttpMixin from "../../../../../global/mixins/HttpMixin";
import {
  forEach,
  isNil,
  cloneDeep,
  groupBy,
  keyBy,
} from "lodash-es";

// @vue/component
export default {
  name: "UiAuswahlKatalog",
  components: {
    PuxCloak,
    UiAuswahlKatalogElement,
  },
  directives: {
    translate,
  },
  mixins: [
    UIComponentMixin,
    HttpMixin,
  ],
  props: {
    model: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      default: undefined,
    },
    dependencyValue: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      statusReadonly: false,
      statusLoading: false,
      list: [],
    };
  },
  computed: {
    modelObj() {
      return keyBy(this.model || [], "pk");
    },

    globalObj() {
      const GLOBAL_OBJ = {
        modelIndex: {},
        model: [],
        grouped: {},
      };
      forEach(this.list, item => {
        const CURRENT_PK = item.pk;
        const CURRENT_MODEL = this.modelObj[item.pk] || {};
        const ITEM_FILTERED = {
          freitext: isNil(CURRENT_MODEL.freitext) ? false : CURRENT_MODEL.freitext || false,
          aktiv: isNil(CURRENT_MODEL.aktiv) ? true : CURRENT_MODEL.aktiv || false,
          uebergeordneter_wert: item.uebergeordneter_wert || null,
          pk: CURRENT_PK,
          bez: item.uebergeordneter_wert && item.kaw_kbez ? item.kaw_kbez : item.bez,
        };
        GLOBAL_OBJ.modelIndex[CURRENT_PK] = GLOBAL_OBJ.model.length;
        GLOBAL_OBJ.model.push(cloneDeep(ITEM_FILTERED));
      });
      GLOBAL_OBJ.grouped = groupBy(GLOBAL_OBJ.model, "uebergeordneter_wert");
      return GLOBAL_OBJ;
    },
  },
  watch: {
    dependencyValue: {
      immediate: true,
      handler(newVal) {
        if (!newVal) {
          this.list = [];
          return;
        }
        this.updateList();
      },
    },
  },
  methods: {
    updateList() {
      this.statusLoading = true;
      this.getHttp({
        url: "katalog/",
        urlParams: {
          key: this.dependencyValue,
        },
        apiSaveId: this.dependencyValue,
      }).then(
        response => {
          this.list = response;
          this.setModel();
          this.statusLoading = false;
        }
      );
    },

    setModel() {
      if (!this.statusReadonly) {
        this.onInput_mixin({ value: this.globalObj.model });
      }
    },

    changeModel({ indexModel, currentModel, childElementsModelIndex, parentModelIndex, childElementsModelIndexFromParent }) {
      // eslint-disable-next-line vue/no-mutating-props
      this.model.splice(indexModel, 1, currentModel);
      if (childElementsModelIndex) { // from parent
        if (currentModel.aktiv) {
          forEach(childElementsModelIndex, index => {
            const CURRENT_MODEL = cloneDeep(this.model[index]);
            CURRENT_MODEL.aktiv = true;
            // eslint-disable-next-line vue/no-mutating-props
            this.model.splice(index, 1, CURRENT_MODEL);
          });
        } else {
          forEach(childElementsModelIndex, index => {
            const CURRENT_MODEL = cloneDeep(this.model[index]);
            CURRENT_MODEL.aktiv = false;
            CURRENT_MODEL.freitext = false;
            // eslint-disable-next-line vue/no-mutating-props
            this.model.splice(index, 1, CURRENT_MODEL);
          });
        }
      } else if (parentModelIndex) { // from child
        if (currentModel.aktiv) {
          const PARENT_MODEL = cloneDeep(this.model[parentModelIndex]);
          PARENT_MODEL.aktiv = true;
          // eslint-disable-next-line vue/no-mutating-props
          this.model.splice(parentModelIndex, 1, PARENT_MODEL);
        } else {
          let statusInactive = true;
          forEach(childElementsModelIndexFromParent, index => {
            const CURRENT_MODEL = this.model[index];
            if (CURRENT_MODEL.aktiv) {
              statusInactive = false;
              return false;
            }
          });
          if (statusInactive) {
            const PARENT_MODEL = cloneDeep(this.model[parentModelIndex]);
            PARENT_MODEL.aktiv = false;
            PARENT_MODEL.freitext = false;
            // eslint-disable-next-line vue/no-mutating-props
            this.model.splice(parentModelIndex, 1, PARENT_MODEL);
          }
        }
      }
    },
  },
};
