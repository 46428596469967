import {
  isNil,
} from "lodash-es";

export default function FocusAPI() {
  const setFocusTo = selector => {
    const ELEMENT = document.querySelector(selector);
    if (!ELEMENT) {
      return;
    }
    const HAS_TABINDEX = !isNil(ELEMENT.getAttribute("tabindex"));
    if (HAS_TABINDEX) {
      ELEMENT.focus();
    } else {
      ELEMENT.setAttribute("tabindex", "-1");
      ELEMENT.focus();
      ELEMENT.removeAttribute("tabindex");
    }
  };

  return {
    setFocusTo,
  };
}
