import {
  ref,
} from "vue";

export const isIframe = ref(undefined);

export default function IframeAPI() {
  return {
    isIframe,
  };
}
