import AButton from "aloha-vue/src/AButton/AButton";
import PuxTranslate from "../../../../PuxTranslate/PuxTranslate.vue";

import UiValidatedJsonModeManyMixin from "../../UiValidatedJsonModeManyMixin";

// @vue/component
export default {
  name: "ModeJsonReadOnly",
  components: {
    AButton,
    PuxTranslate,
  },
  mixins: [
    UiValidatedJsonModeManyMixin
  ],
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isReadonly() {
      return true;
    }
  },
};
