import {
  computed,
} from "vue";

import AMobileAPI from "aloha-vue/src/compositionAPI/AMobileAPI";

import {
  forEach,
} from "lodash-es";

export default function MobileAPI({
  configLocalRightChildrenFiltered = computed(() => []),
}) {
  const idForActionsMobile = "navbar_btn_actions";

  const {
    isMobileWidth,
  } = AMobileAPI();

  const componentsFilteredMobile = computed(() => {
    const COMPONENTS = {
      actions: [],
      theMenuUser: undefined,
      loginLink: undefined,
    };

    forEach(configLocalRightChildrenFiltered.value, item => {
      if (item.tag === "the-menu-user") {
        COMPONENTS.theMenuUser = item;
        return;
      }
      if (item.attributes?.to?.name === "root.login") {
        COMPONENTS.loginLink = item;
        return;
      }
      if (item.tag === "a-link") {
        COMPONENTS.actions.push({
          type: "link",
          text: item.text,
          ...item.attributes || {},
        });
      } else if (item.tag === "the-accessibility-button") {
        COMPONENTS.actions.push({
          type: "template",
          slotName: "the-accessibility-button",
        });
      } else if (item.tag === "the-search-global") {
        COMPONENTS.actions.push({
          type: "link",
          text: "_LNK_NAVBAR_MOBILE_SEARCH_",
          to: {
            name: "root.suche",
          },
        });
      }
    });

    return COMPONENTS;
  });

  const isTheMenuUserMobileVisible = computed(() => {
    return !!componentsFilteredMobile.value.theMenuUser;
  });

  const loginLinkMobile = computed(() => {
    return componentsFilteredMobile.value.loginLink;
  });

  const isLoginLinkMobileVisible = computed(() => {
    return !!loginLinkMobile.value;
  });

  const actionsFilteredMobile = computed(() => {
    return componentsFilteredMobile.value.actions;
  });

  const hasActionsMobile = computed(() => {
    return !!actionsFilteredMobile.value.length;
  });

  return {
    actionsFilteredMobile,
    hasActionsMobile,
    idForActionsMobile,
    isLoginLinkMobileVisible,
    isMobileWidth,
    isTheMenuUserMobileVisible,
    loginLinkMobile,
  };
}
