import {
  computed,
  toRef,
} from "vue";

import {
  uiValidatedJsonErrorLabels,
} from "../../../ui/UiValidatedJson/compositionAPI/UiValidatedJsonErrorLabelsAPI";
import {
  uiDynamischeFormDefinitionErrorLabels,
} from "../../../ui/UiDynamischeFormDefinition/compositionAPI/UiDynamischeFormDefinitionErrorLabelsAPI";

import {
  isPlainObject,
  isString,
} from "lodash-es";

export default function LabelAPI(props, {
  idLocal = computed(() => ""),
}) {
  const errorLabels = toRef(props, "errorLabels");
  const shouldReplaceLabel = toRef(props, "shouldReplaceLabel");

  const replacedLabel = computed(() => {
    if (!errorLabels.value._optionsLabel) {
      return errorLabels.value._label;
    }
    if (errorLabels.value._optionsLabel.type === "validated_json") {
      // TODO: funktioniert nicht richtig für untere Ebene
      return uiValidatedJsonErrorLabels.value[idLocal.value];
    }
    if (errorLabels.value._optionsLabel.type === "form_definition") {
      return uiDynamischeFormDefinitionErrorLabels.value[idLocal.value];
    }

    return errorLabels.value._label;
  });

  const currentLabel = computed(() => {
    if (isString(errorLabels.value)) {
      return errorLabels.value;
    } else if (isPlainObject(errorLabels.value)) {
      if (shouldReplaceLabel.value &&
        errorLabels.value._shouldReplaceLabel) {
        return replacedLabel.value;
      }
      return errorLabels.value._label;
    }
    return "";
  });

  return {
    currentLabel,
  };
}
