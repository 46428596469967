import UIComponentMixin from "../../../../../../global/components/ui/UIComponentMixin";

import {
  cloneDeep,
  find,
  isEmpty,
} from "lodash-es";

// @vue/component
export default {
  name: "UiDynamischeTabelleSpaltenAuswahlRadio",
  mixins: [
    UIComponentMixin,
  ],
  props: {
    model: {
      type: [String, null],
      required: true,
    },
    dependencyValue: {
      type: [Array, Object],
      default: undefined,
    },
  },
  data() {
    return {
      modelLocal: cloneDeep(this.model),
    };
  },
  computed: {
    isEmptyList() {
      return isEmpty(this.list);
    },
    list() {
      return this.dependencyValue || [];
    },
  },
  watch: {
    dependencyValue() {
      this.resetModel();
    },
  },
  methods: {
    resetModel() {
      const ITEM = find(this.dependencyValue, ["id", this.model]);
      if (!ITEM) {
        this.modelLocal = undefined;
        this.onInput_mixin({ value: undefined });
      } else {
        this.modelLocal = cloneDeep(this.model) || "";
      }
    },
    toggleModel(id, $event) {
      this.modelLocal === id ? this.modelLocal = "" : this.modelLocal = id;
      this.onInput_mixin({ value: this.modelLocal, $event });
    },
  },
};
