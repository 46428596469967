import {
  ref,
} from "vue";

export const targetRoute = ref(undefined);

export function addErrorListenerForCodeSplitting() {
  window.addEventListener("error", event => {
    if (!event.target?.src ||
      (!event.target.src.includes("chunk.") && !event.target.src.includes("bundle."))) {
      return;
    }

    event.stopPropagation();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (!urlParams.has("try")) {
      let path = "";
      let queryParams = "";
      if (targetRoute.value?.path) {
        path = targetRoute.value.path;
        const urlParamsFromRoute = new URLSearchParams(targetRoute.value.query);
        urlParamsFromRoute.set("try", "1");
        queryParams = urlParamsFromRoute.toString();
      } else {
        path = window.location.pathname;
        urlParams.set("try", "1");
        queryParams = urlParams.toString();
      }
      window.location.href = `${ path }${ queryParams ? `?${ queryParams }` : "" }`;
    }
  }, true);
}
