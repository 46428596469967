import { toFormElementFromParameter } from "../../../../../global/functions/mappingForParameterToFormElement";

export default {
  data() {
    return {
      jsonParam: {
        bez: "_LBL_DYNAMISCHE_TABELLE_PDF_TRANSPOSE_",
        default: "_TXT_DYNAMISCHE_TABELLE_PDF_TRANSPOSE_HELP_TEXT_",
        help_text: null,
        readonly: false,
        options: {
          allow_null: true,
          required: false
        },
        typ: "validated_json",
        many: false,
        as_json: true,
        fields: {
          aktiv: {
            prio: 0,
            bez: "_LBL_DYNAMISCHE_TABELLE_PDF_TRANSPOSE_AKTIV_",
            default: false,
            help_text: "_TXT_DYNAMISCHE_TABELLE_PDF_TRANSPOSE_AKTIV_HELP_TEXT_",
            readonly: false,
            options: {
              allow_null: false,
              required: true
            },
            typ: "boolean"
          },
          element_color: {
            prio: 10,
            bez: "_LBL_DYNAMISCHE_TABELLE_PDF_TRANSPOSE_ELEMENT_COLOR_",
            default: "#e0e0e0",
            help_text: "_TXT_DYNAMISCHE_TABELLE_PDF_TRANSPOSE_ELEMENT_COLOR_HELP_TEXT_",
            readonly: false,
            options: {
              allow_null: false,
              required: true
            },
            typ: "color"
          },
          label_width: {
            prio: 20,
            bez: "_LBL_DYNAMISCHE_TABELLE_PDF_TRANSPOSE_LABEL_WIDTH_",
            default: 30,
            help_text: "_TXT_DYNAMISCHE_TABELLE_PDF_TRANSPOSE_LABEL_WIDTH_HELP_TEXT_",
            readonly: false,
            options: {
              allow_null: false,
              required: true,
              min_value: null,
              max_value: null
            },
            typ: "integer"
          },
          label_alignment: {
            prio: 30,
            bez: "_LBL_DYNAMISCHE_TABELLE_PDF_TRANSPOSE_LABEL_ALIGNMENT_",
            default: "keep",
            help_text: "_TXT_DYNAMISCHE_TABELLE_PDF_TRANSPOSE_LABEL_ALIGNMENT_HELP_TEXT_",
            readonly: false,
            options: {
              allow_null: false,
              required: true
            },
            typ: "single_choice",
            choices: [
              [
                "keep",
                "_LBL_DYNAMISCHE_TABELLE_PDF_TRANSPOSE_ALIGNMENT_BEHALTEN_"
              ],
              [
                "left",
                "_LBL_DYNAMISCHE_TABELLE_PDF_TRANSPOSE_ALIGNMENT_LEFT_"
              ],
              [
                "center",
                "_LBL_DYNAMISCHE_TABELLE_PDF_TRANSPOSE_ALIGNMENT_CENTER_"
              ],
              [
                "right",
                "_LBL_DYNAMISCHE_TABELLE_PDF_TRANSPOSE_ALIGNMENT_RIGHT_"
              ]
            ]
          },
          value_width: {
            prio: 40,
            bez: "_LBL_DYNAMISCHE_TABELLE_PDF_TRANSPOSE_VALUE_WIDTH_",
            default: 70,
            help_text: "_TXT_DYNAMISCHE_TABELLE_PDF_TRANSPOSE_VALUE_WIDTH_HELP_TEXT_",
            readonly: false,
            options: {
              allow_null: false,
              required: true,
              min_value: null,
              max_value: null
            },
            typ: "integer"
          },
          value_alignment: {
            prio: 50,
            bez: "_LBL_DYNAMISCHE_TABELLE_PDF_TRANSPOSE_VALUE_ALIGNMENT_",
            default: "keep",
            help_text: "_TXT_DYNAMISCHE_TABELLE_PDF_TRANSPOSE_VALUE_ALIGNMENT_HELP_TEXT_",
            readonly: false,
            options: {
              allow_null: false,
              required: true
            },
            typ: "single_choice",
            choices: [
              [
                "keep",
                "_LBL_DYNAMISCHE_TABELLE_PDF_TRANSPOSE_ALIGNMENT_BEHALTEN_"
              ],
              [
                "left",
                "_LBL_DYNAMISCHE_TABELLE_PDF_TRANSPOSE_ALIGNMENT_LEFT_"
              ],
              [
                "center",
                "_LBL_DYNAMISCHE_TABELLE_PDF_TRANSPOSE_ALIGNMENT_CENTER_"
              ],
              [
                "right",
                "_LBL_DYNAMISCHE_TABELLE_PDF_TRANSPOSE_ALIGNMENT_RIGHT_"
              ]
            ]
          },
          sum_label: {
            prio: 60,
            bez: "_LBL_DYNAMISCHE_TABELLE_PDF_TRANSPOSE_SUM_LABEL_",
            default: "Summen",
            help_text: "_TXT_DYNAMISCHE_TABELLE_PDF_TRANSPOSE_SUM_LABEL_HELP_TEXT_",
            readonly: false,
            options: {
              allow_null: false,
              required: true,
              max_length: null,
              min_length: null
            },
            typ: "string"
          },
        },
        prio: 5,
        wert: null
      }
    };
  },
  computed: {
    jsonOptions() {
      return toFormElementFromParameter({
        obj: { dimensionen: this.jsonParam },
        showLabel: true,
        staticOptions: {
          required: this.options.required,
          options: this.options.options
        }
      }).list[0];
    }
  }
};
