import {
  h,
} from "vue";

import PuxTranslate from "../../PuxTranslate/PuxTranslate.vue";

import {
  isUndefined,
} from "lodash-es";

// @vue/component
export default {
  name: "UiTemplate",
  components: {
    PuxTranslate,
  },
  inheritAttrs: false,
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  render() {
    if (this.options.slot &&
      this.$slots[this.options.slot]) {
      return this.$slots[this.options.slot]();
    }
    if (!isUndefined(this.options.html)) {
      return h(PuxTranslate, {
        html: this.options.html,
        extra: this.options.extra,
      });
    }
    return this.$slots.default();
  },
};
