const AuszahlungDetails = () => import(/* webpackChunkName: "AuszahlungDetails" */ "../../views/Auszahlung/AuszahlungDetails/AuszahlungDetails.vue");
const AuszahlungList = () => import(/* webpackChunkName: "AuszahlungList" */ "../../views/Auszahlung/AuszahlungList/AuszahlungList.vue");

const AuszahlungslisteDetails = () => import(/* webpackChunkName: "AuszahlungslisteDetails" */ "../../views/Auszahlungsliste/AuszahlungslisteDetails/AuszahlungslisteDetails.vue");
const AuszahlungslisteList = () => import(/* webpackChunkName: "AuszahlungslisteList" */ "../../views/Auszahlungsliste/AuszahlungslisteList/AuszahlungslisteList.vue");
const BelegeList = () => import(/* webpackChunkName: "BelegeList" */ "../../views/Belege/BelegeList/BelegeList.vue");
const VertragDetails = () => import(/* webpackChunkName: "VertragDetails" */ "../../views/Vertrag/VertragDetails/VertragDetails.vue");
const VertragList = () => import(/* webpackChunkName: "VertragList" */ "../../views/Vertrag/VertragList/VertragList.vue");

export default [
  {
    path: "/vertrag/",
    name: "root.vertrag",
    component: VertragList,
    meta: {
      permissions: [
        "vertrag.view",
      ],
    },
  },
  {
    path: "/vertrag/:id/",
    name: "root.vertrag.details",
    component: VertragDetails,
    meta: {
      permissions: [
        "vertrag.view",
      ],
    },
  },
  {
    path: "/vertrag/:id/auszahlung/:aus_id/",
    name: "root.vertrag.details.auszahlung",
    component: AuszahlungDetails,
    meta: {
      permissions: [
        "vertrag.view",
        "auszahlung.view",
      ],
    },
  },
  {
    path: "/auszahlung/",
    name: "root.auszahlung",
    component: AuszahlungList,
    meta: {
      permissions: [
        "auszahlung.view",
      ],
    },
  },
  {
    path: "/vertragsbeleg/",
    name: "root.vertragsbeleg",
    component: BelegeList,
    meta: {
      instance_pages: "vertragsbeleg",
      permissions: [
        "vertragskostenpositionsbelege.view",
      ],
    },
  },
  {
    path: "/auszahlungslisten/",
    name: "root.auszahlungslisten",
    component: AuszahlungslisteList,
    meta: {
      permissions: [
        "vertrag.cortex.auszahlungslisten.ui",
      ],
    },
  },
  {
    path: "/auszahlungslisten/:id/",
    name: "root.auszahlungslisten.details",
    component: AuszahlungslisteDetails,
    meta: {
      permissions: [
        "vertrag.cortex.auszahlungslisten.ui",
      ],
    },
  },
];
