import translate from "../../../../../../global/directives/translate";
import PuxIcon from "../../../../../../global/components/PuxIcon/PuxIcon.vue";
import {
  cloneDeep,
  map,
} from "lodash-es";

// @vue/component
export default {
  name: "UiAuswahlKatalogElement",
  components: {
    PuxIcon,
  },
  directives: {
    translate,
  },
  props: {
    element: {
      type: Object,
      required: true,
    },
    index: {
      type: [String, Number],
      required: true,
    },
    grouped: {
      type: Object,
      required: false,
      default: undefined,
    },
    modelIndex: {
      type: Object,
      required: true,
    },
    model: {
      type: Array,
      required: true,
    },
    parentModelIndex: {
      type: Number,
      required: false,
      default: undefined,
    },
    childElementsModelIndexFromParent: {
      type: Array,
      required: false,
      default: undefined,
    },
    currentModel: {
      type: Object,
      required: true,
    },
    changeModel: {
      type: Function,
      required: true,
    },
    statusReadOnly: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    htmlId() {
      return this.element.pk || this.index;
    },

    idActive() {
      return `${ this.htmlId }_active`;
    },

    idText() {
      return `${ this.htmlId }_freitext`;
    },

    childGroup() {
      return this.grouped && this.grouped[this.element.pk];
    },

    childElementsModelIndex() {
      return map(this.childGroup, child => this.modelIndex[child.pk]);
    },

    statusIsChildGroup() {
      return this.childGroup && this.childGroup.length;
    },

    currentModelIndex() {
      return this.modelIndex[this.element.pk];
    },

    statusChecked() {
      return this.currentModel.aktiv || false;
    },

    statusCheckedText() {
      return this.currentModel.freitext || false;
    },
  },
  methods: {
    clickCheckbox() {
      const MODEL = cloneDeep(this.currentModel);
      MODEL.aktiv = !this.statusChecked;
      if (!MODEL.aktiv) {
        MODEL.freitext = false;
      }
      const ARGUMENTS = {
        indexModel: this.currentModelIndex,
        currentModel: MODEL,
        parentModelIndex: this.parentModelIndex,
        childElementsModelIndexFromParent: this.childElementsModelIndexFromParent,
      };
      if (this.statusIsChildGroup) {
        ARGUMENTS.childElementsModelIndex = this.childElementsModelIndex;
      }
      this.changeModel(ARGUMENTS);
    },

    clickCheckboxText() {
      if (!this.statusChecked) {
        return;
      }
      const MODEL = cloneDeep(this.currentModel);
      MODEL.freitext = !this.statusCheckedText;
      this.changeModel({
        indexModel: this.currentModelIndex,
        currentModel: MODEL,
      });
    },
  },
};
