import {
  computed,
} from "vue";

export default function ContentAPI() {
  const contentStyle = "body{font-weight:300;}";

  const contentLangs = computed(() => {
    return [
      { title: "Deutsch", code: "de" },
      { title: "English", code: "en" },
    ];
  });

  return {
    contentLangs,
    contentStyle,
  };
}
