import translate from "../../../../../../../global/directives/translate";
import {
  isObject,
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "UiDynamischeAdressenReadOnlyElement",
  directives: {
    translate,
  },
  props: {
    modelCurrent: {
      type: [String, Object],
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    last: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    getCurrentValue() {
      let value = "";
      if (isObject(this.modelCurrent)) {
        forEach(this.modelCurrent, (item, key) => {
          value = this.data[key].bez;
        });
      } else {
        value = this.data[this.modelCurrent].bez;
      }
      return value;
    },

    getRequiredText() {
      let required = false;
      if (isObject(this.modelCurrent)) {
        forEach(this.modelCurrent, item => {
          required = item.required;
        });
      }
      return required ? "Pflicht" : "nicht Pflicht";
    },
  },
};
